<template>
    <div id="main-wrapper">
        <Spinner v-if="loading" />
        <!-- Header -->
        <header class="topbar">
            <Navbar :stop="stopnav" />
        </header>

        <!-- Sidebar -->
        <aside class="left-sidebar">
            <div class="scroll-sidebar">
                <Sidebar @rank="watchRank" />
            </div>
        </aside>

        <!-- Page Wrapper -->
        <div class="page-wrapper mb-3" :class="showRank ? 'opacity-25' : ''">
            <div class="container-fluid">
                <!-- Title -->
                <div class="row mt-5 pb-0 pb-sm-5">
                    <div>
                        <h2 class="main-title">
                            {{ $t("p-conf") }}
                            <span class="payout-id">
                                {{ $t("Payout") }} ID:
                                {{ selectedSinglePayout?.id }}
                            </span>
                        </h2>
                    </div>
                </div>

                <div class="row" :class=" selectedSinglePayout.paymentStatus == 'waiting_data_confirmation' ? 'stepper-container-custom' : '' ">
                    <Stepper :steps="steps" :currentStep="currentStep" @step-changed="goToStep" />
                </div>
                <div class="step-content">
                    <div v-if=" selectedSinglePayout.paymentStatus == 'waiting_data_confirmation' && selectedSinglePayout.status == 'confirmed' ">
                        <div class="col-12 evidence-box">
                            <div class="payment_confirmation_name">
                                <p> {{ $t("dear") }} {{ selectedSinglePayout?.uuid }} {{ $t("pleased-to-confirm") }}
                                    <span class="amount_text">
                                        {{ $formatAmount( selectedSinglePayout?.payout )}}
                                    </span>
                                </p>
                                <p class="secondary_text_amount">
                                    {{ $t("please-confirm-payout") }}
                                </p>
                            </div>
                        </div>
                        <div class="col-sm-6 col-12">
                            <h2 class="paym-method">{{ $t("paym-meth") }}</h2>
                            <h5 class="edit_confirm">
                                {{ $t("edit-conf-paym-meth") }}
                            </h5>
                            <div class="evidance-box-payment" :class="{expanded: selectedPaymentMethod !== null, }">
                                <div class="payment-method-options">
                                    <!-- Payment Methods -->
                                     <template v-for="(method, index ) in paymentMethods" :key="index">
                                        <div v-if="selectedSinglePayout.payout < rangePayout" v-show="shouldShowSection(method)"  class="payment-option" @click="selectPaymentMethod(index)" :class="{ active: selectedPaymentMethod === index, }" >
                                            <!-- Top Section: Checkbox and Method Name -->
                                            <div class="top-section">
                                                <div class="round row">
                                                    <input type="checkbox" :checked=" selectedPaymentMethod === index " :id="'checkbox-' + index" class="payment-checkbox" />
                                                    <label :for="'checkbox-' + index"></label>
                                                </div>
                                                <span class="checkbox-span">{{ method }}</span>
                                                <div class="confirmed-payment-method" v-if=" (selectedPaymentMethod === index && isPaymentConfirmed) || selectedPaymentMethod === null ">
                                                    <font-awesome-icon class="icon-card" v-show=" method === 'Bank Transfer' && bankDetails.iban " icon="fa fa-credit-card"/>
                                                    <span v-show=" method === 'Bank Transfer' && bankDetails.iban ">
                                                        {{ bankDetails.iban.slice( 0, 4)}}
                                                        <img class="payment-icon-iban image" src="../../../public/assets/icons/ibanPoints.svg" alt="Payment Method"/>
                                                        {{ bankDetails.iban.slice( -4 ) }}
                                                    </span>
                                                    <span v-show=" method === 'Crypto' && cryptoDetails.walletAddress ">
                                                        {{ $t("wallet-adr") }}:
                                                        {{ cryptoDetails.walletAddress }}
                                                    </span>
                                                </div>
                                                <button v-if=" (selectedPaymentMethod === index && isPaymentConfirmed) || (selectedPaymentMethod === null && method === 'Bank Transfer' && bankDetails.iban) || (selectedPaymentMethod ===  null &&  method === 'Crypto' && cryptoDetails.walletAddress)"
                                                    @click="editPaymentMethod"
                                                    class="btn btn-link edit-button">
                                                    {{ $t("Edit") }}
                                                </button>
                                            </div>

                                            <!-- Expandable Details -->
                                            <div v-if=" selectedPaymentMethod === index " >
                                                <template v-if="!isPaymentConfirmed" class="expanded" >
                                                    <!-- Bank Transfer Fields -->
                                                    <template
                                                        v-if=" method === 'Bank Transfer'">
                                                        <div class="form-group-row">
                                                            <div
                                                                class="form-group col-sm-6 col-12"
                                                            >
                                                                <label for="name">{{
                                                                    $t(
                                                                        "checkout-name"
                                                                    )
                                                                }}</label>
                                                                <input
                                                                    type="text"
                                                                    id="name"
                                                                    v-model="
                                                                        bankDetails.firstName
                                                                    "
                                                                    :class="{
                                                                        'is-invalid':
                                                                            isNameError,
                                                                    }"
                                                                />
                                                                <div
                                                                    v-if="
                                                                        isNameError &&
                                                                        errors.firstName
                                                                    "
                                                                    class="error-message"
                                                                >
                                                                    {{
                                                                        errors.firstName
                                                                    }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="form-group col-sm-6 col-12"
                                                            >
                                                                <label
                                                                    for="last-name"
                                                                    >{{
                                                                        $t(
                                                                            "checkout-lastName"
                                                                        )
                                                                    }}</label
                                                                >
                                                                <input
                                                                    type="text"
                                                                    id="last-name"
                                                                    v-model="
                                                                        bankDetails.lastName
                                                                    "
                                                                    :class="{
                                                                        'is-invalid':
                                                                            isLastNameError,
                                                                    }"
                                                                />
                                                                <div
                                                                    v-if="
                                                                        isLastNameError &&
                                                                        errors.lastName
                                                                    "
                                                                    class="error-message"
                                                                >
                                                                    {{
                                                                        errors.lastName
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group-row">
                                                            <div
                                                                class="form-group col-sm-12 col-12 wrapped"
                                                            >
                                                                <label
                                                                    for="address"
                                                                    >{{
                                                                        $t(
                                                                            "checkout-address"
                                                                        )
                                                                    }}</label
                                                                >
                                                                <input
                                                                    type="text"
                                                                    autocomplete="off"
                                                                    id="address"
                                                                    ref="bankDetailsAddressInputRef"
                                                                    v-model="
                                                                        bankDetails.address
                                                                    "
                                                                    :class="{
                                                                        'is-invalid':
                                                                            isAddressError,
                                                                    }"
                                                                />
                                                                <div
                                                                    v-if="
                                                                        isAddressError &&
                                                                        errors.address
                                                                    "
                                                                    class="error-message"
                                                                >
                                                                    {{
                                                                        errors.address
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group-row">
                                                            <div
                                                                class="form-group col-sm-8 col-12"
                                                            >
                                                                <label for="iban">{{
                                                                    $t("Bank-N")
                                                                }}</label>
                                                                <input
                                                                    type="text"
                                                                    id="iban"
                                                                    v-model="
                                                                        bankDetails.iban
                                                                    "
                                                                    :class="{
                                                                        'is-invalid':
                                                                            isIBANError,
                                                                    }"
                                                                />
                                                                <div
                                                                    v-if="
                                                                        isIBANError &&
                                                                        errors.iban
                                                                    "
                                                                    class="error-message"
                                                                >
                                                                    {{
                                                                        errors.iban
                                                                    }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="form-group col-sm-4 col-12"
                                                            >
                                                                <label
                                                                    for="swift"
                                                                    >{{
                                                                        $t(
                                                                            "Bank-Swift"
                                                                        )
                                                                    }}</label
                                                                >
                                                                <input
                                                                    type="text"
                                                                    id="swift"
                                                                    v-model="
                                                                        bankDetails.swift
                                                                    "
                                                                    :class="{
                                                                        'is-invalid':
                                                                            isSWIFTError,
                                                                    }"
                                                                />
                                                                <div
                                                                    v-if="
                                                                        isSWIFTError &&
                                                                        errors.swift
                                                                    "
                                                                    class="error-message"
                                                                >
                                                                    {{
                                                                        errors.swift
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>

                                                    <!-- Crypto Fields -->
                                                    <template
                                                        v-else-if="
                                                            method === 'Crypto'
                                                        "
                                                    >
                                                        <div class="form-group">
                                                            <div class="form-group">
                                                                <label for="chain"
                                                                    >Chain</label
                                                                >
                                                                <select v-model="cryptoDetails.chain" id="chain" :class="{ 'is-invalid': ischainError, }">
                                                                    <option disabled selected>--</option>
                                                                    <option value="ERC20">ERC20</option>
                                                                    <option value="Polygon">Polygon</option>
                                                                </select>

                                                                <div
                                                                    v-if="
                                                                        ischainError &&
                                                                        errors.chain
                                                                    "
                                                                    class="error-message"
                                                                >
                                                                    {{
                                                                        errors.chain
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div class="form-group">
                                                                <label
                                                                    for="wallet-address"
                                                                    >{{
                                                                        $t(
                                                                            "wallet-adr"
                                                                        )
                                                                    }}</label
                                                                >
                                                                <input
                                                                    type="text"
                                                                    id="wallet-address"
                                                                    v-model="
                                                                        cryptoDetails.walletAddress
                                                                    "
                                                                    :class="{
                                                                        'is-invalid':
                                                                            isWalletAddressError,
                                                                    }"
                                                                />
                                                                <div
                                                                    v-if="
                                                                        isWalletAddressError &&
                                                                        errors.walletAddress
                                                                    "
                                                                    class="error-message"
                                                                >
                                                                    {{
                                                                        errors.walletAddress
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>

                                                    <template
                                                        v-else-if="
                                                            method === 'Rise'
                                                        "
                                                    >
                                                        <div class="form-group">
                                                            <div class="form-group">
                                                                <label
                                                                    for="rise-email"
                                                                    >Email
                                                                    </label
                                                                >
                                                                <input
                                                                    type="email"
                                                                    id="rise-email"
                                                                    v-model="
                                                                        riseDetails.email
                                                                    "
                                                                />
                                                                <div
                                                                    v-if="
                                                                        isRiseError &&
                                                                        errors.email
                                                                    "
                                                                    class="error-message"
                                                                >
                                                                    {{
                                                                        errors.email
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>

                                                    <!-- Confirm Button -->
                                                    <div
                                                        class="col-12 d-flex justify-content-end pr-8"
                                                    >
                                                        <button
                                                            class="btn btn-primary btn-payment-method"
                                                            @click="
                                                                confirmPaymentMethod
                                                            "
                                                        >
                                                            {{ $t("conf") }}
                                                        </button>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                        <div v-if="selectedSinglePayout.payout > rangePayout" v-show="!shouldShowSection(method)"  class="payment-option" @click="selectPaymentMethod(index)" :class="{ active: selectedPaymentMethod === index, }" >
                                            <!-- Top Section: Checkbox and Method Name -->
                                            <div class="top-section">
                                                <div class="round row">
                                                    <input type="checkbox" :checked=" selectedPaymentMethod === index " :id="'checkbox-' + index" class="payment-checkbox" />
                                                    <label :for="'checkbox-' + index"></label>
                                                </div>
                                                <span class="checkbox-span">{{ method }}</span>
                                                <div class="confirmed-payment-method" v-if=" (selectedPaymentMethod === index && isPaymentConfirmed) || selectedPaymentMethod === null ">
                                                    <font-awesome-icon class="icon-card" v-show=" method === 'Bank Transfer' && bankDetails.iban " icon="fa fa-credit-card"/>
                                                    <span v-show=" method === 'Bank Transfer' && bankDetails.iban ">
                                                        {{ bankDetails.iban.slice( 0, 4)}}
                                                        <img class="payment-icon-iban image" src="../../../public/assets/icons/ibanPoints.svg" alt="Payment Method"/>
                                                        {{ bankDetails.iban.slice( -4 ) }}
                                                    </span>
                                                    <span v-show=" method === 'Crypto' && cryptoDetails.walletAddress ">
                                                        {{ $t("wallet-adr") }}:
                                                        {{ cryptoDetails.walletAddress }}
                                                    </span>
                                                </div>
                                                <button v-if=" (selectedPaymentMethod === index && isPaymentConfirmed) || (selectedPaymentMethod === null && method === 'Bank Transfer' && bankDetails.iban) || (selectedPaymentMethod ===  null &&  method === 'Crypto' && cryptoDetails.walletAddress)"
                                                    @click="editPaymentMethod"
                                                    class="btn btn-link edit-button">
                                                    {{ $t("Edit") }}
                                                </button>
                                            </div>

                                            <!-- Expandable Details -->
                                            <div v-if=" selectedPaymentMethod === index " >
                                                <template v-if="!isPaymentConfirmed" class="expanded" >
                                                    <!-- Bank Transfer Fields -->
                                                    <template
                                                        v-if=" method === 'Bank Transfer'">
                                                        <div class="form-group-row">
                                                            <div
                                                                class="form-group col-sm-6 col-12"
                                                            >
                                                                <label for="name">{{
                                                                    $t(
                                                                        "checkout-name"
                                                                    )
                                                                }}</label>
                                                                <input
                                                                    type="text"
                                                                    id="name"
                                                                    v-model="
                                                                        bankDetails.firstName
                                                                    "
                                                                    :class="{
                                                                        'is-invalid':
                                                                            isNameError,
                                                                    }"
                                                                />
                                                                <div
                                                                    v-if="
                                                                        isNameError &&
                                                                        errors.firstName
                                                                    "
                                                                    class="error-message"
                                                                >
                                                                    {{
                                                                        errors.firstName
                                                                    }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="form-group col-sm-6 col-12"
                                                            >
                                                                <label
                                                                    for="last-name"
                                                                    >{{
                                                                        $t(
                                                                            "checkout-lastName"
                                                                        )
                                                                    }}</label
                                                                >
                                                                <input
                                                                    type="text"
                                                                    id="last-name"
                                                                    v-model="
                                                                        bankDetails.lastName
                                                                    "
                                                                    :class="{
                                                                        'is-invalid':
                                                                            isLastNameError,
                                                                    }"
                                                                />
                                                                <div
                                                                    v-if="
                                                                        isLastNameError &&
                                                                        errors.lastName
                                                                    "
                                                                    class="error-message"
                                                                >
                                                                    {{
                                                                        errors.lastName
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group-row">
                                                            <div
                                                                class="form-group col-sm-12 col-12 wrapped"
                                                            >
                                                                <label
                                                                    for="address"
                                                                    >{{
                                                                        $t(
                                                                            "checkout-address"
                                                                        )
                                                                    }}</label
                                                                >
                                                                <input
                                                                    type="text"
                                                                    autocomplete="off"
                                                                    id="address"
                                                                    ref="bankDetailsAddressInputRef"
                                                                    v-model="
                                                                        bankDetails.address
                                                                    "
                                                                    :class="{
                                                                        'is-invalid':
                                                                            isAddressError,
                                                                    }"
                                                                />
                                                                <div
                                                                    v-if="
                                                                        isAddressError &&
                                                                        errors.address
                                                                    "
                                                                    class="error-message"
                                                                >
                                                                    {{
                                                                        errors.address
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group-row">
                                                            <div
                                                                class="form-group col-sm-8 col-12"
                                                            >
                                                                <label for="iban">{{
                                                                    $t("Bank-N")
                                                                }}</label>
                                                                <input
                                                                    type="text"
                                                                    id="iban"
                                                                    v-model="
                                                                        bankDetails.iban
                                                                    "
                                                                    :class="{
                                                                        'is-invalid':
                                                                            isIBANError,
                                                                    }"
                                                                />
                                                                <div
                                                                    v-if="
                                                                        isIBANError &&
                                                                        errors.iban
                                                                    "
                                                                    class="error-message"
                                                                >
                                                                    {{
                                                                        errors.iban
                                                                    }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="form-group col-sm-4 col-12"
                                                            >
                                                                <label
                                                                    for="swift"
                                                                    >{{
                                                                        $t(
                                                                            "Bank-Swift"
                                                                        )
                                                                    }}</label
                                                                >
                                                                <input
                                                                    type="text"
                                                                    id="swift"
                                                                    v-model="
                                                                        bankDetails.swift
                                                                    "
                                                                    :class="{
                                                                        'is-invalid':
                                                                            isSWIFTError,
                                                                    }"
                                                                />
                                                                <div
                                                                    v-if="
                                                                        isSWIFTError &&
                                                                        errors.swift
                                                                    "
                                                                    class="error-message"
                                                                >
                                                                    {{
                                                                        errors.swift
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>

                                                    <!-- Crypto Fields -->
                                                    <template
                                                        v-else-if="
                                                            method === 'Crypto'
                                                        "
                                                    >
                                                        <div class="form-group">
                                                            <div class="form-group">
                                                                <label for="chain"
                                                                    >Chain</label
                                                                >
                                                                <input
                                                                    type="text"
                                                                    id="chain"
                                                                    v-model="
                                                                        cryptoDetails.chain
                                                                    "
                                                                    :class="{
                                                                        'is-invalid':
                                                                            ischainError,
                                                                    }"
                                                                />

                                                                <div
                                                                    v-if="
                                                                        ischainError &&
                                                                        errors.chain
                                                                    "
                                                                    class="error-message"
                                                                >
                                                                    {{
                                                                        errors.chain
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div class="form-group">
                                                                <label
                                                                    for="wallet-address"
                                                                    >{{
                                                                        $t(
                                                                            "wallet-adr"
                                                                        )
                                                                    }}</label
                                                                >
                                                                <input
                                                                    type="text"
                                                                    id="wallet-address"
                                                                    v-model="
                                                                        cryptoDetails.walletAddress
                                                                    "
                                                                    :class="{
                                                                        'is-invalid':
                                                                            isWalletAddressError,
                                                                    }"
                                                                />
                                                                <div
                                                                    v-if="
                                                                        isWalletAddressError &&
                                                                        errors.walletAddress
                                                                    "
                                                                    class="error-message"
                                                                >
                                                                    {{
                                                                        errors.walletAddress
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>

                                                    <template
                                                        v-else-if="
                                                            method === 'Rise'
                                                        "
                                                    >
                                                        <div class="form-group">
                                                            <div class="form-group">
                                                                <label
                                                                    for="rise-email"
                                                                    >Email
                                                                    </label
                                                                >
                                                                <input
                                                                    type="email"
                                                                    id="rise-email"
                                                                    v-model="
                                                                        riseDetails.email
                                                                    "
                                                                />
                                                                <div
                                                                    v-if="
                                                                        isRiseError &&
                                                                        errors.email
                                                                    "
                                                                    class="error-message"
                                                                >
                                                                    {{
                                                                        errors.email
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>

                                                    <!-- Confirm Button -->
                                                    <div
                                                        class="col-12 d-flex justify-content-end pr-8"
                                                    >
                                                        <button
                                                            class="btn btn-primary btn-payment-method"
                                                            @click="
                                                                confirmPaymentMethod
                                                            "
                                                        >
                                                            {{ $t("conf") }}
                                                        </button>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                     </template>
                                </div>
                            </div>

                            <!-- Confirm Button -->
                            <div class="row">
                                <div class="col-12 d-flex justify-content-end">
                                    <div class="button-container">
                                        <button
                                            class="btn btn-primary choose-payment"
                                            :class="{
                                                expanded:
                                                    selectedPaymentMethod ===
                                                    null,
                                            }"
                                            :disabled="!isPaymentConfirmed"
                                            @click="nextStep"
                                            v-if="
                                                selectedPaymentMethod !== null
                                            "
                                        >
                                            {{ $t("conf") }}
                                        </button>
                                        <button
                                            class="btn btn-primary choose-payment"
                                            :class="{
                                                expanded:
                                                    selectedPaymentMethod !==
                                                    null,
                                            }"
                                            :disabled="!isPaymentConfirmed"
                                            @click="nextStep"
                                            v-else-if="!selectedPaymentMethod"
                                        >
                                            {{ $t("conf") }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="
                            selectedSinglePayout &&
                            selectedSinglePayout?.status === 'confirmed' &&
                            selectedSinglePayout.paymentStatus ==
                                'data_confirmed'
                        "
                        class="row evidense-box-step-3"
                    >
                        <div>
                            <img
                                style="margin-top: 64px"
                                src="../../../public/assets/icons/attention.svg"
                                alt="Payment Method"
                            />
                            <h3 class="awaiting_payment">
                                {{ $t("await-payment") }}
                            </h3>
                            <h5 class="confirmed-text">
                                {{ $t("awaiting-secondary-text") }}
                            </h5>
                        </div>
                        <div class="parent-container">
                            <table class="success-table">
                                <tbody>
                                    <tr>
                                        <td>{{ $t("Payout") }} ID</td>
                                        <td>
                                            {{ selectedSinglePayout?.id }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t("Amount") }}</td>
                                        <td>
                                            {{
                                                $formatAmount(
                                                    selectedSinglePayout?.payout
                                                )
                                            }}
                                        </td>
                                    </tr>
                                    <tr
                                        v-if="
                                            selectedSinglePayout?.paymentMethod
                                                ?.iban
                                        "
                                    >
                                        <td>{{ $t("paym-meth") }}</td>
                                        <td>
                                            {{ $t("bank-transf") }} -
                                            {{
                                                selectedSinglePayout
                                                    ?.paymentMethod?.iban
                                                    ? `****${selectedSinglePayout?.paymentMethod?.iban
                                                          .toString()
                                                          .slice(-4)}`
                                                    : "Not available"
                                            }}
                                        </td>
                                    </tr>

                                    <!-- Crypto Row -->
                                    <tr
                                        v-if="
                                            selectedSinglePayout?.paymentMethod
                                                ?.walletAddress
                                        "
                                    >
                                        <td>{{ $t("paym-meth") }}</td>
                                        <td>
                                            {{ $t("crypto-wallet") }} -
                                            {{
                                                selectedSinglePayout
                                                    ?.paymentMethod
                                                    ?.walletAddress
                                            }}
                                        </td>
                                    </tr>

                                    <tr
                                        v-if="
                                            selectedSinglePayout?.paymentMethod
                                                ?.email
                                        "
                                    >
                                        <td>{{ $t("paym-meth") }}</td>
                                        <td>
                                            Email -
                                            {{
                                                selectedSinglePayout
                                                    ?.paymentMethod
                                                    ?.email
                                            }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t("subm-date") }}</td>
                                        <td>
                                            {{ formattedSubmissionDate }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div
                        v-if="
                            selectedSinglePayout &&
                            selectedSinglePayout.status === 'delayed'
                        "
                        class="row evidense-box-step-3"
                    >
                        <div>
                            <img
                                style="margin-top: 64px"
                                src="../../../public/assets/icons/delayed.svg"
                                alt="Payment Method"
                            />
                            <h3 class="awaiting_payment">
                                {{ $t("delay-paym") }}
                            </h3>
                            <h5 class="confirmed-text">
                                {{ selectedSinglePayout?.agentNotes }}
                            </h5>
                        </div>
                        <div class="parent-container">
                            <table class="success-table">
                                <tbody>
                                    <tr>
                                        <td>{{ $t("Payout") }} ID</td>
                                        <td>
                                            {{ selectedSinglePayout?.id }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t("Amount") }}</td>
                                        <td>
                                            {{
                                                $formatAmount(
                                                    selectedSinglePayout?.payout
                                                )
                                            }}
                                        </td>
                                    </tr>
                                    <tr
                                        v-if="
                                            selectedSinglePayout?.paymentMethod
                                                ?.iban
                                        "
                                    >
                                        <td>{{ $t("paym-meth") }}</td>
                                        <td>
                                            {{ $t("bank-transf") }} -
                                            {{
                                                selectedSinglePayout
                                                    ?.paymentMethod?.iban
                                                    ? `****${selectedSinglePayout?.paymentMethod?.iban
                                                          .toString()
                                                          .slice(-4)}`
                                                    : "Not available"
                                            }}
                                        </td>
                                    </tr>

                                    <!-- Crypto Row -->
                                    <tr
                                        v-if="
                                            selectedSinglePayout?.paymentMethod
                                                ?.walletAddress
                                        "
                                    >
                                        <td>{{ $t("paym-meth") }}</td>
                                        <td>
                                            {{ $t("crypto-wallet") }} -
                                            {{
                                                selectedSinglePayout
                                                    ?.paymentMethod
                                                    ?.walletAddress
                                            }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t("subm-date") }}</td>
                                        <td>
                                            {{ formattedSubmissionDate }}
                                        </td>
                                    </tr>

                                    <tr
                                        v-if="
                                            selectedSinglePayout?.paymentMethod
                                                ?.email
                                        "
                                    >
                                        <td>{{ $t("paym-meth") }}</td>
                                        <td>
                                            Email -
                                            {{
                                                selectedSinglePayout
                                                    ?.paymentMethod
                                                    ?.email
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div
                        v-if="
                            selectedSinglePayout &&
                            selectedSinglePayout.status === 'cancelled'
                        "
                        class="row evidense-box-step-3"
                    >
                        <div>
                            <img
                                style="margin-top: 64px"
                                src="../../../public/assets/icons/cancelled.svg"
                                alt="Payment Method"
                            />
                            <h3
                                class="awaiting_payment"
                                style="text-transform: capitalize"
                            >
                                {{ $t("requestCancelled") }}
                            </h3>
                            <h5 class="confirmed-text">
                                {{ selectedSinglePayout?.agentNotes }}
                            </h5>
                        </div>
                        <div class="parent-container">
                            <table class="success-table">
                                <tbody>
                                    <tr>
                                        <td>{{ $t("Payout") }} ID</td>
                                        <td>
                                            {{ selectedSinglePayout?.id }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t("Amount") }}</td>
                                        <td>
                                            {{
                                                $formatAmount(
                                                    selectedSinglePayout?.payout
                                                )
                                            }}
                                        </td>
                                    </tr>
                                    <tr
                                        v-if="
                                            selectedSinglePayout?.paymentMethod
                                                ?.iban
                                        "
                                    >
                                        <td>{{ $t("paym-meth") }}</td>
                                        <td>
                                            {{ $t("bank-transf") }} -
                                            {{
                                                selectedSinglePayout
                                                    ?.paymentMethod?.iban
                                                    ? `****${selectedSinglePayout?.paymentMethod?.iban
                                                          .toString()
                                                          .slice(-4)}`
                                                    : "Not available"
                                            }}
                                        </td>
                                    </tr>

                                    <!-- Crypto Row -->
                                    <tr
                                        v-if="
                                            selectedSinglePayout?.paymentMethod
                                                ?.walletAddress
                                        "
                                    >
                                        <td>{{ $t("paym-meth") }}</td>
                                        <td>
                                            {{ $t("crypto-wallet") }} -
                                            {{
                                                selectedSinglePayout
                                                    ?.paymentMethod
                                                    ?.walletAddress
                                            }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t("subm-date") }}</td>
                                        <td>
                                            {{ formattedSubmissionDate }}
                                        </td>
                                    </tr>

                                    <tr
                                        v-if="
                                            selectedSinglePayout?.paymentMethod
                                                ?.email
                                        "
                                    >
                                        <td>{{ $t("paym-meth") }}</td>
                                        <td>
                                            Email -
                                            {{
                                                selectedSinglePayout
                                                    ?.paymentMethod
                                                    ?.email
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div
                        v-if="
                            selectedSinglePayout &&
                            selectedSinglePayout.status === 'completed' &&
                            selectedSinglePayout.paymentStatus == 'paid'
                        "
                        class="row evidense-box-step-3"
                    >
                        <div>
                            <img
                                style="margin-top: 64px"
                                src="../../../public/assets/icons/success.svg"
                            />
                            <h1 class="awaiting_payment">
                                {{ $t("congrats") }}!
                            </h1>
                            <h3 style="line-height: 35.25px; font-size: 16px">
                                {{ $t("payment-proceesed") }}
                            </h3>
                            <h5 class="secondary-text-success">
                                {{ $t("amount-of") }}
                                <span class="success-payment-detail">{{
                                    $formatAmount(selectedSinglePayout?.payout)
                                }}</span>
                                {{ $t("transfered-to.bank-acc") }}
                                <span
                                    v-if="
                                        selectedSinglePayout?.paymentMethod
                                            ?.iban
                                    "
                                    class="success-payment-detail"
                                >
                                    {{ $t("bank-transf-ed") }} -
                                    {{
                                        selectedSinglePayout?.paymentMethod
                                            ?.iban
                                            ? `****${selectedSinglePayout?.paymentMethod?.iban
                                                  .toString()
                                                  .slice(-4)}`
                                            : "Not available"
                                    }}
                                </span>

                                <!-- Crypto Row -->
                                <span
                                    v-if="
                                        selectedSinglePayout?.paymentMethod
                                            ?.walletAddress
                                    "
                                >
                                    {{ $t("crypto-wallet") }} -
                                    {{
                                        selectedSinglePayout?.paymentMethod
                                            ?.walletAddress
                                    }} </span
                                >.
                            </h5>

                            <div class="download" @click="downloadPaymentFile">
                                <div class="download-icon">
                                    <img
                                        src="../../../public/assets/icons/download.svg"
                                    />
                                </div>
                                <h5>{{ $t("download-paym-file") }}</h5>
                            </div>
                            <h6 class="secondary-text-success">
                                {{ $t("contact-support") }}
                            </h6>
                            <a
                                class="mail"
                                href="mailto:support@ofpfunding.com?subject=Request for Assistance&body=Dear Support Team,"
                                >support@ofpfunding.com</a
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Footer -->
        <Footer />
    </div>
</template>

<script>
import Sidebar from "../components/Sidebar.vue";
import Navbar from "../components/statics/navbar.vue";
import Footer from "../components/statics/footer.vue";
import Spinner from "../components/Spinner.vue";
import { usePayoutStore } from "../store/payout.store";
import Stepper from "../components/Stepper.vue";
import moment from "moment";
import { reactive } from "vue";
import {debounce} from "lodash";
import {Cancel} from "mdue";

export default {
    name: "PayoutConfirmation",
    components: {
        Sidebar,
        Navbar,
        Footer,
        Stepper,
        Spinner,
    },
    data() {
        return {
            payoutId: this.$route.params.payoutId,
            selectedPayout: null,
            selectedSinglePayout: null,
            selectedUser: null,
            steps: [
                { name: "Payout Confirmed" },
                { name: "Confirm Data" },
                { name: "Awaiting Payment" },
                { name: "Payment Completed" },
            ],
            paymentMethods: ["Bank Transfer", "Crypto", "Rise"],
            selectedPaymentMethod: null,
            paymentMethodsCryptoBank: null,
            currentStep: 1,
            bankDetails: reactive({
                bankName: "",
                firstName: "",
                lastName: "",
                iban: "",
                swift: "",
                address: "",
                createdAt: "",
            }),
            cryptoDetails: reactive({
                walletAddress: "",
                chain: "",
            }),
            riseDetails: reactive({
                email : ''
            }),
            rangePayout: 500,
            isPaymentConfirmed: false,
            isNameError: false,
            isBankNameError: false,
            isIBANError: false,
            isSWIFTError: false,
            isAddressError: false,
            isWalletAddressError: false,
            ischainError: false,
            isRiseError: false,
            isLastNameError: false,
            errors: {
                iban: "",
                swift: "",
                walletAddress: "",
                chain: "",
                address: "",
                firstName: "",
                lastName: "",
                email:""
            },
            hasErrors: false,
            isFormValid: false,
            method: "",
            payload: {
                account_id: "",
                uuid: "",
                payout: null,
                request_payout_id: null,
                paymentMethod: {
                    firstName: "",
                    lastName: "",
                    iban: "",
                    swift: "",
                    sortCode: "",
                    routingNumber: "",
                    address: "",
                    chain: "",
                    walletAddress: "",
                },
            },
            abortCtrl: new AbortController(),
        };
    },

    created() {
        this.restoreState();
        this.fetchPaymentData();
        this.fetchSinglePayoutData();
    },
    computed: {
        formattedSubmissionDate() {
            return moment(this.selectedSinglePayout?.created_at).format(
                "DD-MM-YYYY HH:mm"
            );
        },
        currentStep() {
            const payoutStore = usePayoutStore();
            return payoutStore.getCurrentStep;
        },
    },
    methods: {

        shouldShowSection(method) {
           if(method == 'Bank Transfer' || method == 'Crypto'){
                return true
           }
        },
        goToStep(step) {
            const payoutStore = usePayoutStore();
            payoutStore.setStep(step);
        },
        async downloadPaymentFile() {
            const payoutStore = usePayoutStore();
            payoutStore.downloadFilePayout(this.selectedSinglePayout?._id);
        },
        setSubmissionDate() {
            const currentDate = new Date();
            this.submissionDate = currentDate;
        },
        //IBAN validation
        validateIBAN(iban) {
            const ibanRegex = /^([A-Z]{2})\d{2}[A-Z0-9]{4,30}$/;
            return ibanRegex.test(iban);
        },
        validateEmail(email) {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            return emailRegex.test(email);
        },
        confirmPaymentMethod() {
            // Resetting error flags and error messages
            this.isPaymentConfirmed = false;
            this.isNameError = false;
            this.isBankNameError = false;
            this.isIBANError = false;
            this.isSWIFTError = false;
            this.isAddressError = false;
            this.isWalletAddressError = false;
            this.ischainError = false;
            this.isRiseError = false
            this.isLastNameError = false;
            this.errors = {
                iban: "",
                swift: "",
                walletAddress: "",
                chain: "",
                firstName: "",
                lastName: "",
                email:"",
            };
            this.hasErrors = false;

            // Check selected payment method
            if (this.selectedPaymentMethod === 0) {

                console.log('entro in confirm')
                let isBankDetailsValid = true;

                if (
                    !this.bankDetails.iban ||
                    !this.validateIBAN(this.bankDetails.iban)
                ) {
                    this.errors.iban = this.$t("error-IBAN");
                    this.isIBANError = true;
                    isBankDetailsValid = false;
                    this.hasErrors = true;
                }

                if (!this.bankDetails.firstName) {
                    this.errors.firstName = this.$t("error-first-name-req");
                    this.isNameError = true;
                    isBankDetailsValid = false;
                    this.hasErrors = true;
                }

                if (!this.bankDetails.lastName) {
                    this.errors.lastName = this.$t("error-last-name-req");
                    this.isLastNameError = true;
                    isBankDetailsValid = false;
                    this.hasErrors = true;
                }

                if (!this.bankDetails.swift) {
                    this.errors.swift = this.$t("error-swift-req");
                    this.isSWIFTError = true;
                    isBankDetailsValid = false;
                    this.hasErrors = true;
                }

                if (!this.bankDetails.address) {
                    this.errors.address = this.$t("error-address-req");
                    this.isAddressError = true;
                    isBankDetailsValid = false;
                    this.hasErrors = true;
                }

                if (!isBankDetailsValid) {
                    this.isFormValid = false;
                    return;
                }
                this.isPaymentConfirmed = true;
            }

            if (this.selectedPaymentMethod === 1) {
                let isCryptoDetailsValid = true;

                if (!this.cryptoDetails.walletAddress) {
                    this.errors.walletAddress = this.$t("error-wallet-req");
                    this.isWalletAddressError = true;
                    isCryptoDetailsValid = false;
                    this.hasErrors = true;
                }

                if (!this.cryptoDetails.chain) {
                    this.errors.chain = this.$t("error-chain-req");
                    this.ischainError = true;
                    isCryptoDetailsValid = false;
                    this.hasErrors = true;
                }

                if (!isCryptoDetailsValid) {
                    this.isFormValid = false;
                    return;
                }

                this.isPaymentConfirmed = true;
            }

            if(this.selectedPaymentMethod === 2){
                let isRiseDetailsValid = true;
                if (!this.riseDetails.email) {
                    this.errors.email = this.$t("error-email-req");
                    this.isRiseError = true;
                    isRiseDetailsValid = false;
                    this.hasErrors = true;
                }else if(!this.validateEmail(this.riseDetails.email)){
                    this.errors.email = this.$t("error-email-val");
                    this.isRiseError = true;
                    isRiseDetailsValid = false;
                    this.hasErrors = true;
                }
                if(!isRiseDetailsValid){
                    this.isFormValid = false;
                    return;
                }
                this.isPaymentConfirmed = true;
            }

            if (!this.hasErrors) {
                this.isFormValid = true;
            }
            this.hasErrors = false;

            if (!this.hasErrors) {
                this.setSubmissionDate();
                this.saveState();
            }
        },

        googleMaps() {
            this.$nextTick().then(() => {
                    setTimeout(() => {
                        let autocomplete = new window.google.maps.places.Autocomplete(document.getElementById('address'), { types: ['geocode'] });
                        window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
                            let place = autocomplete.getPlace();
                            this.bankDetails.address = place.formatted_address;
                        });
                    }, 1000);
                });
        },
        selectPaymentMethod(index) {
            if (index === this.selectedPaymentMethod) {
                if(index==0){
                    this.googleMaps()
                }
                return;
            }
            this.selectedPaymentMethod = index;

            console.log(this.selectedPaymentMethod)
            this.isNameError = false;
            this.isIBANError = false;
            this.isBankNameError = false;
            this.isSWIFTError = false;
            this.isWalletAddressError = false;
            this.ischainError = false;
            this.isAddressError = false;
            this.isLastNameError = false;
            this.errors = {
                iban: "",
                swift: "",
                walletAddress: "",
                chain: "",
                firstName: "",
                lastName: "",
            };

            const isBankTransferEmpty =
                !this.bankDetails.firstName ||
                !this.bankDetails.lastName ||
                !this.bankDetails.address ||
                !this.bankDetails.iban ||
                !this.bankDetails.swift;

            const isCryptoEmpty =
                !this.cryptoDetails.chain || !this.cryptoDetails.walletAddress;

            const isRiseEmpty = !this.riseDetails.email
            if (index === 0) {
                // Bank Transfer
                this.isFormValid = !isBankTransferEmpty;
                this.googleMaps()

            } else if (index === 1) {
                // Crypto
                this.isFormValid = !isCryptoEmpty;
            } else if(index === 2) {
                this.isFormValid = !isRiseEmpty
            }

            this.isPaymentConfirmed = false;
        },
        capitalize(name) {
            if (name) {
                return (
                    name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()
                );
            }
            return name;
        },
        editPaymentMethod() {
            this.isPaymentConfirmed = false;
        },

        async fetchSinglePayoutData() {
            const payoutStore = usePayoutStore();
            const response = await payoutStore.fetchDataPayouts();
            const payout = response.data.find(
                (pay) => pay.id === this.payoutId
            );

            if (payout) {
                this.selectedPayout = { ...payout };
            }
            try {
                const response = await payoutStore.fetchSingleDataPayout(
                    this.selectedPayout?._id
                );

                if (response && response._id === this.selectedPayout?._id) {
                    this.selectedSinglePayout = { ...response };
                    if (this.selectedSinglePayout.status == "completed") {
                        this.currentStep = 3;
                    } else if (
                        this.selectedSinglePayout.status == "delayed" ||
                        this.selectedSinglePayout.status == "cancelled"
                    ) {
                        this.currentStep = 2;
                    } else if (
                        this.selectedSinglePayout.status == "confirmed" &&
                        this.selectedSinglePayout.paymentStatus ==
                            "data_confirmed"
                    ) {
                        this.currentStep = 2;
                    } else if (
                        this.selectedSinglePayout.status == "confirmed" &&
                        this.selectedSinglePayout.paymentStatus ==
                            "waiting_data_confirmation"
                    ) {
                        this.currentStep = 1;
                    }
                }
                payoutStore.setStep(this.currentStep);
                this.$router.push({ path: `/customer-payouts/${data.id}` });
            } catch (error) {
                console.error("Error fetching payout data:", error);
            } finally {
            }
            this.saveState();
        },
        async updatePayout() {
            console.log('entro nell update')
            const payoutStore = usePayoutStore();
            const paymentMethod =
                this.selectedPaymentMethod === 0
                    ? {
                          firstName: this.bankDetails.firstName || "",
                          lastName: this.bankDetails.lastName || "",
                          iban: this.bankDetails.iban || "",
                          swift: this.bankDetails.swift || "",
                          sortCode: this.bankDetails.sortCode || "",
                          routingNumber: this.bankDetails.routingNumber || "",
                          address: this.bankDetails.address || "",
                      }
                    : this.selectedPaymentMethod === 1
                    ? {
                          chain: this.cryptoDetails.chain || "",
                          walletAddress: this.cryptoDetails.walletAddress || "",
                      }
                    : this.selectedPaymentMethod === 2 ? {
                          email: this.riseDetails.email || "",
                      } : null;

            if (!paymentMethod) return;

            try {
                const response = await payoutStore.fetchUpdatePayouts(
                    this.selectedSinglePayout._id,
                    { paymentMethod }
                );
                if (response) {
                    this.selectedSinglePayout = { ...response };
                }
            } catch (error) {
                console.error("Error sending payout:", error.message || error);
            }
        },
        async fetchPaymentData() {
            const payoutStore = usePayoutStore();

            try {
                const data = await payoutStore.fetchPaymentDetails();
                if (!data || !Array.isArray(data.details)) {
                    console.error("Invalid response format:", data);
                    return;
                }
                const bankDetail = data.details.find(
                    (detail) => detail.type === "Bank"
                );
                const cryptoDetail = data.details.find(
                    (detail) => detail.type === "Cryptocurrency"
                );

                const riseDetail = data.details.find(
                    (detail) => detail.type === "Rise"
                );
                if (bankDetail) {
                    this.bankDetails = {
                        firstName: bankDetail.firstName || "",
                        lastName: bankDetail.lastName || "",
                        iban: bankDetail.iban || "",
                        swift: bankDetail.swift || "",
                        address: bankDetail.address || "",
                        createdAt: bankDetail.createdAt || "",
                    };
                    this.selectedPaymentMethod = 0;
                    localStorage.setItem(
                        "bankDetails",
                        JSON.stringify(this.bankDetails)
                    );
                    localStorage.setItem("selectedPaymentMethod", "0");
                }

                if (cryptoDetail) {
                    this.cryptoDetails = {
                        walletAddress: cryptoDetail.walletAddress || "",
                        chain: cryptoDetail.chain || "",
                    };
                    localStorage.setItem(
                        "cryptoDetails",
                        JSON.stringify(this.cryptoDetails)
                    );

                    if (!bankDetail) {
                        this.selectedPaymentMethod = 1;
                        localStorage.setItem("selectedPaymentMethod", "1");
                    }
                }
                if (riseDetail) {
                    this.riseDetails = {
                        email : riseDetail.email || ""
                    };
                    localStorage.setItem(
                        "rise",
                        JSON.stringify(this.riseDetails)
                    );

                    if (!bankDetail && !cryptoDetail) {
                        this.selectedPaymentMethod = 2;
                        localStorage.setItem("selectedPaymentMethod", "2");
                    }
                }

                this.isPaymentConfirmed = !!(bankDetail || cryptoDetail || riseDetail);
                this.saveState();
            } catch (error) {
                console.error("Error fetching payment data:", error);
            }
        },

        nextStep() {
            const payoutStore = usePayoutStore();
            this.updatePayout();
            this.saveState();
            payoutStore.setStep(2);
        },

        saveState() {
            const state = {
                currentStep: this.currentStep,
                selectedPayout: this.selectedPayout,
                bankDetails: this.bankDetails,
                cryptoDetails: this.cryptoDetails,
                bankDetails: this.bankDetails,
                cryptoDetails: this.cryptoDetails,
                selectedSinglePayout: this.selectedSinglePayout,
            };
            localStorage.setItem(
                "payoutConfirmationState",
                JSON.stringify(state)
            );
        },
        restoreState() {
            const state = localStorage.getItem("payoutConfirmationState");
            if (state) {
                const parsedState = JSON.parse(state);
                this.selectedPaymentMethod =
                    parsedState.selectedPaymentMethod || null;
                this.bankDetails = parsedState.bankDetails || {};
                this.cryptoDetails = parsedState.cryptoDetails || {};
                this.currentStep = parsedState.currentStep;
                this.isPaymentConfirmed =
                    parsedState.isPaymentConfirmed || false;
            } else {
                this.fetchSinglePayoutData();
            }
        },
        watchStateChanges() {
            watch(
                () => this.bankDetails,
                () => {
                    this.saveState();
                },
                { deep: true }
            );

            watch(
                () => this.cryptoDetails,
                () => {
                    this.saveState();
                },
                { deep: true }
            );
        },
        checkPaymentConfirmed() {
            return this.method === "Bank Transfer" || this.method === "Crypto";
        },
        mounted() {
            this.watchStateChanges();
            this.restoreState();
            this.fetchPaymentData();
            this.loadData();
            this.fetchSinglePayoutData();
        },
    },
};
</script>

<style lang="scss" scoped>
*,
*::before,
*::after {
    font-family: "Heebo", sans-serif !important;
    color: rgba(255, 255, 255, 0.93);
}
.confirmed-text {
    margin: 0 auto;
    line-height: 29px;
    font-size: 20px;
    font-weight: 300;
    width: 600px;
}
.stepper-container-custom {
    margin-top: 100px !important;
}
.choose-payment {
    margin-bottom: 418px;
}
.icon-card {
    width: 24px;
    height: 24px;
    margin-left: -78px;
}
.download-icon {
    background: rgba(255, 255, 255, 0.05);
    height: 60px;
    width: 60px;
}
.download-icon img {
    margin: 10px;
}
.download {
    margin-top: 20px;
    margin-bottom: 40px;
    width: 282px;
    height: 60px;
    background: rgba(255, 255, 255, 0.02);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    cursor: pointer;
}
.download h5 {
    right: 28px;
    margin: 0;
    text-align: left;
    position: absolute;
}

.mail {
    text-decoration: none;
    color: rgba(255, 193, 168, 1);
    font-size: 16px;
}
.secondary-text-success {
    color: rgba(255, 255, 255, 0.6);
    line-height: 23.5px;
}
.success-payment-detail {
    font-weight: 600;
}
.payment-icon {
    margin-right: 8px;
}
.awaiting_payment {
    color: #ffffff99;
    padding: 20px 0px;
    font-size: 24px;
}
.payment-icon-iban {
    width: 68px;
    height: 6px;
    margin-right: 2px;
}
.is-invalid {
    border-color: rgba(255, 104, 68, 1) !important;
}

.error-message {
    color: rgba(255, 104, 68, 1);
    font-size: 0.875rem;
    margin-top: 0.25rem;
}

.edit-button {
    margin-left: auto;
    padding: 5px 10px;
    font-size: 18px;
    font-weight: 400;
    color: rgba(255, 104, 68, 1);
}
.confirmed-payment-method {
    margin-left: -67px;
    margin-top: 80px;
}
.edit_confirm {
    padding-top: 8px;
    padding-bottom: 8px;

    color: rgba(255, 255, 255, 0.6);
}
.step-content {
    margin-top: 100px;
}
.pr-8 {
    padding-right: 8px;
    padding-bottom: 40px;
}
.form-group-row {
    display: flex;
    width: 100%;
    margin-top: -10px;
    padding-left: 18px;
    padding-right: 28px;
}
.crypto {
    padding-left: 18px;
    padding-right: 10px;
}
.form-group {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
}

.form-group label {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 8px;
    color: rgba(255, 255, 255, 0.6);
}

.form-group input {
    height: 40px;
    padding: 8px;
    font-size: 20px;
    border: 1px solid rgba(255, 255, 255, 0.38);
    background-color: transparent;
    height: 69px;
}

.btn-primary {
    width: 158px;
    height: 48px;
    padding-left: 12px;
    gap: 10px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    border: none;
    cursor: pointer;
}
.round {
    background: transparent;
    text-align: left;
    margin-top: 25px;
}
.checkbox-span {
    margin-left: 18px;
    font-size: 24px;
    font-weight: 24px;
}
.round input[type="checkbox"] {
    visibility: hidden;
}

.round label {
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.38);
    background-color: transparent;
    cursor: pointer;
    margin-left: 8px;
}

.round label:after {
    content: "";
    position: absolute;
    left: 4px;
    top: 4px;
    width: 14px;
    height: 14px;
    background-color: rgba(255, 104, 68, 1);
    border-radius: 50%;
    opacity: 0;
}

.round input[type="checkbox"]:checked + label {
    background-color: transparent;
    border-color: rgba(255, 104, 68, 1);
}

.round input[type="checkbox"]:checked + label:after {
    opacity: 1;
}

.payment-method-options {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.checkbox-wrapper {
    margin-right: 12px;
}

.payment-checkbox {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid rgba(255, 104, 68, 1);
    background-color: transparent;
    position: relative;
}

.payment-checkbox:checked {
    background-color: rgba(255, 104, 68, 1);
}

.payment-option {
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 23px;
    width: 100%;
    height: auto;
    background: rgba(255, 255, 255, 0.05);
    margin-bottom: 18px;
    cursor: pointer;
}

.payment-option .top-section {
    display: flex;
    align-items: center;
    width: 100%;
}

.page-wrapper {
    margin-top: 20px;
    padding-bottom: 20px;
}

::v-deep .payment_confirmation_name {
    font-family: Heebo;
    font-weight: 400;
    font-size: 18px;
}

::v-deep .amount_text {
    font-family: Heebo;
    font-weight: 500;
    font-size: 30px;
    margin-left: 8px;
}

::v-deep .secondary_text_amount {
    color: #ff9571;
}

::v-deep .evidence-box {
    width: 96%;
    height: 102px;
    position: absolute;
    top: 178px;
    left: 28px;
    padding: 3px 20px;
    background: rgba(255, 255, 255, 0.05);

    font-weight: 300;
}
::v-deep .evidense-box-step-3 {
    width: 98%;
    height: 549px;
    top: 178px;
    left: 28px;
    padding: 3px 20px;
    background: rgba(255, 255, 255, 0.05);
    text-align: center;
    font-family: Heebo, sans-serif !important;
    margin-left: 10px !important;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-bottom: 413px;
    font-weight: 300;
}

::v-deep .success-table {
    width: 611px;
    height: 184px;
    border: 1px solid rgba(255, 255, 255, 0.05);
    padding-top: 60px;
}
.success-table td:first-child {
    background: rgba(255, 255, 255, 0.03);
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    text-align: center;
    width: 298px;
}
.success-table th {
    font-weight: bold;
    color: #ccc;
    text-transform: capitalize;
}
.success-table td:last-child {
    text-align: left;
    color: #ffffff99;

    padding-left: 40px;
}
.parent-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
}

::v-deep .payout-id {
    font-size: 20px;
    font-weight: 300;
    color: #ff9571;
    font-family: Heebo;
    margin-left: 8px;
    background: rgba(255, 255, 255, 0.02);
    padding: 10px;
    width: 181px;
    height: 49px;
}
h2 {
    font-family: Heebo;
    font-weight: 500;
}
::v-deep .text-button {
    background: none;
    border: none;
    color: rgba(255, 255, 255, 0.93) !important;
    font-size: 14px;
    font-weight: 300;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
}

::v-deep .text-button svg {
    fill: #ff9571;
}

::v-deep .pending-status {
    color: rgba(255, 149, 113, 1) !important;
}

::v-deep .paid-status {
    color: rgba(40, 167, 69, 1) !important;
}

::v-deep .confirmed-status {
    color: rgb(242, 245, 57) !important;
}

::v-deep .tabulator .tabulator-col-sorter {
    display: none !important;
}
::v-deep .tabulator {
    background-color: rgba(255, 255, 255, 0.05) !important;
    color: rgba(255, 255, 255, 0.93) !important;
    font-size: 14px;
    font-weight: 300;
    text-align: left;
    font-family: Heebo;
    padding-bottom: 40px;
}

::v-deep .tabulator-col-title {
    font-weight: 500;
}

::v-deep .tabulator-table {
    background-color: rgba(255, 255, 255, 0.05) !important;
}

::v-deep .tabulator-header .tabulator-col {
    background-color: rgba(255, 255, 255, 0.04) !important;
    border-bottom: none !important;
}

::v-deep .tabulator .tabulator-row:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.2) !important;
}

::v-deep .tabulator .tabulator-row:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.2) !important;
}

::v-deep .tabulator .tabulator-header {
    background-color: rgba(255, 255, 255, 0.04) !important;
    border-bottom: none !important;
    border-top: none !important;
}

::v-deep .tabulator .tabulator-footer {
    background-color: rgba(23, 23, 23, 0.048) !important;
    color: transparent !important;
    border-top: none !important;
}

::v-deep .tabulator .tabulator-cell {
    background-color: rgba(255, 255, 255, 0.037) !important;
    padding: 10px !important;
    padding-left: 16px !important;
    text-align: left !important;
}

::v-deep .tabulator-row {
    border: none !important;
    box-shadow: none !important;
}

::v-deep .tabulator-cell {
    border: none !important;
}

::v-deep .tabulator-header .tabulator-col {
    border-bottom: none !important;
    padding-left: 5px !important;
    font-size: 14px;
}

.image {
    width: 20px;
}

/* Mobile version */
@media (max-width: 1035px) {
    h2 {
        display: block;
    }

    .payout-id {
        display: block;
        margin-top: 15px;
        margin-left: 0;
        width: 100%;
    }
    .evidence-box {
        top: 200px;
        left: auto;
        height: 180px;
        padding: 10px;
        width: 93%;
    }
    .amount_text {
        font-size: 20px;
    }
    .stepper-container-custom {
        margin-top: 130px !important;
    }
    .form-group-row {
        margin-top: 10px;
        padding-right: 8px;
        display: block;
        padding-left: 0px;
    }
    .btn-primary {
        width: 100%;
        padding-right: 0px;
    }
    .btn-payment-method {
        margin-left: 8px;
    }
    .success-table td {
        padding: 8px 20px;
        width: 50%;
    }
    .confirmed-text {
        margin: 0 auto;
        line-height: 29px;
        font-size: 16px;
        font-weight: 300;
        width: auto;
    }
    .awaiting_payment {
        padding: 20px 0px;
        font-size: 20px;
    }

    ::v-deep .evidense-box-step-3 img {
        height: 48px;
        width: 48px;
        margin-top: 44px;
    }
    .success-table {
        margin-left: -30px;
        margin-right: -30px;
    }
    .download-icon img {
        margin: 7px;
    }
    .stepper-container {
        margin-bottom: -30px !important;
    }
    ::v-deep .payout-id {
        font-size: 14px;
        font-weight: 300;
        background-color: transparent;
        margin-left: -10px;
        order: -1;
        margin-bottom: -15px;
        margin-top: -10px;
    }
    .main-title {
        display: flex;
        flex-direction: column;
    }
    .stepper-container-custom {
        margin-top: 210px !important;
    }
    .topbar {
        margin-top: -20px;
    }
    ::v-deep .payment_confirmation_name {
        padding: 20px;
        font-size: 16px;
    }
    .secondary_text_amount {
        font-size: 16px;
    }
    .payment-method-options {
        margin-top: 110px;
        display: block;
        width: 100%;
    }
    .expanded {
        margin-top: -100px;
    }
    .evidance-box-payment:not(.expanded) {
        display: flex;
        width: 100%;
        height: 480px;
        position: relative;
        bottom: 100px;
        left: 28px;
        padding: 3px 20px;
        background: rgba(255, 255, 255, 0.05);
        margin-left: -30px;
        margin-bottom: -180px;
    }
    .choose-payment:not(.expanded) {
        max-width: 370px;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

    .paym-method {
        margin-top: 120px;
        margin-left: 8px;
    }
    .checkbox-span {
        margin-bottom: 12px;
        font-size: 20px;
        margin-left: 20px;
    }
    .checkbox-span {
        width: 20px;
        height: 20px;
    }
    .round {
        margin-top: 20px;
    }
    .payment-option {
        padding-bottom: 0;
    }
    .payment-option {
        padding-top: 20px;
    }
    .checkbox-span {
        min-width: 200px;
    }

    .confirmed-payment-method {
        padding-bottom: 10px;
    }
    .edit_confirm {
        margin-left: 8px;
    }
    .icon-card {
        margin-left: 0;
    }
    .confirmed-payment-method {
        margin-left: -200px;
    }
    .payment-option .top-section {
        padding-bottom: 10px;
        margin-top: -20px;
    }
}
select {
    height: 48px;
    border: 1px solid rgb(255, 255, 255, 0.38);

}
</style>

