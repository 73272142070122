<template>
    <!--Fidelity-->
    <li v-if="fidelityPoints" class="nav-small-cap btnStyle">
        <a class="btn mx-2 text-center p-2" href="/buy-account"><font-awesome-icon class="me-2" icon="fa-solid fa-medal"/>{{ fidelityPoints.total.toFixed(2) }}</a>
    </li>
    <!-- /Fidelity Point -->
</template>

<script setup>
import { useUserStore } from '../../store/user.store';
import { storeToRefs } from 'pinia';

const userStore = useUserStore();
const { fidelityPoints } = storeToRefs(userStore);
</script>

<style scoped>
    .page-wrapper{
        min-height: 100px!important;
    }
    .btnStyle{
        border-radius: 10px;
        background-color: rgba(194,79,53,0.2);
    }
    a{
        color: #eb6e52;
    }
</style>
