import { createApp } from "vue";
import { createGtm } from "@gtm-support/vue-gtm";

require("./bootstrap");
require("./socket");

import "@popperjs/core";
import { Tooltip, Popover } from "bootstrap";
/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import {
    faWallet,
    faPercent,
    faLineChart,
    faArrowUp,
    faArrowDown,
    faMoneyBillWave,
    faCoins,
    faCaretDown,
    faWindowClose,
    faLevelDown,
    faSackDollar,
    faPlusSquare,
    faComments,
    faIdCard,
    faTrophy,
    faMoneyBillTrendUp,
    faUserGear,
    faCloudDownload,
    faGlobe,
    faLock,
    faServer,
    faUserTag,
    faSatelliteDish,
    faLockOpen,
    faCalendar,
    faDollar,
    faDatabase,
    faPercentage,
    faChartLine,
    faArrowTrendUp,
    faCircleCheck,
    faCircleXmark,
    faCircleInfo,
    faShareNodes,
    faHouse,
    faCartShopping,
    faHandshake,
    faChartPie,
    faMoneyBill,
    faGear,
    faEye,
    faNewspaper,
    faDisplay,
    faUsers,
    faUserShield,
    faSpinner,
    faCheckSquare,
    faMedal,
    faHandsHelping,
    faArrowRightFromBracket,
    faUser,
    faCreditCard,
    faBook,
    faChalkboardTeacher,
    faCertificate,
    faClockRotateLeft,
    faCloudArrowDown,
    faFilePdf,
    faArrowRight,
    faArrowUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import VueCountryDropdown from "vue-country-dropdown";
library.add(
    faWallet,
    faPercent,
    faLineChart,
    faCloudArrowDown,
    faFilePdf,
    faCircleCheck,
    faArrowUp,
    faArrowDown,
    faArrowRight,
    faTwitter,
    faMoneyBillWave,
    faCoins,
    faCaretDown,
    faWindowClose,
    faLevelDown,
    faSackDollar,
    faPlusSquare,
    faComments,
    faInstagram,
    faIdCard,
    faTrophy,
    faMoneyBillTrendUp,
    faUserGear,
    faCloudDownload,
    faGlobe,
    faLock,
    faServer,
    faUserTag,
    faSatelliteDish,
    faLockOpen,
    faCalendar,
    faDollar,
    faDatabase,
    faPercentage,
    faChartLine,
    faArrowTrendUp,
    faFacebook,
    faCircleXmark,
    faCircleInfo,
    faShareNodes,
    faHouse,
    faCartShopping,
    faHandshake,
    faChartPie,
    faMoneyBill,
    faGear,
    faEye,
    faNewspaper,
    faDisplay,
    faUsers,
    faUserShield,
    faIdCard,
    faSpinner,
    faCheckSquare,
    faMedal,
    faHandsHelping,
    faArrowRightFromBracket,
    faStar,
    faUser,
    faCreditCard,
    faBook,
    faChalkboardTeacher,
    faCertificate,
    faLock,
    faClockRotateLeft,
    faArrowUpRightFromSquare
);
import VueCookies from "vue3-cookies";
import App from "./App.vue";
import axios from "axios";
import router from "./router";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import { createI18n } from "vue-i18n";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import VCalendar from "v-calendar";
import { faStar } from "@fortawesome/free-regular-svg-icons";

import { createPinia } from "pinia";
import * as Sentry from "@sentry/vue";

const messages = {
    EN: require("./locales/en.json"),
    en: require("./locales/en.json"),
    it: require("./locales/it.json"),
    es: require("./locales/es.json"),
    pt: require("./locales/pt.json"),
};

const i18n = createI18n({
    legacy: false,
    locale: "en",
    messages,
});

axios.interceptors.request.use(
    (config) => {
        if (config.url.includes("L#") || config.url.includes("D#")) {
            config.url = config.url.replace("#", "%23");
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status !== 401) {
            /* Sentry.captureException("Http error " + error.config.url, {
                contexts: {
                    request: error.response
                        ? {
                              error: error.response?.data,
                              status: error.response?.status,
                              url: error.config?.url,
                              method: error.config?.method,
                              headers: error.config?.headers,
                          }
                        : error.request || {},
                },
            }); */
        }
        return Promise.reject(error);
    }
);

const app = createApp(App);

if (location.hostname !== "127.0.0.1" && location.hostname !== "localhost" && !location.hostname.endsWith(".test")) {
    /* Sentry.init({
        app,
        dsn: "https://cbf813d7b17892c2e8a894b04477b19f@o4506345213526016.ingest.us.sentry.io/4508102801948672",
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration(),
        ],
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            "localhost",
            /^https:\/\/api\.ofpfunding\.com\/api/,
            /^https:\/\/dev\.api\.ofpfunding\.com\/api/,
        ],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        ignoreErrors: [
            /\/user\/getOpenTrades/,
            /\/order\/find/,
            /\/cart\/getCart/,
            /\/accounts\/getAllCustomerAccounts/,
            /\/ecommerce\/getCustomerReward/,
            /\/user\/getTrades2/,
            /\/ecommerce\/items/,
            /\/getUserTrackingForChart\?/,
            /\/customers\/update\/details/,
            /\/accounts\/getAccountInfo/,
            /\/ecommerce\/cleanCart/,
            /\/cart\/addCouponToCart/,
            /Failed to fetch/,
        ],
    }); */
}

/** Global Vars */
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$languages = {
    en: "en-US",
    it: "it-IT",
    es: "es-ES",
    pt: "pt-PT",
};
if (
    location.hostname === "127.0.0.1" ||
    location.hostname === "localhost" ||
    location.hostname.endsWith(".test")
) {
    app.config.globalProperties.$trustpayment_domain = "test_ofpfunding119867";
    app.config.globalProperties.$baseUrl = "http://127.0.0.1:3000/api";
    app.config.globalProperties.$urlDashbaord = "http://127.0.0.1:3000";
} else {
    app.config.globalProperties.$urlDashbaord = "https://client.ofpfunding.com";
    app.config.globalProperties.$baseUrl = "https://api.ofpfunding.com/api";
    //app.config.globalProperties.$baseUrl = 'https://ofp-api.ait-tech.it/api';
    app.config.globalProperties.$trustpayment_domain = "ofpfunding119868";
    if (location.hostname.includes("dev")) {
        app.config.globalProperties.$urlDashbaord =
            "https://dev.client.ofpfunding.com";
        app.config.globalProperties.$baseUrl =
            "https://dev.api.ofpfunding.com/api";
        app.config.globalProperties.$trustpayment_domain =
            "test_ofpfunding119867";
    }
}
app.use(router);
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(VueTelInput);
app.use(i18n);
app.use(VCalendar, {});
app.use(VueCookies, {
    expireTimes: "7d",
    secure: true,
});
app.use(
    createGtm({
        id: "GTM-KTLX35D",
        vueRouter: router,
    })
);

app.use(VueCountryDropdown);
app.directive("tooltip", {
    mounted(el) {
        new Tooltip(el);
    },
});

app.directive("popover", {
    mounted(el) {
        new Popover(el);
    },
});

let token = app.config.globalProperties.$cookies.get("token") || "";
if (token[token.length - 1] === "/") {
    token = token.slice(0, token.length - 1);
}
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

axios.interceptors.request.use(
    (config) => {
        let token = app.config.globalProperties.$cookies.get("token") || "";
        if (token[token.length - 1] === "/") {
            token = token.slice(0, token.length - 1);
        }

        config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

const pinia = createPinia();
pinia.use(() => {
    let token = app.config.globalProperties.$cookies.get("token") || "";
    if (token[token.length - 1] === "/") {
        token = token.slice(0, token.length - 1);
    }

    const httpClient = axios.create({
        baseURL: app.config.globalProperties.$baseUrl,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
        },
    });

    httpClient.interceptors.request.use(
        (config) => {
            token = app.config.globalProperties.$cookies.get("token") || "";
            if (token[token.length - 1] === "/") {
                token = token.slice(0, token.length - 1);
            }
            
            if (config.url.includes("L#") || config.url.includes("D#")) {
                config.url = config.url.replace("#", "%23");
            }
            
            let token = app.config.globalProperties.$cookies.get("token") || "";
            if (token[token.length - 1] === "/") {
                token = token.slice(0, token.length - 1);
            }

            config.headers.Authorization = `Bearer ${token || ""}`;
            config.headers["Accept-Language"] =
                app.config.globalProperties.$i18n.locale || "en";

            const guestId = app.config.globalProperties.$cookies.get("guest_id") || undefined;
            if (guestId) {
                config.headers["x-guest-id"] = guestId;
            }

            return config;
        },
        function (error) {
            return Promise.reject(error);
        }
    );

    httpClient.interceptors.response.use(undefined, (err) => {
        const { config, response } = err;
        if (!config || !config.retry) {
            if (config.url.includes("auth")) {
                return Promise.reject(err);
            }

            if (response?.status !== 401) {
                /* Sentry.captureException("Http error " + config.url, {
                    contexts: {
                        request: response
                            ? {
                                  error: response?.data,
                                  status: response?.status,
                                  url: config?.url,
                                  method: config?.method,
                                  headers: config?.headers,
                              }
                            : err.request || {},
                    },
                }); */
            }
            return Promise.reject(err);
        }
        if (response.status !== 502) {
            if (response?.status !== 401) {
                /* Sentry.captureException("Http error " + config.url, {
                    contexts: {
                        request: response
                            ? {
                                  error: response?.data,
                                  status: response?.status,
                                  url: config?.url,
                                  method: config?.method,
                                  headers: config?.headers,
                              }
                            : err.request || {},
                    },
                }); */
            }
            return Promise.reject(err);
        }
        config.retry -= 1;
        const delayRetryRequest = new Promise((resolve) => {
            setTimeout(() => {
                console.log("retry the request", config.url);
                resolve();
            }, config.retryDelay || 1000);
        });
        return delayRetryRequest.then(() => httpClient(config));
    });

    return {
        $httpClient: httpClient,
    };
});
app.use(pinia);

import NumberPlugin from "./plugins/number";
app.use(NumberPlugin);
import BrevoPlugin from "./plugins/brevo";
app.use(BrevoPlugin);
import KlaviyoPlugin from "./plugins/klaviyo";
app.use(KlaviyoPlugin);
import DatePlugin from "./plugins/date";
app.use(DatePlugin);
import Notifications from "@kyvg/vue3-notification";
app.use(Notifications);

app.mount("#app");
