<template>
    <Layout :loading="loading" :title="$t('buyaccount-title')" :custom-background="true">
        <div class="d-md-flex flex-column justify-content-center align-items-center gap-4 px-2">
            <CustomAccountFilters v-if="!showBundles" v-model="plan"
            :key="index" @update:modelValue="onPlanChanged"
            :capitals="capitals"
            :drawdowns="drawdowns"
            :profit_splits="profitSplits"
            :payout_frequencies="frequencies"
            :currencies="currencies" />

            <template v-if="settingStore.setting && !showBundles">
                <BuyAccountSummary class="w-100"
                :items="selectedItems"
                :showBundleBox="showBundleBox"
                :processing="processing" v-model="index"
                @update:modelValue="onIndexChanged" @buy-account="onBuyAccount" @update:showBundle="changeValue"/>

                <a v-if="canAdd" class="compare-plans d-none d-md-block" href="#" @click="addPlan">{{ comparePlansTitle}}</a>
            </template>

            <template v-if="showBundles">
                <SummaryBundles/>
                <a class="compare-plans-small" @click="changeValue(false)" href="#">Get a single account without offers</a>
            </template>
        </div>
    </Layout>
    <!-- button FLoat table Bundles -->
    <button v-if="showBundleStrip" @click="changeValue(true)" class="bundlesBtn d-none d-lg-block">Exclusice Offer<br><span>14-25 February</span></button>

</template>

<script setup>
import {
    ref,
    unref,
    watchEffect,
    readonly,
    toRaw,
    inject,
    watch,
    onMounted,
    computed,
    onBeforeMount,
} from "vue";

import Layout from "../layouts/header-sidebar-title-layout.vue";
import CustomAccountFilters from "../components/custom-account/Filters.vue";
import BuyAccountSummary from "../components/custom-account/Summary.vue";
import SummaryBundles from "../components/custom-account/SummaryBundles.vue";


import { useGtm } from "@gtm-support/vue-gtm";
import { useRouter } from "vue-router";
import { useNotification } from "@kyvg/vue3-notification";
import { storeToRefs } from "pinia";
import { useUserStore } from "../store/user.store";
import { useItemsStore } from "../store/items.store";
import { useCartStore } from "../store/cart.store";
import { useSettingStore } from "../store/setting.store"
import { usePopup } from "../store/popup.store";
import { useI18n } from "vue-i18n";
import {
    isValidItem,
    items2Sizes,
    items2MaxDailyDrawdowns,
    items2ProfitSplits,
    items2PayoutFrequencies,
    items2Currencies,
} from "../components/custom-account/utils/item";
import { useCookies } from "vue3-cookies";
import { useMarketingStore } from "../store/marketing.store";

const router = useRouter();

const userStore = useUserStore();
const { user } = storeToRefs(userStore);
const { fetchItems } = useItemsStore();
const cartStore = useCartStore();
const { addItemToCart, fetchCart } = cartStore;
const { cart } = storeToRefs(cartStore);
const settingStore = useSettingStore()
const $gtm = useGtm();
const trackKlaviyoEvent = inject("$trackKlaviyoEvent");
const formatNumber = inject("$formatNumber");
const { notify } = useNotification();
const marketingStore = useMarketingStore();

const { popup } = usePopup();

const i18n = useI18n();

const showBundles = ref(false)

const loading = ref(false);
const processing = ref(false);
const index = ref(0);
const items = ref([]);
const defaultPlan = {
    capital: 10000,
    drawdown: 5,
    profit_split: 80,
    payout_frequency: "monthly", // 'monthly' 'biweekly', 'on-demand'
    currency: "USD",
    platform: "ctrader",
};
const plans = ref([defaultPlan]);
const plan = ref(plans.value[0]);
const capitals = computed(() =>
    unref(items)
        ? items2Sizes(unref(items))
        : [5, 10, 25, 50, 100, 200].map((e) => e * 1000)
);
const drawdowns = computed(() =>
    unref(items) ? items2MaxDailyDrawdowns(unref(items)) : [3, 4, 5]
);
const profitSplits = computed(() =>
    unref(items) ? items2ProfitSplits(unref(items)) : [26, 40, 60, 80, 85, 90, 95]
);
const frequencies = computed(() =>
    unref(items)
        ? items2PayoutFrequencies(unref(items))
        : ["mountly", "biweekly", "on-demand"]
);
const currencies = computed(() =>
    unref(items) ? items2Currencies(unref(items)) : ["USD", "EUR", "GBP"]
);
const comparePlansTitle = computed(() => {
    switch (index.value) {
        case 0:
            return "Compare Plans";
        case 1:
            return "Compare Another Plan";
        default:
            return "";
    }
});

const showBundleBox = ref(true)
const showBundleStrip = ref(false)
const addPlan = () => {
    plans.value.push({ ...plans.value.at(-1) });
    plan.value = plans.value.at(-1);
    index.value = plans.value.length - 1;
    showBundleBox.value = false
    showBundleStrip.value=true
};
const onPlanChanged = () => {
    plans.value[index.value] = plan.value;

    const item = findItem(plan.value);
    if (user.value) {
        marketingStore.trackInteractions('items_selections', {
            item_id: item.version.id,
            max_daily_drawdown: item.props.max_daily_drawdown,
            price: item.version.price,
            currency: item.version.currency,
            size: item.version.size,
            name: item.version.name,
        });
    }
};
const onIndexChanged = () => {
    plan.value = unref(plans.value[index.value]);
};
const canAdd = computed(() => plans.value.length < 3);

const findItem = (plan) => {
    const item = unref(items)
        ? unref(items).find((item) => {
            if (item.payout_type !== plan.payout_frequency) return false;
            if (item.props.profit_split !== plan.profit_split) return false;
            if (item.props.max_daily_drawdown !== plan.drawdown) return false;

            const version = item.versions
                ? item.versions.find(
                    (version) =>
                        version.currency_account === plan.currency &&
                        version.size === plan.capital
                )
                : null;
            return !!version;
        })
        : null;

    if (item) {
        item.version = item.versions.find(
            (version) =>
                version.currency_account === plan.currency &&
                version.size === plan.capital
        );
        item.platform = plan.platform;

        $gtm.push({
            event: "view_item_v2",
            ecommerce: {
                value: item.version.price,
                currency: item.version.currency,
                items: [
                    {
                        item_name: item.version.name,
                        item_id: item.version.id,
                        price: item.version.price,
                        quantity: 1,
                    },
                ],
            },
            user_data: user.value
                ? {
                    first_name: user.value.firstName,
                    last_name: user.value.lastName,
                    email_address: user.value.email,
                    phone_number: user.value.phone,
                    user_id: user.value._id,
                }
                : {},
        });

        return JSON.parse(JSON.stringify(toRaw(item)));
    }
    return null;
};

const selectedItems = computed(() =>
    unref(items)
        ? unref(plans)
            .map(findItem)
            .filter((e) => !!e)
        : []
);
const selectedItem = computed(() =>
    unref(items) ? findItem(unref(plan)) : null
);
const { cookies } = useCookies();
const unallowedCountries = ["ru", "ir", "iq", "kp", "sy", "af", "ye", "so"];
const onBuyAccount = async (item) => {
    const country = user.value?.country || "";
    if (unallowedCountries.includes(country.toLowerCase())) {
        popup({
            settings: {
                disableCloseButton: true,
            },
            heading: i18n.t("buyaccount-unallowedCountry-title"),
            image: "unallowed-country.svg",
            body: i18n.t("buyaccount-unallowedCountry"),
            title: i18n.t("buyaccount-unallowedCountry-title"),
        });
        return;
    }

    const normalizePlatform = (platform) => {
        platform = platform ? platform.toLowerCase().trim() : "ctrader";
        switch (platform) {
            case "ctrader":
            case "c-trader":
                return "ctrader";
            case "match-trader":
            case "match-trade":
                return "match-trader";
            default:
                return platform;
        }
    };
    try {
        await fetchCart();
        if ((cart.value?.items || []).length) {
            return;
        }
    } catch (e) {
        console.error(e);
        notify({
            title: "Error",
            text: "An error occurred while fetching the cart",
            type: "error",
        });
        return;
    }

    processing.value = true;

    try {
        await addItemToCart(
            item.version.id,
            normalizePlatform(item.platform),
            item.payout_type === "on-demand" ? "on-demand" : "",
            item.type,
            item.payout_type,
            item.props.max_daily_drawdown
        );
    } catch (e) {
        console.error(e);
        notify({
            title: "Error",
            text: "An error occurred while adding the item to the cart",
            type: "error",
        });
        return;
    } finally {
        processing.value = false;
    }

    try {
        const brevoPayload = {
            ITEM: item.version.id,
            PRICE: parseFloat(item.version.price).toFixed(2),
            CURR: unref(item.version.currency),
            SIZE: formatNumber(item.version.size),
            NAME: item.version.name,
        };
        if (user.value) {
            trackKlaviyoEvent(
                "cart_updated",
                {
                    email: user.value.email,
                    FIRSTNAME: user.value.firstName,
                    LASTNAME: user.value.lastName,
                },
                brevoPayload
            );
            // $gtm.trackEvent({
            //     event: "add_to_cart",
            //     value: brevoPayload,
            // });
        }
        $gtm.push({
            event: "add_to_cart_v2",
            ecommerce: {
                value: item.version.price,
                currency: item.version.currency,
                items: [
                    {
                        item_name: item.version.name,
                        item_id: item.version.id,
                        price: item.version.price,
                        quantity: 1,
                    },
                ],
            },
            user_data: user.value
                ? {
                    first_name: user.value.firstName,
                    last_name: user.value.lastName,
                    email_address: user.value.email,
                    user_id: user.value._id,
                    phone_number: user.value.phone,
                    country: user.value.country,
                    city: user.value.city,
                    state: user.value.state,
                    postal_code: user.value.zip
                        ? user.value.zip
                            .replaceAll("-", "")
                            .replaceAll(" ", "")
                        : undefined,
                }
                : {},
        });
    } finally {
        router.push("/checkout");
    }
};

const changeValue = (value) => {
    if(value){
        showBundleStrip.value = false
    }else{
        showBundleStrip.value = true
    }
    showBundles.value = value
}

const showPopupIfCartNotEmpty = () => {
    if ((cart.value?.items || []).length) {
        popup({
            settings: {
                disableClose: true,
            },
            heading: i18n.t("shop-checkoutText"),
            image: "cart.svg",
            body: i18n.t("buyaccount-already-in-cart"),
            buttonText: i18n.t("buyaccount-go-to-checkout"),
            buttonClick: () => router.replace("/checkout"),
        });
    }
}

watch(user, async () => {
    if (user.value) {
        await fetchCart();
        showPopupIfCartNotEmpty();
    }
});

onMounted(async () => {
    try {
        if (cookies.get('guest_id')) {
            await fetchCart();
            showPopupIfCartNotEmpty();
        }
        settingStore.setting.type = "default_buy_account_item",
            await settingStore.showType()
        const [size, payoutType, dd, model] = settingStore.setting.value.split('|');
        const sizeMatch = size.match(/^(\d+)K/);
        const sizeAcc = sizeMatch ? parseInt(sizeMatch[1]) * 1000 : null;
        const currency = size.replace(/[0-9K]/g, '');
        const payoutMatch = size.match(/(\d+)(?!.*\d)/);
        const payoutModel = payoutMatch ? payoutMatch[1] : null;
        plan.value.capital = sizeAcc,
            plan.value.drawdown = parseInt(dd),
            plan.value.profit_split = parseInt(payoutModel)
        plan.value.payout_frequency = payoutType
        plan.value.currency = currency
        plans.value = [plan.value]

        $gtm.push({ ecommerce: null });
        items.value = (await fetchItems({ type: "custom" })).filter((item) =>
            isValidItem(item)
        );
    } finally {
        loading.value = false;
    }
    return;
});
onBeforeMount(async () => {

})
</script>

<style scoped lang="scss">
.compare-plans {
    color: rgba(254, 122, 92);
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 36px;
    text-decoration: underline;
}
.compare-plans-small {
    color: rgba(254, 122, 92);
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 36px;
    text-decoration: underline;
    text-align: center;
    margin: auto;
    display: block;
}

.compare-plans-mobile {
    color: rgba(254, 122, 92);
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    text-decoration: underline;
}

.bundlesBtn{
    font-family: 'Heebo';
    min-width: 204px;
    min-height: 56px;
    position: fixed;
    right: 8px;
    top: 30%;
    transform: rotate(-90deg) translateY(50%);
    transform-origin: right bottom;
    background: linear-gradient(116.97deg, #2C25A1 -2.55%, #25996B 58.62%, #2C25A1 101.15%);
    color: rgba(255, 255, 255, 0.93);
    border: none;
    border-radius:32px 32px 0px 0px;
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
    padding-top: 5px;
    padding-bottom: 40px;
    span{
        font-weight: 300;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.87);
    }
}
</style>
