<template>
    <Spinner v-if="loadingPage"/>
  <div :style="loadingPage?'opacity:20%':''" style="height: 90vh;">
    <div v-if="!isHorizontal && loading" class="text-center w-100" style="position: absolute; top: 50%; transform: translate(0,-50%);">
        <i class="icons-Rotate-Gesture fs-1">
            <p>{{ $t('Horizontal') }}</p>
        </i>
        <a href="/trial" class="btn btn-info">{{ $t('Back') }}</a>
    </div>
    <div class="m-2" style="height: 90%;" v-if="isHorizontal && loading">
        <ChartLine/>
    </div>
  </div>
</template>

<script>
import ChartLine from "./ChartLine";
import Spinner from "./Spinner"
export default {
    components:{
        ChartLine,Spinner
    },
  data() {
    return {
      hasReloaded: false,
      loading:true,
      loadingPage:true
    };
  },
  computed: {
    isHorizontal() {
      return window.screen.orientation.angle === 90 || window.screen.orientation.angle === -90;
    }
  },
  mounted() {
    window.addEventListener('orientationchange', this.handleOrientationChange);
    window.onload = () => {
            this.loadingPage = false;
        };
  },
  beforeUnmount() {
    window.removeEventListener('orientationchange', this.handleOrientationChange);
  },
  methods: {
    handleOrientationChange() {
        this.loading=false
      if (!this.hasReloaded) {
        this.hasReloaded = true;
        window.location.reload();
        this.loading=true
      } else {
      }
    }
  },
};
</script>

<style>

</style>
