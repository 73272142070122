import {defineStore} from "pinia";
import {useRouter} from "vue-router";

export const useMarketingToolsStore = defineStore('marketing-tools', {
    state: () => ({
        activeMarketingTool: {},
        loading: false,
    }),
    getters: {
        getActivePopupImageUrl: state => () => {
            return state.activeMarketingTool?.bannerPopupUrl;
        },
        getActiveFooterImageUrl: state => () => {
            return state.activeMarketingTool?.bannerFooterUrl;
        },
        getActiveFooterTexts: state => () => {
            return state.activeMarketingTool?.bannerFooterTexts;
        },
    },
    actions: {
        async fetchActiveMarketingTool() {
            this.loading = true;

            try {
                const router = useRouter();
                const opts = {};
                if (router?.currentRoute?.value?.query?.mtactiveid) {
                    opts.headers = {
                        'X-Marketing-Tool-Active-Id': router.currentRoute.value.query?.mtactiveid,
                    }
                }
                const response = await this.$httpClient.get('/marketing-tools/active', opts);
                this.activeMarketingTool = response.data;
            } catch (e) {
                console.error(e);
            } finally {
                this.loading = false;
            }
        }
    },
})
