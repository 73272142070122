/**
 * For any precise floating point calculation
 *
 * @export
 * @class Decimal
 */
export default class Decimal {
    
    constructor(num) {
        this.num = num
    }

    decimal(num2, operator) {
        const num1 = this.num
        const len1 = this.getDecimalLen(num1)
        const len2 = this.getDecimalLen(num2)
        let base = 0
        switch (operator) {
            case '+':
                base = this.getExponent(len1, len2)
                this.num = (this.safeRoundUp(num1, base) + this.safeRoundUp(num2, base)) / base
                break
            case '-':
                base = this.getExponent(len1, len2)
                this.num = (this.safeRoundUp(num1, base) - this.safeRoundUp(num2, base)) / base
                break
            case '*':
                this.num =
                    this.safeRoundUp(
                        this.safeRoundUp(num1, this.getExponent(len1)),
                        this.safeRoundUp(num2, this.getExponent(len2)),
                    ) / this.getExponent(len1 + len2)
                break
            case '/':
                base = this.getExponent(len1, len2)
                this.num = this.safeRoundUp(num1, base) / this.safeRoundUp(num2, base)
                break
            case '%':
                base = this.getExponent(len1, len2)
                this.num = (this.safeRoundUp(num1, base) % this.safeRoundUp(num2, base)) / base
                break
        }
        return this
    }

    plus(num2) {
        return this.decimal(num2, '+')
    }

    minus(num2) {
        return this.decimal(num2, '-')
    }

    multiply(num2) {
        return this.decimal(num2, '*')
    }

    divide(num2) {
        return this.decimal(num2, '/')
    }

    remainder(num2) {
        return this.decimal(num2, '%')
    }

    toNumber() {
        return this.num
    }

    getDecimalLen(num) {
        const strArr = `${num}`.split('e')
        return (`${strArr[0]}`.split('.')[1] || '').length - (strArr[1] ? +strArr[1] : 0)
    }

    getExponent(num1, num2) {
        return Math.pow(10, !!num2 ? Math.max(num1, num2) : num1)
    }

    safeRoundUp(num, exponent) {
        return Math.round(num * exponent)
    }
}