<template>
    <Layout
        :loading="loading"
        :title="$t('buyaccount-title')"
        :custom-background="true"
    >
        <div
            class="d-md-flex flex-column justify-content-center align-items-center gap-4 px-2"
        >
            <CustomAccountFilters
                v-model="plan"
                :key="index"
                @update:modelValue="onPlanChanged"
                :capitals="capitals"
                :drawdowns="drawdowns"
                :profit_splits="profitSplits"
                :payout_frequencies="frequencies"
                :currencies="currencies"
            />

            <BuyAccountSummary
                class="w-100"
                :items="selectedItems"
                :processing="processing"
                v-model="index"
                @update:modelValue="onIndexChanged"
                @buy-account="onBuyAccount"
            />

            <a
                v-if="canAdd"
                class="compare-plans d-none d-md-block"
                href="#"
                @click="addPlan"
                >{{ comparePlansTitle }}</a
            >
        </div>
    </Layout>
</template>

<script setup>
import {
    ref,
    unref,
    watchEffect,
    readonly,
    toRaw,
    inject,
    watch,
    onMounted,
    computed,
} from "vue";

import Layout from "../layouts/header-sidebar-title-layout.vue";
import CustomAccountFilters from "../components/custom-account/Filters.vue";
import BuyAccountSummary from "../components/custom-account/Summary.vue";

import { useGtm } from "@gtm-support/vue-gtm";
import { useRouter } from "vue-router";
import { useNotification } from "@kyvg/vue3-notification";
import { storeToRefs } from "pinia";
import { useUserStore } from "../store/user.store";
import { useItemsStore } from "../store/items.store";
import { useCartStore } from "../store/cart.store";
import { usePopup } from "../store/popup.store";
import { useI18n } from "vue-i18n";
import {
    isValidItem,
    items2Sizes,
    items2MaxDailyDrawdowns,
    items2ProfitSplits,
    items2PayoutFrequencies,
    items2Currencies,
} from "../components/custom-account/utils/item";
import { useMarketingStore } from "../store/marketing.store";

const router = useRouter();

const userStore = useUserStore();
const { user } = storeToRefs(userStore);
const { fetchItems } = useItemsStore();
const cartStore = useCartStore();
const { addItemToCart, fetchCart } = cartStore;
const { cart } = storeToRefs(cartStore);
const $gtm = useGtm();
const trackKlaviyoEvent = inject("$trackKlaviyoEvent");
const formatNumber = inject("$formatNumber");
const { notify } = useNotification();
const marketingStore = useMarketingStore();

const { popup } = usePopup();

const i18n = useI18n();

const defaultPlan = {
    capital: 25000,
    drawdown: 5,
    profit_split: 80,
    payout_frequency: "monthly", // 'monthly' 'biweekly', 'on-demand'
    currency: "USD",
    platform: "ctrader",
};

const loading = ref(false);
const processing = ref(false);
const index = ref(0);
const items = ref([]);
const plans = ref([defaultPlan]);
const plan = ref(plans.value[0]);

const capitals = computed(() =>
    unref(items)
        ? items2Sizes(unref(items))
        : [5, 10, 25, 50, 100, 200].map((e) => e * 1000)
);
const drawdowns = computed(() =>
    unref(items) ? items2MaxDailyDrawdowns(unref(items)) : [3, 4, 5]
);
const profitSplits = computed(() =>
    unref(items) ? items2ProfitSplits(unref(items)) : [26, 40, 60, 80]
);
const frequencies = computed(() =>
    unref(items)
        ? items2PayoutFrequencies(unref(items))
        : ["mountly", "biweekly", "on-demand"]
);
const currencies = computed(() =>
    unref(items) ? items2Currencies(unref(items)) : ["USD", "EUR", "GBP"]
);
const comparePlansTitle = computed(() => {
    switch (index.value) {
        case 0:
            return "Compare Plans";
        case 1:
            return "Compare Another Plan";
        default:
            return "";
    }
});
const addPlan = () => {
    plans.value.push({ ...plans.value.at(-1) });
    plan.value = plans.value.at(-1);
    index.value = plans.value.length - 1;
};
const onPlanChanged = () => {
    plans.value[index.value] = plan.value;

    const item = findItem(plan.value);
    marketingStore.trackInteractions('items_selections', {
        item_id: item.version.id,
        max_daily_drawdown: item.props.max_daily_drawdown,
        price: item.version.price,
        currency: item.version.currency,
        size: item.version.size,
        name: item.version.name,
    });
};
const onIndexChanged = () => {
    plan.value = unref(plans.value[index.value]);
};
const canAdd = computed(() => plans.value.length < 3);

const findItem = (plan) => {
    const item = unref(items)
        ? unref(items).find((item) => {
              if (item.payout_type !== plan.payout_frequency) return false;
              if (item.props.profit_split !== plan.profit_split) return false;
              if (item.props.max_daily_drawdown !== plan.drawdown) return false;

              const version = item.versions
                  ? item.versions.find(
                        (version) =>
                            version.currency_account === plan.currency &&
                            version.size === plan.capital
                    )
                  : null;
              // console.log('version:',version.id)
              return !!version;
          })
        : null;

    if (item) {
        item.version = item.versions.find(
            (version) =>
                version.currency_account === plan.currency &&
                version.size === plan.capital
        );
        item.platform = plan.platform;
        // console.log('found', plan.capital, item.name, item.version.id)
        return JSON.parse(JSON.stringify(toRaw(item)));
    }
    return null;
};

const selectedItems = computed(() =>
    unref(items)
        ? unref(plans)
              .map(findItem)
              .filter((e) => !!e)
        : []
);
const selectedItem = computed(() =>
    unref(items) ? findItem(unref(plan)) : null
);
const unallowedCountries = ["ru", "ir", "iq", "kp", "sy", "af", "ye", "so"];
const onBuyAccount = async (item) => {
    const country = user.value.country;
    if (unallowedCountries.includes(country.toLowerCase())) {
        popup({
            settings: {
                disableCloseButton: true,
            },
            heading: i18n.t("buyaccount-unallowedCountry-title"),
            image: "unallowed-country.svg",
            body: i18n.t("buyaccount-unallowedCountry"),
            title: i18n.t("buyaccount-unallowedCountry-title"),
        });
        return;
    }

    const normalizePlatform = (platform) => {
        platform = platform ? platform.toLowerCase().trim() : "ctrader";
        switch (platform) {
            case "ctrader":
            case "c-trader":
                return "ctrader";
            case "match-trader":
            case "match-trade":
                return "match-trader";
            default:
                return platform;
        }
    };
    try {
        await fetchCart();
        if ((cart.value?.items || []).length) {
            return;
        }
    } catch (e) {
        console.error(e);
        notify({
            title: "Error",
            text: "An error occurred while fetching the cart",
            type: "error",
        });
        return;
    }

    processing.value = true;

    try {
        await addItemToCart(
            item.version.id,
            normalizePlatform(item.platform),
            item.payout_type === "on-demand" ? "on-demand" : "",
            item.type,
            item.payout_type,
            item.props.max_daily_drawdown
        );
    } catch (e) {
        console.error(e);
        notify({
            title: "Error",
            text: "An error occurred while adding the item to the cart",
            type: "error",
        });
        return;
    } finally {
        processing.value = false;
    }

    try {
        const brevoPayload = {
            c
        };
        trackKlaviyoEvent(
            "cart_updated",
            {
                email: user.value.email,
                FIRSTNAME: user.value.firstName,
                LASTNAME: user.value.lastName,
            },
            brevoPayload
        );
        $gtm.trackEvent({
            event: "add_to_cart",
            value: brevoPayload,
        });
    } finally {
        router.push("/checkout");
    }
};

watch(user, async () => {
    if (user.value) {
        await fetchCart();
        if ((cart.value?.items || []).length) {
            popup({
                settings: {
                    disableClose: true,
                },
                heading: i18n.t("shop-checkoutText"),
                image: "cart.svg",
                body: i18n.t("buyaccount-already-in-cart"),
                buttonText: i18n.t("buyaccount-go-to-checkout"),
                buttonClick: () => router.replace("/checkout"),
            });
        }
    }
});

onMounted(async () => {
    try {
        items.value = (await fetchItems({ type: "custom" })).filter((item) =>
            isValidItem(item)
        );
    } finally {
        loading.value = false;
    }
    return;
});
</script>

<style scoped lang="scss">
.compare-plans {
    color: rgba(254, 122, 92);
    font-family: Heebo, "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 24px;
    line-height: 36px;
    text-decoration: underline;
}
.compare-plans-mobile {
    color: rgba(254, 122, 92);
    font-family: Heebo, "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    text-decoration: underline;
}
</style>
