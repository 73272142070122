<template>
    <div class="px-2 d-md-flex justify-content-center w-100 mb-5 mb-md-0">
        <table :class="tableClass">
            <thead>
                <tr>
                    <th class="d-none d-md-table-cell">Account type</th>
                    <th
                        class="d-none d-md-table-cell clickable"
                        colspan="1"
                        v-for="(item, index) in items"
                        :key="index"
                        @click="emit('update:modelValue', index)"
                    >
                        <span>{{ title(index) }}</span>
                    </th>
                    <th
                        colspan="2"
                        v-for="(item, index) in items"
                        :key="index"
                        class="clickable d-md-none"
                        @click="emit('update:modelValue', index)"
                    >
                        <span>{{ title(index) }}</span>
                        <button
                            class="btn-buy mt-4 d-md-none"
                            @click="buyAccount(item)"
                            :disabled="processing || !item"
                        >
                            <span v-if="!processing">
                                <span class="left">
                                    {{ $t("buyaccount-buy_account") }}
                                </span>
                                <span class="right price">
                                    ${{ item.version.price.toFixed(2) }}
                                </span>
                            </span>
                            <i class="fa fa-spin fa-spinner" v-else></i>
                        </button>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="table-item" nowrap>
                        {{ $t("buyaccount-account-size") }}
                    </td>
                    <td
                        class="table-item"
                        v-for="(item, index) in items"
                        :key="index"
                    >
                        {{
                            $formatAmountRounded(
                                item.version.size,
                                item.version.currency_account
                            )
                        }}
                    </td>
                </tr>
                <tr>
                    <td
                        v-for="(item, index) in dividers"
                        :key="index"
                        class="divider"
                    >
                        <div class="divider" />
                    </td>
                </tr>

                <tr>
                    <td nowrap>{{ $t("leverage") }}</td>
                    <td v-for="(item, index) in items" :key="index">
                        {{ formatLeverage(item.props.leverage) }}
                    </td>
                </tr>
                <tr>
                    <td
                        v-for="(item, index) in dividers"
                        :key="index"
                        class="divider"
                    >
                        <div class="divider" />
                    </td>
                </tr>
                <tr>
                    <td nowrap>{{ $t("max_daily_drawdown") }}</td>
                    <td v-for="(item, index) in items" :key="index">
                        {{ formatPercents(item.props.max_daily_drawdown) }}
                    </td>
                </tr>
                <tr>
                    <td
                        v-for="(item, index) in dividers"
                        :key="index"
                        class="divider"
                    >
                        <div class="divider" />
                    </td>
                </tr>
                <tr>
                    <td nowrap>{{ $t("max_drawdown") }}</td>
                    <td v-for="(item, index) in items" :key="index">
                        {{ formatPercents(item.props.max_drawdown) }}
                    </td>
                </tr>
                <tr>
                    <td
                        v-for="(item, index) in dividers"
                        :key="index"
                        class="divider"
                    >
                        <div class="divider" />
                    </td>
                </tr>
                <tr>
                    <td nowrap>{{ $t("max_trader_value_score") }}</td>
                    <td v-for="(item, index) in items" :key="index">
                        {{
                            formatPercents(item.props.max_trader_value_score) ||
                            "-"
                        }}
                    </td>
                </tr>
                <tr>
                    <td
                        v-for="(item, index) in dividers"
                        :key="index"
                        class="divider"
                    >
                        <div class="divider" />
                    </td>
                </tr>
                <tr>
                    <td nowrap>{{ $t("Payouts") }}</td>
                    <td v-for="(item, index) in items" :key="index">
                        {{ formatPayoutFrequency(item.payout_type) }}
                    </td>
                </tr>
                <tr>
                    <td
                        v-for="(item, index) in dividers"
                        :key="index"
                        class="divider"
                    >
                        <div class="divider" />
                    </td>
                </tr>
                <tr>
                    <td nowrap>{{ $t("profit_split") }}</td>
                    <td v-for="(item, index) in items" :key="index">
                        {{ formatPercents(item.props.profit_split) }}
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td>{{ $t("payout-calculator") }}</td>
                    <td
                        v-for="(item, index) in items"
                        :key="index"
                        @click="openPayoutCalculatorModal(item)"
                        :style="{ cursor: 'pointer' }"
                    >
                        <i class="fa fa-info-circle"></i>
                    </td>
                </tr>
                <tr>
                    <td class="d-none d-md-table-cell" rowspan="2">
                        {{ $t("price") }}
                    </td>
                    <td
                        class="d-none d-md-table-cell"
                        rowspan="2"
                        v-for="(item, index) in items"
                        :key="index"
                    >
                        <div>
                            <div class="price">{{ formatPrice(item) }}</div>
                            <div class="pt-4">
                                <button
                                    class="btn-buy"
                                    @click="buyAccount(item)"
                                    :disabled="processing || !item"
                                >
                                    <span v-if="!processing">{{
                                        $t("buyaccount-buy_account")
                                    }}</span>
                                    <i class="fa fa-spin fa-spinner" v-else></i>
                                </button>
                            </div>
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
    <PayoutCalculator
        :showModal="showModal"
        @update:showModal="showModal = $event"
        :selectedItem="selectedItem"
    />
</template>
<script setup>
import { ref, toRaw, computed } from "vue";

import {
    formatPayoutFrequency,
    formatLeverage,
    formatPercents,
} from "./utils/format";
import Bundle from "./Bundle.vue";
import PayoutCalculator from "./PayoutCalculator.vue";

const showModal = ref(false);
const selectedItem = ref(null);

const props = defineProps({
    modelValue: {
        required: true,
        type: Number,
    },
    items: {
        required: true,
        type: Array,
    },
    processing: false,
});

const emit = defineEmits(["update:modelValue", "buy-account"]);

const bundlePromoEnabled = computed(() => {
    const untilDate = new Date("2024-10-14 23:59:59");
    return new Date() <= untilDate;
});

const title = (index) => {
    return index === 0 ? "Custom Plan" : `Custom Plan #${index + 1}`;
};

const dividers = computed(() => [0, ...props.items.map((e, index) => index)]);
const tableClass = computed(
    () => `i${props.items.length} s${props.modelValue + 1}`
);

const formatPrice = (item) => {
    return item && item.version ? `$${item.version.price.toFixed(2)}` : "-";
};

const buyAccount = (item) => {
    emit("buy-account", toRaw(item));
};

const openPayoutCalculatorModal = (item) => {
    showModal.value = true;
    selectedItem.value = item;
};
</script>
<style scoped lang="scss">
.price {
    font-size: 26px;
    line-height: 26px;
    font-weight: 700;
}

table {
    margin-top: 40px;
    border: 1px solid rgba(255, 255, 255, 0.2);

    th {
        font-weight: 600;
        font-size: 24px;
        background: rgba(255, 255, 255, 0.1);
    }

    @media screen and (max-width: 767.98px) {
        border: none;

        th {
            text-align: center;
            position: relative;
            font-size: 1rem;
            vertical-align: text-top;
            height: 80px;

            .btn-buy {
                position: absolute;
                left: 0;
                right: 0;
                top: 2rem;
                border-radius: 10px;
                font-family: "Josefin Sans", sans-serif;
                font-optical-sizing: auto;
                font-weight: 600;
                font-style: normal;
                font-size: 14px;
                line-height: 20px;
                color: white;
                background: rgb(255, 104, 68, 1);
                padding: 8px 16px 8px 16px;
                margin: auto;
                width: 100%;
                min-width: 150px;
                max-width: 350px;
                border: none;
                vertical-align: sub;
            }

            .btn-buy .price {
                font-weight: 700;
                font-style: normal;
                font-size: 16px;
            }

            .btn-buy span .left {
                float: left;
            }

            .btn-buy span .right {
                padding-top: 2px;
                float: right;
            }

            .btn-buy:disabled {
                color: rgba(255, 255, 255, 0.5);
                background: rgba(255, 255, 255, 0.05);
            }
        }
    }
}

table tfoot {
    border-top: 1px solid rgba(255, 255, 255, 0.2);

    @media screen and (max-width: 767.9px) {
        border-top: none;
    }
}
table th,
td {
    padding: 24px;
    text-align: center;
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    max-width: 300px;
    color: white;
    border-left: 1px solid rgba(255, 255, 255, 0.2);

    @media screen and (max-width: 767.98px) {
        font-size: 16px;
        padding: 1rem 0;
        border-left: none;
        max-width: 150px;
        word-break: break-word;
        text-wrap: balance;

        &.table-item {
            padding-top: 2.5rem;
        }

        &:first-child {
            text-align: left;
        }

        &:not(th):last-child {
            text-align: right;
        }
    }
}

table th.clickable {
    cursor: pointer;
}

table.i1 {
    max-width: 794px;
    width: 100%;
}

table.i2 {
    max-width: 1102px;
    width: 100%;
}

table.i3 {
    max-width: 1218px;
    width: 100%;
}

table.s1 tr td:first-child + td {
    background-color: rgba(255, 255, 255, 0.05);
}

table.s1 tr th:first-child + th {
    background-color: rgba(255, 255, 255, 0.05);
}

table.i2.s1 tr th:first-child + th,
table.i3.s1 tr th:first-child + th {
    background-color: rgba(255, 255, 255, 0.05);

    span {
        background: linear-gradient(
            125.02deg,
            #ff6846 8.97%,
            #fb8469 21.12%,
            #7352fd 89.46%
        );
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

table.i1.s1 tr td:first-child + td {
    background-color: transparent;
}

table.i1.s1 tr th:first-child + th {
    background-color: rgba(255, 255, 255, 0.1);
}

table.s2 tr td:first-child + td + td {
    background-color: rgba(255, 255, 255, 0.05);
}

table.s2 tr th:first-child + th + th {
    background-color: rgba(255, 255, 255, 0.05);

    span {
        background: linear-gradient(
            125.02deg,
            #ff6846 8.97%,
            #fb8469 21.12%,
            #7352fd 89.46%
        );
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

table.s3 tr td:first-child + td + td + td {
    background-color: rgba(255, 255, 255, 0.05);
}

table.s3 tr th:first-child + th + th + th {
    background-color: rgba(255, 255, 255, 0.05);

    span {
        background: linear-gradient(
            125.02deg,
            #ff6846 8.97%,
            #fb8469 21.12%,
            #7352fd 89.46%
        );
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

td.divider {
    height: 1px;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 0px;
    padding-bottom: 0px;

    @media screen and (max-width: 797.7px) {
        &:first-child {
            padding-right: 0;
        }

        &:last-child {
            padding-left: 0;
        }
    }
}
div.divider {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.btn-buy {
    height: 41px;
    padding: 10px 8px 10px 8px;
    border-radius: 8px;
    font-family: Heebo, "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: white;
    background: rgb(255, 104, 68, 1);
    margin: auto;
    width: 218px;
    min-width: 150px;
    max-width: 218px;
    border: none;
}

.btn-buy:disabled {
    color: rgba(255, 255, 255, 0.5);
    background: rgba(255, 255, 255, 0.05);
}
</style>
