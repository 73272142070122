<template>
    <h1>Oauth</h1>
</template>

<script>
export default {
    mounted(){
        console.log(this.$route.params.token)
    }
}
</script>

<style>

</style>
