<template>
    <div id="main-wrapper">
        <!-- Header -->
        <header class="topbar">
            <Navbar :stop="stopnav" />
        </header>

        <!-- Sidebar -->
        <aside class="left-sidebar">
            <div class="scroll-sidebar">
                <Sidebar @rank="watchRank" />
            </div>
        </aside>

        <!-- Page Wrapper -->
        <div class="page-wrapper mb-3" :class="showRank ? 'opacity-25' : ''">
            <div class="container-fluid">
                <!-- Title -->
                <div class="row mt-5 pb-5">
                    <div>
                        <h2 class="card-title text-white">{{ $t("all-p") }}</h2>
                    </div>
                    <Spinner v-if="loading" />
                    <div
                        id="payout-table-wrapper"
                        class="col-lg-12 col-md-12 mt-4"
                    >
                        <!-- Table Section -->
                        <div id="payout-table"></div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Footer -->
        <Footer />
    </div>
</template>

<script>
import Sidebar from "../components/Sidebar.vue";
import Navbar from "../components/statics/navbar.vue";
import Footer from "../components/statics/footer.vue";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import { usePayoutStore } from "../store/payout.store";
import { useUserStore } from "../store/user.store";
import moment from "moment";
import Spinner from "../components/Spinner.vue";
import { useI18n } from "vue-i18n";

export default {
    name: "Payouts",
    components: {
        Footer,
        Navbar,
        Sidebar,
        Spinner,
    },
    data() {
        return {
            isEditing: false,
            payouts: [],
            selectedPayout: null,
            editAmount: null,
            editStatus: "",
            stopnav: false,
            showRank: false,
            waitingPayoutsCount: 0,
            loading: true,
            steps: [],
        };
    },

    methods: {
        openEditForm(row) {
            const data = row.getData();
            if (data) {
                const payoutStore = usePayoutStore();
                this.selectedPayout = data;
                this.editAmount = data.payout;
                this.editStatus = data.paymentStatus;
                this.isEditing = true;
                payoutStore.setStep(1);
                this.$router.push({ path: `/customer-payouts/${data.id}` });
            }
        },
        onConfirmed(row) {
            const data = row.getData();
            if (data) {
                const payoutStore = usePayoutStore();
                this.selectedPayout = data;
                this.editAmount = data.payout;
                this.editStatus = data.paymentStatus;
                this.isEditing = true;
                payoutStore.setStep(2);
                this.$router.push({ path: `/customer-payouts/${data.id}` });
            }
        },
        onPaid(row) {
            const data = row.getData();
            if (data) {
                const payoutStore = usePayoutStore();
                this.selectedPayout = data;
                this.editAmount = data.payout;
                this.editStatus = data.paymentStatus;
                this.isEditing = true;
                payoutStore.setStep(3);
                this.$router.push({ path: `/customer-payouts/${data.id}` });
            }
        },

        saveEdit() {
            this.isEditing = false;
        },

        cancelEdit() {
            this.isEditing = false;
        },
        async getPayouts() {
            this.loading = true;
            const payoutStore = usePayoutStore();
            const response = await payoutStore.fetchDataPayouts();
            if (response && Array.isArray(response.data)) {
                const tableData = response.data
                    .filter((pay) => pay.id && pay.paymentStatus)
                    .map((pay) => ({
                        id: pay.id,
                        account_number: pay.uuid,
                        created_at: this.formatDate(pay.created_at),
                        payout_amount: pay.payout,
                        paymentStatus: pay.paymentStatus,
                        status: pay.status,
                        _id: pay._id,
                    }));
                this.payouts = tableData;
                this.initializeTabulator();
                this.loading = false;
            } else {
                this.loading = true;
            }
        },

        initializeTabulator() {
            if (this.tabulator) {
                this.tabulator.destroy();
            }

            this.tabulator = new Tabulator("#payout-table", {
                data: this.payouts,
                paginationSize: this.itemsPerPage,
                responsiveLayout:"hide",
                layout: "fitColumns",
                columns: [
                    { title: "Payout ID", field: "id", sortable: true, width:150, responsive:0 },
                    {
                        title: this.$t("AccNumb"),
                        field: "account_number",
                        hozAlign: "center",
                        sortable: false,
                        minWidth:170,
                        responsive:0
                    },
                    {
                        title: this.$t("Date"),
                        field: "created_at",
                        sortable: false,
                        minWidth:150, responsive:0
                    },
                    {
                        title: this.$t("Amount") + "(USD)",
                        field: "payout_amount",
                        hozAlign: "center",
                        sortable: false,
                        formatter: "money",
                        formatterParams: {
                            decimal: ".",
                            thousand: ",",
                            symbol: "$",
                        },
                        minWidth:150, responsive:0
                    },
                    {
                        title: this.$t("Status"),
                        field: "status",
                        hozAlign: "center",
                        sortable: false,
                        formatter: function (cell) {
                            const status = cell.getValue();
                            let iconHtml = "";
                            let statusText = '';
                            if (status === "completed") {
                                iconHtml = `<i class="fas fa-check-double" style="color: green;"></i>`;
                                statusText = "Completed";
                            } else if (status === "cancelled") {
                                iconHtml = `<i class="fas fa-times" style="color: red; margin-right: 4px"></i>`;
                                statusText = "Cancelled";
                            } else if (status === "delayed") {
                                iconHtml = `<i class="fas fa-clock" style="color: orange;"></i>`;
                                statusText = "Delayed";
                            } else if (status === "confirmed") {
                                iconHtml = `<i class="fas fa-check" style="color: #1E90FF;"></i>`;
                                statusText = "Confirmed";
                            }

                            return `${iconHtml} <span class="iconHtml">${statusText}</span>`;
                        },
                        minWidth:150, responsive:0
                    },

                    {
                        title: this.$t("PaymStat"),
                        field: "paymentStatus",
                        hozAlign: "center",
                        formatter: function (cell) {
                            const paymentStatus = cell.getValue();
                            let iconHtml = "";
                            let statusText = "";

                            if (paymentStatus === "paid") {
                                iconHtml = `<i class="fas fa-check-double" style="color: green;"></i>`;
                                statusText = "Paid";
                            } else if (
                                paymentStatus === "waiting_data_confirmation"
                            ) {
                                iconHtml = `<i class="fas fa-clock" style="color: orange;"></i>`;
                                statusText = "Waiting data confirmation";
                            } else if (paymentStatus === "data_confirmed") {
                                iconHtml = `<i class="fas fa-check" style="color: #1E90FF;"></i>`;
                                statusText = "Data confirmed";
                            }
                            return `${iconHtml} <span class="iconHtml">${statusText}</span>`;
                        },
                        sortable: false,
                        minWidth:150, responsive:0
                    },
                    ,
                    {
                        title: this.$t("Action"),
                        field: "action",
                        hozAlign: "center",
                        formatter: function (cell) {
                            const value = cell.getData().paymentStatus;
                            let actionButton = "";
                            if (value === "waiting_data_confirmation") {
                                actionButton = `
                            <button class="text-button edit-button">
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.4616 6.87821L17.272 2.68946C17.1327 2.55014 16.9673 2.43962 16.7853 2.36421C16.6033 2.28881 16.4082 2.25 16.2112 2.25C16.0142 2.25 15.8191 2.28881 15.6371 2.36421C15.4551 2.43962 15.2897 2.55014 15.1504 2.68946L3.59009 14.2498C3.45019 14.3886 3.33928 14.5538 3.2638 14.7358C3.18832 14.9178 3.14977 15.113 3.1504 15.3101V19.4998C3.1504 19.8976 3.30843 20.2791 3.58974 20.5604C3.87104 20.8417 4.25257 20.9998 4.6504 20.9998H20.4004C20.5993 20.9998 20.7901 20.9208 20.9307 20.7801C21.0714 20.6395 21.1504 20.4487 21.1504 20.2498C21.1504 20.0509 21.0714 19.8601 20.9307 19.7194C20.7901 19.5788 20.5993 19.4998 20.4004 19.4998H10.9616L21.4616 8.99977C21.601 8.86048 21.7115 8.69511 21.7869 8.5131C21.8623 8.33109 21.9011 8.136 21.9011 7.93899C21.9011 7.74198 21.8623 7.5469 21.7869 7.36489C21.7115 7.18288 21.601 7.0175 21.4616 6.87821ZM8.84009 19.4998H4.6504V15.3101L12.9004 7.06009L17.0901 11.2498L8.84009 19.4998ZM18.1504 10.1895L13.9616 5.99977L16.2116 3.74977L20.4004 7.93946L18.1504 10.1895Z" fill="#FF9571"/>
                                </svg>
                                Edit
                            </button>
                        `;
                            } else if (value === "paid") {
                                actionButton = `
                            <button class="text-button paid">
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_6581_29312)">
                                        <path d="M23.3357 11.6962C23.3029 11.6222 22.5088 9.86062 20.7435 8.09531C18.3913 5.74312 15.4204 4.5 12.1504 4.5C8.88038 4.5 5.90944 5.74312 3.55726 8.09531C1.79194 9.86062 0.994132 11.625 0.965069 11.6962C0.922425 11.7922 0.900391 11.896 0.900391 12.0009C0.900391 12.1059 0.922425 12.2097 0.965069 12.3056C0.997882 12.3797 1.79194 14.1403 3.55726 15.9056C5.90944 18.2569 8.88038 19.5 12.1504 19.5C15.4204 19.5 18.3913 18.2569 20.7435 15.9056C22.5088 14.1403 23.3029 12.3797 23.3357 12.3056C23.3783 12.2097 23.4004 12.1059 23.4004 12.0009C23.4004 11.896 23.3783 11.7922 23.3357 11.6962ZM12.1504 18C9.26476 18 6.74382 16.9509 4.65694 14.8828C3.80067 14.0313 3.07218 13.0603 2.49413 12C3.07203 10.9396 3.80053 9.9686 4.65694 9.11719C6.74382 7.04906 9.26476 6 12.1504 6C15.036 6 17.5569 7.04906 19.6438 9.11719C20.5018 9.9684 21.2318 10.9394 21.8113 12C21.1354 13.2619 18.1907 18 12.1504 18ZM12.1504 7.5C11.2604 7.5 10.3903 7.76392 9.65032 8.25839C8.91029 8.75285 8.33352 9.45566 7.99292 10.2779C7.65233 11.1002 7.56321 12.005 7.73685 12.8779C7.91048 13.7508 8.33906 14.5526 8.9684 15.182C9.59774 15.8113 10.3996 16.2399 11.2725 16.4135C12.1454 16.5872 13.0502 16.4981 13.8725 16.1575C14.6947 15.8169 15.3975 15.2401 15.892 14.5001C16.3865 13.76 16.6504 12.89 16.6504 12C16.6491 10.8069 16.1746 9.66303 15.331 8.81939C14.4873 7.97575 13.3435 7.50124 12.1504 7.5ZM12.1504 15C11.557 15 10.977 14.8241 10.4837 14.4944C9.99032 14.1648 9.60581 13.6962 9.37874 13.1481C9.15168 12.5999 9.09227 11.9967 9.20803 11.4147C9.32378 10.8328 9.6095 10.2982 10.0291 9.87868C10.4486 9.45912 10.9832 9.1734 11.5651 9.05764C12.1471 8.94189 12.7503 9.0013 13.2984 9.22836C13.8466 9.45542 14.3151 9.83994 14.6448 10.3333C14.9744 10.8266 15.1504 11.4067 15.1504 12C15.1504 12.7956 14.8343 13.5587 14.2717 14.1213C13.7091 14.6839 12.946 15 12.1504 15Z" fill="white" fill-opacity="0.7"/>
                                    </g>
                                </svg>
                                View
                            </button>
                        `;
                            } else if (value === "data_confirmed") {
                                actionButton = `
                            <button class="text-button confirm-button">
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_6581_29312)">
                                        <path d="M23.3357 11.6962C23.3029 11.6222 22.5088 9.86062 20.7435 8.09531C18.3913 5.74312 15.4204 4.5 12.1504 4.5C8.88038 4.5 5.90944 5.74312 3.55726 8.09531C1.79194 9.86062 0.994132 11.625 0.965069 11.6962C0.922425 11.7922 0.900391 11.896 0.900391 12.0009C0.900391 12.1059 0.922425 12.2097 0.965069 12.3056C0.997882 12.3797 1.79194 14.1403 3.55726 15.9056C5.90944 18.2569 8.88038 19.5 12.1504 19.5C15.4204 19.5 18.3913 18.2569 20.7435 15.9056C22.5088 14.1403 23.3029 12.3797 23.3357 12.3056C23.3783 12.2097 23.4004 12.1059 23.4004 12.0009C23.4004 11.896 23.3783 11.7922 23.3357 11.6962ZM12.1504 18C9.26476 18 6.74382 16.9509 4.65694 14.8828C3.80067 14.0313 3.07218 13.0603 2.49413 12C3.07203 10.9396 3.80053 9.9686 4.65694 9.11719C6.74382 7.04906 9.26476 6 12.1504 6C15.036 6 17.5569 7.04906 19.6438 9.11719C20.5018 9.9684 21.2318 10.9394 21.8113 12C21.1354 13.2619 18.1907 18 12.1504 18ZM12.1504 7.5C11.2604 7.5 10.3903 7.76392 9.65032 8.25839C8.91029 8.75285 8.33352 9.45566 7.99292 10.2779C7.65233 11.1002 7.56321 12.005 7.73685 12.8779C7.91048 13.7508 8.33906 14.5526 8.9684 15.182C9.59774 15.8113 10.3996 16.2399 11.2725 16.4135C12.1454 16.5872 13.0502 16.4981 13.8725 16.1575C14.6947 15.8169 15.3975 15.2401 15.892 14.5001C16.3865 13.76 16.6504 12.89 16.6504 12C16.6491 10.8069 16.1746 9.66303 15.331 8.81939C14.4873 7.97575 13.3435 7.50124 12.1504 7.5ZM12.1504 15C11.557 15 10.977 14.8241 10.4837 14.4944C9.99032 14.1648 9.60581 13.6962 9.37874 13.1481C9.15168 12.5999 9.09227 11.9967 9.20803 11.4147C9.32378 10.8328 9.6095 10.2982 10.0291 9.87868C10.4486 9.45912 10.9832 9.1734 11.5651 9.05764C12.1471 8.94189 12.7503 9.0013 13.2984 9.22836C13.8466 9.45542 14.3151 9.83994 14.6448 10.3333C14.9744 10.8266 15.1504 11.4067 15.1504 12C15.1504 12.7956 14.8343 13.5587 14.2717 14.1213C13.7091 14.6839 12.946 15 12.1504 15Z" fill="white" fill-opacity="0.7"/>
                                    </g>
                                </svg>
                                View
                            </button>
                        `;
                            }
                            return actionButton;
                        },
                        cellClick: (e, cell) => {
                            if (e.target.classList.contains("edit-button")) {
                                this.openEditForm(cell.getRow());
                            } else if (e.target.classList.contains("paid")) {
                                this.onPaid(cell.getRow());
                            } else if (
                                e.target.classList.contains("confirm-button")
                            ) {
                                this.onConfirmed(cell.getRow());
                            }
                        },
                        sortable: false,
                        minWidth:100,
                         responsive:0
                    },
                ],
            });
        },
        formatDate(dateString) {
            return moment
                .tz(dateString, "Europe/Rome")
                .format("DD-MM-YYYY HH:mm");
        },
    },
    mounted() {
        useUserStore()
            .me()
            .then(() => {
                this.getPayouts();
            })
            .catch((error) => {
                this.loading = false;
                console.error("Error loading user data:", error);
            });
        this.initializeTabulator();
        this.getPayouts();
    },
};
</script>

<style scoped>
.iconHtml {
    margin-left: 8px;
    text-transform: capitalize;
}
h2 {
    font-family: Heebo;
    font-weight: 500;
}
::v-deep .text-button {
    background: none;
    border: none;
    color: rgba(255, 255, 255, 0.93) !important;
    font-size: 14px;
    font-weight: 300;
    cursor: pointer;
    display: flex;
    align-items: center;
}
::v-deep .text-button svg {
    fill: #ff9571;
    margin-right: 8px;
}
::v-deep .tabulator .tabulator-col-sorter {
    display: none !important;
}
::v-deep .tabulator {
    background-color: rgba(255, 255, 255, 0.05) !important;
    color: rgba(255, 255, 255, 0.93) !important;
    font-size: 14px;
    font-weight: 300;
    text-align: left;
    font-family: Heebo;
    padding-bottom: 40px;
}

::v-deep .tabulator-col-title {
    font-weight: 500;
}

::v-deep .tabulator-table {
    background-color: rgba(255, 255, 255, 0.05) !important;
}

::v-deep .tabulator-header .tabulator-col {
    background-color: rgba(255, 255, 255, 0.04) !important;
    border-bottom: none !important;
}

::v-deep .tabulator .tabulator-row:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.2) !important;
}

::v-deep .tabulator .tabulator-row:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.2) !important;
}

::v-deep .tabulator .tabulator-header {
    background-color: rgba(255, 255, 255, 0.04) !important;
    border-bottom: none !important;
    border-top: none !important;
}

::v-deep .tabulator .tabulator-footer {
    background-color: rgba(23, 23, 23, 0.048) !important;
    color: transparent !important;
    border-top: none !important;
}

::v-deep .tabulator .tabulator-cell {
    background-color: rgba(255, 255, 255, 0.037) !important;
    padding: 10px !important;
    padding-left: 16px !important;
    text-align: left !important;
}

::v-deep .tabulator-row {
    border: none !important;
    box-shadow: none !important;
}

::v-deep .tabulator-cell {
    border: none !important;
}

::v-deep .tabulator-header .tabulator-col {
    border-bottom: none !important;
    padding-left: 5px !important;
    font-size: 14px;
}

select,
option {
    background-color: rgba(255, 255, 255, 0.1) !important;
    color: white !important;
    border: 1px solid white;
}

select,
option {
    background-color: #ffffff0d !important;
    border: none;
}

img {
    width: 20px;
}
@media (max-width: 768px) {
    #payout-table-wrapper {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    ::v-deep .tabulator-header .tabulator-col {
        font-size: 12px;
    }

    ::v-deep .tabulator .tabulator-cell {
        font-size: 12px;
        padding: 8px !important;
    }
}
</style>
