<template>
    <!-- Limit Offer Mobile -->
    <div class="limitOfferMobile hiddenDesktop">
        <h3>EXCLUSIVE BUNDLE OFFER</h3>
        <p>2 Instantly Funded<span> Accounts at a Special Price</span></p>
        <div class="btnBundles">
            <button class="btnBundle1" @click="handleClick(0)">Bundle 1</button>
            <button class="btnBundle2" @click="handleClick(1)" >Bundle 2</button>
        </div>
    </div>
    <!-- END Limit Offer Mobile -->

    <!-- Table Price -->
    <div class="px-2 d-md-flex justify-content-center w-100 mb-5 mb-md-0">
        <table :class="tableClass">
            <thead>
                <tr>
                    <th class="d-none d-md-table-cell table-items-start">Account type</th>
                    <th class="d-none d-md-table-cell clickable" colspan="1" v-for="(item, index) in items" :key="index"
                        @click="emit('update:modelValue', index)">
                        <span>{{ title(index) }}</span>
                    </th>
                    <th colspan="2" v-for="(item, index) in items" :key="index" class="clickable d-md-none"
                        @click="emit('update:modelValue', index)">
                        <span>{{ title(index) }}</span>
                        <button class="btn-buy mt-4 d-md-none" @click="buyAccount(item)"
                            :disabled="processing || !item">
                            <span v-if="!processing">
                                <span class="left">
                                    {{ $t("buyaccount-buy_account") }}
                                </span>
                                <span class="right price">
                                    <span class="discounted-price-mobile"
                                        v-if="item.version.promo_rules?.some(p => p.show_discounted_price)">${{
                                            item.version.original_price }}</span>
                                    ${{ item.version.price.toFixed(2) }}
                                </span>
                            </span>
                            <i class="fa fa-spin fa-spinner" v-else></i>
                        </button>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="table-items-start paddingSmall" nowrap>
                        {{ $t("buyaccount-account-size") }}
                    </td>
                    <td class="table-item paddingSmallR" v-for="(item, index) in items" :key="index">
                        {{
                            $formatAmountRounded(
                                item.version.size,
                                item.version.currency_account
                            )
                        }}
                    </td>
                </tr>
                <tr>
                    <td v-for="(item, index) in dividers" :key="index" class="divider">
                        <div class="divider" />
                    </td>
                </tr>

                <tr>
                    <td class="table-items-start" nowrap>{{ $t("leverage") }}</td>
                    <td class="paddingSmallR" v-for="(item, index) in items" :key="index">
                        {{ formatLeverage(item.props.leverage) }}
                    </td>
                </tr>
                <tr>
                    <td v-for="(item, index) in dividers" :key="index" class="divider">
                        <div class="divider" />
                    </td>
                </tr>
                <tr>
                    <td class="table-items-start" nowrap>{{ $t("max_daily_drawdown") }}</td>
                    <td class="paddingSmallR" v-for="(item, index) in items" :key="index">
                        {{ formatPercents(item.props.max_daily_drawdown) }}
                    </td>
                </tr>
                <tr>
                    <td v-for="(item, index) in dividers" :key="index" class="divider">
                        <div class="divider" />
                    </td>
                </tr>
                <tr>
                    <td class="table-items-start" nowrap>{{ $t("max_drawdown") }}</td>
                    <td class="paddingSmallR" v-for="(item, index) in items" :key="index">
                        {{ formatPercents(item.props.max_drawdown) }}
                    </td>
                </tr>
                <tr>
                    <td  v-for="(item, index) in dividers" :key="index" class="divider">
                        <div class="divider" />
                    </td>
                </tr>
                <tr>
                    <td class="table-items-start" nowrap>{{ $t("max_trader_value_score") }}</td>
                    <td class="paddingSmallR" v-for="(item, index) in items" :key="index">
                        {{
                            formatPercents(item.props.max_trader_value_score) ||
                            "-"
                        }}
                    </td>
                </tr>
                <tr>
                    <td v-for="(item, index) in dividers" :key="index" class="divider">
                        <div class="divider" />
                    </td>
                </tr>
                <tr>
                    <td class="table-items-start" nowrap>{{ $t("Payouts") }}</td>
                    <td class="paddingSmallR" v-for="(item, index) in items" :key="index">
                        {{ formatPayoutFrequency(item.payout_type) }}
                    </td>
                </tr>
                <tr>
                    <td v-for="(item, index) in dividers" :key="index" class="divider">
                        <div class="divider" />
                    </td>
                </tr>
                <tr>
                    <td class="table-items-start" nowrap>{{ $t("profit_split") }}</td>
                    <td class="paddingSmallR" v-for="(item, index) in items" :key="index">
                        {{ formatPercents(item.props.profit_split) }}
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td class="table-items-start">{{ $t("payout-calculator") }}</td>
                    <td v-for="(item, index) in items" :key="index" @click="openPayoutCalculatorModal(item)"
                        :style="{ cursor: 'pointer' }">
                        <i class="fa fa-info-circle paddingSmallR"></i>
                    </td>
                </tr>
                <tr>
                    <td class="d-none d-md-table-cell table-items-start" rowspan="2">
                        {{ $t("price") }}
                    </td>
                    <td class="d-none d-md-table-cell" rowspan="2" v-for="(item, index) in items" :key="index">
                        <div>
                            <div class="discounted-price"
                                v-if="item.version.promo_rules?.some(p => p.show_discounted_price)">
                                <span class="original-price">{{ $formatAmount(item.version.original_price) }}</span>
                                <span class="text-success" v-if="item.version.discount_type === 'percentage'">-{{
                                    item.version.discount_value }}% OFF</span>
                                <span class="text-success" v-else-if="item.version.discount_type === 'fixed'">-{{
                                    $formatAmount(item.version.discount_value) }} OFF</span>
                                <span class="price">{{ formatPrice(item) }}</span>
                            </div>
                            <div v-else>
                                {{ formatPrice(item) }}
                            </div>
                            <div class="pt-4">
                                <button class="btn-buy" @click="buyAccount(item)" :disabled="processing || !item">
                                    <span v-if="!processing">{{
                                        $t("buyaccount-buy_account")
                                    }}</span>
                                    <i class="fa fa-spin fa-spinner" v-else></i>
                                </button>
                            </div>
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>
        <!-- END Table Price -->

        <!-- Limit Offer Desktop -->
        <div class="limitOffer" v-if="showBundleBox && bundlePromoEnabled">
            <div>
                <h3 class="margin16">LIMITED OFFER</h3>
                <p class="margin28">Get <strong>2</strong> Instantly Funded <br>
                    <span>Accounts at a Special Price</span>
                </p>
                <p class="points">✓ 2 accounts instead of 1</p>
                <p class="points">✓ 95% Profit Split</p>
            </div>
            <div class="counterdown">
                <button @click="emit('update:showBundle', true)">Show Bundles</button>
                <div class="countdownContainer">
                    <div class="countdown-block">
                        <span id="days">{{ daysDiff }}</span>
                        <p>
                            {{ $t('countdown.days') }}
                        </p>
                    </div>
                    <div class="countdown-block">
                        <span id="hours">{{ hoursDiff }}</span>
                        <p>
                            {{ $t('countdown.hours') }}
                        </p>
                    </div>
                    <div class="countdown-block">
                        <span id="minutes">{{ minDiff }}</span>
                        <p>
                            {{ $t('countdown.minutes') }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- END Limit Offer Desktop -->

    </div>
    <PayoutCalculator :showModal="showModal" @update:showModal="showModal = $event" :selectedItem="selectedItem" />
</template>
<script setup>
import { ref, toRaw, computed, watch, onMounted } from "vue";

import { useItemsStore } from '../../store/items.store';
const itemsStore = useItemsStore();

import {
    formatPayoutFrequency,
    formatLeverage,
    formatPercents,
} from "./utils/format";
import Bundle from "./Bundle.vue";
import PayoutCalculator from "./PayoutCalculator.vue";
import { useGtm } from "@gtm-support/vue-gtm";
import { valueOrDefault } from "chart.js/helpers";

const showModal = ref(false);
const selectedItem = ref(null);
const $gtm = useGtm();

const props = defineProps({
    modelValue: {
        required: true,
        type: Number,
    },
    items: {
        required: true,
        type: Array,
    },
    showBundleBox: false,
    processing: false,
});

const emit = defineEmits(["update:modelValue", "buy-account"]);


const untilDate = new Date('2025-02-25 23:59:59');
const daysDiff = ref('00');
const hoursDiff = ref('00');
const minDiff = ref('00');
const secondsDiff = ref('00');

const getTimeDiff = () => {
  const currentDate = new Date();
  const timeLeft = untilDate.getTime() - currentDate.getTime();
  if (timeLeft <= 0) {
    router.replace('/buy-account');
  }

  let delta = Math.abs(timeLeft) / 1000;

  const days = Math.floor(delta / 86400).toString();
  daysDiff.value = days.length === 1 ? `0${days}` : days;
  delta -= Number(days) * 86400;

  const hours = (Math.floor(delta / 3600) % 24).toString();
  hoursDiff.value = hours.length === 1 ? `0${hours}` : hours;
  delta -= Number(hours) * 3600;

  const min = (Math.floor(delta / 60) % 60).toString();
  minDiff.value = min.length === 1 ? `0${min}` : min;

  const sec = Math.floor(delta % 60).toString();
  secondsDiff.value = sec.length === 1 ? `0${sec}` : sec;
}

const handleClick = (value) =>{
    itemsStore.switchSelectedBundle(value)
    emit('update:showBundle', true)
}

const bundlePromoEnabled = computed(() => {
    const untilDate = new Date("2025-02-25 23:59:59");
    return new Date() <= untilDate;
});

const title = (index) => {
    return index === 0 ? "Custom Plan" : `Custom Plan #${index + 1}`;
};

const dividers = computed(() => [0, ...props.items.map((e, index) => index)]);
const tableClass = computed(
    () => `i${props.items.length} s${props.modelValue + 1}`
);

const formatPrice = (item) => {
    return item && item.version ? `$${item.version.price.toFixed(2)}` : "-";
};

const buyAccount = (item) => {
    emit("buy-account", toRaw(item));
};

const openPayoutCalculatorModal = (item) => {
    showModal.value = true;
    selectedItem.value = item;
};

onMounted(async () => {
  getTimeDiff();
})
</script>
<style scoped lang="scss">
.table-items-start{
    padding-left: 60px;
    text-align: start!important;
}
.margin28{
    margin-bottom: 28px;
}
.margin16{
    margin-bottom: 16px;
}
.price {
    font-size: 26px;
    line-height: 26px;
    font-weight: 700;
}

table {
    margin-top: 40px;
    border: 1px solid rgba(255, 255, 255, 0.2);

    th {
        font-weight: 600;
        font-size: 24px;
        background: rgba(255, 255, 255, 0.1);
    }
    @media screen and (max-width: 767.98px) {
        border: none;
        .paddingSmall{
            padding-top: 35px!important;
        }
        .table-items-start{
            padding-left: 20px;
            text-align: start!important;
        }
        .paddingSmallR{
            padding-right: 20px;
            text-align: end!important;
        }
        th {
            text-align: center;
            position: relative;
            font-size: 1rem;
            vertical-align: text-top;
            height: 80px;

            .btn-buy {
                position: absolute;
                left: 0;
                right: 0;
                top: 2rem;
                border-radius: 10px;
                font-optical-sizing: auto;
                font-weight: 600;
                font-style: normal;
                font-size: 14px;
                line-height: 20px;
                color: white;
                background: rgb(255, 104, 68, 1);
                padding: 8px 16px 8px 16px;
                margin: auto;
                width: 100%;
                min-width: 150px;
                max-width: 350px;
                border: none;
                vertical-align: sub;
            }

            .btn-buy .price {
                font-weight: 700;
                font-style: normal;
                font-size: 16px;
            }

            .btn-buy span .left {
                float: left;
            }

            .btn-buy span .right {
                padding-top: 2px;
                float: right;
            }

            .btn-buy:disabled {
                color: rgba(255, 255, 255, 0.5);
                background: rgba(255, 255, 255, 0.05);
            }
        }
    }
}

table tfoot {
    border-top: 1px solid rgba(255, 255, 255, 0.2);

    @media screen and (max-width: 767.9px) {
        border-top: none;
    }
}

table th,
td {
    padding: 24px;
    text-align: center;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    max-width: 300px;
    color: white;
    border-left: 1px solid rgba(255, 255, 255, 0.2);

    @media screen and (max-width: 767.98px) {
        font-size: 16px;
        padding: 1rem 0;
        border-left: none;
        max-width: 150px;
        word-break: break-word;
        text-wrap: balance;

        &.table-item {
            padding-top: 2.5rem;
        }

        &:first-child {
            text-align: left;
        }

        &:not(th):last-child {
            text-align: right;
        }
    }
}

table th.clickable {
    cursor: pointer;
}

table.i1 {
    max-width: 794px;
    width: 100%;
}

table.i2 {
    max-width: 1102px;
    width: 100%;
}

table.i3 {
    max-width: 1218px;
    width: 100%;
}

table.s1 tr td:first-child+td {
    background-color: rgba(255, 255, 255, 0.05);
}

table.s1 tr th:first-child+th {
    background-color: rgba(255, 255, 255, 0.05);
}

table.i2.s1 tr th:first-child+th,
table.i3.s1 tr th:first-child+th {
    background-color: rgba(255, 255, 255, 0.05);

    span {
        background: linear-gradient(125.02deg,
                #ff6846 8.97%,
                #fb8469 21.12%,
                #7352fd 89.46%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

table.i1.s1 tr td:first-child+td {
    background-color: transparent;
}

table.i1.s1 tr th:first-child+th {
    background-color: rgba(255, 255, 255, 0.1);
}

table.s2 tr td:first-child+td+td {
    background-color: rgba(255, 255, 255, 0.05);
}

table.s2 tr th:first-child+th+th {
    background-color: rgba(255, 255, 255, 0.05);

    span {
        background: linear-gradient(125.02deg,
                #ff6846 8.97%,
                #fb8469 21.12%,
                #7352fd 89.46%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

table.s3 tr td:first-child+td+td+td {
    background-color: rgba(255, 255, 255, 0.05);
}

table.s3 tr th:first-child+th+th+th {
    background-color: rgba(255, 255, 255, 0.05);

    span {
        background: linear-gradient(125.02deg,
                #ff6846 8.97%,
                #fb8469 21.12%,
                #7352fd 89.46%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

td.divider {
    height: 1px;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 0px;
    padding-bottom: 0px;

    @media screen and (max-width: 797.7px) {
        &:first-child {
            padding-right: 0;
        }

        &:last-child {
            padding-left: 0;
        }
    }
}

div.divider {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.btn-buy {
    height: 41px;
    padding: 10px 8px 10px 8px;
    border-radius: 8px;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: white;
    background: rgb(255, 104, 68, 1);
    margin: auto;
    width: 218px;
    min-width: 150px;
    max-width: 218px;
    border: none;
}

.btn-buy:disabled {
    color: rgba(255, 255, 255, 0.5);
    background: rgba(255, 255, 255, 0.05);
}

.discounted-price {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

}

.original-price {
    text-decoration: line-through;
    color: white;
}

.text-success {
    color: #6CF542 !important;
}

.discounted-price-mobile {
    text-decoration: line-through;
}

.right.price {
    display: flex;
    gap: 0.25rem;
}

/* Style Bundles*/

.limitOffer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Heebo';
    background: linear-gradient(178.05deg, #120E5C -18.74%, #0B442D 78.59%, #1C1675 146.27%);
    border-radius: 32px;
    margin-left: 80px;
    margin-top: 40px;
    min-width: 300px;
    text-align: center;
    padding: 34px;

    h3 {
        font-weight: 600;
        font-size: 32px;
        line-height: 33px;
        color: #FFF2ED;
    }

    p {
        font-weight: 400;
        font-size: 26px;
        line-height: 24px;
        color: #FFC0A8;

        span {
            font-size: 21px;
        }
    }

    .points {
        font-weight: 700;
        font-size: 21px;
        line-height: 24px;
        color: rgba(255, 255, 255, 0.8);
        text-align: left;
    }

    .counterdown button {
        color: rgba(255, 255, 255, 0.93);
        padding: 12px 32px;
        gap: 10px;
        width: 158px;
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid #25996B;
        border-radius: 8px;
        margin-bottom: 30px;
    }

    .countdownContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .countdown-block {
            width: 60px;
            height: 56px;
            background: rgba(255, 255, 255, 0.1);
            margin-bottom: 30px;
            padding: 8px 12px;
            border-radius: 8px;
        }

        .countdown-block:nth-child(1),
        .countdown-block:nth-child(2) {
            margin-right: 20px;
        }

        span {
            font-weight: 900;
            font-size: 16px;
            line-height: 24px;
            color: rgba(255, 255, 255, 0.93);
        }

        p {
            font-weight: 300;
            font-size: 12px;
            line-height: 18px;
            color: #A5DEFF;
        }
    }
}

.limitOfferMobile {
    width: 104%;
    margin-left: -2% !important;
    margin: 36px 0px 0px 0px;
    background-color: #0C0C2C;
    text-align: center;

    h3 {
        font-weight: 600;
        font-size: 24px;
        line-height: 38px;
        color: #FFF2ED;
        text-shadow: 1px 0px 10px #FF6363;
        padding-top: 12px;
        word-spacing: 2px;
        letter-spacing: 2px;
    }

    p {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #FF6844;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        span {
            font-weight: 500;
            font-size: 14.3px;
            line-height: 20px;
            color: rgba(255, 255, 255, 0.93);
        }
    }

    .btnBundles {
        padding-bottom: 12px;
    }

    .btnBundle1 {
        width: 156px;
        height: 54px;
        padding: 10px;
        margin-right: 16px;
        color: rgba(255, 255, 255, 0.93);
        font-weight: 500;
        font-size: 14px;
        border: none;
        border-radius: 8px;
        background: linear-gradient(148.57deg, #1E1A64 10.21%, #3F37CE 53.76%, #1E1A64 92.53%), #FFFFFF;
    }

    .btnBundle2 {
        width: 156px;
        height: 54px;
        padding: 10px;
        color: rgba(255, 255, 255, 0.93);
        font-weight: 500;
        font-size: 14px;
        border: none;
        border-radius: 8px;
        background: linear-gradient(116.97deg, #2C25A1 -2.55%, #25996B 58.62%, #2C25A1 101.15%);
    }
}

@media (max-width:768px) {
    .limitOffer {
        display: none;
    }
}

@media (min-width:768px) {
    .limitOfferMobile {
        display: none;
    }
}
</style>
