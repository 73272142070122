import {defineStore} from "pinia";
import {useCookies} from "vue3-cookies";

export const useMarketingStore = defineStore('marketing-store', {
    state: () => ({
        //
    }),
    actions: {
        async trackInteractions(interactionType, data) {
            try {
                const response = await this.$httpClient.patch("/marketing/track", {
                    interactions: {
                        [interactionType]: [
                            data
                        ]
                    }
                });
                return response.data;
            } catch (e) {
                console.error(e);
            }
        }
    }
})
