<template>
   <section id="wrapper" class="error-page" style="height: 100vh;width:100vw;position: relative">
    <div class="d-flex justify-content-center" style="margin: 0 auto;"><img src="assets/images/403.png" alt="" style="max-width:100%"></div>
        <div class="error-box" style="z-index: 999; position: absolute;top:90%; right: 50%; transform: translate(50%, -50%);">
            <div class="error-body text-center fw-bold">
                <h3 class="text-uppercase fw-bold">FORBIDDON ERROR!</h3>
                <p class=" m-t-30 m-b-30 text-lowercase">YOU DON'T HAVE PERMISSION TO ACCESS ON THIS SERVER.</p>
                <p  style="font-size: 25px;" class="text-primary m-t-30 m-b-30 text-uppercase">Lost your way? We help you find your way back!</p>
                <a href="/home" class="btn btn-primary btn-rounded waves-effect waves-light m-b-40 text-white">Home</a>
             </div>

        </div>
    </section>
</template>

<script>

</script>

<style>

</style>
