export default class State {
    map
    states = 0
  
    constructor(map) {
      this.map = map
    }
  
    add(state) {
      this.states |= state
    }
  
    delete(state) {
      this.states &= ~state
    }
  
    toggle(state) {
      if (this.has(state)) {
        this.delete(state)
      } else {
        this.add(state)
      }s
    }
  
    has(state) {
      return !!(this.states & state)
    }
  }