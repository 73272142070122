<template>
    <nav class="navbar top-navbar navbar-expand-md navbar-dark" style="background-color: rgba(255, 255, 255, 0.1);">
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
        <div class="navbar-header d-none d-md-flex" style="background-color: transparent;">
            <ul class="navbar-nav me-auto">
                <!-- This is  -->
                <li class="nav-item">
                    <a :class="stop || stopnav?'disabled':''"
                       class="nav-link nav-toggler d-block d-md-none waves-effect waves-dark"
                       href="javascript:void(0)"><i class="ti-menu"></i></a></li>
                <li class="nav-item">
                    <a
                        class="nav-link sidebartoggler d-none d-lg-block d-md-block waves-effect waves-dark"
                        href="javascript:void(0)"><i class="icon-menu"></i></a></li>
            </ul>
            <a class="navbar-brand" href="/home">
                <!-- Logo icon --><b>
                <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
                <!-- Dark Logo icon -->

                <img src="/assets/images/OFP.png" alt="" style="width: 40%;">
                <!-- Light Logo icon -->

            </b>
            </a>
        </div>
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
      <div class="d-flex flex-column w-100 ">
        <div class="navbar-collapse py-2">
            <!-- ============================================================== -->
            <!-- toggle and nav items -->
            <!-- ============================================================== -->
            <ul class="navbar-nav me-1 me-lg-auto d-block d-md-none" >
                <!-- This is  -->
                <li class="nav-item nav-toggler ">
                    <a :class="stop || stopnav?'disabled':''"
                       class="nav-link d-block d-md-none waves-effect waves-dark"
                       href="javascript:void(0)"><i class="ti-menu"></i></a></li>
                <li class="nav-item sidebartoggler">
                    <a
                        class="nav-link sidebartoggler d-none d-lg-block d-md-block waves-effect waves-dark"
                        href="javascript:void(0)"><i class="icon-menu"></i></a></li>
            </ul>
            <ul class="navbar-nav my-lg-0 me-2 me-lg-5 ms-0 ms-lg-auto d-flex gap-2 gap-xl-4 align-items-center" style="display: flex!important;">
                <bannerFidelity />
                <li class="nav-small-cap d-none d-lg-block ">
                    <a  class="btn btn-ofp text-white mx-2 me-1 text-center p-2 animated-button " href="/buy-account" style="border-radius: 10px;" v-if="user?._id">{{ $t('navbar-buy') }}</a>
                    <a  class="btn btn-ofp text-white mx-2 me-1 text-center p-2 animated-button disabled" style="border-radius: 10px;" v-else>{{ $t('navbar-buy') }}</a>
                </li>
                 <li role="button">
                        <div role="button" class="btn btn-outline-primary position-relative" @click="goShopCheckout()" >
                            <i class="mdi mdi-cart fs-5" style="position: relative;"></i>
                            <span v-if="cart?.items?.length > 0" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger text-white">
                                {{ cart?.items?.length || 0 }}
                            </span>
                        </div>
                </li>
                <li class="nav-small-cap postion-relative ">
                    <img @click="showProhibitenStrategyPopup" role="button" class="" src="/assets/icons/prohibiten-strategy.svg" alt="">
                </li>
                <!-- <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle py-0 pt-1 text-white" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="mdi mdi-bell-ring-outline fs-3 position-relative">
                            <span v-if="newToRead==true" class="position-absolute top-0 start-100 translate-middle p-1 bg-primary border border-light rounded-circle">
                                <span class="visually-hidden">New alerts</span>
                            </span>
                        </i>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-center dropdown-menu-lg-end " style="width: 50vw;">
                       <div class="p-4 d-flex justify-content-between">
                            <h4 class="text-white fw-bold ">Notifications ({{ notifNew }})</h4>
                            <p role="button" class="text-primary">&#10003;&#10003; Check all read</p>
                       </div>
                        <li v-for="not in notifications" :key="not" class="d-flex" style="display: flex!important; width: 100%;">
                            <div class="dropdown-item text-white d-flex gap-4  align-items-center" aria-expanded="false"  style="display: flex!important;">
                                <font-awesome-icon icon="fa-solid fa-envelope" class="text-primary" v-if="!not.read" />
                                <font-awesome-icon icon="fa-solid fa-envelope-open" v-if="not.read" />
                                <div class="d-flex flex-column gap-2"  style="display: flex!important; width: 100%;">
                                    <div class="d-flex justify-content-between">
                                        <h6 class="hide-menu mb-0">{{not.title}}</h6>
                                        <p class="mb-0">{{ FormatData(not.createdAt) }}</p>
                                    </div>
                                    <p class="mb-0 w-100 d-none d-lg-block text-dark">{{ not.message.substr(0,50) }} ...</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </li> -->
                <div class="dropdown">
                    <a class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <img :src="'/assets/images/' + selectedLanguage + '.png'" class="rounded-circle me-2" alt="Flag" style="width: 20px; height: 20px;">
                        <i class="fa fa-chevron-circle-down"></i>
                    </a>
                    <ul class="dropdown-menu">
                        <li v-for="language in languages" :key="language.code">
                            <a
                                class="dropdown-item"
                                href="#"
                                @click.prevent="saveLanguagePreference(language)"
                            >
                                <img :src="'/assets/images/' + language.code + '.png'" class="rounded-circle me-2" alt="Flag" style="width: 20px; height: 20px;">
                                {{ language.name }}
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="dropdown" style="display: flex!important;">
                    <div class="d-flex dropdown-toggle align-items-center" role="button" data-bs-toggle="dropdown" v-if="user?._id">
                        <div class="box-icona nav-small-cap me-2">
                            <div v-if="!user?.image" class=" d-flex align-items-center justify-content-center user-img bg-success text-white fs-3 text-uppercase fw-bold lettershadow" style="display: flex!important; "> {{ profileLetter }}</div>
                            <img v-else  :src="user.image" alt="user-img"  style="display: flex!important;" aria-expanded="false" class="d-flex align-items-center justify-content-center img-circle user-img">
                        </div>
                        <li class="nav-item d-none d-md-block customer-name">
                            {{ user ? (user.firstName || '') : '' }} {{ user ? (user.lastName || '') : '' }}
                        </li>
                    </div>
                    <div class="d-flex dropdown-toggle align-items-center" v-else>
                        <div class="box-icona nav-small-cap me-2">
                            <img src="/assets/images/OFP.png" alt="user-img"  style="display: flex!important;" aria-expanded="false" class="d-flex align-items-center justify-content-center img-circle user-img">
                        </div>
                        <li class="nav-item d-none d-md-block customer-name">
                            Guest
                        </li>
                    </div>

                    <ul class="dropdown-menu" style="right: 0!important;">
                        <li> <a class="dropdown-item text-white" href="/user"
                                aria-expanded="false"><font-awesome-icon icon="fa-solid fa-user-gear "  /><span
                                class="hide-menu">{{$t('navbar-settings')}}</span></a>
                        </li>
                        <li><hr></li>
                        <li> <a class="dropdown-item text-white"  @click="logout()"
                                aria-expanded="false"><font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket " class="text-danger"/><span class="hide-menu text-danger">{{ $t('navbar-Logout') }}</span></a>
                        </li>
                    </ul>
                </div>
            </ul>
        </div>
      </div>
    </nav>
</template>

<script setup >
import {ref, watch, readonly, getCurrentInstance} from 'vue';
import {useI18n} from "vue-i18n";
import axios from 'axios';
import {useUserStore} from '../../store/user.store';
import {usePopup} from '../../store/popup.store';
import {useCartStore} from '../../store/cart.store';
import {useCookies} from "vue3-cookies";
import {useRouter} from "vue-router";
import bannerFidelity from './bannerFidelity';
import {storeToRefs} from 'pinia';

defineProps(['stop', 'stopnav']);

const { cookies } = useCookies();

const router = useRouter();

const { popup } = usePopup();

const userStore = useUserStore();
const { update } = userStore;
const { user } = storeToRefs(userStore);

const i18n = useI18n();
const languages = readonly([
    { code: 'en', name: 'English', formatData: 'en-US' },
    { code: 'it', name: 'Italiano', formatData: 'it-IT'  },
    { code: 'es', name: 'Español', formatData: 'es-ES'  },
    { code: 'pt', name: 'Português', formatData: 'pt-PT'  },
]);
const selectedLanguage = ref(languages[0].code);
const saveLanguagePreference = language => {
    selectedLanguage.value = language.code;
    i18n.locale.value = language.code;

    update({ language: language.code });
}

const profileLetter = ref('');
const cartStore = useCartStore();
const { fetchCart } = cartStore;
const { cart } = storeToRefs(cartStore);
watch(user, async (user) => {
    if (!user) {
        return;
    }

    i18n.locale.value = (user.language && user.language?.length != '') ? user.language.toLowerCase() : 'en';
    selectedLanguage.value = i18n.locale.value;

    const firstNameInitial = user && user.firstName ? user.firstName[0] : '';
    const lastNameInitial = user && user.lastName ? user.lastName[0] : '';

    profileLetter.value = firstNameInitial + lastNameInitial;

    await fetchCart();
});
const logout = () => {
    cookies.keys().forEach(key => cookies.remove(key));
    cookies.remove();
    window.location.replace('/');
}
const Mt = n => window.open(`https://ofpfunding.com/faq/metatrader-${n}/`);
const goShopCheckout = () => {
    router.push({ path: (cart.value?.items?.length > 0) ? '/checkout' : '/buy-account' });
}
const showProhibitenStrategyPopup = () => {
    popup({
        title: i18n.t('navbar-Strategies-Title'),
        body: `
                    <div class="d-flex flex-column gap-2">
                        <a href="https://www.youtube.com/watch?v=EdODxOFxi2k" class="d-none d-lg-block" target="_blank">
                            <img src="https://img.youtube.com/vi/EdODxOFxi2k/hqdefault.jpg" alt="Anteprima video">
                        </a>
                        <br>
                        <p class="text-left">${i18n.t('navbar-Strategies-Text1')} <a target="_blank" href="https://www.youtube.com/watch?v=EdODxOFxi2k">https://www.youtube.com/watch?v=EdODxOFxi2k</a></p>
                        <p class="text-left">${i18n.t('navbar-Strategies-Text2')}</p>
                        <p class="text-left">${i18n.t('navbar-Strategies-Text3')}</p>
                   </div>`
    })
}
</script>

<style scoped>
.user-img {
    border-radius: 100%;
    width: 50px !important;
    height: 50px;

}

select{
    background-color: #462f39!important ;
    color: white!important;
    border: none!important;
}
.iconaFoto {
    display: none;
    opacity: 100%;
}

.m10 {
    margin-right: 10px;
}

.boxPosition{
    width: 50%;
    left: 50%;
    transform: translate(-50%, 0%);
    background-color: #010916;
}
.displeyFlex{
    display: flex!important;
}

.animated-button {

  animation: stretchRotate 3s infinite;
}

@keyframes stretchRotate {
  0% {
    transform: scaleX(1) rotate(0deg);
  }
  25% {
    transform: scaleX(1.1) rotate(5deg);
  }
  50% {
    transform: scaleX(1) rotate(0deg);
  }
  75% {
    transform: scaleX(1.1) rotate(-5deg);
  }
  100% {
    transform: scaleX(1) rotate(0deg);
  }
}

@media (max-width: 992px){
    .boxPosition{
        width: 90%;
        transform: translate(-50%, 0%);
        background-color: #010916;
        height: 75vh;
        overflow-y:auto ;
        margin: auto;
        z-index: 999;
    }
}
.customer-name {
    font-family: 'Heebo';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.313rem;
    color: white;
}
</style>
