<template>
    <div>
        <div class="d-flex gap-5">
            <p v-for="acc in accounts" :key="acc">
                Account: <span class="text-white"> {{ acc.username }}</span> ->
                {{ $t("CurrentBalance") }}:
                <span class="text-success">{{
                    $formatAmount(acc.balance, acc.currency)
                }}</span>
            </p>
        </div>
        <div>
            <canvas id="accountsChart" width="200"></canvas>
        </div>
    </div>
</template>

<script>
import Chart from "chart.js/auto";
import moment from "moment-timezone";

export default {
    data() {
        return {
            colorCodes: [
                // rgb
                [237, 147, 141],
                [242, 201, 76],
                [216, 141, 237],
                [179, 185, 255],
                [255, 223, 179],
                [179, 255, 186],
                [255, 242, 179],
                [255, 255, 255],
                [141, 226, 237],
                [152, 141, 237],
            ],
            accounts: [],
            data: [],
            max: [],
            chartData: {
                labels: [],
                datasets: [],
            },
            areaChart: null,
        };
    },
    methods: {
        accountMetrics() {
            let list = sessionStorage.getItem("accounts");
            let allaccounts = JSON.parse(list).list_account;
            allaccounts.forEach((elemento) => {
                let acc = sessionStorage.getItem(elemento);
                let element = JSON.parse(acc);
                if (element.status != "0") {
                    this.accounts.push(element);
                }
            });
            this.accounts.forEach((account, ind) => {
                let metricheAcc = JSON.parse(
                    localStorage.getItem(account.userID)
                );
                if (!metricheAcc) {
                    return false;
                }

                if (!metricheAcc.dailyGrowth) {
                    return false;
                }

                let gradient = document
                    .getElementById("accountsChart")
                    .getContext("2d")
                    .createLinearGradient(0, 0, 0, 400);
                gradient.addColorStop(0, `rgba(${this.colorCodes[ind]}, 1)`);
                gradient.addColorStop(1, `rgba(${this.colorCodes[ind]}, 0)`);

                const balances = [];

                const data = {
                    label: account.username,
                    data: [],
                    backgroundColor: gradient,
                    borderColor: `rgba(${this.colorCodes[ind]}, 1)`,
                    borderWidth: 2,
                    fill: true,
                    lineTension: 0.4,
                    pointStyle: "circle",
                    pointRadius: 10,
                    pointHoverRadius: 15,
                };
                this.chartData.datasets.push(data);

                const startDate = moment.tz(
                    account.created_at,
                    "Europe/Moscow"
                );
                const endDate = moment.tz("Europe/Moscow").add(1, "days");
                while (startDate <= endDate) {
                    const date = moment
                        .tz(startDate, "Europe/Moscow")
                        .format("YYYY-MM-DD");
                    if (!this.chartData.labels.includes(date)) {
                        this.chartData.labels.push(date);
                    }

                    startDate.add(1, "days");
                }

                if (metricheAcc.dailyGrowth) {
                    metricheAcc.dailyGrowth.forEach((element) => {
                        this.chartData.labels.forEach((data) => {
                            if (
                                data == element.date &&
                                element.balance !==
                                    balances[balances.length - 1]
                            ) {
                                balances.push(+element.balance);
                            } else if (
                                balances[balances.length - 1] != undefined
                            ) {
                                balances.push(+balances[balances.length - 1]);
                            } else {
                                balances.push(+account.initial_balance);
                            }
                        });
                    });
                }

                const uniqueBalancesAmountIndexes = [];
                balances.forEach((balance, index) => {
                    if (balances.indexOf(balance) === index) {
                        uniqueBalancesAmountIndexes.push(
                            balances.lastIndexOf(balance)
                        );
                    }
                });

                if (uniqueBalancesAmountIndexes.length > 2) {
                    uniqueBalancesAmountIndexes.forEach((index) => {
                        if (
                            this.chartData.datasets &&
                            this.chartData.datasets[ind]
                        ) {
                            this.chartData.datasets[ind].data?.push(
                                balances[index]
                            );
                        }
                    });
                } else {
                    if (
                        this.chartData.datasets &&
                        this.chartData.datasets[ind]
                    ) {
                        this.chartData.datasets[ind].data = balances;
                    }
                }

                const maxValue = Math.max(...balances);
                this.max.push(maxValue);
                if (ind == this.accounts.length - 1) {
                    this.updateChart();
                }
            });
        },
        createChart() {
            const ctx = document.getElementById("accountsChart");
            this.areaChart = new Chart(ctx, {
                type: "line",
                data: this.chartData,
                maintainAspectRatio: false,
                options: {
                    plugins: {
                        tooltip: {
                            padding: 10,
                            boxPadding: 3,
                            xAlign: "center",
                            yAlign: "center",
                            titleSpacing: 15,
                            bodySpacing: 10,
                            font: {
                                size: 30,
                            },
                        },
                    },

                    color: "#ffffff",
                    responsive: true,
                    scales: {
                        y: {
                            suggestedMax: Math.max(...this.max),
                            beginAtZero: true,
                            ticks: {
                                color: "white",
                            },
                        },
                        x: {
                            ticks: {
                                color: "white",
                                display: false,
                            },
                        },
                    },
                },
            });
        },
        updateChart() {
            if (this.areaChart) {
                this.areaChart.destroy();
            }
            this.createChart();
        },
        destroyChart() {
            if (this.lineChart !== null) {
                this.lineChart.destroy();
                this.lineChart = null;
            }
        },
        formatCurrency(value) {
            const formatter = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
            });
            return formatter.format(value);
        },
        beforeUnmount() {
            this.destroyChart();
        },
    },
    mounted() {
        this.accountMetrics();
    },
};
</script>

<style></style>
