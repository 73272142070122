<template>
   <div class="d-flex justify-content-between align-items-center" style="display: flex!important;">
    <h3 class="text-white ">{{ $t('chart-BarPL') }}</h3>
    <select class="" v-model="selectOption" style="height: 50%;">
                <option value="Daily"  id="showdaily">{{ $t('Daily') }}</option>
                <option value="Weekly"  id="showweekly" >{{ $t('Weekly') }}</option>
                <option value="Monthly"  id="showmonthly"  >{{ $t('Monthly') }}</option>
    </select>
   </div>
   <div class="gap-4 mb-2 d-flex displayFlex" >
        <div>
            <p class="text-dark">{{ $t('AvW') }}</p>
            <p v-if="metriche.averageWin" class="text-success">{{ currency }}{{ metriche.averageWin.toFixed(2) }}</p>
            <p v-else>-</p>
        </div>
        <div>
            <p class="text-dark">{{ $t('AvL') }}</p>
            <p v-if="metriche.averageLoss" class="text-danger">{{ currency }}{{ metriche.averageLoss.toFixed(2) }}</p>
            <p v-else>-</p>
        </div>
        <div class="border border-1"></div>
        <div>
            <p class="text-dark">{{ $t('TotTradesW') }}</p>
            <p v-if="metriche.wonTrades">{{ metriche.wonTrades }}</p>
            <p v-else>-</p>
        </div>
        <div>
            <p class="text-dark">{{ $t('TotTradesL') }}</p>
            <p v-if="metriche.lostTrades">{{ metriche.lostTrades }}</p>
            <p v-else>-</p>
        </div>
   </div>
    <div class="card p-3">
       <div v-show="daily">
        <canvas id="chartd"></canvas>
       </div>
       <div v-show="weekly">
        <canvas id="chartw"></canvas>
       </div>
       <div v-show="monthly">
        <canvas id="chartm"></canvas>
       </div>
    </div>
</template>

<script>
import Chart from "chart.js/auto"
export default {
    props:{
        trades:Array,
        metriche:Object,
        currency:String
    },
    data(){
        return{
            daily:true,
            selectOption:'Daily',
            weekly:false,
            monthly:false,
            groupedTrades:{},
            result:{},
            config:{
                type: 'bar',
                data: this.result,
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: true,
                            text: 'Chart.js Bar Chart'
                            }
                    }
                },
            },
            Chartday:null,

        }
    },
    methods:{
         formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        getMonthlyTrades() {
            this.Chartday = null;
            this.groupedTrades = {};

            this.trades.forEach((trade) => {
                const closeTime = new Date(trade.closeTime);
                const year = closeTime.getFullYear();
                const month = closeTime.getMonth() + 1; // +1 perché i mesi in JavaScript sono indicizzati da 0 a 11
                const key = `${year}-${month}`;

                if (!this.groupedTrades.hasOwnProperty(key)) {
                this.groupedTrades[key] = [];
                }

                if (trade.type !== 'BALANCE') {
                this.groupedTrades[key].push(trade.profit.toFixed(2));
                }
            });
            this.result = {};

            for (const key in this.groupedTrades) {
                const values = this.groupedTrades[key];

                const positiveSum = values.reduce((acc, value) => {
                const parsedValue = parseFloat(value);
                return parsedValue > 0 ? acc + parsedValue : acc;
                }, 0);

                const negativeSum = values.reduce((acc, value) => {
                const parsedValue = parseFloat(value);
                return parsedValue < 0 ? acc + parsedValue : acc;
                }, 0);

                this.result[key] = {
                positiveSum,
                negativeSum
                };
            }

            const sortedData = Object.entries(this.result).sort((a, b) => {
                const [yearA, monthA] = a[0].split('-');
                const [yearB, monthB] = b[0].split('-');
                return new Date(yearA, monthA - 1) - new Date(yearB, monthB - 1);
            });

            this.result = Object.fromEntries(sortedData);

            const dates = Object.keys(this.result);
            const positiveValues = Object.values(this.result).map((item) => item.positiveSum);
            const negativeValues = Object.values(this.result).map((item) => item.negativeSum);
            this.createChart(dates, positiveValues, negativeValues, 'chartm');
        },
        getWeeklyTrades() {
            this.Chartday = null;
            this.groupedTrades = {};
            const settimane = {};

            this.trades.forEach((trade) => {
                const closeTime = new Date(trade.closeTime);
                const weekStart = this.getWeekStart(closeTime);
                const weekEnd = this.getWeekEnd(closeTime);
                const weekKey = `${this.formatDate(weekStart)} / ${this.formatDate(weekEnd)}`;

                if (trade.type !== 'BALANCE') {
                if (!settimane.hasOwnProperty(weekKey)) {
                    settimane[weekKey] = {};
                }

                const profit = parseFloat(trade.profit.toFixed(2));
                if (profit > 0) {
                    settimane[weekKey].positiveSum = (settimane[weekKey].positiveSum || 0) + profit;
                } else {
                    settimane[weekKey].negativeSum = (settimane[weekKey].negativeSum || 0) + profit;
                }
                }
            });

            const sortedData = Object.entries(settimane).reverse();
            this.settimane = Object.fromEntries(sortedData.slice(-8));
            const dates = Object.keys(this.settimane)
            const positiveValues = Object.values(this.settimane).map(item => item.positiveSum);
            const negativeValues = Object.values(this.settimane).map(item => item.negativeSum);
            this.createChart(dates, positiveValues, negativeValues, 'chartw');

        },

        getWeekStart(date) {
        const startOfWeek = new Date(date);
        startOfWeek.setDate(date.getDate() - date.getDay() + 1);
        return startOfWeek;
        },

        getWeekEnd(date) {
        const endOfWeek = new Date(date);
        endOfWeek.setDate(date.getDate() - date.getDay() + 7);
        return endOfWeek;
        },
        getDailyTrades() {
            this.Chartday = null;
            this.groupedTrades = {};
            this.trades.forEach((trade) => {
                trade.closeTime = new Date(trade.closeTime).toDateString()
            const closeTime =new Date(trade.closeTime).toDateString();
            if (this.groupedTrades.hasOwnProperty(closeTime) && trade.closeTime == closeTime && trade.type!= 'BALANCE') {
                this.groupedTrades[closeTime].push(trade.profit.toFixed(2));
            } else if(trade.closeTime == closeTime && trade.type != 'BALANCE') {
                this.groupedTrades[closeTime] = [trade.profit.toFixed(2)];
            }
            });
            this.result = {};

            for (const key in this.groupedTrades) {
            const values = this.groupedTrades[key];

            const positiveSum = values.reduce((acc, value) => {
                const parsedValue = parseFloat(value);
                return parsedValue > 0 ? acc + parsedValue : acc;
            }, 0);

            const negativeSum = values.reduce((acc, value) => {
                const parsedValue = parseFloat(value);
                return parsedValue < 0 ? acc + parsedValue : acc;
            }, 0);

            this.result[key] = {
                positiveSum,
                negativeSum
            };
            }

            const sortedData = Object.entries(this.result).sort((a, b) => new Date(a[0]) - new Date(b[0]));
            this.result = Object.fromEntries(sortedData.slice(-15));
            const dates = Object.keys(this.result);
            const positiveValues = Object.values(this.result).map(item => item.positiveSum);
            const negativeValues = Object.values(this.result).map(item => item.negativeSum);
            this.createChart(dates,positiveValues, negativeValues, 'chartd')


        },
        createChart(lab, val1, val2, id){
            this.destroyChart()
              // Creazione del grafico
              const ctx = document.getElementById(id).getContext('2d');
                this.Chartday=new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: lab,
                    datasets: [
                    {
                        label: 'Profits',
                        data: val1,
                        backgroundColor: 'rgba(0,194,146, 0.5)',
                        borderColor: 'rgba(0,194,146,1)',

                        borderWidth: 1
                    },
                    {
                        label: 'Loss',
                        data: val2,
                        backgroundColor: 'rgba(228,106,118, 0.5)',
                        borderColor:  'rgba(228,106,118, 1)',
                        borderWidth: 1
                    }
                    ]
                },
                options: {
                    color:'#ffffff',
                    scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                        color:'white',

                    },
                    grid:{
                        display:true,
                            color:'white'
                        }
                    },
                    x:{
                        ticks: {
                        color:'white',},
                        grid:{
                            display:false,

                        }
                    }
                    },
                }
                });
        },
        destroyChart() {
            if (this.Chartday !== null) {
                this.Chartday.destroy();
                this.Chartday = null;
            }
        },
        showdaily(){
            this.daily=true
            this.weekly=false
            this.monthly=false
            this.destroyChart()
            this.getDailyTrades()
        },
        showweekly(){
            this.daily=false
            this.weekly=true
            this.monthly=false
            this.destroyChart()
            this.getWeeklyTrades()
        },
        showmonthly(){
            this.daily=false
            this.weekly=false
            this.monthly=true
            this.destroyChart()
            this.getMonthlyTrades()
        }
    },
    watch: {
        selectOption() {
            if(this.selectOption=="Daily"){
                this.showdaily()
            }else if(this.selectOption=="Monthly"){
                this.showmonthly()
            }else if(this.selectOption=="Weekly"){
                this.showweekly()
            }
    }
  },
    mounted(){
        this.getDailyTrades()
    },
    beforeUnmount() {
       this.destroyChart();
     }
}
</script>

<style scoped>
select{
    background-color: #3c4452;
    color: white;
}
.displayFlex{
    display: flex !important;
}

</style>
