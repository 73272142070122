<template>
    <nav class="sidebar-nav pt-0">
        <ul id="sidebarnav" class="mt-3 pt-3">
            <li>
                <RouterLink
                    class="btn btn-outline-primary waves-effect waves-dark border border-primary text-white mx-3 text-center p-2 animated-button"
                    to="/buy-account"
                    style="border-radius: 10px"
                    >{{ $t("navbar-buy") }}</RouterLink
                >
            </li>
            <li>
                <RouterLink
                    class="waves-effect waves-dark"
                    to="/home"
                    aria-expanded="false"
                    ><font-awesome-icon
                        icon="fa-solid fa-house"
                        class="m10"
                    /><span class="hide-menu">{{ $t("sidebar-home") }} </span>
                </RouterLink>
            </li>

            <li>
                <RouterLink
                    class="waves-effect waves-dark nav-link"
                    to="/calendar"
                    aria-expanded="false"
                >
                    <font-awesome-icon
                        icon="fa-solid fa-calendar"
                        class="m10"
                    /><span class="hide-menu">
                        {{ $t("sidebar-tradingCalendar") }}
                    </span>
                </RouterLink>
            </li>
            <li>
                <a
                    class="waves-effect waves-dark nav-link"
                    href="https://overviewforex.com/tools/"
                    target="_blank"
                    aria-expanded="false"
                    ><i class="mdi mdi-wrench colorIcon"></i
                    ><span class="hide-menu">
                        {{ $t("sidebar-Tools") }}
                    </span>
                </a>
            </li>
            <li>
                <RouterLink
                    class="waves-effect waves-dark nav-link"
                    to="/leaderboard"
                    aria-expanded="false"
                >
                    <font-awesome-icon
                        icon="fa-solid fa-trophy"
                        class="m10"
                    /><span class="hide-menu">{{ $t("sidebar-Lead") }}</span>
                </RouterLink>
            </li>
            <li>
                <RouterLink
                    class="waves-effect waves-dark nav-link"
                    to="/orders"
                    aria-expanded="false"
                >
                    <font-awesome-icon
                        icon="fa-solid fa-cart-shopping"
                        class="m10"
                    /><span class="hide-menu">{{ $t("sidebar-Orders") }}</span>
                </RouterLink>
            </li>
            <li>
                <RouterLink
                    class="waves-effect waves-dark nav-link"
                    to="/certificate"
                    aria-expanded="false"
                >
                    <font-awesome-icon
                        icon="fa-solid fa-certificate "
                        class="m10"
                    /><span class="hide-menu">{{
                        $t("sidebar-Certificati")
                    }}</span>
                </RouterLink>
            </li>
            <li>
                <RouterLink
                    class="waves-effect waves-dark"
                    to="/news"
                    aria-expanded="false"
                    ><font-awesome-icon
                        icon="fa-solid fa-newspaper"
                        class="m10"
                    /><span class="hide-menu">{{ $t("sidebar-News") }}</span>
                </RouterLink>
            </li>
            <li>
                <RouterLink
                    class="waves-effect waves-dark"
                    to="/economic-calendar"
                    aria-expanded="false"
                >
                    <font-awesome-icon
                        icon="fa-solid fa-calendar"
                        class="m10"
                    /><span class="hide-menu">{{
                        $t("sidebar-Calendar")
                    }}</span>
                </RouterLink>
            </li>
            <li>
                <a
                    class="waves-effect waves-dark nav-link"
                    href="https://overviewforex.com/course/"
                    target="_blank"
                    aria-expanded="false"
                    ><font-awesome-icon
                        icon="fa-solid fa-book"
                        class="m10"
                    /><span class="hide-menu">
                        {{ $t("sidebar-Educ") }}
                    </span>
                </a>
            </li>
            <li>
                <a class="waves-effect waves-dark" target="_blank" href="https://ofpfunding.com/mental-coach" aria-expanded="false" >
                    <font-awesome-icon icon="fa-solid fa-chalkboard-teacher" class="m10"/>
                    <span class="hide-menu">{{ $t("sidebar-Coach") }}</span>
                </a>
            </li>

            <li>
                <RouterLink class="waves-effect waves-dark menu-item nav-link" to="/customer-payouts" aria-expanded="false">
                    <font-awesome-icon icon="fa-solid fa-wallet" class="m10"/>
                    <span class="hide-menu"
                        >{{ $t("payouts") }}
                        <span v-if="badgeCount > 0" class="badge badge-danger ml-2" >
                            {{ badgeCount }}
                        </span>
                    </span>
                </RouterLink>
            </li>
            <li class="nav-small-cap py-1">
                <hr />
            </li>
            <li>
                <a
                    class="waves-effect waves-dark"
                    href="https://ofpfunding.com/"
                    target="_blank"
                    aria-expanded="false"
                    ><font-awesome-icon
                        icon="fa-solid fa-globe"
                        class="m10"
                    /><span class="hide-menu">{{ $t("sidebar-Web") }}</span>
                </a>
            </li>
            <li>
                <a
                    class="waves-effect waves-dark pe-0"
                    href="https://discord.com/channels/916660711789322300/916660712259072043"
                    target="_blank"
                    aria-expanded="false"
                    ><i class="mdi mdi-discord colorIcon"></i
                    ><span class="hide-menu m-0">{{
                        $t("sidebar-Discord")
                    }}</span>
                </a>
            </li>
            <li>
                <a
                    class="waves-effect waves-dark pe-0"
                    href="https://ofpfunding.com/earn-points-and-rewards-at-ofp-trading-made-simple/ofp-affiliate-program/"
                    target="_blank"
                    aria-expanded="false"
                    ><i class="mdi mdi-link-variant colorIcon"></i
                    ><span class="hide-menu m-0">{{
                        $t("sidebar-Affiliate")
                    }}</span>
                </a>
            </li>
            <li class="nav-small-cap py-1">
                <TrustPilot />
            </li>
            <li @click="rank()">
                <a
                    class="waves-effect waves-dark pe-0"
                    href="#"
                    aria-expanded="false"
                >
                    <i class="mdi mdi-pencil-box-outline"></i
                    ><span class="hide-menu m-0">{{
                        $t("sidebar-Rank")
                    }}</span></a
                >
            </li>
        </ul>
    </nav>
</template>

<script setup>
import { RouterLink } from "vue-router";
import TrustPilot from "./TrustPilot";
import { onMounted, ref, watch } from "vue";
import { useUserStore } from "../store/user.store";
import { storeToRefs } from "pinia";
import { usePayoutStore } from "../store/payout.store";

const props = defineProps({
    preventNavigation: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(["rank"]);

const rankPopup = ref(false);
const badgeCount = ref(0);

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const payoutStore = usePayoutStore();

const rank = () => {
    rankPopup.value = true;
    emit("rank", rankPopup.value);
};

const stopIt = (e) => {
    e.preventDefault();
};

const disableNavigation = () => {
    const currentDomain = window.location.host;

    document.querySelectorAll("#sidebarnav a").forEach((el) => {
        if (el.href.includes(currentDomain)) {
            el.classList.add("disabled");
            el.addEventListener("click", stopIt);
        }
    });
};

const enableNavigation = () => {
    const currentDomain = window.location.host;

    document.querySelectorAll("#sidebarnav a").forEach((el) => {
        if (el.href.includes(currentDomain)) {
            el.classList.remove("disabled");
            el.removeEventListener("click", stopIt);
        }
    });
};
const getPayouts = async () => {
    try {
        if(user.value !== null){
            const response = await payoutStore.fetchDataPayouts();
            if (response?.data?.length) {
                badgeCount.value = response.data.filter(pay => pay.paymentStatus === "waiting_data_confirmation").length;
            }
        }
    } catch (error) {
        console.error("Error fetching payouts:", error);
    }
};

onMounted(() => {
    if (
        props.preventNavigation ||
        (!props.preventNavigation && user.value === null)
    ) {
        disableNavigation();
    }

    if (user.value) {
        enableNavigation();
    }

    getPayouts()
});

watch(
    user,
    (newVal) => {
        if (
            props.preventNavigation ||
            (!props.preventNavigation && newVal === null)
        ) {
            disableNavigation();
        }

        if (newVal) {
            enableNavigation();
        }
    },
    {
        immediate: true,
        deep: true,
    }
);
</script>

<style scoped>
.icon-path {
    fill: #8d97ad;
    transition: fill 0.3s ease;
}

.icon-path:hover {
    fill: #ff5733;
}
.menu-item:hover .icon {
    fill: #ff5733;
}
.icon {
    transition: stroke 0.3s ease, fill 0.3s ease;
}

.badge-danger {
    background-color: #ff6844;
    color: white;
    font-size: 10px;
    padding: 4px 8px;
    border-radius: 50%;
}
.ml-2 {
    margin-left: 8px;
}

span {
    font-family: "Heebo", sans-serif !important;
    color: #ffffff61;
    font-weight: 400;
    font-size: 14px;
}
.m10 {
    margin-right: 10px;
}

.m5 {
    margin-right: 5px;
}

.user-img {
    border-radius: 100%;
    width: 100px !important;
    height: 100px;
}

.iconaFoto {
    display: none;
    opacity: 100%;
}

.box-icona:hover .iconaFoto {
    display: block;
}

.colorIcon {
    color: #8d97ad;
}

.opacitaDisable {
    opacity: 30%;
}

.animated-button {
    animation: stretchRotate 3s infinite;
}

@keyframes stretchRotate {
    0% {
        transform: scaleX(1) rotate(0deg);
    }

    25% {
        transform: scaleX(1.1) rotate(5deg);
    }

    50% {
        transform: scaleX(1) rotate(0deg);
    }

    75% {
        transform: scaleX(1.1) rotate(-5deg);
    }

    100% {
        transform: scaleX(1) rotate(0deg);
    }
}
</style>
