<template>
    <BrandSection :loading="loading">
        <template v-slot:content>
            <div class="form-horizontal form-material">
                <h2 class="text-center text-white mb-3">OFP is under maintenance.</h2>
                <h5 class="subtitle">
                    We’re currently carrying out some maintenance on our systems, but don’t worry—everything is running smoothly. Trades are being tracked, payouts are being processed, and balances are syncing as usual. There’s nothing you need to do, we’ve got it all covered. Thanks for your understanding while we work to make things even better!
                </h5>
                <div class="text-center">
                    <a href="https://discord.com/channels/916660711789322300/916660712259072043">Discord</a>
                </div>
            </div>
        </template>
        <template v-slot:image-section>
            <div class="col-xs-12 image-text">
                <h3 class="text-center text-white mb-3">We'll back soon.</h3>
            </div>
        </template>
    </BrandSection>
</template>

<script>
import Spinner from "../components/Spinner";
import ImageSection from "../components/layout/ImageSection.vue";
import BrandSection from "../components/layout/BrandSection.vue";
import NeedVerifyEmail from "../components/NeedVerifyEmail.vue";
import EmailSent from "../components/EmailSent.vue";
import { useUserStore } from "../store/user.store";
export default {
    components: {
        Spinner,
        ImageSection,
        BrandSection,
        NeedVerifyEmail,
        EmailSent,
    },
    setup() {
        const userStore = useUserStore();
        return { userStore };
    },
    data() {
        return {
            email: "",
            password: "",
            error: false,
            typePsw: true,
            loading: true,
            langParam: "",
            showVerifyEmail: false,
            bannedUser: false,
            emailSent: false,
            userError: null,
        };
    },
    mounted() {
        this.checkCredentials();
        this.checkUrlLang();
    },
    methods: {
        resendEmail() {
            this.loading = true;
            axios
                .get(
                    `${this.$baseUrl}/customers/resend-email?email=${this.email}`
                )
                .then((response) => {
                    if (response.status === 200) {
                        this.emailSent = true;
                        this.showVerifyEmail = false;
                    } else {
                        this.error = true;
                        this.textError = response.message;
                        this.emailSent = false;
                        this.showVerifyEmail = false;
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    this.error = true;
                    this.textError = error.message;
                    this.emailSent = false;
                    this.showVerifyEmail = false;
                });
        },
        checkUrlLang() {
            this.langParam = this.$route.params.lang;
            if (this.langParam) {
                this.$i18n.locale = this.langParam;
            } else {
                this.langParam = "";
                this.$i18n.locale = "en";
            }
        },
        checkCredentials() {
            if (this.$cookies.get("login")) {
                if (!this.$cookies.get("emailAccount")) {
                    this.loading = false;
                    return;
                }
                this.email = this.$cookies.get("emailAccount");

                axios
                    .get(
                        `${this.$baseUrl}/customers/getCustomerInfo?email=${this.email}`
                    )
                    .then(({ data }) => {
                        this.loading = false;
                        if (data?.verified) {
                            window.location.replace("/home");
                        } else {
                            this.showVerifyEmail = true;
                        }
                    })
                    .catch((error) => (this.loading = false));
            } else {
                if (this.$cookies.get("emailAccount")) {
                    this.email = this.$cookies.get("emailAccount");
                }
                this.loading = false;
            }
        },
        changeTipePsw($event) {
            $event.target.classList.toggle("fa-eye");
            $event.target.classList.toggle("fa-eye-slash");
            this.typePsw = !this.typePsw;
        },
        async logIn() {
            this.userError = null;

            try {
                await this.userStore.login(this.email, this.password);
                await this.userStore.me();

                this.$cookies.set("login", true, Infinity);
                this.$cookies.set("emailAccount", this.email, Infinity);

                this.$gtm.push({
                    event: 'login_v2',
                    user_data: {  // user data from account
                        first_name: this.userStore.user.firstName,
                        last_name: this.userStore.user.lastName,
                        email_address: this.userStore.user.email,
                        user_id: this.userStore.user._id,
                    }
                })

                this.$router.go("/home");
            } catch (error) {
                console.error(error);

                this.userError = error.response?.data.message;
                if (error.response?.status === 403) {
                    this.userError = null;
                    this.showVerifyEmail = true;
                }
            }
        },
    },
};
</script>
<style scoped lang="scss">
.subtitle {
    color: rgba(255, 255, 255, 0.5);
}

.toggle-password {
    position: absolute;
    right: 12px;
    bottom: 12px;
    font-size: 16px;
    cursor: pointer;
}

.image-text {
    margin-top: 77px;
}

.remember-credentials {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    color: #c24f35;
    cursor: pointer;
    width: 20px;
    height: 20px;
    border: 1px solid #fff;
    border-radius: 4px;
    display: grid;
    place-content: center;

    &:checked {
        background-color: #fff;
    }

    &::before {
        content: "";
        width: 14px;
        height: 14px;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em #c24f35;
        transform-origin: bottom left;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }

    &:checked::before {
        transform: scale(1);
    }
}

.error-message {
    font-size: 12px;
    font-weight: 500;
    position: absolute;
}
</style>
