<template>
    <div class="w-100" v-if="status!='0' && profit.bestProfit!=null && profit.bestProfit>0">
        <div  class="row mx-auto py-2 px-1 d-flex justify-content-between rounded " style="background-color: rgba(194, 79, 53, 0.2)!important;">
            <div class="col-12 d-block d-xl-flex flex align-items-center justify-content-between px-lg-5 p-3">
                <div>
                    <h4>{{ $t('bannerPayout1') }} <span class="text-primary fw-bold ms-1">{{ formatCurrency(profit.bestProfit) }}</span></h4>
                </div>
                <div class="d-none d-xl-block">
                    <img :src="'/assets/images/arrowRight.png'" alt="">
                </div>
                <div class="d-block d-xl-none my-3 text-center">
                    <i class="mdi mdi-arrow-down"></i>
                </div>
                <div>
                    <h4>{{ $t('bannerPayout2') }} <span class="text-primary fw-bold ms-1">{{ formatCurrency(payout) }}</span> </h4>
                </div>
            </div>
        </div>
  </div>
</template>
<script>
export default {
    name:'bannerViolation',
  methods:{
    formatCurrency(value) {
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: "USD",
                minimumFractionDigits: 2,
            });
            const formattedValue = formatter.format(value);
            return formattedValue
        },

        //il mio bestProfit e' di 150.000
        //sono un account on demand (TVS 20%), model 40%
        // per calcolare l aspettativa del mio payout-> 150.000/20*40

        calcPayout(){
        if ( this.props && this.props.max_trader_value_score ) {
            const numbersArray = this.model.match(/\d+/g); //estraggo il model dal type_account -> es: Monthly 40%
            let extractedNumbers = numbersArray ? numbersArray.join(', ') : '';
            this.payout = ( this.profit.bestProfit / this.props.max_trader_value_score ) * Number(extractedNumbers)
        }
        else {
            let tvr = 25;
            if (this.model.includes('Monthly') ) {
                tvr = 25;
            }
            else if (this.model.includes('Biweekly' )) {
                tvr = 22;
            }
            else {
                tvr = 20;
            }
            const numbersArray = this.model.match(/\d+/g); //estraggo il model dal type_account -> es: Monthly 40%
            let extractedNumbers = numbersArray ? numbersArray.join(', ') : '';
            this.payout = ( this.profit.bestProfit / tvr ) * Number(extractedNumbers)
        }
    }
  },
  props:{
        status:String,
        model:String,
        profit:Object,
        props:Object
    },
    mounted(){
       this.calcPayout()
    },
  data(){
    return{
        payout:0
    }
  },
}
</script>

<style scoped>
.borderArrow{
    width: 10%;
}
img{
    height: 10px;
}
</style>
