<template>
    <Layout :loading="loading" :title="$t('buyaccount-title')" :custom-background="true">
        <div class="d-md-flex flex-column justify-content-center align-items-center gap-4 px-2">
            <CustomAccountFilters v-if="!showBundles" v-model="plan"
                                  :key="index" @update:modelValue="onPlanChanged"
                                  :capitals="capitals"
                                  :drawdowns="drawdowns"
                                  :profit_splits="profitSplits"
                                  :payout_frequencies="frequencies"
                                  :currencies="currencies" />

            <template v-if="settingStore.setting && !showBundles">
                <BuyAccountSummary class="w-100"
                                   :items="selectedItems"
                                   :showBundleBox="showBundleBox"
                                   :processing="processing" v-model="index"
                                   @update:modelValue="onIndexChanged" @buy-account="onBuyAccount" @update:showBundle="changeValue"/>

                <a v-if="canAdd" class="compare-plans d-none d-md-block" href="#" @click="addPlan">{{ comparePlansTitle}}</a>
            </template>

            <template v-if="showBundles">
                <SummaryBundles
                    from-end-user
                />
                <a class="compare-plans-small" @click="changeValue(false)" href="#">Get a single account without offers</a>
            </template>
        </div>
    </Layout>
    <!-- button FLoat table Bundles -->
    <button v-if="showBundleStrip" @click="changeValue(true)" class="bundlesBtn">Exclusice Offer<br><span>14-25 February</span></button>

</template>

<script setup>
import {
    ref,
    unref,
    watchEffect,
    readonly,
    toRaw,
    inject,
    watch,
    onMounted,
    computed,
    onBeforeMount,
} from "vue";

import Layout from "../../layouts/enduser-layout.vue";
import CustomAccountFilters from "../../components/custom-account/Filters.vue";
import BuyAccountSummary from "../../components/custom-account/Summary.vue";

import { useGtm } from "@gtm-support/vue-gtm";
import { useRouter } from "vue-router";
import { useNotification } from "@kyvg/vue3-notification";
import { storeToRefs } from "pinia";
import { useItemsStore } from "../../store/items.store";
import { useCartStore } from "../../store/cart.store";
import {useSettingStore} from "../../store/setting.store"
import { usePopup } from "../../store/popup.store";
import { useI18n } from "vue-i18n";
import {
    isValidItem,
    items2Sizes,
    items2MaxDailyDrawdowns,
    items2ProfitSplits,
    items2PayoutFrequencies,
    items2Currencies,
} from "../../components/custom-account/utils/item";
import { useCookies } from "vue3-cookies";
import { useMarketingStore } from "../../store/marketing.store";
import SummaryBundles from "../../components/custom-account/SummaryBundles.vue";

const router = useRouter();

const { fetchItems } = useItemsStore();
const cartStore = useCartStore();
const { addItemToCart, fetchCart, clean } = cartStore;
const { cart } = storeToRefs(cartStore);
const settingStore = useSettingStore()
const $gtm = useGtm();
const trackKlaviyoEvent = inject("$trackKlaviyoEvent");
const formatNumber = inject("$formatNumber");
const { notify } = useNotification();
const marketingStore = useMarketingStore();

const { popup } = usePopup();

const i18n = useI18n();

const loading = ref(false);
const processing = ref(false);
const index = ref(0);
const items = ref([]);
const defaultPlan = {
    capital: 10000,
    drawdown: 5,
    profit_split: 80,
    payout_frequency: "monthly", // 'monthly' 'biweekly', 'on-demand'
    currency: "USD",
    platform: "ctrader",
};
const plans = ref([defaultPlan]);
const plan = ref(plans.value[0]);
const capitals = computed(() =>
    unref(items)
        ? items2Sizes(unref(items))
        : [5, 10, 25, 50, 100, 200].map((e) => e * 1000)
);
const drawdowns = computed(() =>
    unref(items) ? items2MaxDailyDrawdowns(unref(items)) : [3, 4, 5]
);
const profitSplits = computed(() =>
    unref(items) ? items2ProfitSplits(unref(items)) : [26, 40, 60, 80, 85, 90, 95]
);
const frequencies = computed(() =>
    unref(items)
        ? items2PayoutFrequencies(unref(items))
        : ["mountly", "biweekly", "on-demand"]
);
const currencies = computed(() =>
    unref(items) ? items2Currencies(unref(items)) : ["USD", "EUR", "GBP"]
);
const comparePlansTitle = computed(() => {
    switch (index.value) {
        case 0:
            return "Compare Plans";
        case 1:
            return "Compare Another Plan";
        default:
            return "";
    }
});
const addPlan = () => {
    plans.value.push({ ...plans.value.at(-1) });
    plan.value = plans.value.at(-1);
    index.value = plans.value.length - 1;
};
const onPlanChanged = () => {
    plans.value[index.value] = plan.value;

    const item = findItem(plan.value);
};
const onIndexChanged = () => {
    plan.value = unref(plans.value[index.value]);
};
const canAdd = computed(() => plans.value.length < 3);

const findItem = (plan) => {
    const item = unref(items)
        ? unref(items).find((item) => {
            if (item.payout_type !== plan.payout_frequency) return false;
            if (item.props.profit_split !== plan.profit_split) return false;
            if (item.props.max_daily_drawdown !== plan.drawdown) return false;

            const version = item.versions
                ? item.versions.find(
                    (version) =>
                        version.currency_account === plan.currency &&
                        version.size === plan.capital
                )
                : null;
            return !!version;
        })
        : null;

    if (item) {
        item.version = item.versions.find(
            (version) =>
                version.currency_account === plan.currency &&
                version.size === plan.capital
        );
        item.platform = plan.platform;

        $gtm.push({
            event: "view_item_v2",
            ecommerce: {
                value: item.version.price,
                currency: item.version.currency,
                items: [
                    {
                        item_name: item.version.name,
                        item_id: item.version.id,
                        price: item.version.price,
                        quantity: 1,
                    },
                ],
            },
            user_data: {},
        });

        return JSON.parse(JSON.stringify(toRaw(item)));
    }
    return null;
};

const selectedItems = computed(() =>
    unref(items)
        ? unref(plans)
            .map(findItem)
            .filter((e) => !!e)
        : []
);
const selectedItem = computed(() =>
    unref(items) ? findItem(unref(plan)) : null
);
const { cookies } = useCookies();
const unallowedCountries = ["ru", "ir", "iq", "kp", "sy", "af", "ye", "so"];
const onBuyAccount = async (item) => {
    loading.value = true
    await fetchCart();
    await clean()
    await addItemToCart(
        item.version.id,
        "ctrader",
        item.payout_type === "on-demand" ? "on-demand" : "",
        item.type,
        item.payout_type,
        item.props.max_daily_drawdown
    );
    try {
        $gtm.push({
            event: "add_to_cart_v2",
            ecommerce: {
                value: item.version.price,
                currency: item.version.currency,
                items: [
                    {
                        item_name: item.version.name,
                        item_id: item.version.id,
                        price: item.version.price,
                        quantity: 1,
                    },
                ],
            },
            user_data: {},
        });
    } finally {
        loading.value = false
        window.open(`https://client.ofpfunding.com/checkout`, '_blank');
    }
};

const showBundleBox = ref(true)
const showBundleStrip = ref(false)
const showBundles = ref(false)
const changeValue = (value) => {
    if(value){
        showBundleStrip.value = false
    }else{
        showBundleStrip.value = true
    }
    showBundles.value = value
}
const showPopupIfCartNotEmpty = () => {
    if ((cart.value?.items || []).length) {
        popup({
            settings: {
                disableClose: true,
            },
            heading: i18n.t("shop-checkoutText"),
            image: "cart.svg",
            body: i18n.t("buyaccount-already-in-cart"),
            buttonText: i18n.t("buyaccount-go-to-checkout"),
            buttonClick: () => router.replace("/checkout"),
        });
    }
}

onMounted(async () => {
    try {
        settingStore.setting.type = "default_buy_account_item",
            await settingStore.showType()
        const [size, payoutType, dd, model] = settingStore.setting.value.split('|');
        const sizeMatch = size.match(/^(\d+)K/);
        const sizeAcc = sizeMatch ? parseInt(sizeMatch[1]) *1000  : null;
        const currency = size.replace(/[0-9K]/g, '');
        const payoutMatch = size.match(/(\d+)(?!.*\d)/);
        const payoutModel = payoutMatch ? payoutMatch[1] : null;
        plan.value.capital = sizeAcc,
            plan.value.drawdown = parseInt(dd),
            plan.value.profit_split = parseInt(payoutModel)
        plan.value.payout_frequency = payoutType
        plan.value.currency = currency
        plans.value = [plan.value]

        $gtm.push({ ecommerce: null });
        items.value = (await fetchItems({ type: "custom" })).filter((item) =>
            isValidItem(item)
        );
    } finally {
        loading.value = false;
    }
    return;
});
onBeforeMount(async() =>{

})
</script>

<style scoped lang="scss">
.compare-plans {
    color: rgba(254, 122, 92);
    font-family: Heebo, "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 24px;
    line-height: 36px;
    text-decoration: underline;
}
.compare-plans-mobile {
    color: rgba(254, 122, 92);
    font-family: Heebo, "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    text-decoration: underline;
}
</style>
