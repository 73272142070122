import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
    state: () => ({
        user: null,
        fidelityPoints: 0,
        accounts: [],
    }),
    getters: {
        getUser: (state) => {
            return state.user;
        },
    },
    actions: {
        async login(username, password) {
            const { data } = await this.$httpClient.post("/auth/login", {
                username,
                password,
            });
            this.user = data.user;

            const { useCookies } = require("vue3-cookies");
            useCookies().cookies.set("token", data.access_token.token, "30d");

            return data;
        },
        async me() {
            const { data } = await this.$httpClient.get(`/auth/me`);
            this.user = data;

            this.getFidelityPoints();

            return data;
        },
        async update(data) {
            await this.$httpClient.patch(`/customers/update/details`, data);
            this.me();
        },
        async updatePayoutDetails(data) {
            const request = await this.$httpClient.post(
                `/customers/setPayoutDetail`,
                data
            );
            this.me();
            return request;
        },
        async getFidelityPoints() {
            const response = await this.$httpClient.get(
                `/ecommerce/getCustomerReward`
            );
            const data = response.data || {};
            data.total = parseInt(data.total ?? 0);
            this.fidelityPoints = data;
            return data;
        },
        async getAccounts() {
            const response = await this.$httpClient.get(
                `/accounts/getAllCustomerAccounts`
            );
            const data = response.data || [];
            this.accounts = data;
            return data;
        },
    },
});
