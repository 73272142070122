<template>
    <!-- KYC -->
    <div v-if="user && !user.kyc">
        <div class="mx-auto p-2 d-flex justify-content-between rounded kyc-banner">
            <div class="col-6 d-flex align-items-center justify-content-start fw-bold" style="display: flex!important;">
                <i class="mdi mdi-alert-outline fs-3 text-white me-2 d-none d-lg-block"></i>
                <h6 v-if="orderWaitingKyc" class="m-0 p-0 text-white text-center">{{ $t('KYC-Banner-wait-order') }}</h6>
                <h6 v-else class="m-0 p-0 text-white text-center">{{ $t('KYC-Banner') }}</h6>
            </div>
            <div class="d-flex col-6 justify-content-end align-items-center" style="display: flex!important;">
                <button v-if="veriff == null" class="btn btn-ofp" @click="startVeriff()">{{ $t('KYC-Verify') }}</button>
            </div>
        </div>
        <div id='veriff-root' class="mt-2 col-12"></div>
    </div>
    <!-- /KYC -->
</template>

<script>
import { Veriff } from '@veriff/js-sdk';
import { mapGetters } from 'pinia';
import { useUserStore } from '../../store/user.store';

export default {
    name: 'banner',
    computed: {
        ...mapGetters(useUserStore, ['user'])

    },
    mounted(){
        this.checkOrderWaitingKyc()
    },
    methods: {
        checkOrderWaitingKyc(){
            try {
                axios.get(`${this.$baseUrl}/orders/getAllCustomerOrders`).then((response)=>{
                    this.orderWaitingKyc = response.data.orders_ofp.some(item => item.status === 'paid_waiting_kyc');
            })}
            catch (error) {
                console.log(error)
            }
        },
        startVeriff() {
            let id = this.user._id;
            this.veriff = new Veriff({
                apiKey: '3f684eb9-2d9e-4ff2-8aed-993ee59d4b85',
                parentId: 'veriff-root',
                onSession: function (err, response) {
                    if (err) {
                        console.error(err);
                        return;
                    }
                    window.open(response.verification.url, '_blank');
                }
            });
            this.veriff.setParams({
                vendorData: id,
                person: {
                    givenName: this.user.firstName,
                    lastName: this.user.lastName
                }
            });
            this.veriff.mount();
        }
    },
    data() {
        return {
            veriff: null,
            orderWaitingKyc : false
        }
    }
}
</script>

<style scoped lang="scss">
#veriff-root {
    max-width: 100%!important;
    color: white!important;
}
.veriff-description {
    color: white!important;
}
.kyc-banner {
    background-color: rgba(194, 79, 53, 0.2) !important;
    display: flex !important;
    border-radius: 8px !important;
    padding: 16px 24px;

    h6 {
        font-family: 'Heebo';
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.063rem;
        text-align: left;
    }
}
</style>
