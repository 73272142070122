<template>
  <div v-if="showModal">
    <div class="modal-backdrop" @click="closeModal"></div>
    <div
      class="modal fade d-block show"
      id="payoutCalculator"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body text-center gradient-border">
            <button
              type="button"
              class="btn-close"
              aria-label="Close"
              @click="closeModal"
            >
              <i class="fas fa-times"></i>
            </button>
            <h2>
              {{$t('payout-calculator')}}
            </h2>
            <p>
              {{ $t('payout-calculator1') }}
            </p>
            <div class="slider-container">
              <p>
                {{ $t('payout-calculator-monthly-return') }}
              </p>
              <div class="range-tooltip" :style="{ 'margin-left': `calc(${expectedMonthlyReturn}% - 25px)` }">{{ expectedMonthlyReturn }}%</div>
              <input type="range" min="1" max="100" step="1" class="slider" v-model="expectedMonthlyReturn" :style="{ background: `linear-gradient(to right, #f50 ${expectedMonthlyReturn}%, #ccc ${expectedMonthlyReturn}%)` }">
            </div>
            <div class="actions">
              <div class="action-item">
                <div>
                    {{ $t('payout-total-profits') }}
                </div>
                <div>
                  {{ $formatAmountRounded(totalProfits) }}
                </div>
              </div>
              <button class="action-item" @click="closeModal">
                <div>
                    {{ $t('payout-excpected') }}
                </div>
                <div>
                  {{ $formatAmountRounded(payoutExpected) }}
                </div>
              </button>
            </div>
            <p class="info">
              {{ $t('payout-calculator2') }} <br>
              {{ $t('payout-calculator3') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "PayoutCalculator",
    data() {
      return {
        expectedMonthlyReturn: 1,
        totalProfits: 0,
        payoutExpected: 0
      }
    },
    props: {
      showModal: {
        type: Boolean,
        default: false
      },
      selectedItem: Object
    },
    methods: {
      closeModal() {
        this.$emit('update:showModal', false);
      }
    },
    watch: {
      expectedMonthlyReturn(newVal) {
        this.totalProfits = (this.selectedItem.version.size * newVal) / 100;
      },
      totalProfits(newVal) {
        this.payoutExpected = (this.selectedItem.props.profit_split * newVal) / 100;
      },
      selectedItem(newVal) {
        this.totalProfits = (newVal.version.size * this.expectedMonthlyReturn) / 100;
        this.payoutExpected = (newVal.props.profit_split * this.totalProfits) / 100;
      }
    }
}
</script>

<style scoped lang="scss">
    .info{
      font-size: 13px;
    }

    .modal-content {
      background: rgba(16, 16, 42, 1);
      color: white;
      max-width: 95vw;

      @media (min-width: 768.1px) {
        max-width: unset;
      }
    }
    .modal-backdrop {
      position: fixed;
      top: 0;
      right: 0;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1040;
      
      @media (min-width: 768.1px) {
        margin-left: 220px;
        width: calc(100vw - 220px);
      }
    }
    .modal {
      z-index: 1050;

      @media (min-width: 768.1px) {
        width: calc(100% - 220px);
        margin-left: 220px;
        right: 0;
      }
    }
    .modal-dialog {
      min-width: 580px;
    }
    .modal-body {
      background: rgba(16, 16, 42, 1);
      padding: 16px;
      position: relative;
      border: none;
      color: rgba(255, 255, 255, 0.93);
      font-family: Heebo;
      
      @media (min-width: 768.1px) {
        padding: 32px 110px;
      }

      h2 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 16px;

        @media (min-width: 768.1px) {
          font-size: 28px;
          line-height: 41.13px;
        }
      }

      p {
        font-size: 14px;
        font-weight: 500;

        @media (min-width: 768.1px) {
          font-size: 16px;
          line-height: 23.5px;
        }

        &:last-child {
          font-size: 14px;
          font-weight: 400;
          line-height: 20.56px;
          color: rgba(255, 255, 255, 0.6);
          margin-top: 32px;
        }
      }

      .slider-container {
        background: rgba(255, 255, 255, 0.05);
        padding: 20px;
        margin-bottom: 24px;
        text-align: left;
        
        @media (min-width: 768.1px) {
          padding: 31px 59px;
        }

        .range-tooltip {
          background: rgba(255, 255, 255, 0.05);
          padding: 6px 8px 6px 8px;
          border-radius: 96px;
          width: 50px;
          height: 32px;
          text-align: center;
        }

        input {
          width: 100%;
          -webkit-appearance: none;
          appearance: none;
          height: 4px;
          border-radius: 4px;
          background: rgba(168, 168, 168, 0.7);
          cursor: pointer;
          outline: none;

          &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            height: 15px;
            width: 15px;
            background-color: rgba(255, 104, 68, 1);
            border-radius: 50%;
            border: none;
            transition: .2s ease-in-out;
          }

          &::-moz-range-thumb {
            height: 15px;
            width: 15px;
            background-color: rgba(255, 104, 68, 1);
            border-radius: 50%;
            border: none;
            transition: .2s ease-in-out;
          }
        }
      }

      .actions {
        display: flex;
        gap: 20px;
        flex-direction: column;

        @media (min-width: 768.1px) {
          flex-direction: row;
        }

        button {
          background: rgba(255, 104, 68, 1);
          padding: 0;
        }

        .action-item {
          border: 1px solid rgba(255, 192, 168, 0.3);
          color: rgba(255, 255, 255, 0.93);
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex: 1;
          border-radius: 4px;

          div {
            padding: 20px 15px;
            height: 100%;
            width: 50%;
            display: flex;
            align-items: center;
          }

          div:first-child {
            font-size: 14px;
            font-weight: 400;
            line-height: 20.56px;
            border-right: 1px solid rgba(255, 192, 168, 0.3);
          }

          div:last-child {
            font-size: 20px;
            font-weight: 600;
            line-height: 29.38px;
          }
        }
      }

      .btn-close {
        position: absolute;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 16px;
        right: 16px;

        @media (min-width: 768.1px) {
          top: 32px;
          right: 32px;
        }
      }
    }
</style>

