export default {
    install: (app, options) => {
        const localeByIso = {
            'en': 'en-US',
            'es': 'es-ES',
            'it': 'it-IT',
            'pt': 'pt-PT',
        }
        const currentLocale = localeByIso[app.config.globalProperties.$i18n.locale || 'en'];

        app.config.globalProperties.$formatDate = (date, locale) => {
            return new Intl.DateTimeFormat(locale || currentLocale, { 
                dateStyle: 'medium',
                timeStyle: 'medium',
                hour12: false
            }).format(new Date(date));
        }

        app.provide('$formatDate', app.config.globalProperties.$formatDate);
    }
}