<template>
    <Spinner v-if="loading" />
    <!-- price Bundles Desktop -->
    <div class="px-2 d-md-flex justify-content-center w-100 mb-5 mb-md-0 hiddenMobile">
        <table class=''>
            <thead>
                <tr>
                    <th class="d-none d-md-table-cell table-items-start">Account Type</th>
                    <th class="m-0 p-0" colspan="1" v-for="(item, index) in takeBundles" :key="index">
                        <div :class="index == 0 ? 'bundle1' : 'bundle2'" class="w-100 py-3">{{ item.id.split("-")[0] }}
                        </div>
                        <div class="d-flex flex-row gap-0 justify-content-between">
                            <span :class="index == 0 ? 'bundleaccount1' : 'bundle2'" class="p-3 w-50 text center"
                                v-for="(account, indexaccount) in item.items" :key="account">Account {{ indexaccount + 1
                                }}</span>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="table-items-start" nowrap>
                        {{ $t("buyaccount-account-size") }}
                    </td>
                    <td class="m-0 p-0" v-for="(item, index) in takeBundles" :key="index">
                        <div class="d-flex justify-content-between">
                            <div class="w-50" v-for="(account, accountindex) in item.items" :key="account">
                                <p class="px-0 py-4 my-0" :class="accountindex == 0 ? 'bgopacita1' : ''">{{ account.size
                                    }}
                                </p>
                            </div>
                        </div>
                    </td>
                </tr>

                <tr>
                    <td class="table-items-start" nowrap>{{ $t("leverage") }}</td>
                    <td class="m-0 p-0" v-for="(item, index) in takeBundles" :key="index">
                        <div class="d-flex justify-content-between">
                            <div class="w-50" v-for="(account, accountindex) in item.items" :key="account">
                                <p class="px-0 py-4 my-0" :class="accountindex == 0 ? 'bgopacita1' : ''">1:100</p>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="table-items-start" nowrap>{{ $t("max_daily_drawdown") }}</td>
                    <td class="m-0 p-0" v-for="(item, index) in takeBundles" :key="index">
                        <div class="d-flex justify-content-between">
                            <div class="w-50" v-for="(account, accountindex) in item.items" :key="account">
                                <p class="px-0 py-4 my-0" :class="accountindex == 0 ? 'bgopacita1' : ''">
                                    {{ account.max_daily_drawdown }}%</p>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="table-items-start" nowrap>{{ $t("max_drawdown") }}</td>
                    <td class="m-0 p-0" v-for="(item, index) in takeBundles" :key="index">
                        <div class="d-flex justify-content-between">
                            <div class="w-50" v-for="(account, accountindex) in item.items" :key="account">
                                <p class="px-0 py-4 my-0" :class="accountindex == 0 ? 'bgopacita1' : ''">10%</p>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="table-items-start" nowrap>{{ $t("max_trader_value_score") }}</td>
                    <td class="m-0 p-0" v-for="(item, index) in takeBundles" :key="index">
                        <div class="d-flex justify-content-between">
                            <div class="w-50" v-for="(account, accountindex) in item.items" :key="account">
                                <p class="px-0 py-4 my-0" :class="accountindex == 0 ? 'bgopacita1' : ''">25%</p>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="table-items-start" nowrap>{{ $t("Payouts") }}</td>
                    <td class="m-0 p-0" v-for="(item, index) in takeBundles" :key="index">
                        <div class="d-flex justify-content-between">
                            <div class="w-50" v-for="(account, accountindex) in item.items" :key="account">
                                <p class="text-capitalize px-0 py-4 my-0"
                                    :class="accountindex == 0 ? 'bgopacita1' : ''">
                                    {{ account.payout_type }}</p>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="table-items-start" nowrap>{{ $t("profit_split") }}</td>
                    <td class="m-0 p-0" v-for="(item, index) in takeBundles" :key="index">
                        <div class="d-flex justify-content-between">
                            <div class="w-50" v-for="(account, indexaccount) in item.items" :key="account">
                                <p class="px-0 py-4 my-0"
                                    :class="indexaccount == 0 ? 'bgprofitsplit1' : 'bgprofitsplit2'">{{
                                        ProfitSplit(account.item) }}%</p>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td class="d-none d-md-table-cell table-items-start" rowspan="2">
                        {{ $t("price") }}
                    </td>
                    <td class="d-none d-md-table-cell bgopacita1" rowspan="2" v-for="(item, index) in takeBundles"
                        :key="index">
                        <div>
                            <div class="discounted-price">
                                <span v-if="index == 0" class="original-price">
                                    {{$formatAmount(347)}}
                                </span>
                                <span v-else class="original-price">
                                    {{$formatAmount(642)}}
                                </span>
                            </div>
                            <div class="fs-2 mt-2">
                                {{$formatAmount(item.price)}}
                            </div>
                            <div class="pt-4">
                                <button class="btn-buy" @click="buyBundle(item.id)">
                                    <span>{{
                                        $t("buyaccount-buy_account")
                                    }}</span>
                                </button>
                            </div>
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
    <!-- END Bundles Desktop -->

    <!-- Limit Offer Mobile -->
    <div class="limitOfferMobile hiddenDesktop">
        <h3>EXCLUSIVE BUNDLE OFFER</h3>
        <p>2 Instantly Funded<span> Accounts at a Special Price</span></p>
        <div class="btnBundles">
            <button class="btnBundle1" @click="handleClick(0)">Bundle 1</button>
            <button class="btnBundle2" @click="handleClick(1)" >Bundle 2</button>
        </div>
    </div>
    <!-- END Limit Offer Mobile -->

    <!-- Table Price Bundles Mobile -->
    <div v-if="itemsStore.havesingleBundle" class="px-2 d-md-flex justify-content-center w-100 mb-5 mb-md-0 hiddenDesktop">
        <div class="titleBundle" :class="itemsStore.selectedBundle.name.includes('BUNDLE1')?'bundle1':' bundle2'">{{ itemsStore.selectedBundle.name.split('-')[0] }}</div>
        <table class='tableBundleMob w-100'>
            <thead>
                <tr>
                    <th class="m-0 p-0" id="opacity">Account Type</th>
                    <th class="m-0 p-0" :class="itemsStore.selectedBundle.name.includes('BUNDLE1')?'bundle1':' bundle2'">Account 1</th>
                    <th class="m-0 p-0" :class="itemsStore.selectedBundle.name.includes('BUNDLE1')?'bundle1':' bundle2'">Account 2</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="m-0 p-0" nowrap>{{ $t("buyaccount-account-size") }}</td>
                        <th class="m-0 p-0" v-for="item in itemsStore.selectedBundle.items" :key="item">
                            {{ item.size }}
                        </th>
                </tr>
                <tr>
                    <td nowrap>{{ $t("leverage") }}</td>
                    <td class="m-0 p-0">1:100</td>
                    <td class="m-0 p-0">1:100</td>
                </tr>
                <tr>
                    <td nowrap>{{ $t("max_daily_drawdown") }}</td>
                    <th class="m-0 p-0" v-for="item in itemsStore.selectedBundle.items" :key="item">
                            {{ item.max_daily_drawdown }}%
                        </th>
                </tr>
                <tr>
                    <td nowrap>{{ $t("max_drawdown") }}</td>
                    <td class="m-0 p-0">10%</td>
                    <td class="m-0 p-0">10%</td>
                </tr>
                <tr>
                    <td nowrap>Max IS</td>
                    <td class="m-0 p-0">25%</td>
                    <td class="m-0 p-0">25%</td>
                </tr>
                <tr>
                    <td nowrap>{{ $t("Payouts") }}</td>
                    <th class="m-0 p-0 text-capitalize" v-for="item in itemsStore.selectedBundle.items" :key="item">
                            {{ item.payout_type }}
                    </th>
                </tr>
                <tr>
                    <td nowrap>{{ $t("profit_split") }}</td>
                    <th class="m-0 p-0" v-for="(item,index) in itemsStore.selectedBundle.items" :key="item" :id="index==0?'bundle1mobile':'bundle2mobile'">
                        {{  ProfitSplit(item.item) }}%
                    </th>
                </tr>
            </tbody>
        </table>
        <div class="prices w-100 m-0 align-items-center">
            <!-- Regular Price -->
            <div class="d-flex w-75 box-regular">
                <div class="w-50">
                    <p class="reg-title">{{ $t('bundle.regularPrice') }}</p>
                    <p class="reg-subtitle">{{ $t('bundle.regularSubtitle') }}</p>
                </div>
                <div class="w-50 pricedown">
                    <p class="crossed-out" v-if="itemsStore.selectedBundle.id == 'BUNDLE1-FEB2025'">{{$formatAmount(347)}}</p>
                    <p class="crossed-out" v-else>{{$formatAmount(642)}}</p>
                </div>
            </div>

            <!-- Bundle Price -->
            <div class="d-flex w-75 box-bundle">
                <div class="w-50">
                    <p class="bundle-title">{{ $t('bundle.bundlePrice') }}</p>
                </div>
                <div class="w-50">
                    <p class="bundle-price">{{$formatAmount(itemsStore.selectedBundle.price)}}</p>
                </div>
            </div>
            <button class="btn w-75" @click="buyBundle(itemsStore.selectedBundle.id)">
                {{ $t('bundle.buyNow') }}
            </button>
        </div>
    </div>

    <!-- END Table Price Bundles Mobile -->


</template>

<script setup>
import { ref, toRaw, computed, watch, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useCookies } from "vue3-cookies";
import Spinner from "../Spinner.vue";

import { useUserStore } from '../../store/user.store';
import { storeToRefs } from 'pinia';
import { useItemsStore } from '../../store/items.store';
import { useCartStore } from "../../store/cart.store";

const props = defineProps({
    fromEndUser: {
        type: Boolean,
        default: false,
    }
})

const router = useRouter();
const loading = ref(false)

const { cookies } = useCookies();
const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const cartStore = useCartStore()
const { addBundleToCart , clean, fetchCart } = cartStore;

const itemsStore = useItemsStore();

const takeBundles = ref([])
const bundles2 = ref([])

const buyBundle = async (note) => {
    loading.value= true
    if(props.fromEndUser){
        await fetchCart();
        await clean()
    }
    loading.value= true
    const currentDate = new Date();
    const expirationDate = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);
    cookies.set('bundle', note, { expires: expirationDate });
    await addBundleToCart(note)
    loading.value= false
    if (props.fromEndUser) {
        window.open(`https://client.ofpfunding.com/checkout`, '_blank');
    } else {
        router.push('/checkout');
    }
}


const takeData = async () => {
    console.log('take', itemsStore)
    if(!bundles2.value.length){
        bundles2.value = await itemsStore.fetchBundles();
    }
    takeBundles.value = bundles2.value
}

const ProfitSplit = (item) => {
    let value = item.match(/\d+$/)[0];
    return value
}

const handleClick = (value) =>{
    itemsStore.switchSelectedBundle(value)
    emit('update:showBundle', true)
}

onMounted(async () => {
    await takeData()
})
</script>

<style scoped lang="scss">
#opacity{
    background-color: rgba(255, 255, 255, 0.08)!important;
}
.table-items-start{
    padding-left: 20px;
    text-align: start!important;
}
.bundle1 {
    background: conic-gradient(from 180deg at 50% 50%, #3F37CE 0deg, #332E8B 174.24deg, #3F37CE 360deg);
}
#bundle1mobile{
    background-color: #544ce04f!important;
}

#bundle2mobile{
    background: conic-gradient(from 180deg at 50% 50%, #3F37CE 0deg, #332E8B 174.24deg, #3F37CE 360deg)!important;
}
.bundleaccount1 {
    background: linear-gradient(148.57deg, #272278 10.21%, #3F37CE 87.03%);
}

.bgprofitsplit1 {
    background-color: rgb(84 76 224 / 30%);
}

.bgopacita1 {
    background-color: rgba(255, 255, 255, 0.08);
}

.bundle2 {
    background: linear-gradient(249.1deg, #259997 5.96%, #20199E 90.46%);

}

.bundleaccount2 {
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
        linear-gradient(249.1deg, #259997 5.96%, #20199E 90.46%);

}

.bgprofitsplit2 {
    background: linear-gradient(148.57deg, #272278 10.21%, #3F37CE 87.03%);
}

.price {
    font-size: 26px;
    line-height: 26px;
    font-weight: 700;
}

table {
    width: 75% !important;
    margin-top: 40px;
    border: 1px solid rgba(255, 255, 255, 0.2);

    th {
        font-weight: 600;
        font-size: 24px;
        background: rgba(255, 255, 255, 0.1);
    }

    @media screen and (max-width: 767.98px) {
        border: none;

        th {
            text-align: center;
            position: relative;
            font-size: 1rem;
            vertical-align: text-top;
            height: 80px;
            background-color: transparent!important;

            .btn-buy {
                position: absolute;
                left: 0;
                right: 0;
                top: 2rem;
                border-radius: 10px;
                font-optical-sizing: auto;
                font-weight: 600;
                font-style: normal;
                font-size: 14px;
                line-height: 20px;
                color: white;
                background: rgb(255, 104, 68, 1);
                padding: 8px 16px 8px 16px;
                margin: auto;
                width: 100%;
                min-width: 150px;
                max-width: 350px;
                border: none;
                vertical-align: sub;
            }

            .btn-buy .price {
                font-weight: 700;
                font-style: normal;
                font-size: 16px;
            }

            .btn-buy span .left {
                float: left;
            }

            .btn-buy span .right {
                padding-top: 2px;
                float: right;
            }

            .btn-buy:disabled {
                color: rgba(255, 255, 255, 0.5);
                background: rgba(255, 255, 255, 0.05);
            }
        }
    }
}

table tfoot {
    border-top: 1px solid rgba(255, 255, 255, 0.2);

    @media screen and (max-width: 767.9px) {
        border-top: none;
    }
}

table th,
td {
    padding-top: 24px;
    padding-bottom: 24px;
    text-align: center;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    max-width: 300px;
    color: white;
    border-left: 1px solid rgba(255, 255, 255, 0.2);

    @media screen and (max-width: 767.98px) {
        font-size: 16px;
        padding: 1rem 0;
        border-left: none;
        max-width: 150px;
        word-break: break-word;
        text-wrap: balance;

        &.table-item {
            padding-top: 2.5rem;
        }

        &:first-child {
            text-align: left;
        }

        &:not(th):last-child {
            text-align: right;
        }
    }
}

table th.clickable {
    cursor: pointer;
}

table.i1 {
    max-width: 794px;
    width: 100%;
}

table.i2 {
    max-width: 1102px;
    width: 100%;
}

table.i3 {
    max-width: 1218px;
    width: 100%;
}

table.s1 tr td:first-child+td {
    background-color: rgba(255, 255, 255, 0.05);
}

table.s1 tr th:first-child+th {
    background-color: rgba(255, 255, 255, 0.05);
}

table.i2.s1 tr th:first-child+th,
table.i3.s1 tr th:first-child+th {
    background-color: rgba(255, 255, 255, 0.05);

    span {
        background: linear-gradient(125.02deg,
                #ff6846 8.97%,
                #fb8469 21.12%,
                #7352fd 89.46%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

table.i1.s1 tr td:first-child+td {
    background-color: transparent;
}

table.i1.s1 tr th:first-child+th {
    background-color: rgba(255, 255, 255, 0.1);
}

table.s2 tr td:first-child+td+td {
    background-color: rgba(255, 255, 255, 0.05);
}

table.s2 tr th:first-child+th+th {
    background-color: rgba(255, 255, 255, 0.05);

    span {
        background: linear-gradient(125.02deg,
                #ff6846 8.97%,
                #fb8469 21.12%,
                #7352fd 89.46%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

table.s3 tr td:first-child+td+td+td {
    background-color: rgba(255, 255, 255, 0.05);
}

table.s3 tr th:first-child+th+th+th {
    background-color: rgba(255, 255, 255, 0.05);

    span {
        background: linear-gradient(125.02deg,
                #ff6846 8.97%,
                #fb8469 21.12%,
                #7352fd 89.46%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

td.divider {
    height: 1px;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 0px;
    padding-bottom: 0px;

    @media screen and (max-width: 797.7px) {
        &:first-child {
            padding-right: 0;
        }

        &:last-child {
            padding-left: 0;
        }
    }
}

div.divider {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.btn-buy {
    height: 41px;
    padding: 10px 8px 10px 8px;
    border-radius: 8px;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: white;
    background: rgb(255, 104, 68, 1);
    margin: auto;
    width: 218px;
    min-width: 150px;
    max-width: 218px;
    border: none;
}

.btn-buy:disabled {
    color: rgba(255, 255, 255, 0.5);
    background: rgba(255, 255, 255, 0.05);
}

.discounted-price {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

}

.original-price {
    position: relative;
    display: inline-block;
    color: rgb(160, 160, 160);
}

.original-price::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 45%;
    width: 12%;
    height: 2px;
    background-color: #fe805a;
    transform: rotate(-45deg);
    transform-origin: center;
}

.text-success {
    color: #6CF542 !important;
}

.discounted-price-mobile {
    text-decoration: line-through;
}

.right.price {
    display: flex;
    gap: 0.25rem;
}

/* Style Bundles*/

.limitOffer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Heebo';
    background: linear-gradient(178.05deg, #120E5C -18.74%, #0B442D 78.59%, #1C1675 146.27%);
    border-radius: 32px;
    margin-left: 80px;
    margin-top: 40px;
    min-width: 300px;
    text-align: center;
    padding: 34px;

    h3 {
        font-weight: 600;
        font-size: 32px;
        line-height: 33px;
        color: #FFF2ED;
    }

    p {
        font-weight: 400;
        font-size: 26px;
        line-height: 24px;
        color: #FFC0A8;

        span {
            font-size: 21px;
        }
    }

    .points {
        font-weight: 700;
        font-size: 21px;
        line-height: 24px;
        color: rgba(255, 255, 255, 0.8);
        text-align: left;
    }

    .counterdown button {
        color: rgba(255, 255, 255, 0.93);
        padding: 12px 32px;
        gap: 10px;
        width: 158px;
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid #25996B;
        border-radius: 8px;
        margin-bottom: 30px;
    }

    .countdownContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .countdown-block {
            width: 60px;
            height: 56px;
            background: rgba(255, 255, 255, 0.1);
            margin-bottom: 30px;
            padding: 8px 12px;
            border-radius: 8px;
        }

        .countdown-block:nth-child(1),
        .countdown-block:nth-child(2) {
            margin-right: 20px;
        }

        span {
            font-weight: 900;
            font-size: 16px;
            line-height: 24px;
            color: rgba(255, 255, 255, 0.93);
        }

        p {
            font-weight: 300;
            font-size: 12px;
            line-height: 18px;
            color: #A5DEFF;
        }
    }
}


@media screen and (min-width: 768px) {
    .hiddenDesktop {
        display: none !important;
    }
}

@media screen and (max-width: 768px) {
    .hiddenMobile {
        display: none;
    }

    .limitOfferMobile {
        width: 104%;
        margin-left: -2% !important;
        margin: 36px 0px 0px 0px;
        background-color: #0C0C2C;
        text-align: center;

        h3 {
            font-weight: 600;
            font-size: 24px;
            line-height: 38px;
            color: #FFF2ED;
            text-shadow: 1px 0px 10px #FF6363;
            padding-top: 12px;
            word-spacing: 2px;
            letter-spacing: 2px;
        }

        p {
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: #FF6844;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

            span {
                font-weight: 500;
                font-size: 14.3px;
                line-height: 20px;
                color: rgba(255, 255, 255, 0.93);
            }
        }

        .btnBundles {
            padding-bottom: 12px;
        }

        .btnBundle1 {
            width: 156px;
            height: 54px;
            padding: 10px;
            margin-right: 16px;
            color: rgba(255, 255, 255, 0.93);
            font-weight: 500;
            font-size: 14px;
            border: none;
            border-radius: 8px;
            background: linear-gradient(148.57deg, #1E1A64 10.21%, #3F37CE 53.76%, #1E1A64 92.53%), #FFFFFF;
        }

        .btnBundle2 {
            width: 156px;
            height: 54px;
            padding: 10px;
            color: rgba(255, 255, 255, 0.93);
            font-weight: 500;
            font-size: 14px;
            border: none;
            border-radius: 8px;
            background: linear-gradient(116.97deg, #2C25A1 -2.55%, #25996B 58.62%, #2C25A1 101.15%);
        }
    }

    .titleBundle {
        margin-top: 24px;
        padding: 6px;
        border-right: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 12px 12px 0px 0px;
        font-weight: 600;
        font-size: 14px;
        line-height: 28px;
        text-align: center;
        color: rgba(255, 255, 255, 0.93);
    }

    .tableBundleMob {
        font-family: 'Heebo';
        width: 100% !important;
        margin-top: 0px !important;
        border-collapse: separate;
        border-spacing: 0 0;

        th {
            border: 1px solid rgba(255, 255, 255, 0.1);
            font-weight: 500;
            font-size: 14px;
            color: rgba(255, 255, 255, 0.93);
            vertical-align: middle;
            height: 54px;
            background-color: transparent!important;
        }

        tbody td {
            border-color: rgba(255, 255, 255, 0.1);
            border-style: solid;
            border-width:1px;
            color: rgba(255, 255, 255, 0.93);
            font-size: 12px;
            font-weight: 400;
            height: 40px;
        }

        td:nth-child(1) {
            text-align: left !important;
            padding-left: 5px !important;
            color: rgba(255, 255, 255, 0.60);
        }

        tbody tr:first-child td {
            border-top-width: 1px;
        }

        tbody tr:last-child td {
            border-bottom-width: 1px;
        }

        tr,
        th,
        td {
            text-align: center !important;
        }

        td:not(:first-child) {
            width: 33%;
        }

        .bgAccountBundle1 {
            background: linear-gradient(148.57deg, #272278 10.21%, #3F37CE 87.03%), #FFFFFF;
        }
        .bgAccountBundle2 {
            background: linear-gradient(148.57deg, #272278 10.21%, #3F37CE 87.03%), #FFFFFF;
        }
    }

    .btn {
        padding: 10px 8px 10px 8px;
        border-radius: 8px;
        background: rgba(255, 104, 70, 1);
        font-size: 16px;
        font-weight: 600;
        line-height: 23.5px;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 1);
    }

    .prices {
        font-family: 'Heebo';
        background: rgba(255, 255, 255, 0.05);
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        border-radius: 0 0px 16px 16px;

        .box-regular,
        .box-bundle {
            border-bottom: solid rgba(255, 255, 255, 0.10) 1px;
            display: flex !important
        }

        .reg-title {
            font-size: 16px;
            line-height: 16px;
            font-weight: 300;
            text-align: center;
            color: rgba(255, 255, 255, 0.87) !important;
            text-align: left;
            margin: 0px;
        }

        .reg-subtitle {
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            text-align: center;
            color: rgba(255, 255, 255, 0.87);
            text-align: left;
            margin: 5px 0px;
        }

        .crossed-out {
            position: relative;
            display: inline-block;
            font-size: 24px;
            line-height: 39px;
            font-weight: 500;
            text-align: center;
            color: rgba(255, 255, 255, 0.60);
            padding: 0px;
            text-align: center !important;
        }

        .crossed-out::after {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            height: 2px;
            background-color: #FF9571;
            transform: rotate(152deg);
        }

        .pricedown {
            text-align: center !important;
        }

        .bundle-title {
            font-size: 18px;
            font-weight: 500;
            line-height: 46px;
            color: #95AAFF;
            text-align: left;
        }

        .bundle-price {
            font-size: 32px !important;
            font-weight: 600;
            line-height: 46px;
            text-align: center;
            color: rgba(255, 255, 255, 0.93);
        }

        .btn {
            margin-bottom: 19px;
        }

    }

    .back-price {
        margin-top: 32px;
        font-weight: 300;
        font-size: 16px;
        text-align: center;
        color: #FF9571;
    }





}
</style>
