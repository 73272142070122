<template>
     <Layout :loading="loading" title="Redeem Your OFP Giveaway" :custom-background="true">
        <div class="position-relative maxContainer">
            <div class="container-fluid px-4 mt-1 mt-lg-5">
                <div class="row" v-if="!verifyEmailCheck">
                    <div class="input-group input-group-mobile mb-3 col-12 p-0">
                        <h4 class="text-white mb-2 mx-auto fw-semibold" id="inputGroup-sizing-default">{{ $t('redeem-title') }}</h4>                        <font-awesome-icon icon="fas fa-arrow-down" />
                        <div class="d-flex dflex-mobile col-12 col-lg-8 col-xl-5  mx-auto justify-content-between p-0 m-0">
                            <div class="mt-3 mx-auto dflex-mobile ms-0">
                                <input  id="input0" type="text" maxlength="4" class="def-txt-input form-control" placeholder="XXXX" name="chars[1]" autofocus :class="{'border-danger': !checkCode.value}"
                                    inputmode="numeric"
                                    pattern="\d*"
                                    @keypress="event => { if (!/[0-9]/.test(event.key)) event.preventDefault(); }"
                                    @input="moveFocus(0)"
                                    @focus="clearErrorCode"
                                    v-model="code[0]"
                                >
                            </div>
                            <div class="ms-2 mt-3 mx-auto dflex-mobile">
                                <input  id="input1" type="text" maxlength="4" class="def-txt-input form-control" placeholder="XXXX" name="chars[2]" :class="{'border-danger': !checkCode.value}"
                                    inputmode="numeric"
                                    pattern="\d*"
                                    @keypress="event => { if (!/[0-9]/.test(event.key)) event.preventDefault(); }"
                                    @input="moveFocus(1)"
                                    @focus="clearErrorCode"
                                    v-model="code[1]"
                                >
                            </div>
                            <div class="ms-2 mt-3 mx-auto dflex-mobile">
                                <input id="input2" type="text" maxlength="4" class="def-txt-input form-control" placeholder="XXXX" name="chars[3]" :class="{'border-danger': !checkCode.value}"
                                    inputmode="numeric"
                                    pattern="\d*"
                                    @keypress="event => { if (!/[0-9]/.test(event.key)) event.preventDefault(); }"
                                    @input="moveFocus(2)"
                                    @focus="clearErrorCode"
                                    v-model="code[2]"
                                >
                            </div>
                            <div class="ms-2 mt-3 mx-auto dflex-mobile me-0">
                                <input id="input3" type="text" maxlength="4" class="def-txt-input form-control" placeholder="XXXX" name="chars[4]" :class="{'border-danger': !checkCode.value}"
                                    inputmode="numeric"
                                    pattern="\d*"
                                    @keypress="event => { if (!/[0-9]/.test(event.key)) event.preventDefault(); }"
                                    @input="moveFocus(3)"
                                    @focus="clearErrorCode"
                                    v-model="code[3]"
                                >
                            </div>
                        </div>
                        <div class="d-flex dflex-mobile col-12 col-lg-8 col-xl-5  mx-auto justify-content-between p-0 m-0">
                            <small v-if="!checkCode.value" class="text-danger mt-1 text-start">
                                {{checkCode.message}}
                            </small>
                        </div>
                    </div>
                    <div class="input-group input-group-mobile mb-3 col-12 p-0">
                        <div class="form col-12 col-lg-8 col-xl-5  mt-3 d-flex flex-column mx-auto dflex-mobile">
                            <input required type="email" class="form-control w-100 border-mobile p-0 p-2" :class="{'border-danger': !checkEmail.value}"
                                v-model="redeemStore.email" placeholder="Insert your email"
                                aria-label="Recipient's username" aria-describedby="button-addon2"
                                @blur="validateEmail"
                                @focus="clearError"
                                :readonly="user?.email">
                            <small v-if="!checkEmail.value" class="text-danger mt-1 text-start">
                                {{ checkEmail.message }}
                            </small>
                            <small v-if="checkEmail.status == 404" class="text-white mt-1 text-start">
                                {{ $t('redeem-1')}} <a href="/register">{{  $t('redeem-2') }}</a> {{  $t('redeem-3') }}
                            </small>
                        </div>
                    </div>
                    <button class="mt-5 mx-auto btn btn-primary col-6 col-lg-2 fw-bold" @click="checkRedeem()">{{ $t('verify') }}</button>
                </div>
                <div  v-if="verifyEmailCheck" >
                    <div class="d-flex flex-column mx-auto col-12 col-lg-8 col-xl-5  align-items-center justify-content-center boxCode">
                        <h4 class="text-success mt-2 mb-1 text-center fw-semibold" id="inputGroup-sizing-default">{{ account.code }}</h4>
                        <p class="text-white mb-2 text-center ">{{ $t('code-valid') }}</p>
                    </div>
                    <div class="row mt-5 justify-content-center">
                        <div class="col-12 col-lg-8 col-xl-5  gradient-border d-flex flex-row mt-5 p-1">
                            <div class="content w-100 dflex-mobile flex-column">
                                <h5 class="border-bottom mb-4 mx-auto fw-semibold">{{ $t('account-details') }}</h5>
                                <div class="dflex-mobile flex-row justify-content-around">
                                    <div class="">
                                        <div class='infoaccount'>
                                            <h5>{{ $t('Size') }} : </h5>
                                            <h5>{{ account.size }} K</h5>
                                        </div>
                                        <div class='infoaccount'>
                                            <h5>{{ $t('profit_split') }} : </h5>
                                            <h5>{{ account.profitSplit }} %</h5>
                                        </div>
                                        <div class='infoaccount'>
                                            <h5>{{ $t('max_daily_drawdown') }} : </h5>
                                            <h5>{{ account.MDD }} %</h5>
                                        </div>
                                    </div>
                                    <div class="">
                                        <div class='infoaccount justify-content-end'>
                                            <h5>{{ $t('Currency') }} :  </h5>
                                            <h5>{{ account.currency }}</h5>
                                        </div>
                                        <div class='infoaccount justify-content-end'>
                                            <h5>{{ $t('redeem-PayoutType') }} : </h5>
                                            <h5>{{ account.type }}</h5>
                                        </div>
                                        <div class='infoaccount justify-content-end'>
                                            <h5>{{ $t('shop-table3') }} : </h5>
                                            <h5>{{ account.MD }} %</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5 justify-content-center">
                        <h3 class="text-center text-white fw-semibold">{{ $t('redeem-platform') }} :</h3>
                        <div class="d-flex justify-content-between col-12 col-lg-8 col-xl-5  mt-5 p-0 m-0 dflex-mobile" >
                            <div v-for="(item, index) in platforms" :key="index" :class="{'dflex-mobile col-3 form-check d-inline-block d-md-flex flex-row align-items-center justify-content-center p-0': true}" @click="selectPlatform(item)" >
                                <div class="platform">
                                    <div class="platform-content"
                                        :class="isSelected(item) ? ['border', 'border-2', 'border-danger', 'platform-selected'] : []">
                                        <img class="platform-image" :src="platformImage(item)" :alt="formatPlatform(item)">
                                    </div>
                                    <div class="platform-label">
                                        {{ formatPlatform(item) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row my-3 justify-content-center">
                        <button class="mt-5 mb-5 mb-lg-0 btn btn-primary fw-bold col-6 col-lg-2" @click="redeemAccount()">{{ $t('redeem-claim') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
    <Footer />
</template>

<script setup>
import {
    ref,
    onMounted,
    watch,
    readonly,
    inject,
    watchEffect,
    computed,
    nextTick,
} from "vue";

import Layout from "../layouts/header-sidebar-title-layout.vue";
import Footer from "../components/statics/footer.vue";
import Spinner from "../components/Spinner";

import { usePopup } from "../store/popup.store";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from 'vue-i18n';
import { storeToRefs } from "pinia";
import { useCartStore } from "../store/cart.store";
import { useRedeemStore } from "../store/redeem.store";
import { useUserStore } from "../store/user.store";
import {  formatPlatform, platformImage } from "../components/custom-account/utils/format";
import { useCookies } from "vue3-cookies";

const { popup } = usePopup();
const route = useRoute();
const router = useRouter();

const { cookies } = useCookies();

const userStore = useUserStore();
const { user, fidelityPoints, guestId, getUser } = storeToRefs(userStore);

const redeemStore = useRedeemStore();
const {verifyRedeem} = redeemStore

const cartStore = useCartStore();
const {
    fetchCart,
    confirmOrder,
    payOrder,
    deleteCart,
    addItemToCart,
    setPlatformForCart,
} = cartStore;
const { cart } = storeToRefs(cartStore);

const i18n = useI18n();

const platforms = ref(['Ctrader', 'Matchtrader', 'Tradelocker'])
const platformSelected = ref('Ctrader')

const loading= ref(false)

const paymentError = ref("");
const code = ref(['' , '' , '' , '' ])

const account = ref({
    type: "Monthly",
    size: '25K',
    currency: 'USD',
    profitSplit: '80%',
    MDD: '5%',
    MD: '10%',
    id:'25KUSD80',
    code: ''

})
const selectPlatform = (item) => platformSelected.value = item
const isSelected = (item) => platformSelected.value && item ? platformSelected.value == item : false

const normalizePlatform = (platform) => {
    platform = platform ? platform : "ctrader";
    switch (platform) {
        case "Ctrader":
        case "c-trader":
            return "ctrader";
        case "match-trader":
        case "Matchtrader" :
            return "match-trader";
        case "match-trade":
            return "match-trader";
        case "Tradelocker" :
            return "tradelocker"
        default:
            return platform;
    }
};

const headers = ref('')

const checkEmail = ref ({
    value : true,
    message: '',
    status : ''
})
const checkCode = ref ({
    value : true,
    message: ''
})
const verifyEmailCheck = ref(false)

const validateEmail = () => {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (redeemStore.email && !emailPattern.test(redeemStore.email)) {
            checkEmail.value.value = false;
            checkEmail.value.message = i18n.t('redeem-valid-email');
        } else {
            checkEmail.value.value = true;
            checkEmail.value.message = '';
        }
    }

const clearError = () => {
    checkEmail.value.value = true;
    checkEmail.value.message = '';
};

const clearErrorCode = () => {
    checkCode.value.value = true;
    checkCode.value.message = '';
}

const checkRedeem = async () => {
    loading.value = true
    try {
        const { data, headers } = await verifyRedeem(combinedCode());
        account.value ={
            type: data['redeem'].account_payout_type,
            size : data['redeem'].account_size / 1000,
            currency: data['redeem'].account_currency,
            profitSplit: data['redeem'].account_profit_split,
            MDD: data['redeem'].account_mdd,
            MD: data['redeem'].account_md,
            id: data['redeem'].item_id,
            code : data['redeem'].code
        }

        if (guestId) {
            cookies.set(
                "emailAccount",
                redeemStore.email,
                Infinity
            );

            if (headers['x-auth-token']) {
                cookies.set("token", headers['x-auth-token'], '30d');
                await userStore.me();
            }
        }

        verifyEmailCheck.value= true
        loading.value = false
    } catch (error) {
        loading.value = false
        if( error.response.data.message.includes('email')){
            checkEmail.value.value = false
            checkEmail.value.message = error.response.data.message
            checkEmail.value.status = error.response.data.status
        }else{
            checkCode.value.value = false
            checkCode.value.message = error.response.data.message
        }
    }
}

const moveFocus = (index) => {
    if (code.value[index].length === 4 && index < 3) {
        document.getElementById(`input${index + 1}`).focus();
    }
};

const combinedCode = () => {
    return code.value.join("-");
};

const redeemAccount = async (throwError = false) => {
    loading.value=true
    try {
        await fetchCart()
        if(user.value){
            await deleteCart()
        }
        console.log(platformSelected)
        await addItemToCart(
            account.value.id,
            normalizePlatform(platformSelected.value),
            "",
            "custom",
            account.value.type,
            account.value.MDD,
            user.value.email,
            `Redeem ` + new Date().toISOString(),
        );
        const ref_origin =
            route.query.ref_origin ||
            cookies.get("ref_origin") ||
            sessionStorage.getItem("ref_origin") ||
            localStorage.getItem("ref_origin") ||
            null;
        const utm_campaign =
            route.query.utm_campaign || cookies.get("utm_campaign") || null;
        const utm_id = route.query.utm_id || cookies.get("utm_id") || null;
        const utm_medium =
            route.query.utm_medium || cookies.get("utm_medium") || null;
        const utm_source =
            route.query.utm_source || cookies.get("utm_source") || null;

        const customerDetails = ref({
            customer_id: user.value?._id,
            customer_email: redeemStore.email,
            billing_details: {
                name: user.value?.firstName,
                surname: user.value?.lastName,
                country: user.value?.country,
                address: user.value?.address,
                city: user.value?.city,
                zip: user.value?.zip,
                phone: user.value?.phone,
                lang: user.value?.language,
            },
        });
        const orderToConfirm = {
            ...customerDetails.value,
            customer_email: redeemStore.email,
            ref_origin,
            redeem_code: combinedCode(),
            note: "Redeem " + new Date().toISOString(),
            marketing: { utm_campaign, utm_id, utm_medium, utm_source },
            affiliate: cookies.get("ofp_aff")
                ? {
                        bannerId: cookies.get("a_bid"),
                        referralId: cookies.get("ofp_aff"),
                    }
                : undefined,
        };

        await setPlatformForCart(user.value?._id, normalizePlatform(platformSelected.value) )
        const {data} = await confirmOrder(orderToConfirm);
        if(data._id){
            const payData = {
            order_id : data.id,
            use_points : 0,
            payment_type : "OFP"
            }
            const dataPay = await payOrder(payData);
            if(data.total <= 0){
                router.push({
                    name: 'successPay',
                    query: {
                        customer_id: user.value?._id || '',
                        payment_type: ""
                    }
                })
            }
        }else{
            paymentError.value =
                data.name === "TotalAllocationError" ||
                data.name === "ModelAllocationError" ||
                data.name === "AllocationError"
                    ? data.message
                    : "An error occurred while processing your payment. Please try again later.";
            if (throwError) {
                throw new Error(paymentError.value);
            } else {
                popup({
                    settings: { disableClose: true },
                    heading: i18n.t("checkout-error-heading"),
                    image: "alert.svg",
                    body: paymentError.value,
                    buttonText: i18n.t("retry"),
                    buttonClick: () => {},
                });
            }
        }


        loading.value=false
    } catch (error) {
        console.log(error)
    }
}

watch(
    () => user.value,
    (user) => {
        if (user?.email) {
            redeemStore.email = user.email
        }
    },
    { immediate: true, deep: true }
);

</script>

<style lang="scss" scoped>

.btn-primary {
    background-color: #ff6844!important;
}

.container-fluid {
    margin-bottom: 100px;
    @media (max-width: 767px) {
    padding: 40px 10px;
    margin-bottom: 10px;
    };
}

.bg {
    padding: 60px 16px;

        @media (min-width: 768px) {
        padding: 140px 100px;
        }
}

input, textarea {
    background-color: transparent!important;
    color: white!important;
    border: 1px solid white;
    border-radius: 5px!important;
    max-height: 35px!important;
    min-height: 35px!important;
}

input:focus, textarea:focus {
    border: 1px solid #ff6844;
    box-shadow: none;
}

.input-group-text {
    color: white;
    background-color: transparent!important;
    border: none;
}

.input-group-mobile {
    display: flex!important;
    flex-direction: column;
    text-align: center;
}

.gradient-border {
    display: flex!important;
    position: relative;
    padding: 1px;
    background: linear-gradient(142.67deg, #FFB7A7 8.58%, #FB8469 25.6%, #FB8469 35.86%, #7D5FFC 66.99%, #FB8469 98.31%);
    border-radius: 10px;
}

.gradient-border .content {
    position: relative;
    padding: 16px;
    background-color: #2c1726;
    border-radius: 10px;
    color: white!important;
}

.content > div {
    margin-bottom: 10px;
}

.josefin-sans {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
}

.platform-list {
    margin-left: -20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.platform {
    width: 100%;
    height: 140px;

        @media (max-width: 768px) {
            height: 55px;
        };
}

.platform-content {
    border: 1px solid #686F7C;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    height: 75px;
    width: 100%;

        @media (max-width: 768px) {
            height: 55px;
        };
}

.platform-image {
    height: 50px;
    margin: auto;

        @media (max-width: 768px) {
        max-height: 35px;
        };
}

.platform-label {
    padding-top: 10px;
    text-align: center;
    font-size: 16px;

        @media (max-width: 768px) {
            font-size: 10px;
        };
}

.platform-selected {
    background: rgba(255, 105, 68, 0.332);
}

.disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
}

.disabled
.platform-content {
    cursor: not-allowed !important;
}

.infoaccount{
    display: flex!important;
    column-gap: 10px;
}

.border-mobile{
    border: 1px solid white;
    border-radius:5px!important;
}

.check-style{
    display: flex!important;
    align-items: center;
    background-color: #ff6844 ;
    border-radius: 0 5px 5px 0px;
}

.dflex-mobile{
    display: flex!important;
}

.disabledInput {
    pointer-events: none;
    opacity: 0.5;
    color: #a0a0a0;
}

.boxCode{
    background-color: #5e5e5e59;
    border-radius: 5px;
}
.maxContainer{
    margin-top: 100px;

    @media (max-width: 768px) {
        margin-top: 20px;
    };
}
</style>
