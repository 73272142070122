<template>
    <div class="stepper-container">
        <div v-for="(step, index) in steps" :key="index" class="stepper-item">
            <div class="step-align">
                <div :class="['step', getStepClass(index)]">
                    <span>{{ index + 1 }}</span>
                </div>
                <div class="step-name" :style="{ color: getNameColor(index) }">
                    {{ step.name }}
                </div>
            </div>
            <div
                v-if="index < steps.length - 1"
                :class="['step-line', getLineClass(index)]"
            ></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        steps: {
            type: Array,
            required: true,
        },
        currentStep: {
            type: Number,
            required: true,
        },
    },
    methods: {
        getStepClass(index) {
            if (index < this.currentStep) {
                return "step-completed";
            } else if (
                index === this.currentStep &&
                index !== this.steps.length - 1
            ) {
                return "step-active";
            } else if (
                index === this.steps.length - 1 &&
                index === this.currentStep
            ) {
                return "step-completed";
            } else {
                return "step-inactive";
            }
        },
        getLineClass(index) {
            if (index < this.currentStep - 1) {
                return "step-line-completed";
            } else if (index === this.currentStep - 1) {
                return "step-line-active";
            } else {
                return "";
            }
        },
        getNameColor(index) {
            if (index < this.currentStep) {
                return "white";
            } else if (index === this.currentStep) {
                return "white";
            } else {
                return "rgba(255, 255, 255, 0.5)";
            }
        },
    },
};
</script>

<style scoped>
.stepper-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.step-align {
    display: flex;
    margin-top: 25px;
    position: relative;
    flex-direction: column;
    justify-content: center;
    position: relative;
    align-items: center;
    z-index: 2;
}

.step-name {
    margin-top: 8px;
    margin-top: 10px;
    font-size: 16px;
    font-family: Heebo, sans-serif;
    font-weight: 400 !important;
    text-align: center;
    white-space: nowrap;
    z-index: 3;
    display: flex;
    position: absolute;
    margin-top: 70px;
}

.stepper-item {
    display: flex;
    align-items: center;
    position: relative;
}

.step {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease, background-color 0.3s ease, width 0.3s ease,
        height 0.3s ease;
    margin-bottom: 26px;
}

.step.step-completed {
    background: rgba(255, 104, 68, 1);
    color: white;
    opacity: 1;
}

.step.step-active {
    width: 52px;
    height: 52px;
    background: rgba(255, 255, 255, 0.1);
    border: 1.5px solid rgba(255, 104, 68, 1);
    opacity: 1;
    border-radius: 50%;
}

.step.step-inactive {
    background: rgba(255, 255, 255, 0.1);
    opacity: 0.5;
    color: white !important;
}

.step-line {
    min-width: 360px;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.1);
    opacity: 1;
    z-index: 3;
}

.step-line-completed {
    background-color: rgba(255, 104, 68, 1);
}

.step-line-active {
    background-color: rgba(255, 104, 68, 1);
}

.step span {
    font-size: 32px;
    font-weight: 400;
    color: white;
    font-family: Heebo;
}
@media (max-width: 1035px) {
    .stepper-container {
        display: flex;
        align-items: center;
    }
    .step {
        width: 28px;
        height: 28px;
    }
    .step span {
        font-size: 14px;
    }
    .step.step-active {
        width: 28px;
        height: 28px;
    }
    .step.step-active span {
        font-size: 14px;
    }
    .step-name {
        margin-top: 70px;
        font-size: 13px;
        font-weight: 400 !important;
        text-align: center;
        white-space: normal;
        word-wrap: break-word;
        line-height: 1.4;
        z-index: 3;
        display: flex;
        flex-direction: column;
        position: absolute;
        text-align: center;
        color: #ffffffed;
    }
    .step-line{
        height: 1px
    }
}
@media (max-width: 1623px) {
    .step-line {
        min-width: 160px;
    }
}
@media (max-width: 768px) {
    .step-line {
        min-width: 56px;
    }
}
</style>
