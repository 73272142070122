<template>
    <div id="main-wrapper ">
        <!--        <Spinner v-if="loading"/>-->
        <!-- Topbar -->
        <header class="topbar" :class="{ 'opacity-25': loading }">
            <Navbar :stopnav="stopnavbar" ref="navbarRef" />
        </header>
        <!-- /Toolbar -->

        <!-- Sidebar -->
        <aside class="left-sidebar" :class="{ 'opacity-25': loading }">
            <div class="scroll-sidebar">
                <Sidebar @rank="watchRank" />
            </div>
        </aside>
        <!-- /Sidebar -->

        <!-- Page -->
        <div class="page-wrapper" :class="{ 'opacity-25': showRank }">
            <template v-if="accountInfo">
                <bannerViolation
                    :status="accountInfo.status"
                    :reason="accountInfo.reason"
                />
                <bannerPayout
                    v-if="accountInfo.consistencyScore"
                    :props="accountInfo.props"
                    :status="accountInfo.status"
                    :profit="accountInfo.consistencyScore"
                    :model="accountInfo.type_account"
                />
            </template>

            <div>
                <div class="container-fluid">
                    <div class="row mt-3">
                        <div
                            class="col-12 mt-2 mt-lg-0 d-flex align-items-center justify-content-between d-flex"
                        >
                            <!-- Breadcrumb -->
                            <div
                                class="d-flex justify-content-start gap-1"
                                style="display: flex !important"
                            >
                                <p
                                    class="text-white-50 m-0 aling-items-center"
                                    style="display: flex !important"
                                >
                                    <router-link
                                        class="text-white-50"
                                        to="/home"
                                    >
                                        <font-awesome-icon
                                            icon="fa-solid fa-house"
                                            class="m10"
                                        />
                                        Dashboard
                                    </router-link>
                                </p>
                                <p class="text-white-50 m-0">&raquo;</p>
                                <p class="text-primary m-0">
                                    {{
                                        $t("Account_Details", {
                                            number: accountInfo?.username,
                                        })
                                    }}
                                </p>
                            </div>
                            <!-- / Breadcrumb -->

                            <!-- Info Account -->
                            <div class="mt-2 mt-lg-0">
                                <div
                                    class="displayFlex gap-2 justify-content-end"
                                >
                                    <button
                                        type="button"
                                        class="btn border border-white text-white d-flex gap-2"
                                        :class="{
                                            'btn-disabled': !accountInfo,
                                        }"
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModal"
                                        :disabled="!accountInfo"
                                    >
                                        <i
                                            class="mdi mdi-information-outline"
                                        ></i>
                                        <span class="d-none d-lg-block"
                                            >{{ $t("Info") }} Account</span
                                        >
                                    </button>
                                    <button
                                        type="button"
                                        class="btn border border-primary text-primary d-flex gap-2"
                                        :class="{
                                            'btn-disabled': !accountInfo,
                                        }"
                                        :disabled="!accountInfo"
                                        @click="refreshData()"
                                    >
                                        <i class="mdi mdi-refresh"></i>
                                        <span class="d-none d-lg-block">{{
                                            $t("UpdateData")
                                        }}</span>
                                    </button>
                                </div>
                                <p class="mt-2 text-end">
                                    {{ $t("DelayData") }}
                                </p>
                            </div>
                            <!-- Info account -->
                        </div>
                    </div>

                    <!-- Header -->
                    <div class="row m-0 w-100 p-2 mt-3 mt-md-0">
                        <NextPayout
                            :accountInfo="accountInfo"
                            v-if="accountInfo"
                        />
                    </div>
                    <!-- / Header -->

                    <!-- Modal: Info Account -->
                    <div
                        v-if="accountInfo"
                        class="modal fade"
                        id="exampleModal"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h1
                                        class="modal-title fs-3 text-white"
                                        id="exampleModalLabel"
                                    >
                                        Account: {{ accountInfo.username }}
                                    </h1>
                                    <button
                                        type="button"
                                        class="btn btn-close text-white"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        X
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="m-2">
                                        <ul
                                            class="nav nav-tabs"
                                            id="infoAccountTab"
                                            role="tablist"
                                        >
                                            <li
                                                class="nav-item"
                                                role="presentation"
                                            >
                                                <a
                                                    id="cred"
                                                    class="nav-link active fw-bold"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#credentials-tab"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="credentials"
                                                    aria-selected="true"
                                                >
                                                    {{ $t("Credentials") }}
                                                </a>
                                            </li>
                                            <li
                                                class="nav-item"
                                                role="presentation"
                                            >
                                                <a
                                                    id="info"
                                                    class="nav-link fw-bold"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#info-tab"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="info"
                                                    aria-selected="true"
                                                >
                                                    Account {{ $t("Info") }}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div
                                        class="tab-content px-2"
                                        id="infoAccountTabContent"
                                    >
                                        <div
                                            class="tab-pane fade show active"
                                            id="credentials-tab"
                                            role="tabpanel"
                                            aria-labelledby="credentials-tab"
                                        >
                                            <div
                                                class="d-flex flex-column data-account w-100 py-2 px-2"
                                            >
                                                <p
                                                    class="d-flex justify-content-between mb-0"
                                                >
                                                    {{ $t("Login") }}:
                                                    <span
                                                        id="login"
                                                        :class="{
                                                            'text-white': true,
                                                            'fw-bold': true,
                                                            'ms-4': true,
                                                            'me-2': true,
                                                            blurred:
                                                                !credentials,
                                                        }"
                                                    >
                                                        <i
                                                            data-toggle="tooltip"
                                                            data-bs-placement="bottom"
                                                            title="Copy"
                                                            role="button"
                                                            @click="
                                                                copyToClipboard(
                                                                    'login'
                                                                )
                                                            "
                                                            :class="{
                                                                'ti-layers': true,
                                                                btn: true,
                                                                blurred:
                                                                    !credentials,
                                                            }"
                                                        ></i>
                                                        {{
                                                            accountInfo.username
                                                        }}
                                                    </span>
                                                </p>

                                                <hr class="m-0" />

                                                <p
                                                    class="d-flex justify-content-between mb-0"
                                                >
                                                    {{ $t("Password") }}:
                                                    <span
                                                        id="password"
                                                        :class="{
                                                            'text-white': true,
                                                            'fw-bold': true,
                                                            'ms-4': true,
                                                            'me-2': true,
                                                            blurred:
                                                                !credentials,
                                                        }"
                                                    >
                                                        <i
                                                            :class="{
                                                                blurred:
                                                                    !credentials,
                                                            }"
                                                            role="button"
                                                            @click="
                                                                copyToClipboard(
                                                                    'password'
                                                                )
                                                            "
                                                            class="ti-layers btn"
                                                        ></i>
                                                        {{
                                                            accountInfo.password
                                                        }}
                                                    </span>
                                                </p>

                                                <hr class="m-0" />

                                                <p
                                                    class="d-flex justify-content-between mb-0"
                                                >
                                                    {{ $t("Server") }}:
                                                    <span
                                                        id="server"
                                                        :class="{
                                                            'text-white': true,
                                                            'fw-bold': true,
                                                            'ms-4': true,
                                                            'me-2': true,
                                                            blurred:
                                                                !credentials,
                                                        }"
                                                    >
                                                        <i
                                                            :class="{
                                                                blurred:
                                                                    !credentials,
                                                            }"
                                                            role="button"
                                                            @click="
                                                                copyToClipboard(
                                                                    'server'
                                                                )
                                                            "
                                                            class="ti-layers btn"
                                                        ></i>
                                                        {{ accountInfo.server }}
                                                    </span>
                                                </p>

                                                <hr class="m-0" />

                                                <p
                                                    class="d-flex justify-content-between mb-0"
                                                >
                                                    {{ $t("Platform") }}:
                                                    <span
                                                        :class="{
                                                            'text-white': true,
                                                            'text-uppercase': true,
                                                            'fw-bold': true,
                                                            'ms-4': true,
                                                            'me-2': true,
                                                            blurred:
                                                                !credentials,
                                                        }"
                                                    >
                                                        {{
                                                            accountInfo.platform
                                                        }}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>

                                        <div
                                            class="tab-pane fade"
                                            id="info-tab"
                                            role="tabpanel"
                                            aria-labelledby="info-tab"
                                        >
                                            <div
                                                class="my-3 d-flex flex-column data-account p-2"
                                                style="display: flex !important"
                                            >
                                                <div
                                                    class="d-flex align-items-center justify-content-between"
                                                    style="
                                                        display: flex !important;
                                                    "
                                                >
                                                    <div
                                                        class="d-flex align-items-center"
                                                        style="
                                                            display: flex !important;
                                                        "
                                                    >
                                                        <div class="row-icon">
                                                            <i
                                                                class="icons-Calendar-4"
                                                            ></i>
                                                        </div>
                                                        <div
                                                            class="d-flex flex-column"
                                                            style="
                                                                display: flex !important;
                                                            "
                                                        >
                                                            <h6
                                                                class="m-0 fw-bold"
                                                            >
                                                                {{
                                                                    $t("Start")
                                                                }}
                                                            </h6>
                                                            <p class="m-0">
                                                                {{
                                                                    $t(
                                                                        "StartTradPeriod"
                                                                    )
                                                                }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p
                                                            v-if="
                                                                accountInfo.created_at
                                                            "
                                                            class="m-0"
                                                        >
                                                            {{
                                                                accountInfo.created_at.toLocaleString()
                                                            }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    class="d-flex align-items-center justify-content-between"
                                                    style="
                                                        display: flex !important;
                                                    "
                                                >
                                                    <div
                                                        class="d-flex align-items-center"
                                                        style="
                                                            display: flex !important;
                                                        "
                                                    >
                                                        <div class="row-icon">
                                                            <i
                                                                class="icons-Dollar-Sign2"
                                                            ></i>
                                                        </div>
                                                        <div
                                                            class="d-flex flex-column"
                                                            style="
                                                                display: flex !important;
                                                            "
                                                        >
                                                            <h6
                                                                class="m-0 fw-bold"
                                                            >
                                                                {{
                                                                    $t(
                                                                        "InitBalance"
                                                                    )
                                                                }}
                                                            </h6>
                                                            <p class="m-0">
                                                                {{ $t("Size") }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p class="m-0">
                                                            {{ currency }}
                                                            {{
                                                                formatAmount(
                                                                    accountInfo.initial_balance
                                                                )
                                                            }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    class="d-flex align-items-center justify-content-between"
                                                    style="
                                                        display: flex !important;
                                                    "
                                                >
                                                    <div
                                                        class="d-flex align-items-center"
                                                        style="
                                                            display: flex !important;
                                                        "
                                                    >
                                                        <div class="row-icon">
                                                            <i
                                                                class="icons-Coins"
                                                            ></i>
                                                        </div>
                                                        <div
                                                            class="d-flex flex-column"
                                                            style="
                                                                display: flex !important;
                                                            "
                                                        >
                                                            <h6
                                                                class="m-0 fw-bold"
                                                            >
                                                                {{
                                                                    $t(
                                                                        "Currency"
                                                                    )
                                                                }}
                                                            </h6>
                                                            <p class="m-0"></p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p
                                                            class="m-0"
                                                            v-if="
                                                                accountInfo.currency
                                                            "
                                                        >
                                                            {{
                                                                accountInfo.currency
                                                            }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    class="d-flex align-items-center justify-content-between"
                                                    style="
                                                        display: flex !important;
                                                    "
                                                >
                                                    <div
                                                        class="d-flex align-items-center"
                                                        style="
                                                            display: flex !important;
                                                        "
                                                    >
                                                        <div class="row-icon">
                                                            <i
                                                                class="icons-Money-2"
                                                            ></i>
                                                        </div>
                                                        <div
                                                            class="d-flex flex-column"
                                                            style="
                                                                display: flex !important;
                                                            "
                                                        >
                                                            <h6
                                                                class="m-0 fw-bold"
                                                            >
                                                                {{
                                                                    $t(
                                                                        "WithdrawalTot"
                                                                    )
                                                                }}
                                                            </h6>
                                                            <p class="m-0"></p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p class="m-0">
                                                            {{ currency }}
                                                            {{
                                                                accountInfo.totalPayouts
                                                            }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    class="d-flex align-items-center justify-content-between"
                                                    style="
                                                        display: flex !important;
                                                    "
                                                >
                                                    <div
                                                        class="d-flex align-items-center"
                                                        style="
                                                            display: flex !important;
                                                        "
                                                    >
                                                        <div class="row-icon">
                                                            <i
                                                                class="icons-Satelite"
                                                            ></i>
                                                        </div>
                                                        <div
                                                            class="d-flex flex-column"
                                                            style="
                                                                display: flex !important;
                                                            "
                                                        >
                                                            <h6
                                                                class="m-0 fw-bold"
                                                            >
                                                                {{
                                                                    $t(
                                                                        "Platform"
                                                                    )
                                                                }}
                                                            </h6>
                                                            <p class="m-0">
                                                                {{
                                                                    $t(
                                                                        "PlatformText"
                                                                    )
                                                                }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p
                                                            class="m-0"
                                                            v-if="
                                                                accountInfo.platform
                                                            "
                                                        >
                                                            {{
                                                                accountInfo.platform.toUpperCase()
                                                            }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    class="d-flex align-items-center justify-content-between"
                                                    style="
                                                        display: flex !important;
                                                    "
                                                >
                                                    <div
                                                        class="d-flex align-items-center"
                                                        style="
                                                            display: flex !important;
                                                        "
                                                    >
                                                        <div class="row-icon">
                                                            <i
                                                                class="icons-Server"
                                                            ></i>
                                                        </div>
                                                        <div
                                                            class="d-flex flex-column"
                                                            style="
                                                                display: flex !important;
                                                            "
                                                        >
                                                            <h6
                                                                class="m-0 fw-bold"
                                                            >
                                                                {{
                                                                    $t("Server")
                                                                }}
                                                            </h6>
                                                            <p class="m-0">
                                                                {{
                                                                    $t(
                                                                        "ServerText"
                                                                    )
                                                                }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p class="m-0">
                                                            {{
                                                                accountInfo.server
                                                            }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- / Modal: Info Account -->

                    <!-- Content Header -->
                    <template v-if="true">
                        <div
                            v-if="accountInfo"
                            class="d-flex justify-content-between align-items-center mt-4"
                        >
                            <h2
                                class="text-white fw-bold mt-2 d-none d-lg-block"
                            >
                                Account: {{ accountInfo.username }}
                            </h2>
                            <p class="d-block d-lg-none text-center">
                                {{ $t("ResetDaily") }}
                            </p>
                            <div
                                class="d-flex justify-content-center align-items-center gap-2"
                                style="display: flex !important"
                            >
                                <p class="m-0 d-none d-lg-block">
                                    {{ $t("ResetDaily") }}:
                                </p>
                                <div
                                    class="progress-bar progress-padding"
                                    :style="`background: radial-gradient(closest-side, #010916 80%, transparent 80% 100%), conic-gradient(#fb9678 ${
                                        100 - (countdown.hours * 100) / 24
                                    }%, rgb(251 150 120 / 50%) 0)`"
                                >
                                    {{ countdown.hours }}hr
                                    {{ countdown.minutes }}m
                                    <progress
                                        :value="countdown.hours"
                                        min="0"
                                        max="24"
                                        class="d-none"
                                    ></progress>
                                </div>
                            </div>
                        </div>
                        <div
                            v-else
                            class="d-flex justify-content-between align-items-center mt-4 skeleton"
                        >
                            <h2
                                class="text-white fw-bold mt-2 d-none d-lg-block"
                            ></h2>
                            <p
                                class="d-block d-lg-none text-center skeleton-exclude"
                            >
                                {{ $t("ResetDaily") }}
                            </p>
                            <div
                                class="d-flex justify-content-center align-items-center gap-2"
                                style="display: flex !important"
                            >
                                <p
                                    class="m-0 d-none d-lg-block skeleton-exclude"
                                >
                                    {{ $t("ResetDaily") }}:
                                </p>
                                <div
                                    class="progress-bar progress-padding"
                                    :style="`background: radial-gradient(closest-side, #010916 80%, transparent 80% 100%), conic-gradient(#fb9678 100%, rgb(251 150 120 / 50%) 0)`"
                                >
                                    0hr 0m
                                    <progress
                                        :value="0"
                                        min="0"
                                        max="24"
                                        class="d-none"
                                    ></progress>
                                </div>
                            </div>
                        </div>
                    </template>
                    <!-- / Content Header -->

                    <!-- Content -->
                    <div
                        class="row marginpage text-white justify-content-evenly my-5 pb-5"
                    >
                        <!-- Widgets: Desktop -->
                        <div
                            class="row d-none d-xl-flex justify-content-between p-0"
                        >
                            <div class="col-sm-3">
                                <!-- Growth % -->
                                <div class="card">
                                    <div class="card-body flex-column active">
                                        <div class="mt-3">
                                            <div class="px-0">
                                                <h2
                                                    v-if="accountInfo"
                                                    class="my-auto fw-bold text-primary"
                                                >
                                                    {{
                                                        (
                                                            accountInfo.growth ||
                                                            0
                                                        ).toFixed(2)
                                                    }}%
                                                </h2>
                                                <h2 v-else>
                                                    <i
                                                        class="fa fa-spinner fa-spin fa-2xl"
                                                    ></i>
                                                </h2>
                                            </div>
                                        </div>
                                        <div
                                            class="d-flex no-block al m-r-15ign-items-center"
                                        >
                                            <div class="m-t-10 d-flex gap-2">
                                                <h6
                                                    class="text-white font-medium"
                                                >
                                                    {{ $t("trial-growth") }}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-3">
                                <!-- Total profits -->
                                <div class="card">
                                    <div class="card-body flex-column active">
                                        <div class="mt-3">
                                            <div class="px-0">
                                                <h2
                                                    v-if="accountMetrics"
                                                    class="my-auto fw-bold text-primary"
                                                >
                                                    {{ currency }}
                                                    {{
                                                        formatAmount(
                                                            (
                                                                accountMetrics.profit ||
                                                                0
                                                            ).toFixed(2)
                                                        )
                                                    }}
                                                </h2>
                                                <h2 v-else>
                                                    <i
                                                        class="fa fa-spinner fa-spin fa-2xl"
                                                    ></i>
                                                </h2>
                                            </div>
                                        </div>
                                        <div
                                            class="d-flex no-block al m-r-15ign-items-center"
                                        >
                                            <div class="m-t-10 d-flex gap-2">
                                                <h6
                                                    class="text-white font-medium"
                                                >
                                                    {{ $t("trial-totProf") }}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-3">
                                <!--- Account balance --->
                                <div class="card">
                                    <div class="card-body flex-column active">
                                        <div class="mt-3">
                                            <div class="px-0">
                                                <h2
                                                    v-if="accountMetrics"
                                                    class="my-auto fw-bold text-primary"
                                                >
                                                    {{ currency }}
                                                    {{
                                                        formatAmount(
                                                            accountMetrics.balance ||
                                                                0
                                                        )
                                                    }}
                                                </h2>
                                                <h2 v-else>
                                                    <i
                                                        class="fa fa-spinner fa-spin fa-2xl"
                                                    ></i>
                                                </h2>
                                            </div>
                                        </div>
                                        <div
                                            class="d-flex no-block al m-r-15ign-items-center"
                                        >
                                            <div class="m-t-10 d-flex gap-2">
                                                <h6
                                                    class="text-white font-medium"
                                                >
                                                    Acc. Balance
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-3">
                                <!--- Account equity --->
                                <div class="card">
                                    <div class="card-body flex-column active">
                                        <div class="mt-3">
                                            <div class="px-0">
                                                <h2
                                                    v-if="accountMetrics"
                                                    class="my-auto fw-bold text-primary"
                                                >
                                                    {{ currency }}
                                                    {{
                                                        formatAmount(
                                                            accountMetrics.equity ||
                                                                0
                                                        )
                                                    }}
                                                </h2>
                                                <h2 v-else>
                                                    <i
                                                        class="fa fa-spinner fa-spin fa-2xl"
                                                    ></i>
                                                </h2>
                                            </div>
                                        </div>
                                        <div
                                            class="d-flex no-block al m-r-15ign-items-center"
                                        >
                                            <div class="m-t-10 d-flex gap-2">
                                                <h6
                                                    class="text-white font-medium"
                                                >
                                                    Acc. Equity
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="row d-none d-xl-flex justify-content-between p-0"
                        >
                            <div class="col-sm-3">
                                <!--- Profit factor --->
                                <div class="card">
                                    <div class="card-body flex-column active">
                                        <div class="mt-3">
                                            <div class="px-0">
                                                <h2
                                                    v-if="accountMetrics"
                                                    class="my-auto fw-bold text-primary"
                                                >
                                                    {{
                                                        (
                                                            accountMetrics.profitFactor ||
                                                            0
                                                        ).toFixed(2)
                                                    }}
                                                </h2>
                                                <h2 v-else>
                                                    <i
                                                        class="fa fa-spinner fa-spin fa-2xl"
                                                    ></i>
                                                </h2>
                                            </div>
                                        </div>
                                        <div
                                            class="d-flex no-block al m-r-15ign-items-center"
                                        >
                                            <div class="m-t-10 d-flex gap-2">
                                                <h6
                                                    class="text-white font-medium"
                                                >
                                                    {{ $t("ProfitFactor") }}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-3">
                                <!--- Expectacy --->
                                <div class="card">
                                    <div class="card-body flex-column active">
                                        <div class="mt-3">
                                            <div class="px-0">
                                                <h2
                                                    v-if="accountMetrics"
                                                    class="my-auto fw-bold text-primary"
                                                >
                                                    {{ currency }}
                                                    {{
                                                        formatAmount(
                                                            accountMetrics?.expectancy ||
                                                                0
                                                        )
                                                    }}
                                                </h2>
                                                <h2 v-else>
                                                    <i
                                                        class="fa fa-spinner fa-spin fa-2xl"
                                                    ></i>
                                                </h2>
                                            </div>
                                        </div>
                                        <div
                                            class="d-flex no-block al m-r-15ign-items-center"
                                        >
                                            <div class="m-t-10 d-flex gap-2">
                                                <h6
                                                    class="text-white font-medium"
                                                >
                                                    {{ $t("Expectancy") }}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-3">
                                <!--- R:R --->
                                <div class="card">
                                    <div class="card-body flex-column active">
                                        <div class="mt-3">
                                            <div class="px-0">
                                                <template v-if="accountMetrics">
                                                    <h2
                                                        v-if="isNaN(rr)"
                                                        class="my-auto fw-bold text-primary"
                                                    >
                                                        -
                                                    </h2>
                                                    <h2
                                                        v-else
                                                        class="my-auto fw-bold text-primary"
                                                    >
                                                        1 : {{ rr }}
                                                    </h2>
                                                </template>
                                                <h2 v-else>
                                                    <i
                                                        class="fa fa-spinner fa-spin fa-2xl"
                                                    ></i>
                                                </h2>
                                            </div>
                                        </div>
                                        <div
                                            class="d-flex no-block al m-r-15ign-items-center"
                                        >
                                            <div class="m-t-10 d-flex gap-2">
                                                <h6
                                                    class="text-white font-medium"
                                                >
                                                    {{ $t("profile-ARR") }}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-3">
                                <!--- commissions --->
                                <div class="card">
                                    <div class="card-body flex-column active">
                                        <div class="mt-3">
                                            <div class="px-0">
                                                <h2
                                                    v-if="accountMetrics"
                                                    class="my-auto fw-bold text-primary"
                                                >
                                                    {{ currency }}
                                                    {{
                                                        formatAmount(
                                                            accountMetrics?.commissions ||
                                                                0
                                                        )
                                                    }}
                                                </h2>
                                                <h2 v-else>
                                                    <i
                                                        class="fa fa-spinner fa-spin fa-2xl"
                                                    ></i>
                                                </h2>
                                            </div>
                                        </div>
                                        <div
                                            class="d-flex no-block al m-r-15ign-items-center"
                                        >
                                            <div class="m-t-10 d-flex gap-2">
                                                <h6
                                                    class="text-white font-medium"
                                                >
                                                    {{ $t("trial-totComm") }}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /Widgets: Desktop -->

                        <!-- Widgets: Mobile -->
                        <div
                            id="carouselExampleInterval"
                            class="carousel slide d-sm-block d-xl-none"
                            data-bs-ride="carousel"
                        >
                            <div
                                class="carousel-inner"
                                style="
                                    width: 95% !important ;
                                    margin: 0 auto !important;
                                "
                            >
                                <div
                                    class="carousel-item active"
                                    data-bs-interval="10000"
                                >
                                    <div class="card lossBox" id="card-primary">
                                        <div
                                            class="card-body flex-column active"
                                        >
                                            <div class="mt-3">
                                                <div class="px-0">
                                                    <h2
                                                        v-if="accountMetrics"
                                                        class="my-auto fw-bold text-primary"
                                                    >
                                                        {{
                                                            formatAmount(
                                                                accountMetrics.profitFactor ||
                                                                    0
                                                            )
                                                        }}
                                                    </h2>
                                                    <h2 v-else>
                                                        <i
                                                            class="fa fa-spinner fa-spin fa-2xl"
                                                        ></i>
                                                    </h2>
                                                </div>
                                            </div>
                                            <div
                                                class="d-flex no-block al m-r-15ign-items-center"
                                            >
                                                <div
                                                    class="m-t-10 d-flex gap-2"
                                                >
                                                    <h6
                                                        class="text-white font-medium"
                                                    >
                                                        {{ $t("ProfitFactor") }}
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="carousel-item"
                                    data-bs-interval="2000"
                                >
                                    <div class="card expBox" id="card-aqua">
                                        <div
                                            class="card-body flex-column active"
                                        >
                                            <div class="mt-3">
                                                <div class="px-0">
                                                    <h2
                                                        v-if="accountMetrics"
                                                        class="my-auto fw-bold text-primary"
                                                    >
                                                        {{ currency }}
                                                        {{
                                                            formatAmount(
                                                                accountMetrics.expectancy ||
                                                                    0
                                                            )
                                                        }}
                                                    </h2>
                                                    <h2 v-else>
                                                        <i
                                                            class="fa fa-spinner fa-spin fa-2xl"
                                                        ></i>
                                                    </h2>
                                                </div>
                                            </div>
                                            <div
                                                class="d-flex no-block al m-r-15ign-items-center"
                                            >
                                                <div
                                                    class="m-t-10 d-flex gap-2"
                                                >
                                                    <h6
                                                        class="text-white font-medium"
                                                    >
                                                        {{ $t("Expectancy") }}
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="card winBox" id="card-pink">
                                        <div
                                            class="card-body flex-column active"
                                        >
                                            <div class="mt-3">
                                                <div class="px-0">
                                                    <h2
                                                        v-if="accountMetrics"
                                                        class="my-auto fw-bold text-primary"
                                                    >
                                                        {{ currency }}
                                                        {{
                                                            formatAmount(
                                                                accountMetrics.balance ||
                                                                    0
                                                            )
                                                        }}
                                                    </h2>
                                                    <h2 v-else>
                                                        <i
                                                            class="fa fa-spinner fa-spin fa-2xl"
                                                        ></i>
                                                    </h2>
                                                </div>
                                            </div>
                                            <div
                                                class="d-flex no-block al m-r-15ign-items-center"
                                            >
                                                <div
                                                    class="m-t-10 d-flex gap-2"
                                                >
                                                    <h6
                                                        class="text-white font-medium"
                                                    >
                                                        Acc. Balance
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="card totalBox" id="card-azure">
                                        <div
                                            class="card-body flex-column active"
                                        >
                                            <div class="mt-3">
                                                <div class="px-0">
                                                    <h2
                                                        v-if="accountMetrics"
                                                        class="my-auto fw-bold text-primary"
                                                    >
                                                        {{ currency }}
                                                        {{
                                                            (
                                                                accountMetrics.equity ||
                                                                0
                                                            ).toFixed(2)
                                                        }}
                                                    </h2>
                                                    <h2 v-else>
                                                        <i
                                                            class="fa fa-spinner fa-spin fa-2xl"
                                                        ></i>
                                                    </h2>
                                                </div>
                                            </div>
                                            <div
                                                class="d-flex no-block al m-r-15ign-items-center"
                                            >
                                                <div
                                                    class="m-t-10 d-flex gap-2"
                                                >
                                                    <h6
                                                        class="text-white font-medium"
                                                    >
                                                        Acc. Equity
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button
                                class="carousel-control-prev"
                                type="button"
                                data-bs-target="#carouselExampleInterval"
                                data-bs-slide="prev"
                            >
                                <span
                                    class="carousel-control-prev-icon"
                                    aria-hidden="true"
                                ></span>
                                <span class="visually-hidden">Previous</span>
                            </button>
                            <button
                                class="carousel-control-next"
                                type="button"
                                data-bs-target="#carouselExampleInterval"
                                data-bs-slide="next"
                            >
                                <span
                                    class="carousel-control-next-icon"
                                    aria-hidden="true"
                                ></span>
                                <span class="visually-hidden">Next</span>
                            </button>
                        </div>
                        <div
                            id="carouselExampleInterval2"
                            class="carousel slide d-sm-block d-xl-none"
                            data-bs-ride="carousel"
                        >
                            <div
                                class="carousel-inner"
                                style="
                                    width: 95% !important ;
                                    margin: 0 auto !important;
                                "
                            >
                                <div
                                    class="carousel-item active"
                                    data-bs-interval="10000"
                                >
                                    <div class="card lossBox" id="card-primary">
                                        <div
                                            class="card-body flex-column active"
                                        >
                                            <div class="mt-3">
                                                <div class="px-0">
                                                    <h2
                                                        v-if="accountInfo"
                                                        class="my-auto fw-bold text-primary"
                                                    >
                                                        {{
                                                            (
                                                                accountInfo.growth ||
                                                                0
                                                            ).toFixed(2)
                                                        }}%
                                                    </h2>
                                                    <h2 v-else>
                                                        <i
                                                            class="fa fa-spinner fa-spin fa-2xl"
                                                        ></i>
                                                    </h2>
                                                </div>
                                            </div>
                                            <div
                                                class="d-flex no-block al m-r-15ign-items-center"
                                            >
                                                <div
                                                    class="m-t-10 d-flex gap-2"
                                                >
                                                    <h6
                                                        class="text-white font-medium"
                                                    >
                                                        {{ $t("trial-growth") }}
                                                        %
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="carousel-item"
                                    data-bs-interval="2000"
                                >
                                    <div class="card expBox" id="card-aqua">
                                        <div
                                            class="card-body flex-column active"
                                        >
                                            <div class="mt-3">
                                                <div
                                                    class="px-0"
                                                    v-if="accountMetrics"
                                                >
                                                    <h2 v-if="isNaN(rr)">-</h2>
                                                    <h2
                                                        v-else
                                                        class="my-auto fw-bold text-primary"
                                                    >
                                                        1 : {{ rr }}
                                                    </h2>
                                                </div>
                                                <div v-else>
                                                    <i
                                                        class="fa fa-spinner fa-spin fa-2xl"
                                                    ></i>
                                                </div>
                                            </div>
                                            <div
                                                class="d-flex no-block al m-r-15ign-items-center"
                                            >
                                                <div
                                                    class="m-t-10 d-flex gap-2"
                                                >
                                                    <h6
                                                        class="text-white font-medium"
                                                    >
                                                        {{ $t("profile-ARR") }}
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="card winBox" id="card-pink">
                                        <div
                                            class="card-body flex-column active"
                                        >
                                            <div class="mt-3">
                                                <div class="px-0">
                                                    <h2
                                                        v-if="accountMetrics"
                                                        class="my-auto fw-bold text-primary"
                                                    >
                                                        {{ currency }}
                                                        {{
                                                            formatAmount(
                                                                (
                                                                    accountMetrics.profit ||
                                                                    0
                                                                ).toFixed(2)
                                                            )
                                                        }}
                                                    </h2>
                                                    <h2 v-else>
                                                        <i
                                                            class="fa fa-spinner fa-spin fa-2xl"
                                                        ></i>
                                                    </h2>
                                                </div>
                                            </div>
                                            <div
                                                class="d-flex no-block al m-r-15ign-items-center"
                                            >
                                                <div
                                                    class="m-t-10 d-flex gap-2"
                                                >
                                                    <h6
                                                        class="text-white font-medium"
                                                    >
                                                        {{
                                                            $t("trial-totProf")
                                                        }}
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="card totalBox" id="card-azure">
                                        <div
                                            class="card-body flex-column active"
                                        >
                                            <div class="mt-3">
                                                <div class="px-0">
                                                    <h2
                                                        v-if="accountMetrics"
                                                        class="my-auto fw-bold text-primary"
                                                    >
                                                        {{ currency }}
                                                        {{
                                                            formatAmount(
                                                                accountMetrics.commissions ||
                                                                    0
                                                            )
                                                        }}
                                                    </h2>
                                                    <h2 v-else>
                                                        <i
                                                            class="fa fa-spinner fa-spin fa-2xl"
                                                        ></i>
                                                    </h2>
                                                </div>
                                            </div>
                                            <div
                                                class="d-flex no-block al m-r-15ign-items-center"
                                            >
                                                <div
                                                    class="m-t-10 d-flex gap-2"
                                                >
                                                    <h6
                                                        class="text-white font-medium"
                                                    >
                                                        {{
                                                            $t("trial-totComm")
                                                        }}
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button
                                class="carousel-control-prev"
                                type="button"
                                data-bs-target="#carouselExampleInterval2"
                                data-bs-slide="prev"
                            >
                                <span
                                    class="carousel-control-prev-icon"
                                    aria-hidden="true"
                                ></span>
                                <span class="visually-hidden">Previous</span>
                            </button>
                            <button
                                class="carousel-control-next"
                                type="button"
                                data-bs-target="#carouselExampleInterval2"
                                data-bs-slide="next"
                            >
                                <span
                                    class="carousel-control-next-icon"
                                    aria-hidden="true"
                                ></span>
                                <span class="visually-hidden">Next</span>
                            </button>
                        </div>
                        <!-- /Widgets: Mobile -->

                        <!-- Charts: Stats -->
                        <div
                            class="row p-0 mt-5"
                            v-if="
                                accountMetrics?.currencySummary &&
                                accountMetrics?.currencySummary.length > 0
                            "
                        >
                            <div class="d-flex justify-content-between">
                                <h3 class="text-start text-white">
                                    {{ $t("Stats") }}
                                </h3>
                                <div class="col-5 col-lg-1 ms-auto">
                                    <select
                                        class="form-select my-3"
                                        v-model="selectedCurrency"
                                        aria-label="Default select example"
                                    >
                                        <option value="all">
                                            {{ $t("All") }}
                                        </option>
                                        <option
                                            v-for="element in accountMetrics?.currencySummary"
                                            :value="element.currency"
                                            :key="element"
                                        >
                                            {{ element.currency }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="row stats-charts">
                                <div class="col-12 col-lg-4 mb-1">
                                    <div class="card mb-0 d-flex flex-column">
                                        <div
                                            class="d-flex flex-column text-white"
                                        >
                                            <h5 class="text-white">
                                                {{ $t("trial-NtotTrades") }}
                                            </h5>
                                            <h1 v-if="accountMetrics.trades">
                                                {{ accountMetrics?.trades }}
                                            </h1>
                                            <h1 v-else>0</h1>
                                        </div>
                                        <TotalTradesBarChart
                                            :selectedoption="selectedCurrency"
                                            :metrics="accountMetrics"
                                            :currency="currency"
                                            v-if="accountMetrics"
                                        />
                                    </div>
                                </div>
                                <div class="col-12 col-lg-4 mb-1">
                                    <div class="card mb-0">
                                        <div
                                            class="d-flex flex-column text-white"
                                        >
                                            <h5>{{ $t("trial-totProf") }}</h5>
                                            <h1 v-if="accountMetrics?.profit">
                                                {{ currency }}
                                                {{
                                                    formatAmount(
                                                        accountMetrics.profit.toFixed(
                                                            2
                                                        )
                                                    )
                                                }}
                                            </h1>
                                            <h1 v-else>0</h1>
                                        </div>
                                        <TotalProfitBarChart
                                            :selectedoption="selectedCurrency"
                                            :metrics="accountMetrics"
                                            :currency="currency"
                                            v-if="accountMetrics"
                                        />
                                    </div>
                                </div>
                                <div class="col-12 col-lg-4 mb-1">
                                    <div class="card mb-0">
                                        <div
                                            class="d-flex flex-column text-white"
                                        >
                                            <h5>
                                                {{ $t("trial-WtotTrades") }}
                                            </h5>
                                            <h1
                                                v-if="
                                                    accountMetrics?.wonTradesPercent
                                                "
                                            >
                                                {{
                                                    accountMetrics.wonTradesPercent.toFixed(
                                                        2
                                                    )
                                                }}%
                                            </h1>
                                            <h1 v-else>0%</h1>
                                        </div>
                                        <ProfitsPieChart
                                            :selectedoption="selectedCurrency"
                                            :metrics="accountMetrics"
                                            :currency="currency"
                                            v-if="accountMetrics"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /Charts: Stats -->

                        <!-- Chart -->
                        <div
                            class="row d-xl-flex d-md-block justify-content-between p-0 mb-xl-5 mb-5"
                        >
                            <div class="box-trail col-12">
                                <div class="py-3 d-block my-5">
                                    <div style="height: 90%">
                                        <ChartLine
                                            :currency="currency"
                                            :metrics="accountMetrics"
                                            :chartTracking="
                                                accountChartTracking
                                            "
                                            :accountId="accountId"
                                            :account="accountInfo"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- / Chart -->

                        <div
                            class="modal fade"
                            id="exampleModalReset"
                            tabindex="-1"
                            aria-labelledby="exampleModalResetLabel"
                            aria-hidden="true"
                            v-if="!showCreditCardModal"
                        >
                            <Spinner v-if="loadingPay" />

                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h2
                                            class="modal-title"
                                            id="exampleModalResetLabel"
                                        >
                                            {{ $t("btn-reset") }}
                                        </h2>
                                        <button
                                            type="button"
                                            class="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div class="modal-body">
                                        <div
                                            class="col-12 mb-3 d-flex align-items-center p-2 gap-3 flex-row"
                                        >
                                            <h5 class="m-0 p-0 mb-2 text-white">
                                                {{ $t("trial-resetCost") }} :
                                            </h5>
                                            <h4>
                                                $
                                                {{
                                                    formatAmount(tvsReset.total)
                                                }}
                                            </h4>
                                        </div>
                                        <div
                                            v-if="tvsReset.total > 0"
                                            class="gap-2 col-12 d-block d-xl-flex flex-column"
                                        >
                                            <div
                                                class="d-flex justify-content-between flex-row"
                                            >
                                                <div
                                                    class="col-12 col-xl-5 btn btn-outline-primary border-1 d-flex flex-column gap-3 align-items-center justify-content-between p-xl-4 mb-4 flexdisplay"
                                                    @click="payWith('paragon')"
                                                >
                                                    <div
                                                        class="d-flex justify-content-center align-items-center flexdisplay gap-3 col-12"
                                                    >
                                                        <PaymentMethods />
                                                    </div>
                                                    <div
                                                        class="d-flex gap-2 align-items-center"
                                                    >
                                                        <h6
                                                            class="text-uppercase text-white m-0 p-0"
                                                        >
                                                            Credit or Debit Card
                                                            (Paragon Processor)
                                                        </h6>
                                                        <font-awesome-icon
                                                            class="d-none d-xl-block"
                                                            :icon="[
                                                                'fas',
                                                                'credit-card',
                                                            ]"
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    class="col-12 col-xl-5 btn btn-outline-primary border-1 d-flex flex-column gap-3 align-items-center justify-content-between p-xl-4 mb-4 flexdisplay"
                                                    @click="
                                                        payWith('solidgate')
                                                    "
                                                >
                                                    <div
                                                        class="d-flex justify-content-center align-items-center flexdisplay gap-3 col-12"
                                                    >
                                                        <PaymentMethods />
                                                    </div>
                                                    <div
                                                        class="d-flex gap-2 align-items-center"
                                                    >
                                                        <h6
                                                            class="text-uppercase text-white m-0 p-0"
                                                        >
                                                            Credit or Debit Card
                                                        </h6>
                                                        <font-awesome-icon
                                                            class="d-none d-xl-block"
                                                            :icon="[
                                                                'fas',
                                                                'credit-card',
                                                            ]"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="d-flex justify-content-between flex-row"
                                            >
                                                <div
                                                    class="col-12 col-xl-5 btn btn-outline-primary border-1 d-flex flex-column gap-3 align-items-center justify-content-between p-xl-4 mb-4 flexdisplay"
                                                    @click="payWith('coinbase')"
                                                >
                                                    <div
                                                        class="d-flex align-items-center flexdisplay"
                                                    >
                                                        <img
                                                            class="payment-image"
                                                            src="/assets/images/coinbase_white.png"
                                                            alt=""
                                                        />
                                                    </div>

                                                    <div
                                                        class="d-flex gap-2 align-items-center"
                                                    >
                                                        <h6
                                                            class="text-uppercase text-white m-0 p-0"
                                                        >
                                                            Cryptocurrency
                                                        </h6>
                                                        <font-awesome-icon
                                                            class="d-none d-xl-block"
                                                            :icon="[
                                                                'fas',
                                                                'coins',
                                                            ]"
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    class="col-12 col-xl-5 btn btn-outline-primary border-1 d-flex flex-column gap-3 align-items-center justify-content-between p-xl-4 mb-4 flexdisplay"
                                                    @click="payWith('confirmo')"
                                                >
                                                    <div
                                                        class="d-flex align-items-center flexdisplay"
                                                    >
                                                        <img
                                                            class="payment-image"
                                                            src="/assets/images/confirmo_white.svg"
                                                            alt=""
                                                        />
                                                    </div>

                                                    <div
                                                        class="d-flex gap-2 align-items-center"
                                                    >
                                                        <h6
                                                            class="text-uppercase text-white m-0 p-0"
                                                        >
                                                            Cryptocurrency
                                                        </h6>
                                                        <font-awesome-icon
                                                            class="d-none d-xl-block"
                                                            :icon="[
                                                                'fas',
                                                                'coins',
                                                            ]"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <h5>
                                                <span class="text-danger"
                                                    >Attention</span
                                                >
                                                : Please contact the Customer
                                                Support
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button
                                            type="button"
                                            class="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                        >
                                            {{ $t("Close") }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ModalCreditCard
                            :showCreditCardModal="showCreditCardModal"
                            @update:showCreditCardModal="
                                showCreditCardModal = $event
                            "
                            :merchantData="solidgateData"
                            @update:loadingPayment="loadingPay = $event"
                            ref="creditCardModal"
                        />

                        <!-- Rules -->
                        <LossAnalytics
                            :canReset="canReset"
                            :resetTvs="postTvsTot"
                        />
                        <!-- / Rules -->

                        <template v-if="accountInfo && accountMetrics">
                            <!--Objectives-->
                            <div
                                v-if="!loading"
                                class="box-trail col my-4"
                                style="height: 350px"
                            >
                                <h3 class="card-title text-white">
                                    {{ $t("trial-rulesCheck") }}
                                </h3>
                                <div
                                    class="card"
                                    style="
                                        height: 320px;
                                        overflow-y: auto;
                                        max-width: 100%;
                                        overflow-x: auto;
                                    "
                                >
                                    <table class="table mb-0" style="">
                                        <thead
                                            class="bg-primary"
                                            style="
                                                position: sticky;
                                                top: 0;
                                                height: 60px;
                                            "
                                        >
                                            <tr>
                                                <th>
                                                    {{ $t("trial-rulesTab1") }}
                                                </th>
                                                <th>
                                                    {{ $t("trial-rulesTab2") }}
                                                </th>
                                                <th>
                                                    {{ $t("trial-rulesTab3") }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody style="height: 250px">
                                            <tr class="verticalText">
                                                <td class="text-white">
                                                    <h5>
                                                        {{
                                                            $t(
                                                                "trial-rulesTab4"
                                                            )
                                                        }}
                                                        <br />-{{
                                                            formatAmount(
                                                                percent5
                                                            )
                                                        }}
                                                    </h5>
                                                </td>
                                                <td class="text-white">
                                                    <h4>
                                                        <span>{{
                                                            formatAmount(
                                                                lossAnalytics.daily_loss
                                                            )
                                                        }}</span>
                                                    </h4>
                                                </td>
                                                <td v-if="!accountInfo.rule_1">
                                                    <h5>
                                                        <i
                                                            class="mdi mdi-close-circle-outline text-danger"
                                                        ></i
                                                        >{{ $t("NPass") }}
                                                    </h5>
                                                </td>
                                                <td v-else>
                                                    <h5>
                                                        <i
                                                            class="mdi mdi-check-circle-outline text-success"
                                                        ></i>
                                                        {{ $t("Pass") }}
                                                    </h5>
                                                </td>
                                            </tr>
                                            <tr class="verticalText">
                                                <td class="text-white">
                                                    <h5>
                                                        {{
                                                            $t(
                                                                "trial-rulesTab5"
                                                            )
                                                        }}
                                                        <br />-{{
                                                            formatAmount(
                                                                percent10
                                                            )
                                                        }}
                                                    </h5>
                                                </td>
                                                <td
                                                    class="text-white"
                                                    v-if="accountMetrics"
                                                >
                                                    <h4>
                                                        {{
                                                            formatAmount(
                                                                accountMetrics.profit
                                                            )
                                                        }}
                                                    </h4>
                                                </td>
                                                <td v-if="!accountInfo.rule_2">
                                                    <h5 class="text-white">
                                                        <i
                                                            class="mdi mdi-close-circle-outline text-danger"
                                                        ></i>
                                                        {{ $t("NPass") }}
                                                    </h5>
                                                </td>
                                                <td v-else>
                                                    <h5 class="text-white">
                                                        <i
                                                            class="mdi mdi-check-circle-outline text-success"
                                                        ></i>
                                                        {{ $t("Pass") }}
                                                    </h5>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!--Balance/Equity stats-->
                            <div
                                class="box-trail col my-4"
                                v-if="
                                    Object.values(
                                        accountInfo.worstBestData
                                    ).every(
                                        (obj) => Object.keys(obj).length > 0
                                    )
                                "
                                style="height: 350px"
                            >
                                <h3 class="card-title text-white">
                                    Balance/Equity {{ $t("Stats") }}
                                </h3>
                                <div
                                    class="card"
                                    style="
                                        height: 320px;
                                        overflow-y: auto;
                                        max-width: 100%;
                                        overflow-x: auto;
                                    "
                                >
                                    <table class="table mb-0" style="">
                                        <thead
                                            class="bg-primary"
                                            style="
                                                position: sticky;
                                                top: 0;
                                                height: 60px;
                                            "
                                        >
                                            <tr>
                                                <th>{{ $t("Target") }}</th>
                                                <th>{{ $t("Value") }}</th>
                                                <th>{{ $t("Date") }}</th>
                                                <th>{{ $t("Time") }}</th>
                                            </tr>
                                        </thead>
                                        <tbody style="height: 250px">
                                            <tr
                                                v-for="(
                                                    record, key
                                                ) in accountInfo.worstBestData"
                                                :key="record"
                                                id="middle-table"
                                                class="verticalText"
                                            >
                                                <td class="text-white">
                                                    {{
                                                        key
                                                            .split("_")
                                                            .map(
                                                                (word) =>
                                                                    word
                                                                        .charAt(
                                                                            0
                                                                        )
                                                                        .toUpperCase() +
                                                                    word.slice(
                                                                        1
                                                                    )
                                                            )
                                                            .join(" ")
                                                    }}
                                                </td>
                                                <td class="text-white">
                                                    {{ currency
                                                    }}{{
                                                        formatAmount(
                                                            record.value
                                                        )
                                                    }}
                                                </td>
                                                <td class="text-white">
                                                    {{
                                                        record.time.split(
                                                            "T"
                                                        )[0]
                                                    }}
                                                </td>
                                                <td class="text-white">
                                                    {{
                                                        record.time
                                                            .split("T")[1]
                                                            .substring(0, 8)
                                                    }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!--Profits/Losses Calendar-->
                            <div
                                class="box-trail col my-4"
                                v-if="accountTrades"
                                style="height: 350px"
                            >
                                <h3 class="card-title text-white">
                                    Profits/Losses Calendar
                                </h3>
                                <div
                                    class="card"
                                    style="
                                        height: 320px;
                                        overflow-y: auto;
                                        max-width: 100%;
                                        overflow-x: auto;
                                    "
                                >
                                    <table class="table mb-0">
                                        <thead
                                            class="bg-primary"
                                            style="
                                                position: sticky;
                                                top: 0;
                                                height: 60px;
                                            "
                                        >
                                            <tr>
                                                <th>{{ $t("Day") }}</th>
                                                <th>N. {{ $t("Loss") }}</th>
                                                <th>N. {{ $t("Trades") }}</th>
                                                <th>
                                                    {{ $t("Profits") }}/{{
                                                        $t("Losses")
                                                    }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody style="height: 250px">
                                            <tr
                                                v-for="element in groupedTrades"
                                                :key="element"
                                                class="verticalText"
                                            >
                                                <td style="width: 35%">
                                                    {{ element.close }}
                                                </td>
                                                <td>
                                                    {{
                                                        element.totLots.toFixed(
                                                            2
                                                        )
                                                    }}
                                                </td>
                                                <td>
                                                    {{ element.trades.length }}
                                                </td>
                                                <td
                                                    :class="
                                                        element.totProfitsSum.toFixed(
                                                            2
                                                        ) > 0
                                                            ? 'text-success'
                                                            : 'text-danger'
                                                    "
                                                >
                                                    {{
                                                        element.totProfitsSum.toFixed(
                                                            2
                                                        )
                                                    }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <!--Lots-->
                            <div
                                class="box-trail row d-flex flex-wrap px-2 mb-4"
                                v-if="
                                    lots &&
                                    Object.keys(lots).some(
                                        (key) =>
                                            Object.keys(lots[key]).length > 0
                                    )
                                "
                            >
                                <h3 class="card-title text-white mt-5 pt-3">
                                    {{ $t("WeeklyOv") }}
                                </h3>
                                <hr />
                                <template
                                    v-for="(categoryData, categoryName) in lots"
                                    :key="categoryName"
                                >
                                    <div
                                        :class="{
                                            'col-12 text-center':
                                                countKeysWithValues(lots) === 1,
                                            'col-12 col-lg-6 text-center':
                                                countKeysWithValues(lots) > 1,
                                        }"
                                        class="d-flex flex-column"
                                        v-if="
                                            Object.keys(categoryData).length > 0
                                        "
                                    >
                                        <h3 class="text-capitalize">
                                            {{ categoryName }}
                                        </h3>
                                        <div
                                            class="card"
                                            style="
                                                height: 320px;
                                                overflow-y: auto;
                                                margin-bottom: 10px;
                                            "
                                        >
                                            <table class="table">
                                                <thead
                                                    style="
                                                        position: sticky;
                                                        top: 0;
                                                        height: 60px;
                                                    "
                                                    class="bg-primary"
                                                >
                                                    <tr>
                                                        <th>
                                                            {{ $t("Week") }}
                                                        </th>
                                                        <th>
                                                            {{ $t("NTrades") }}
                                                        </th>
                                                        <th>
                                                            {{ $t("AvLots") }}
                                                        </th>
                                                        <th>
                                                            {{ $t("AvG") }} %
                                                        </th>
                                                        <th>{{ $t("AvP") }}</th>
                                                    </tr>
                                                </thead>
                                                <tbody style="height: 230px">
                                                    <tr
                                                        v-for="(
                                                            range, dateRange
                                                        ) in categoryData"
                                                        :key="dateRange"
                                                        class="verticalText"
                                                    >
                                                        <td>{{ dateRange }}</td>
                                                        <td>
                                                            {{ range.nTrades }}
                                                        </td>
                                                        <td>
                                                            {{
                                                                range.averageVolume
                                                            }}
                                                        </td>
                                                        <td>
                                                            {{
                                                                range.averageGain
                                                            }}%
                                                        </td>
                                                        <td>
                                                            {{
                                                                range.averageProfit
                                                            }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <!--Trade History-->
                            <div
                                class="mt-5"
                                v-if="
                                    (accountTrades &&
                                        accountTrades.length > 0) ||
                                    (openTrades && openTrades.length > 0)
                                "
                            >
                                <div class="row p-0">
                                    <div class="col-12">
                                        <h3 class="text-start text-white">
                                            {{ $t("TradesHist") }}
                                        </h3>
                                        <div
                                            class="gap-4 mb-2 d-none d-lg-flex justify-content-between"
                                        >
                                            <div>
                                                <div class="d-none d-lg-block">
                                                    <p class="text-dark">
                                                        {{ $t("BTrade") }}
                                                    </p>
                                                    <p
                                                        class="text-dark"
                                                        v-if="
                                                            accountMetrics.bestTrade
                                                        "
                                                    >
                                                        <span
                                                            class="text-success"
                                                        >
                                                            {{ currency }}
                                                            {{
                                                                formatAmount(
                                                                    accountMetrics.bestTrade
                                                                )
                                                            }}</span
                                                        >
                                                    </p>
                                                    <p v-else>-</p>
                                                </div>
                                            </div>

                                            <div>
                                                <div class="d-none d-lg-block">
                                                    <p class="text-dark">
                                                        {{ $t("WTrade") }}
                                                    </p>
                                                    <p
                                                        class="text-dark"
                                                        v-if="
                                                            accountMetrics.worstTrade
                                                        "
                                                    >
                                                        <span
                                                            class="text-danger"
                                                        >
                                                            {{ currency }}
                                                            {{
                                                                formatAmount(
                                                                    accountMetrics.worstTrade
                                                                )
                                                            }}</span
                                                        >
                                                    </p>
                                                    <p v-else>-</p>
                                                </div>
                                            </div>
                                            <div class="border border-1"></div>
                                            <div>
                                                <div class="d-none d-lg-block">
                                                    <p class="text-dark">
                                                        {{
                                                            $t(
                                                                "trial-NtotTrades"
                                                            )
                                                        }}
                                                    </p>
                                                    <p
                                                        v-if="
                                                            accountMetrics.trades
                                                        "
                                                    >
                                                        {{
                                                            accountMetrics.trades
                                                        }}
                                                    </p>
                                                    <p v-else>-</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="d-none d-lg-block">
                                                    <p class="text-dark">
                                                        {{
                                                            $t(
                                                                "trial-TotalNLots"
                                                            )
                                                        }}
                                                    </p>
                                                    <p
                                                        v-if="
                                                            accountMetrics.lots
                                                        "
                                                    >
                                                        {{
                                                            formatAmount(
                                                                accountMetrics.lots
                                                            )
                                                        }}
                                                    </p>
                                                    <p v-else>-</p>
                                                </div>
                                            </div>
                                            <div class="border border-1"></div>
                                            <div>
                                                <div class="d-none d-lg-block">
                                                    <p class="text-dark">
                                                        {{
                                                            $t(
                                                                "trial-TotalNPips"
                                                            )
                                                        }}
                                                    </p>
                                                    <p v-if="pipSum">
                                                        {{
                                                            formatAmount(pipSum)
                                                        }}
                                                    </p>
                                                    <p v-else>-</p>
                                                </div>
                                            </div>
                                            <div class="border border-1"></div>
                                            <div>
                                                <div class="d-none d-lg-block">
                                                    <p class="text-dark">
                                                        {{ $t("trial-ADG") }}
                                                    </p>
                                                    <p
                                                        v-if="
                                                            accountMetrics.dailyGain
                                                        "
                                                    >
                                                        {{
                                                            formatAmount(
                                                                accountMetrics.dailyGain
                                                            )
                                                        }}
                                                        %
                                                    </p>
                                                    <p v-else>-</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="d-none d-lg-block">
                                                    <p class="text-dark">
                                                        {{ $t("trial-MG") }}
                                                    </p>
                                                    <p
                                                        v-if="
                                                            accountMetrics.monthlyGain
                                                        "
                                                    >
                                                        {{
                                                            formatAmount(
                                                                accountMetrics.monthlyGain
                                                            )
                                                        }}
                                                        %
                                                    </p>
                                                    <p v-else>-</p>
                                                </div>
                                            </div>
                                            <div class="border border-1"></div>
                                            <div>
                                                <div class="d-none d-lg-block">
                                                    <p class="text-dark">
                                                        {{
                                                            $t("trial-totComm")
                                                        }}
                                                    </p>
                                                    <p
                                                        v-if="
                                                            accountMetrics.commissions
                                                        "
                                                    >
                                                        {{ currency }}
                                                        {{
                                                            formatAmount(
                                                                accountMetrics.commissions
                                                            )
                                                        }}
                                                    </p>
                                                    <p v-else>-</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="gap-4 mb-2 d-flex d-lg-none row"
                                        >
                                            <div class="col-12 displayFlex">
                                                <div class="col-6">
                                                    <div class="">
                                                        <p
                                                            class="text-dark p-0 m-0"
                                                        >
                                                            {{ $t("BTrade") }}
                                                        </p>
                                                        <p
                                                            class="text-success"
                                                            v-if="
                                                                accountMetrics.bestTrade
                                                            "
                                                        >
                                                            {{ currency }}
                                                            {{
                                                                formatAmount(
                                                                    accountMetrics.bestTrade
                                                                )
                                                            }}
                                                        </p>
                                                        <p v-else>-</p>
                                                    </div>
                                                    <div class="">
                                                        <p
                                                            class="text-dark p-0 m-0"
                                                        >
                                                            {{ $t("WTrade") }}
                                                        </p>
                                                        <p
                                                            class="text-danger"
                                                            v-if="
                                                                accountMetrics.worstTrade
                                                            "
                                                        >
                                                            {{ currency }}
                                                            {{
                                                                formatAmount(
                                                                    accountMetrics.worstTrade
                                                                )
                                                            }}
                                                        </p>
                                                        <p v-else>-</p>
                                                    </div>
                                                    <div class="">
                                                        <p
                                                            class="text-dark p-0 m-0"
                                                        >
                                                            {{
                                                                $t(
                                                                    "trial-NtotTrades"
                                                                )
                                                            }}
                                                        </p>
                                                        <p
                                                            v-if="
                                                                accountMetrics.trades
                                                            "
                                                        >
                                                            {{
                                                                accountMetrics.trades
                                                            }}
                                                        </p>
                                                        <p v-else>-</p>
                                                    </div>
                                                    <div class="">
                                                        <p
                                                            class="text-dark p-0 m-0"
                                                        >
                                                            {{
                                                                $t(
                                                                    "trial-TotalNLots"
                                                                )
                                                            }}
                                                        </p>
                                                        <p
                                                            v-if="
                                                                accountMetrics.lots
                                                            "
                                                        >
                                                            {{
                                                                formatAmount(
                                                                    accountMetrics.lots
                                                                )
                                                            }}
                                                        </p>
                                                        <p v-else>-</p>
                                                    </div>
                                                </div>
                                                <div
                                                    class="border border-1 me-2"
                                                ></div>
                                                <div class="col-5">
                                                    <div class="">
                                                        <p
                                                            class="text-dark p-0 m-0"
                                                        >
                                                            {{
                                                                $t(
                                                                    "trial-TotalNPips"
                                                                )
                                                            }}
                                                        </p>
                                                        <p v-if="pipSum">
                                                            {{
                                                                formatAmount(
                                                                    pipSum
                                                                )
                                                            }}
                                                        </p>
                                                        <p v-else>-</p>
                                                    </div>
                                                    <div class="">
                                                        <p
                                                            class="text-dark p-0 m-0"
                                                        >
                                                            {{
                                                                $t("trial-ADG")
                                                            }}
                                                        </p>
                                                        <p
                                                            v-if="
                                                                accountMetrics.dailyGain
                                                            "
                                                        >
                                                            {{
                                                                formatAmount(
                                                                    accountMetrics.dailyGain
                                                                )
                                                            }}
                                                            %
                                                        </p>
                                                        <p v-else>-</p>
                                                    </div>
                                                    <div class="">
                                                        <p
                                                            class="text-dark p-0 m-0"
                                                        >
                                                            {{ $t("trial-MG") }}
                                                        </p>
                                                        <p
                                                            v-if="
                                                                accountMetrics.monthlyGain
                                                            "
                                                        >
                                                            {{
                                                                formatAmount(
                                                                    accountMetrics.monthlyGain
                                                                )
                                                            }}%
                                                        </p>
                                                        <p v-else>-</p>
                                                    </div>
                                                    <div class="">
                                                        <p
                                                            class="text-dark p-0 m-0"
                                                        >
                                                            {{
                                                                $t(
                                                                    "trial-totComm"
                                                                )
                                                            }}
                                                        </p>
                                                        <p
                                                            v-if="
                                                                accountMetrics.commissions
                                                            "
                                                        >
                                                            {{ currency }}
                                                            {{
                                                                formatAmount(
                                                                    accountMetrics.commissions
                                                                )
                                                            }}
                                                        </p>
                                                        <p v-else>-</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card p-3">
                                            <div class="card-body p-0 p-lg-2">
                                                <ul class="nav nav-tabs">
                                                    <li class="nav-item">
                                                        <a
                                                            @click="showclose()"
                                                            id="showclose"
                                                            class="nav-link active fw-bold"
                                                            style="
                                                                background-color: transparent !important;
                                                            "
                                                            aria-current="page"
                                                            >{{
                                                                $t("Closed")
                                                            }}</a
                                                        >
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            @click="showopen()"
                                                            style="
                                                                background-color: transparent !important;
                                                            "
                                                            id="showopen"
                                                            class="nav-link fw-bold"
                                                            >{{
                                                                $t("Opened")
                                                            }}</a
                                                        >
                                                    </li>
                                                </ul>
                                                <div
                                                    class="table card-body p-0"
                                                    style="
                                                        max-height: 700px;
                                                        overflow-y: auto;
                                                        overflow-x: auto;
                                                    "
                                                >
                                                    <table
                                                        v-show="closetrade"
                                                        class="table"
                                                    >
                                                        <thead
                                                            class="bg-primary"
                                                            style="
                                                                position: sticky;
                                                                top: 0;
                                                            "
                                                        >
                                                            <tr>
                                                                <th>
                                                                    {{
                                                                        $t(
                                                                            "table-ticket"
                                                                        )
                                                                    }}
                                                                </th>
                                                                <th>
                                                                    {{
                                                                        $t(
                                                                            "table-openAt"
                                                                        )
                                                                    }}
                                                                </th>
                                                                <th>
                                                                    {{
                                                                        $t(
                                                                            "Type"
                                                                        )
                                                                    }}
                                                                </th>
                                                                <th>
                                                                    {{
                                                                        $t(
                                                                            "Volume"
                                                                        )
                                                                    }}
                                                                </th>
                                                                <th>
                                                                    {{
                                                                        $t(
                                                                            "table-openPrice"
                                                                        )
                                                                    }}
                                                                </th>
                                                                <th>
                                                                    {{
                                                                        $t(
                                                                            "table-closePrice"
                                                                        )
                                                                    }}
                                                                </th>
                                                                <th>
                                                                    {{
                                                                        $t(
                                                                            "table-closeAt"
                                                                        )
                                                                    }}
                                                                </th>
                                                                <th>
                                                                    {{
                                                                        $t(
                                                                            "Profit"
                                                                        )
                                                                    }}
                                                                </th>
                                                                <th>
                                                                    {{
                                                                        $t(
                                                                            "Pips"
                                                                        )
                                                                    }}
                                                                </th>
                                                                <th>
                                                                    {{
                                                                        $t(
                                                                            "table-duration"
                                                                        )
                                                                    }}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody
                                                            v-if="accountTrades"
                                                        >
                                                            <tr
                                                                v-for="trade in accountTrades"
                                                                :key="trade._id"
                                                            >
                                                                <td
                                                                    v-if="
                                                                        trade.symbol
                                                                    "
                                                                >
                                                                    {{
                                                                        trade.symbol
                                                                    }}
                                                                </td>
                                                                <td v-else>
                                                                    -
                                                                </td>
                                                                <td
                                                                    v-if="
                                                                        trade.openTime
                                                                    "
                                                                >
                                                                    {{
                                                                        formatData(
                                                                            trade.openTime
                                                                        )
                                                                    }}
                                                                </td>
                                                                <td v-else>
                                                                    -
                                                                </td>
                                                                <td
                                                                    v-if="
                                                                        trade.type
                                                                    "
                                                                >
                                                                    <p
                                                                        class="m-0 text-black"
                                                                        :class="
                                                                            trade.type.includes(
                                                                                'BUY'
                                                                            )
                                                                                ? 'bg-success text-center rounded-pill'
                                                                                : trade.type.includes(
                                                                                      'SELL'
                                                                                  )
                                                                                ? 'bg-danger text-center rounded-pill'
                                                                                : 'text-warning text-center'
                                                                        "
                                                                    >
                                                                        {{
                                                                            trade.type
                                                                        }}
                                                                    </p>
                                                                </td>
                                                                <td v-else>
                                                                    -
                                                                </td>
                                                                <td
                                                                    v-if="
                                                                        trade.lots ||
                                                                        trade.volume
                                                                    "
                                                                >
                                                                    <template
                                                                        v-if="
                                                                            trade.lots
                                                                        "
                                                                    >
                                                                        {{
                                                                            parseFloat(
                                                                                trade.lots
                                                                            ).toFixed(
                                                                                2
                                                                            )
                                                                        }}
                                                                        lots
                                                                    </template>
                                                                    <template
                                                                        v-else-if="
                                                                            trade.volume
                                                                        "
                                                                    >
                                                                        {{
                                                                            parseFloat(
                                                                                trade.volume
                                                                            ).toFixed(
                                                                                2
                                                                            )
                                                                        }}
                                                                        lots
                                                                    </template>
                                                                </td>
                                                                <td v-else>
                                                                    -
                                                                </td>
                                                                <td
                                                                    v-if="
                                                                        trade.openPrice
                                                                    "
                                                                >
                                                                    {{
                                                                        trade.digits
                                                                            ? trade.openPrice.toFixed(
                                                                                  trade.digits
                                                                              )
                                                                            : trade.openPrice
                                                                    }}
                                                                </td>
                                                                <td v-else>
                                                                    -
                                                                </td>
                                                                <td
                                                                    v-if="
                                                                        trade.closePrice
                                                                    "
                                                                >
                                                                    {{
                                                                        trade.digits
                                                                            ? trade.closePrice.toFixed(
                                                                                  trade.digits
                                                                              )
                                                                            : trade.closePrice
                                                                    }}
                                                                </td>
                                                                <td v-else>
                                                                    -
                                                                </td>
                                                                <td
                                                                    v-if="
                                                                        trade.closeTime
                                                                    "
                                                                >
                                                                    {{
                                                                        formatData(
                                                                            trade.closeTime
                                                                        )
                                                                    }}
                                                                </td>
                                                                <td v-else>
                                                                    -
                                                                </td>
                                                                <td
                                                                    v-if="
                                                                        trade.profit
                                                                    "
                                                                    :class="
                                                                        trade.profit >=
                                                                            0 &&
                                                                        !trade.type.includes(
                                                                            'BALANCE'
                                                                        )
                                                                            ? 'text-success'
                                                                            : trade.profit <
                                                                              0
                                                                            ? 'text-danger'
                                                                            : ''
                                                                    "
                                                                >
                                                                    {{
                                                                        trade.profit.toFixed(
                                                                            2
                                                                        )
                                                                    }}
                                                                </td>
                                                                <td v-else>
                                                                    -
                                                                </td>
                                                                <td
                                                                    v-if="
                                                                        trade.pips
                                                                    "
                                                                >
                                                                    {{
                                                                        trade.pips.toFixed(
                                                                            2
                                                                        )
                                                                    }}
                                                                </td>
                                                                <td v-else>
                                                                    -
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        trade.durationInMinutes
                                                                    }}
                                                                    {{
                                                                        typeof trade.durationInMinutes ===
                                                                        "string"
                                                                            ? ""
                                                                            : "minutes"
                                                                    }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table
                                                        v-show="opentrade"
                                                        class="table"
                                                    >
                                                        <thead
                                                            class="bg-primary"
                                                            style="
                                                                position: sticky;
                                                                top: 0;
                                                            "
                                                        >
                                                            <tr>
                                                                <th>
                                                                    {{
                                                                        $t(
                                                                            "table-ticket"
                                                                        )
                                                                    }}
                                                                </th>
                                                                <th>
                                                                    {{
                                                                        $t(
                                                                            "table-openAt"
                                                                        )
                                                                    }}
                                                                </th>
                                                                <th>
                                                                    {{
                                                                        $t(
                                                                            "Type"
                                                                        )
                                                                    }}
                                                                </th>
                                                                <th>
                                                                    {{
                                                                        $t(
                                                                            "Volume"
                                                                        )
                                                                    }}
                                                                </th>
                                                                <th>
                                                                    {{
                                                                        $t(
                                                                            "table-openPrice"
                                                                        )
                                                                    }}
                                                                </th>
                                                                <th>
                                                                    {{
                                                                        $t(
                                                                            "Status"
                                                                        )
                                                                    }}
                                                                </th>
                                                                <th>
                                                                    {{
                                                                        $t(
                                                                            "Profit"
                                                                        )
                                                                    }}
                                                                </th>
                                                                <th>
                                                                    {{
                                                                        $t(
                                                                            "table-duration"
                                                                        )
                                                                    }}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody
                                                            v-if="openTrades"
                                                        >
                                                            <tr
                                                                v-for="trade in openTrades"
                                                                :key="trade._id"
                                                            >
                                                                <td
                                                                    v-if="
                                                                        trade.symbol
                                                                    "
                                                                >
                                                                    {{
                                                                        trade.symbol
                                                                    }}
                                                                </td>
                                                                <td v-else>
                                                                    -
                                                                </td>
                                                                <td
                                                                    v-if="
                                                                        trade.openTime
                                                                    "
                                                                >
                                                                    {{
                                                                        formatData(
                                                                            trade.openTime
                                                                        )
                                                                    }}
                                                                </td>
                                                                <td v-else>
                                                                    -
                                                                </td>
                                                                <td
                                                                    v-if="
                                                                        trade.type
                                                                    "
                                                                >
                                                                    <p
                                                                        class="m-0 text-black"
                                                                        :class="
                                                                            trade.type.includes(
                                                                                'BUY'
                                                                            )
                                                                                ? 'bg-success text-center rounded-pill'
                                                                                : trade.type.includes(
                                                                                      'SELL'
                                                                                  )
                                                                                ? 'bg-danger text-center rounded-pill'
                                                                                : 'text-warning text-center'
                                                                        "
                                                                    >
                                                                        {{
                                                                            trade.type
                                                                        }}
                                                                    </p>
                                                                </td>
                                                                <td v-else>
                                                                    -
                                                                </td>
                                                                <td
                                                                    v-if="
                                                                        trade.lots ||
                                                                        trade.volume
                                                                    "
                                                                >
                                                                    <template
                                                                        v-if="
                                                                            trade.lots
                                                                        "
                                                                    >
                                                                        {{
                                                                            parseFloat(
                                                                                trade.lots
                                                                            ).toFixed(
                                                                                2
                                                                            )
                                                                        }}
                                                                        lots
                                                                    </template>
                                                                    <template
                                                                        v-else-if="
                                                                            trade.volume
                                                                        "
                                                                    >
                                                                        {{
                                                                            parseFloat(
                                                                                trade.volume
                                                                            ).toFixed(
                                                                                2
                                                                            )
                                                                        }}
                                                                        lots
                                                                    </template>
                                                                </td>
                                                                <td v-else>
                                                                    -
                                                                </td>
                                                                <td
                                                                    v-if="
                                                                        trade.openPrice
                                                                    "
                                                                >
                                                                    {{
                                                                        trade.digits
                                                                            ? trade.openPrice.toFixed(
                                                                                  trade.digits
                                                                              )
                                                                            : trade.openPrice
                                                                    }}
                                                                </td>
                                                                <td v-else>
                                                                    -
                                                                </td>
                                                                <td
                                                                    v-if="
                                                                        trade.status
                                                                    "
                                                                >
                                                                    {{
                                                                        trade.status
                                                                    }}
                                                                </td>
                                                                <td v-else>
                                                                    -
                                                                </td>

                                                                <td
                                                                    v-if="
                                                                        trade.profit
                                                                    "
                                                                    :class="
                                                                        trade.profit >=
                                                                            0 &&
                                                                        !trade.type.includes(
                                                                            'BALANCE'
                                                                        )
                                                                            ? 'text-success'
                                                                            : trade.profit <
                                                                              0
                                                                            ? 'text-danger'
                                                                            : ''
                                                                    "
                                                                >
                                                                    {{
                                                                        trade.profit.toFixed(
                                                                            2
                                                                        )
                                                                    }}
                                                                </td>
                                                                <td v-else>
                                                                    -
                                                                </td>
                                                                <td
                                                                    :class="
                                                                        trade.durationInMinutes <
                                                                        2
                                                                            ? 'text-danger'
                                                                            : ''
                                                                    "
                                                                >
                                                                    {{
                                                                        trade.durationInMinutes
                                                                    }}
                                                                    {{
                                                                        typeof trade.durationInMinutes ===
                                                                        "string"
                                                                            ? ""
                                                                            : "minutes"
                                                                    }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--Profits Chart-->
                            <div
                                v-if="accountTrades && accountTrades.length > 0"
                                class="row p-0 mt-5 d-flex"
                            >
                                <div class="col-12">
                                    <TradesChartDay
                                        :trades="accountTrades"
                                        :metriche="accountMetrics"
                                        :currency="currency"
                                    />
                                </div>
                            </div>
                            <!-- /dopo -->
                        </template>
                    </div>
                    <!--- End Content --->
                </div>
            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->

        <RankModal :showRank="showRank" @close="closeRank" />
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <Footer />
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
</template>

<script setup>
import {
    getCurrentInstance,
    ref,
    onBeforeMount,
    onMounted,
    inject,
    reactive,
} from "vue";
import axios from "axios";
import { useCookies } from "vue3-cookies";

// Components
import Spinner from "../components/Spinner";
import Navbar from "../components/statics/navbar";
import Sidebar from "../components/Sidebar";
import TotalTradesBarChart from "../components/TotalTradesBarChart.vue";
import TotalProfitBarChart from "../components/TotalProfitBarChart.vue";
import ProfitsPieChart from "../components/ProfitsPieChart.vue";
import ChartLine from "../components/ChartLine.vue";
import TradesChartDay from "../components/TradesChartDay.vue";
import RankModal from "../components/RankModal";
import bannerViolation from "../components/statics/bannerViolation.vue";
import bannerPayout from "../components/statics/bannerPayout.vue";
import ChangePlatform from "../components/modals/ChangePlatform.vue";
import PaymentMethods from "../components/checkout/PaymentMethods.vue";
import ModalCreditCard from "../components/ModalCreditCard.vue";
// Route
import { useRoute, useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import moment from "moment/moment";
import NextPayout from "../components/profile/NextPayout.vue";
const router = useRouter();
const route = useRoute();
const { cookies } = useCookies();
import { usePopup } from "../store/popup.store";
import { useI18n } from "vue-i18n";
import LossAnalytics from "../components/profile/LossAnalytics.vue";
import * as Sentry from "@sentry/vue";

import { useAccountStore } from "../store/account.store";
import { useItemsStore } from "../store/items.store";
const accountStore = useAccountStore();
const { openTrades } = storeToRefs(accountStore);

//copy info account
const copyToClipboard = async (value) => {
    var info = document.getElementById(value);
    var range = document.createRange();
    range.selectNode(info);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
};

// Reset Inconsistency Score
const showCreditCardModal = ref(false);
const solidgateData = ref({});
let loadingPay = ref(false);
let canReset = ref(false);
let tvsReset = ref([]);
const getTvsTot = async () => {
    if (
        openTrades.value &&
        openTrades.value.length < 1 &&
        accountInfo.value?.username
    ) {
        axios
            .get(
                `${baseUrl}/ecommerce/tvr?account_id=${accountInfo.value.username}`
            )
            .then((response) => {
                if (!response.data) {
                    canReset.value = true;
                } else if (response.data.status != "completed") {
                    canReset.value = true;
                }
            });
    } else {
        canReset.value = false;
        return false;
    }
};
const postTvsTot = async () => {
    let idAccReset = "";
    let curr = accountInfo.value.currency;
    let type = accountInfo.value.type_account.split("%")[0];
    let size = "";
    if (accountInfo.value.order && accountInfo.value.order.items) {
        idAccReset = accountInfo.value.order.items[0].itemId;
    } else {
        switch (accountInfo.value.value_account) {
            case "200000":
                size = "200K";
                break;
            case "100000":
                size = "100K";
                break;
            case "50000":
                size = "50K";
                break;
            case "25000":
                size = "25K";
                break;
            case "10000":
                size = "10K";
                break;
            case "5000":
                size = "5K";
                break;
        }
        idAccReset = `${size}${curr}${type}`;
    }

    let params = {
        customer_id: accountInfo.value.customer_id,
        item_id: idAccReset,
    };
    if (canReset.value == true) {
        axios
            .post(
                `${baseUrl}/ecommerce/tvr?account_id=${accountInfo.value.username}`,
                params
            )
            .then((response) => {
                tvsReset.value = response.data;
            });
    } else {
        return false;
    }
};
const payWith = (value) => {
    loadingPay.value = true;
    if (canReset.value == true) {
        let payMethods = {
            customer_id: accountInfo.value.customer_id,
            account_id: accountInfo.value.username,
        };
        axios
            .patch(`${baseUrl}/ecommerce/tvr/pay/${value}`, payMethods)
            .then((response) => {
                const data = response.data?.payment_data;
                switch (value) {
                    case "solidgate":
                        if (data?.paymentIntent) {
                            solidgateData.value = {
                                paymentIntent: data?.paymentIntent,
                                merchant: data?.merchant,
                                signature: data?.signature,
                            };
                            document
                                .querySelector(".modal-backdrop.fade.show")
                                .remove();
                            showCreditCardModal.value = true;
                        }
                        break;
                    case "coinbase":
                    case "paragon":
                    case "confirmo":
                        if (data?.url && data?.url.length) {
                            window.location.href = data.url;
                        } else {
                            console.error(data);
                        }
                        break;
                }
                loadingPay.value = false;
            })
            .catch((error) => console.error(error));
    } else {
        return false;
    }
};
// Misc
const baseUrl =
    getCurrentInstance().appContext.config.globalProperties.$baseUrl;
const loading = ref(false);
const stopnavbar = ref(false);
const showRank = ref(false);
const credentials = ref(true);

// Account
const accountId = ref(null);
const currency = ref(null);
const accountInfo = ref(null);
const accountMetrics = ref(null);
const accountChartTracking = ref(null);
const accountTrades = ref(null);

// Trades
const groupedTrades = ref(null);
const dayTrades = ref([]);
const pipSum = ref(0);

// Lots
const lots = ref(null);

// Tabs
const closetrade = ref(true);
const opentrade = ref(false);
// Countdown
const countdown = ref({
    hours: 0,
    minutes: 0,
});

// Stats & Chart
const rr = ref(null);
const consistency = ref(0);
const selectedCurrency = ref("all");
const lossAnalytics = ref({
    daily_loss: 0,
    total_loss: 0,
    account_loss: 0,
    account_progress_bar: 0,
    daily_progress_bar: 0,
});

// Rules
const percent5 = ref(0);
const percent10 = ref(0);

// Refresh

const refreshData = () => {
    localStorage.clear(accountInfo.value.userID);
    location.reload(true);
};

// Popups
const popupShowed = ref(0);
const togglePopup = (popupId) => {
    popupShowed.value = popupShowed.value === popupId ? 0 : popupId;

    stopnavbar.value = popupShowed.value !== 0;
};

// Refs
const navbarRef = ref(null);

const watchRank = (value) => {
    showRank.value = value;
};
const closeRank = () => {
    showRank.value = false;
};
const app = getCurrentInstance().appContext.app;
const formatData = (value) => {
    let date = new Date(value);
    let local =
        app.config.globalProperties.$languages[
            cookies.get("client")?.language || "en"
        ] || app.config.globalProperties.$languages["en"];
    var options = { year: "numeric", month: "long", day: "numeric" };
    var formatter = new Intl.DateTimeFormat(local, options);
    return formatter.format(date);
};
const formatDate = (date) => {
    return moment(date).format("MM DD YYYY, h:mm:ss a");
};
let tradeViolation = ref({});
let counter = ref(0);
let mCount = ref(0);
let hCount = ref(0);
const healthCheckViolationsToTrades = ref({});
const maxDailyDrawdownPercentage = ref(5);
const maxDrawdownPercentage = ref(10);
const { popup } = usePopup();

const getAccountInfo = async () => {
    let item = null;
    try {
        // Loading and setting account info. Needed.
        loading.value = true;

        accountInfo.value = await accountStore.getAccountInfo(accountId.value);
        if (!accountInfo.value) {
            /* Sentry.captureException("There was an issue loading your account", {
                extra: {
                    account_id: accountId.value,
                },
            }); */
            return;
        }
        if (accountInfo.value.health_check_violations) {
            accountInfo.value.health_check_violations.forEach((element) => {
                if (element.rule == "martingale") {
                    mCount.value = element.count;
                } else if (element.rule == "hedging") {
                    hCount.value = element.count;
                }
                counter.value += element.count;
            });
        }
        tradeViolation.value = {
            martingale: "Martingale",
            martingaleCount: mCount.value,
            hedging: "Hedging",
            hedCount: hCount.value,
            counter: counter.value,
        };
        healthCheckViolationsToTrades.value = (
            accountInfo.value.health_check_violations || []
        ).reduce(
            (acc, violation) => {
                acc[violation.rule] = violation.trades;
                return acc;
            },
            { martingale: 0, hedging: 0, "high-frequency-trading": 0 }
        );
        accountInfo.value.created_at = new Date(accountInfo.value.created_at);
        accountInfo.value.nextPayout = accountInfo.value.nextReview;

        const date = new Date();
        date.setDate(date.getDate() + accountInfo.value.nextPayout);
        accountInfo.value.day = date.toLocaleDateString("en-US", {
            weekday: "long",
        });
        accountInfo.value.datePayout = date.toDateString();
        if (
            accountInfo.value &&
            accountInfo.value.illegal_strategies &&
            accountInfo.value.illegal_strategies["expert-advisor"] &&
            accountInfo.value.allow_copytrading
        ) {
            accountInfo.value.illegal_strategies[
                "expert-advisor"
            ].violated = false;
        } else {
            console.error(
                "Object 'illegal_strategies' o 'expert-advisor' not exists"
            );
        }
        let order = null;
        if (accountInfo.value.order_id) {
            try {
                order = await axios.get(
                    `${baseUrl}/order/find/${accountInfo.value.order_id}`
                );
            } catch {
                // NOP, order is already null
            }
        }

        item = order?.data?.items[0] || null;
        if (!item || (item && !item.payout_type)) {
            const size = accountInfo.value.ref_balance
                .toString()
                .replace(/000$/, "K");
            const currency = accountInfo.value.currency;
            const profitSplit = accountInfo.value.type_account.substring(
                0,
                accountInfo.value.type_account.indexOf("%")
            );

            let payout_type = "monthly";
            if (
                accountInfo.value.type_account
                    .toLowerCase()
                    .includes("biweekly")
            ) {
                payout_type = "biweekly";
            } else if (
                accountInfo.value.type_account
                    .toLowerCase()
                    .includes("on demand")
            ) {
                payout_type = "on-demand";
            }

            item = {
                itemId: `${size}${currency}${profitSplit}`.toUpperCase(),
                payout_type,
                type: accountInfo.value.type_account
                    .toLowerCase()
                    .includes("supercharged")
                    ? "supercharged"
                    : "classic",
                max_daily_drawdown: 5,
            };
        }

        loading.value = false;
    } catch (e) {
        console.error(e);
        // Sentry.captureException(e);
    }

    maxDailyDrawdownPercentage.value =
        accountInfo.value?.props?.max_daily_drawdown ||
        item?.max_daily_drawdown ||
        5;
    let _item = null;
    try {
        if (item && item.itemId) {
            _item = await useItemsStore().getItemById(
                item.itemId,
                item.payout_type,
                item.type,
                accountInfo.value?.props?.max_daily_drawdown ||
                    item.max_daily_drawdown
            );
        }
    } catch (e) {
        console.error("Error getting item", e);
        // Sentry.captureException(e);
    }

    maxDrawdownPercentage.value =
        accountInfo.value?.props?.max_drawdown ||
        _item?.props.max_drawdown ||
        item?.max_drawdown ||
        10;

    const initialBalance =
        accountInfo.value?.initial_balance ||
        (_item &&
            Number(
                _item.id
                    .substring(0, _item.id.indexOf("K") + 1)
                    .replace("K", "000")
            )) ||
        0;

    percent5.value = initialBalance * (maxDailyDrawdownPercentage.value / 100);
    percent10.value = initialBalance * (maxDrawdownPercentage.value / 100);
    lots.value =
        accountInfo.value?.lotsWeekly &&
        accountInfo.value?.lotsWeekly.length > 0
            ? accountInfo.value?.lotsWeekly[0]
            : null;

    Promise.all([
        calculateMetrics()
            .then(() => {
                console.debug("Metrics", accountMetrics.value);

                accountInfo.value.growth = accountMetrics.value
                    ? ((accountMetrics.value.balance -
                          accountInfo.value.initial_balance) /
                          accountInfo.value.initial_balance) *
                      100
                    : 0;

                window.$socket.emit("profile-open", {
                    userID: accountInfo.value.userID,
                });
                window.$socket.on("profile-metrics", async (metrics) => {
                    metrics.expire = Date.now() + 5 * 60 * 1000;
                    localStorage.setItem(
                        accountInfo.value.userID,
                        JSON.stringify(metrics)
                    );

                    await calculateMetrics();

                    accountInfo.value.growth = accountMetrics.value
                        ? ((accountMetrics.value.balance -
                              accountInfo.value.initial_balance) /
                              accountInfo.value.initial_balance) *
                          100
                        : 0;
                });
            })
            .catch((error) => {
                console.error("Error calculating metrics", error);
            }),
        getPayouts(),
        getUserTrackingForChart(),
        getTrades().then(() => getTvsTot()),
    ]).then(() => {
        //     console.log(openTrades.value.length, accountInfo.value.status, accountInfo.value.platform.toLowerCase());
        //     if (accountInfo.value.status === 'active' && ['mt4', 'mt5'].includes(accountInfo.value.platform.toLowerCase()) && !accountInfo.value.migrate_to_platform) {
        //         popup({
        //             settings: {
        //                 disableClose: true,
        //                 disableCloseButton: true,
        //             },
        //             component: ChangePlatform,
        //             componentProps: {
        //                 username: accountInfo.value.username,
        //                 balance: accountMetrics.value.balance,
        //                 trades: openTrades.value
        //             }
        //         })
        //     }
    });
};
const countKeysWithValues = (obj) => {
    return Object.values(obj).filter(
        (value) => typeof value === "object" && Object.keys(value).length > 0
    ).length;
};
const getUserTrackingForChart = async () => {
    try {
        loading.value = true;

        const startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        startDate.setDate(startDate.getDate() + 1);
        const endDate = new Date();
        endDate.setDate(endDate.getDate() + 1);
        const start = startDate.toISOString().split("T")[0];
        const end = endDate.toISOString().split("T")[0];
        const { data } = await axios.get(
            `${baseUrl}/getUserTrackingForChart?user_id=${accountInfo.value.userID}&first_date=${start}&last_date=${end}`
        );

        let indexes = [];
        if (data.label.length) {
            let startTime = new Date();
            startTime.setHours(0, 0, 0);

            let endTime = new Date();
            endTime.setDate(endTime.getDate() + 1);
            endTime.setHours(23, 59, 59);

            indexes = data.label
                .filter((label) => {
                    return (
                        new Date(label).getTime() >= startTime.getTime() &&
                        new Date(label).getTime() <= endTime.getTime()
                    );
                })
                .map((label) => data.label.indexOf(label));
            if (indexes.length === 0) {
                // Fallback to last available day
                const lastAvailableDay = data.label[data.label.length - 1];

                startTime = new Date(lastAvailableDay);
                startTime.setHours(0, 0, 0);

                endTime = new Date(lastAvailableDay);
                endTime.setHours(23, 59, 59);

                console.debug(
                    "getUserTrackingForChart @ Fallback to last available day",
                    startTime,
                    endTime
                );
                indexes = data.label
                    .filter((label) => {
                        return (
                            new Date(label).getTime() >= startTime.getTime() &&
                            new Date(label).getTime() <= endTime.getTime()
                        );
                    })
                    .map((label) => data.label.indexOf(label));
            }
        }

        const balances = data.balance.filter((balance, index) =>
            indexes.includes(index)
        );
        const dailyMaxBalance = balances.length > 0 ? Math.max(...balances) : 0;
        let lastEquity = data.equity[data.equity.length - 1] || 0;

        lossAnalytics.value.daily_loss = (
            (dailyMaxBalance - lastEquity) *
            -1
        ).toFixed(2);

        const totalLoss = (accountInfo.value.ref_balance - lastEquity) * 1;
        lossAnalytics.value.total_loss = +(
            isFinite(totalLoss) ? totalLoss : 0
        ).toFixed(0);

        if (
            !lastEquity &&
            !data.equity.length &&
            accountInfo.value.platform === "match-trader"
        ) {
            lastEquity = accountInfo.value.ref_balance;
        }
        const accountLoss =
            percent10.value + (lastEquity - accountInfo.value.ref_balance);
        lossAnalytics.value.account_loss = +(
            isFinite(accountLoss) ? accountLoss : 0
        ).toFixed(0);

        loading.value = false;
        accountChartTracking.value = data;

        lossAnalytics.value.daily_progress_bar = +(
            lossAnalytics.value.daily_loss / percent5.value
        ).toFixed(2);

        lossAnalytics.value.account_progress_bar = +(
            lossAnalytics.value.account_loss / percent10.value
        ).toFixed(2);

        console.debug(
            "ChartTracking",
            JSON.parse(JSON.stringify(accountChartTracking.value))
        );
    } catch (e) {
        console.error(e);
    }
};

const getUserMetrics = async () => {
    const { data } = await axios.get(
        `${baseUrl}/getUserMetrics?user_id=${accountInfo.value.userID}`
    );
    data.expire = Date.now() + 5 * 60 * 1000;

    const cachedMetrics =
        data.name && data.name === "NotFoundError"
            ? null
            : JSON.stringify(data);

    localStorage.setItem(accountInfo.value.userID, cachedMetrics);
    return cachedMetrics;
};

const calculateMetrics = async () => {
    let cachedMetrics = localStorage.getItem(accountInfo.value.userID);
    if (!cachedMetrics) {
        cachedMetrics = await getUserMetrics();
    }

    let metrics = JSON.parse(cachedMetrics);
    if (!metrics) {
        console.debug("No metrics found.", cachedMetrics);
        accountMetrics.value = {};
        return;
    }

    const timestamp = Date.now();

    if (timestamp > metrics.expire) {
        cachedMetrics = await getUserMetrics();
        metrics = JSON.parse(cachedMetrics);
    }

    accountMetrics.value = metrics;

    rr.value = Math.abs(
        (metrics?.averageWin || 0) / (metrics?.averageLoss || 0)
    ).toFixed(2);

    if (metrics.skills) {
        accountMetrics.value.dailyAvailable =
            100 - metrics.skills.daily_available_loss;
        accountMetrics.value.totalAvailable =
            100 - metrics.skills.total_available_loss;

        accountMetrics.value.dailyAvailable =
            accountMetrics.value.dailyAvailable.value >= 100
                ? 100
                : accountMetrics.value.dailyAvailable;
        accountMetrics.value.dailyAvailable =
            accountMetrics.value.dailyAvailable.value <= 0
                ? 0
                : accountMetrics.value.dailyAvailable;

        accountMetrics.value.totalAvailable =
            accountMetrics.value.totalAvailable.value >= 100
                ? 100
                : accountMetrics.value.totalAvailable;
        accountMetrics.value.totalAvailable =
            accountMetrics.value.totalAvailable.value <= 0
                ? 0
                : accountMetrics.value.totalAvailable;
    }

    // console.debug('Metrics', accountMetrics.value);
};

const getPayouts = async () => {
    try {
        const { data } = await axios.post(`${baseUrl}/accounts/getPayouts`, {
            usernameId: accountId.value,
        });
        accountInfo.value.totalPayouts = data.total;
    } catch (e) {
        console.error(e);
    }
};
const requestPay = ref(true);
const requestError = ref(false);
const getTrades = async () => {
    try {
        const nextMonth = new Date();
        nextMonth.setMonth(nextMonth.getMonth() + 1);

        const options = { year: "numeric", month: "2-digit", day: "2-digit" };
        const date = nextMonth
            .toLocaleDateString("en-US", options)
            .split("/")
            .join("-");

        const { data } = await axios.post(
            `${baseUrl}/user/getTrades2?user_id=${accountInfo.value.username}&start_date=01-01-2020&end_date=${date}`
        );
        accountTrades.value = data;
        if (data.length > 0) {
            let groupTrades = {};
            data.forEach((element) => {
                element.openTime = moment(element.openTime).format(
                    "YYYY-MM-DD HH:mm:ss"
                );
                element.closeTime = moment(element.closeTime).format(
                    "YYYY-MM-DD HH:mm:ss"
                );
                element.close = moment(element.closeTime).format("YYYY-MM-DD");
                element.type = element.type.split("_")[2];
                dayTrades.value.push(element.closeTime);
                dayTrades.value = [...new Set(dayTrades.value)];

                if (element.pips != null) {
                    let pipsAsNumber = parseFloat(element.pips);
                    if (!isNaN(pipsAsNumber)) {
                        pipSum.value += pipsAsNumber;
                    }
                }

                if (!element.type.includes("BALANCE")) {
                    if (groupTrades[element.close]) {
                        groupTrades[element.close].push(element);
                    } else {
                        groupTrades[element.close] = [element];
                    }
                }
            });

            groupedTrades.value = Object.keys(groupTrades).map((close) => {
                return {
                    close: close,
                    trades: groupTrades[close],
                };
            });

            groupedTrades.value.forEach((giorno) => {
                giorno.totProfits = [];
                giorno.totLots = 0;
                giorno.trades.forEach((element) => {
                    giorno.totLots = giorno.totLots + element.volume;
                    giorno.totProfits.push(element.profit);
                });
                giorno.totProfitsSum = giorno.totProfits.reduce(
                    (acc, valore) => acc + valore,
                    0
                );
            });
        }
    } catch (e) {
        console.error(e);
        if (e.response && e.response.status === 500) {
            document.getElementById("modalTrades")?.click();
            localStorage.removeItem(accountId);
            // startCountdown()
        }
    }

    try {
        console.debug(
            "Trades",
            JSON.parse(JSON.stringify(accountTrades.value))
        );
    } catch (e) {
        console.error(e);
    }
};

const momentTZ = require("moment-timezone");
const createCountdown = () => {
    const now = momentTZ(); //moment timezone
    const cet = now.tz("Europe/Berlin"); // CET timezone
    const isDaylightSavingTime = cet.isDST(); // Verifica se è in orario legale
    const gmtOffset = isDaylightSavingTime ? 2 : 1; //scelgo UTC +1 o +2
    const nowLocal = now.clone().utcOffset(gmtOffset * 60);
    const midnight = cet.clone().endOf("day");
    // Aggiungi 1 giorno a mezzanotte se l'ora locale >= 24
    if (nowLocal.isAfter(midnight)) {
        midnight.add(1, "day");
    }
    const diff = midnight.diff(nowLocal);
    const duration = momentTZ.duration(diff);
    countdown.value.hours = duration.hours();
    countdown.value.minutes = duration.minutes();
};
const formatAmount = (amount) => {
    return (+amount).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};

const showclose = () => {
    closetrade.value = true;
    opentrade.value = false;
    let element = document.getElementById("showclose");
    element.classList.add("active");
    let element1 = document.getElementById("showopen");
    element1.classList.remove("active");
};
const showopen = () => {
    closetrade.value = false;
    opentrade.value = true;
    let element = document.getElementById("showopen");
    element.classList.add("active");
    let element1 = document.getElementById("showclose");
    element1.classList.remove("active");
};

// Hooks
onBeforeMount(async () => {
    const { params, query } = route;
    if (!params.accountId || !query.currency) {
        await router.replace("/home");
        return;
    }

    accountId.value = params.accountId;
    currency.value = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: query.currency,
    }).formatToParts(1)[0].value;
});

onMounted(async () => {
    createCountdown();

    await getAccountInfo();
    await accountStore.getOpenTrades();
});
</script>

<style scoped>
.pad0Imp {
    padding-left: 0px !important ;
    color: white !important;
}
.modal_dialog {
    min-width: 800px !important;
    margin: auto;
}
.modal-dialog {
    max-width: 800px !important;
}
.modal-content {
    background-color: #0d0a1f !important;
}

.allineamento {
    vertical-align: middle;
}
.displayFlex {
    display: flex !important;
}
.progress-bar {
    height: 100px;
    border-radius: 50%;
}
.progress-padding {
    padding: 20px;
}
.countdown {
    width: 200px;
    height: 200px;
    border: 10px solid gray;
    border-radius: 50%;
    position: relative;
}

.eyered {
    text-decoration: line-through;
    text-decoration-color: red;
}

.tooltip {
    background-color: black;
    color: red;
}

.box-trail > * {
    border-radius: 10px;
    display: flex;
}

.stats-charts > div > * {
    padding: 20px;
}

.info {
    cursor: pointer;
}

.card {
    border-radius: 10px;
    overflow: hidden;
}

.rulesBox {
    position: absolute;
    width: 80%;
    right: 10px;
    padding: 15px;
    z-index: 999999;
    text-align: end;
    box-shadow: 4px 4px 25px black;
}
.rulesBoxtvs {
    position: absolute;
    padding: 15px;
    z-index: 999999;
    text-align: end;
    box-shadow: 4px 4px 25px black;
}
.carousel-control-prev {
    width: 2%;
}

.carousel-control-next {
    width: 2%;
}

h5.font-medium {
    font-size: 1.3rem;
}
.nav-link {
    color: white !important;
}

.nav-link.active {
    color: #fb9678 !important;
    background-color: #454d5d !important;
}

.bgSfumato {
    background-color: #c24f3561;
}

.scroll-sidebar {
    background-color: rgba(255, 255, 255, 0.1);
}
.blurred {
    filter: blur(10px);
}
.data-account {
    row-gap: 10px;
}
#info-tab .row-icon {
    width: 40px;
    margin-right: 10px;
}
#info-tab .row-icon i {
    margin-right: 15px;
    font-size: 30px;
}
thead {
    text-wrap: nowrap;
}

td {
    padding: 0.75rem !important;
}
.verticalText {
    vertical-align: middle;
}

.tvs-calculation-container {
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tvs-calculation-table {
    width: 100%;
    border-collapse: collapse;
    color: #333;
}

.tvs-calculation-table thead {
    border-bottom: 2px solid #eaeaea;
}

.tvs-calculation-table th,
.tvs-calculation-table td {
    text-align: left;
    padding: 0.75rem 1rem;
    font-size: 0.95em;
}

.tvs-calculation-table th {
    font-weight: 600;
}

.tvs-calculation-table tbody tr {
    border-bottom: 1px solid #eaeaea;
}

.tvs-calculation-table tbody tr:last-child {
    border-bottom: none;
}

.tvs-calculation-table tbody td {
    font-weight: 400;
}

.tvs-calculation-table td.profit {
    color: #4caf50;
}

.tvs-calculation-table td.loss {
    color: #f44336;
}
.payment-methods {
    flex-wrap: wrap;
    justify-content: center;
}
.payment-image {
    max-height: 2.5rem;
    max-width: 100%;
}
</style>
