<template>
    <h4 class="text-white text-start">{{ $t('chart-WLRatio') }}</h4>
    <div style="height:300px; display: flex!important;" class="d-flex flex-column justify-content-center align-items-center">
        <canvas ref="chartRef"></canvas>
    </div>
</template>

<script setup>
import Chart from "chart.js/auto"
import {onMounted, ref, shallowRef, watch} from "vue";

// Props
const props = defineProps({
  selectedoption: {
    type: String,
    required: true
  },
  metrics: Object,
  currency: Object
});

// Charts
const chartRef = ref(null); // HTML ref
const chart = ref(null);

const updateChart = () => {
  let data = [];
  if (props.selectedoption.toLowerCase() === 'all') {
    data = [props.metrics.wonTradesPercent, props.metrics.lostTradesPercent];
  } else {
    const element = props.metrics.currencySummary.find((element) => element.currency.toLowerCase() === props.selectedoption.toLowerCase());
    data = [element.total.wonTradesPercent, element.total.lostTradesPercent];
  }

  chart.value.value.data.labels = ["Win %",	"Loss %"];
  chart.value.value.data.datasets = [
    {
      data,
      backgroundColor: [
        'rgba(0,194,146,1)',
        'rgba(228,106,118,1)',
      ],
      borderColor: [
        'white'
      ],
      borderWidth: 1,
      hoverOffset: 4
    }
  ];
  chart.value.value.update();
};

// Hooks
onMounted(() => {
  let chartInstance = new Chart(chartRef.value, {
    type: 'pie',
    data: {
      labels: ["Trades"],
      datasets: [],
    },
    options: {
      responsive: true,
      plugins: {
        legend: {
          display: true,
          labels: {
            color: "white",
            font: {
              size: 13
            }
          }
        }
      },
    },
  });
  chart.value = shallowRef(chartInstance);

  updateChart();
});

watch(() => props.selectedoption, (newVal, oldVal) => {
  if (newVal === oldVal) {
    return;
  }

  updateChart();
});
</script>

<style>

</style>
