<script setup>

</script>

<template>
    <div class="modal fade" id="termsAndConditionModal" tabindex="-1" aria-labelledby="termsAndConditionModalLabel" aria-hidden="true">
        <div  class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-1" id="termsAndConditionModalLabel">{{ $t('T&C') }}</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-header">
                    <h3>{{ $t('TCTitle') }}</h3>
                </div>
                <div class="modal-body">
                    <div>
                        <p>{{ $t('TCText1') }}</p>
                        <p>{{ $t('TCText2') }}</p>
                        <p>{{ $t('TCText3') }}</p>
                        <p>{{ $t('TCText4') }} </p>
                    </div>
                    <br/>
                    <h6 class="fw-bold mt-4">{{ $t('TC1') }}</h6>
                    <p>{{ $t('TC1/1') }}</p>

                    <h6 class="fw-bold mt-4">{{ $t('TC2') }}</h6>
                    <p>{{ $t('TC2/1') }}
                    </p>
                    <p>{{ $t('TC2/2') }} </p>
                    <p>{{ $t('TC2/3') }}</p>
                    <p>{{ $t('TC2/4') }}</p>
                    <p>{{ $t('TC2/5') }}</p>
                    <p>{{ $t('TC2/6') }}</p>
                    <br/>
                    <h6 class="fw-bold mt-4">{{ $t('TC3') }} </h6>
                    <p>{{ $t('TC3/1') }}</p><br/>
                    <div class="ms-3">
                        <p> {{ $t('TC3/1/1') }}
                        </p>
                        <p>{{ $t('TC3/1/2') }}
                        </p>
                        <p>{{ $t('TC3/1/3') }}
                        </p>
                        <p>{{ $t('TC3/1/4') }}
                        </p>
                        <p>{{ $t('TC3/1/5') }}</p>
                        <p>{{ $t('TC3/1/6') }}
                        </p>
                    </div><br/>
                    <p>3{{ $t('TC3/2') }} </p>
                    <p>{{ $t('TC3/3') }} </p>
                    <p>{{ $t('TC3/4') }} </p>
                    <br/>
                    <h6 class="fw-bold mt-4">{{ $t('TC4') }}</h6>
                    <p>{{ $t('TC4/1') }} </p>
                    <p>{{ $t('TC4/2') }} </p>
                    <p>{{ $t('TC4/3') }}</p>
                    <p>{{ $t('TC4/4') }} </p>
                    <p>{{ $t('TC4/5') }}</p>
                    <p>{{ $t('TC4/6') }} </p>
                    <p>{{ $t('TC4/7') }}</p>
                    <h6 class="fw-bold mt-4">{{ $t('TC5') }} </h6>
                    <p>{{ $t('TC5/1') }}
                    </p>
                    <p>{{ $t('TC5/2') }}</p>
                    <div class="ms-3">
                        <p>{{ $t('TC5/2/1') }} </p>
                        <table class="table">
                            <thead>
                            <th>Account {{ $t('InitBalance') }}</th>
                            <th>5,000</th>
                            <th>10,000</th>
                            <th>25,000</th>
                            <th>50,000</th>
                            <th>100,000</th>
                            <th>200,000</th>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{{ $t('Margin') }} 5%</td>
                                <td>250</td>
                                <td>500</td>
                                <td>1,250</td>
                                <td>2,500</td>
                                <td>5,000</td>
                                <td>10,000</td>
                            </tr>
                            <tr>
                                <td>{{ $t('Margin') }} 4%</td>
                                <td>200</td>
                                <td>400</td>
                                <td>1,000</td>
                                <td>2,000</td>
                                <td>4,000</td>
                                <td>8,000</td>
                            </tr>
                            <tr>
                                <td>{{ $t('Margin') }} 3%</td>
                                <td>150</td>
                                <td>300</td>
                                <td>750</td>
                                <td>1,500</td>
                                <td>3,000</td>
                                <td>6,000</td>
                            </tr>
                            </tbody>
                        </table>
                        <p>{{ $t('TC5/2/1b') }}</p>
                        <p> {{ $t('TC5/2/2') }}</p>
                        <p>{{ $t('TC5/2/3') }}</p>
                    </div>
                    <p>{{ $t('TC5/3') }}</p>
                    <div class="ms-3">
                        <p> {{ $t('TC5/3/1') }}
                            <br>{{ $t('TC5/3/1a') }}
                            <br>{{ $t('TC5/3/1b') }}
                            <br>{{ $t('TC5/3/1c') }}
                            <br>{{ $t('TC5/3/1d') }}
                            <br>{{ $t('TC5/3/1e') }}
                            <br>{{ $t('TC5/3/1f') }}
                        </p>
                        <p>
                            {{ $t('TC5/3/2') }}
                            <br>{{ $t('TC5/3/2a') }}
                            <br>{{ $t('TC5/3/2b') }}
                            <br>{{ $t('TC5/3/2c') }}

                        </p>
                        <p>{{ $t('TC5/3/3') }}</p>
                    </div>
                    <p>{{ $t('TC5/4') }}</p>
                    <p>{{ $t('TC5/5') }}</p>
                    <p>{{ $t('TC5/6') }}</p>
                    <p>{{ $t('TC5/6/1') }}</p>
                    <p>{{ $t('TC5/6/2') }} </p>
                    <p>{{ $t('TC5/7') }}</p>
                    <p>{{ $t('TC5/8') }} </p>
                    <p>{{ $t('TC5/9') }}</p>
                    <p>{{ $t('TC5/10') }}</p>
                    <br/>
                    <h6 class="fw-bold mt-4">{{ $t('TC6') }}</h6>
                    <p>{{ $t('TC6/1') }}</p><p>{{ $t('TC6/2') }} </p><p> {{ $t('TC6/3') }}</p><p>{{ $t('TC6/4') }}</p><p>{{ $t('TC6/5') }} </p>
                    <div class="ms-3">
                        <p>
                            {{ $t('TC6/5/1') }}
                            {{ $t('TC6/5/1a') }}{{ $t('TC6/5/1b') }}{{ $t('TC6/5/1c') }}{{ $t('TC6/5/1d') }}
                        </p>
                        <p>{{ $t('TC6/5/2') }}</p>
                    </div>
                    <p>{{ $t('TC6/6') }}</p>
                    <br/>
                    <h6 class="fw-bold mt-4">{{ $t('TC7') }}</h6>
                    <br/>
                    <p>{{ $t('TC7/1') }}</p>
                    <p>{{ $t('TC7/2') }}</p><p>{{ $t('TC7/3') }} </p><p>{{ $t('TC7/4') }}</p><p>{{ $t('TC7/5') }}</p>
                    <div class="ms-3">
                        <p>{{ $t('TC7/5/1') }}</p><p>{{ $t('TC7/5/2') }}</p>
                        <p>{{ $t('TC7/5/3') }}</p>
                        <p>{{ $t('TC7/5/4') }}</p>
                        <p>{{ $t('TC7/5/5') }}</p>
                        <p>{{ $t('TC7/5/6') }} </p>
                        <p>{{ $t('TC7/5/7') }}</p>
                    </div><br/>
                    <p>{{ $t('TC7/6') }}</p>
                    <p>{{ $t('TC7/7') }}</p>
                    <br>
                    <h6 class="fw-bold mt-4">{{ $t('TC8') }}</h6>
                    <p>{{ $t('TC8/1') }}</p>
                    <p>{{ $t('TC8/2') }}</p>
                    <p>{{ $t('TC8/3') }} </p>
                    <p>{{ $t('TC8/4') }} </p>
                    <p>{{ $t('TC8/5') }}</p>
                    <p>{{ $t('TC8/6') }}</p>
                    <br/>
                    <h6 class="fw-bold mt-4">{{ $t('TC9') }} </h6>
                    <br/>{{ $t('TC9/1') }}
                    <br/>{{ $t('TC9/2') }}
                    <br/>{{ $t('TC9/3') }}
                    <br/>
                    <h6 class="fw-bold mt-4">{{ $t('TC10') }} </h6>
                    <p>{{ $t('TC10/1') }}</p>
                    <p>{{ $t('TC10/2') }}</p>
                    <p>{{ $t('TC10/3') }} </p>
                    <br/>

                    <h6 class="fw-bold mt-4">{{ $t('TC11') }}</h6>
                    <p>{{ $t('TC11/1') }} </p>
                    <p>{{ $t('TC11/2') }}</p>
                    <p>{{ $t('TC11/3') }} </p>
                    <p>{{ $t('TC11/4') }}</p>
                    <p>{{ $t('TC11/5') }}</p>
                    <div class="ms-3">
                        <p>{{ $t('TC11/5/1') }}</p>
                        <p>{{ $t('TC11/5/2') }}</p>
                    </div>
                    <br>
                    <p>{{ $t('TC11/6') }}</p>
                    <p>{{ $t('TC11/7') }}</p>
                    <p>{{ $t('TC11/8') }}</p>
                    <br>

                    <h6 class="fw-bold mt-4">{{ $t('TC12') }} </h6><br>
                    <p>{{ $t('TC12/1') }}</p><br/>

                    <h6 class="fw-bold mt-4">{{ $t('TC13') }}</h6><br>
                    <p>{{ $t('TC13/1') }}</p>

                    <h6 class="fw-bold mt-4">{{ $t('TC14') }}</h6><br>
                    <p>{{ $t('TC14/1') }}</p>
                    <p>{{ $t('TC14/2') }}</p>
                    <p>{{ $t('TC14/3') }}</p>
                    <p>{{ $t('TC14/4') }}</p>
                    <p>{{ $t('TC14/5') }}</p>
                    <p></p>
                    <p>{{ $t('TC14/6') }}</p>
                    <p>{{ $t('TC14/7') }}</p>
                    <br/>

                    <h6 class="fw-bold mt-4">{{ $t('TC15') }}</h6><br>
                    {{ $t('TC15/1') }} <br>
                    <div class="ms-3">
                        <p>{{ $t('TC15/1/1') }}</p>
                        <p>{{ $t('TC15/1/2') }}</p>
                        <p>{{ $t('TC15/1/3') }}</p>
                        <p>{{ $t('TC15/1/4') }}</p>
                        <p>{{ $t('TC15/1/5') }}</p>
                        <p>{{ $t('TC15/1/6') }}</p>
                        <p>{{ $t('TC15/1/7') }}</p>
                        <p>{{ $t('TC15/1/8') }}</p>
                        <p>{{ $t('TC15/1/10') }} </p>
                        <p>{{ $t('TC15/1/11') }}</p>
                        <p>{{ $t('TC15/1/12') }}</p>
                        <p>{{ $t('TC15/1/13') }} </p>
                        <p>{{ $t('TC15/1/14') }}</p>
                        <p>{{ $t('TC15/1/15') }}</p>
                        <p>{{ $t('TC15/1/16') }}</p>
                        <p>{{ $t('TC15/1/17') }}</p>
                        <p>{{ $t('TC15/1/18') }}</p>
                        <p> {{ $t('TC15/1/19') }}</p>
                        <p>{{ $t('TC15/1/21') }}</p>
                        <p>{{ $t('TC15/1/22') }}</p>
                        <p>{{ $t('TC15/1/23') }}</p>
                        <p>{{ $t('TC15/1/24') }}
                            <a target="_blank" href="https://ofpfunding.com">www.ofpfunding.com </a>
                            {{ $t('TC15/1/24a') }}
                        </p>
                    </div>
                    <p>{{ $t('TC15/2') }}</p>
                    <div>
                        {{ $t('TC15/2/1') }}
                        <p>{{ $t('TC15/2/2') }}</p>
                        <p>{{ $t('TC15/2/3') }}</p>
                    </div>
                    <br/>
                    <div>
                        <h5>{{ $t('Schedule') }}</h5>
                        <p>{{ $t('Models') }}:</p>
                        <ul>
                            <li>{{ $t('Monthly') }} 26%</li>
                            <li>{{ $t('Monthly') }} 40%</li>
                            <li>{{ $t('Monthly') }} 60%</li>
                            <li>{{ $t('Monthly') }} 80%</li>
                        </ul>
                        <ul>
                            <li>{{ $t('ondemand') }} 26%</li>
                            <li>{{ $t('ondemand') }} 40%</li>
                            <li>{{ $t('ondemand') }} 60%</li>
                            <li>{{ $t('ondemand') }} 80%</li>
                        </ul>
                        <ul>
                            <li>Bi-weekly 26%</li>
                            <li>Bi-weekly 40%</li>
                            <li>Bi-weekly 60%</li>
                            <li>Bi-weekly 80%</li>
                        </ul>
                        <p>{{ $t('Size') }}:</p>
                        <ul>
                            <li>5,000</li>
                            <li>10,000</li>
                            <li>25,000</li>
                            <li>50,000</li>
                            <li>100,000</li>
                            <li>200,000</li>
                        </ul>
                        <p>{{ $t('Currency') }}:</p>
                        <ul>
                            <li>EUR</li>
                            <li>USD</li>
                            <li>GBP</li>
                        </ul>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
