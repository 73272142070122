<template>
    <!-- Footer w/ promo -->
    <!-- Desktop -->
    <footer
        v-if="promo && marketingToolsStore.activeMarketingTool?.bannerFooterUrl"
        :class="{
            'footer': true,
            'd-none': true,
            'd-xl-flex': true,
            'justify-content-between': true,
            'promo-padding-x': promo,
            'promo-padding-y': promo,
            'bg-off-30': promo,
            'scrolling-background': true
        }"
        @click="goBuy()" :style="{ backgroundImage: `url(${marketingToolsStore.activeMarketingTool?.bannerFooterUrl})` }">
    </footer>
    <!-- Mobile Scorrevole-->
    <footer class="footer p-0 d-xl-none bg-off-30 scrolling-background" v-if="promo && marketingToolsStore.activeMarketingTool?.bannerFooterUrl" @click="goBuy()">
        <div class="scrolling-container">
            <div class="scrolling-content">
                <img :src="marketingToolsStore.activeMarketingTool?.bannerFooterUrl" alt="" class="scrolling-image">
                <img :src="marketingToolsStore.activeMarketingTool?.bannerFooterUrl" alt="" class="scrolling-image">
                <img :src="marketingToolsStore.activeMarketingTool?.bannerFooterUrl" alt="" class="scrolling-image">
            </div>
        </div>
    </footer>
    <!--Mobile Statico-->
    <!-- <footer class="footer px-0 d-xl-none bg-off-30 scrolling-background" v-if="promo && marketingToolsStore.activeMarketingTool?.bannerFooterUrl" @click="goBuy()" style="background-size: cover;" :style="{ backgroundImage: `url(${marketingToolsStore.activeMarketingTool?.bannerFooterUrl})` }"></footer> -->



    <!-- Footer w/o promo -->
    <!-- Desktop -->
    <footer class="footer d-none d-md-flex justify-content-center scrolling-background" v-if="!promo" :style="footerImageStyle"  @click="goBuy()">
    </footer>
    <!-- Mobile scorrevole-->
    <footer class="footer p-0 d-xl-none bg-off-30 scrolling-background" v-if="!promo" @click="goBuy()">
        <div class="scrolling-container">
            <div class="scrolling-content">
                <img :src="footerImageStyleUrl" alt="" class="scrolling-image">
                <img :src="footerImageStyleUrl" alt="" class="scrolling-image">
                <img :src="footerImageStyleUrl" alt="" class="scrolling-image">
            </div>
        </div>
    </footer>
    <!--Mobile Statico-->
    <!-- <footer class="footer px-0 d-flex flex-column align-items-center d-md-none scrolling-background" v-if=" !promo" :style="footerImageStyle"  @click="goBuy()"></footer> -->
</template>

<script>
import {useMarketingToolsStore} from "../../store/marketing-tools.store";

export default {
    name: "footer",
    data() {
        return {
            promo: true,
        };
    },
    setup() {
        const marketingToolsStore = useMarketingToolsStore();
        return { marketingToolsStore }
    },
    computed: {
        footerImageStyle() {
            return {
                backgroundImage: `url(${this.$t('footer-image-custom')})`
            };
        },
        footerImageStyleUrl() {
            return `${this.$t('footer-image-custom')}`
        }
    },
    methods: {
        goBuy() {
            this.$router.push({ path: '/buy-account' });
        },
    },
    async beforeMount() {
        if (!this.marketingToolsStore.loading && !this.marketingToolsStore.activeMarketingTool?._id) {
            await this.marketingToolsStore.fetchActiveMarketingTool();
        }
        this.promo = this.marketingToolsStore.activeMarketingTool?._id && this.marketingToolsStore.activeMarketingTool?.bannerFooterUrl ? true: false;
    }
};
</script>

<style scoped>
img{
    margin: 0px!important;
}
.footer {
    position: fixed;
    z-index: 999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-size: contain;
    background-repeat: repeat;
    overflow: hidden;
}
.scrolling-background {
    max-height: 8.5%;
    height: 65px;
    background-position: center;
}
.scrolling-container {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.scrolling-content {
  display: flex;
  align-items: center;
  animation: scrollBackground 5s linear infinite;
}

.scrolling-image {
  height: 65px;
  flex-shrink: 0;
  width: 100%!important;

}

@keyframes scrollBackground {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

#footer-image-custom {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center;
}
div {
    cursor: pointer;
}
h4 {
    font-size: 1.35rem!important;
    font-weight: 600;
    font-family: "Roboto", Sans-serif;
    text-shadow: 0px 3px 2px rgba(0, 0, 0, 0.3);
}
h6 {
    font-family: "Roboto", Sans-serif;
    text-shadow: 0px 3px 2px rgba(0, 0, 0, 0.3);
}
.displayFlex {
    display: flex!important;
}

.gradient-box-off-30, .gradient-box-off-30.gradient-reverse {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    gap: 24px;
    background: linear-gradient(90deg, #FF6844 4.06%, #241D80 97.81%);
    border-radius: 8px;
    flex: none;
    order: 3;
    align-self: stretch;
    flex-grow: 1;
    z-index: 3;
    flex-grow: 1;
    z-index: 2;
}
.gradient-box-off-30 span {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-wrap: nowrap;
    color: #FFFFFF;
}
.promo-padding-x {
    padding-left: 32px;
    padding-right: 32px;
}
.bg-off-30 {
    background-color: #080b1e;
}
.d-none.d-lg-flex {
    display: flex!important;
}
@media (min-width: 992px) {
    .d-none.d-lg-flex {
        display: flex;
    }
}
@media (max-width: 991px) {
    .footer {
        padding-top: 10px;
        padding-bottom: 5px;
    }
    .gradient-box-off-30, .gradient-box-off-30.gradient-reverse {
        padding: 18px;
    }
    .flash-sale {
        width: 150px;
    }
}
@media (max-width: 767px) {
    .footer {
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .gradient-box-off-30, .gradient-box-off-30.gradient-reverse {
        padding: 12px;
    }
    .flash-sale {
        width: 100px;
    }
}
</style>
