<template>
  <div id="main-wrapper">
    <header class="topbar" :class="{ 'opacity-25': loading }">
      <Navbar />
    </header>

    <aside class="left-sidebar" :class="{ 'opacity-25': loading }">
      <div class="scroll-sidebar">
        <Sidebar :prevent-navigation="!user?._id" />
      </div>
    </aside>
    <div class="bg page-wrapper  position-relative">
      <Spinner v-if="loading" />
      <!-- Header Offer -->
      <div class="header">
        <h1>
          <span class="hidden-mobile">{{ $t('header.title') }}</span><br>{{ $t('header.title2') }}
        </h1>
        <p class="step"><span class="orange-title">{{ $t('header.subtitle1') }}</span> {{ $t('header.subtitle2') }}<span class="white-title">{{ $t('header.subtitle3') }}</span></p>
        <p class="text hidden-mobile">{{ $t('header.text') }}
        </p>
        <p class="text-strong hidden-mobile">{{ $t('header.textStrong') }}</p>

        <p class="subtitle-mobile hidden-desktop">{{ $t('header.subtitleMobile1') }} <strong><span class="primary">2</span> {{ $t('header.subtitleMobile2') }}</strong>
          {{ $t('header.subtitleMobile3') }}</p>

        <!-- End Header Offer -->

        <!-- Bundle Offer Mobile -->
        <div id="bundlesCarousel" class="bundles-mobile carousel hidden-desktop slide" data-ride="carousel">

          <div class="carousel-inner" style="z-index: 1;">
            <div v-for="(bundle, index) in bundles" :key="index" class="bundle-mobile-item carousel-item"
              :class="['table-shadow-' + index]">
              <h2 class="bundle-flag">
                {{ bundle.title }}
              </h2>
              <p class="more-account">+</p>

              <table class="bundle-mobile-item__table w-100">
                <thead class="bg-thead">
                  <tr>
                    <th aria-hidden="true"></th>
                    <th>{{ $t('bundle.account1') }}<br><span class="size-account">{{ bundle.accounts[0].size }}K</span></th>
                    <th>{{ $t('bundle.account2') }}<br><span class="size-account">{{ bundle.accounts[1].size }}K</span></th>
                  </tr>
                </thead>
                <tbody class="bg-tbody">
                  <tr>
                    <td>{{ $t('bundle.currency') }}</td>
                    <td>{{ bundle.accounts[0].currency }}</td>
                    <td>{{ bundle.accounts[1].currency }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('bundle.payoutModel') }}</td>
                    <td class="text-capitalize">{{ bundle.accounts[0].payout }}</td>
                    <td class="text-capitalize">{{ bundle.accounts[1].payout }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('bundle.profitSplit') }}</td>
                    <td>{{ bundle.accounts[0].split }}%</td>
                    <td class="ribbon-cell ribbon-effect">{{ bundle.accounts[1].split }}%</td>
                  </tr>
                  <tr>
                    <td>{{ $t('bundle.dailyDrawdown') }}</td>
                    <td>{{ bundle.accounts[0].drawdown }}%</td>
                    <td>{{ bundle.accounts[1].drawdown }}%</td>
                  </tr>
                </tbody>
              </table>
              <div class="prices w-100 m-0 align-items-center">
                <!-- Regular Price -->
                <div class="d-flex w-75 box-regular">
                  <div class="w-50">
                    <p class="reg-title">{{ $t('bundle.regularPrice') }}</p>
                    <p class="reg-subtitle">{{ $t('bundle.regularSubtitle') }}</p>
                  </div>
                  <div class="w-50">
                    <p class="crossed-out">${{ bundle.price }}</p>
                  </div>
                </div>

                <!-- Bundle Price -->
                <div class="d-flex w-75 box-bundle">
                  <div class="w-50">
                    <p class="bundle-title">{{ $t('bundle.bundlePrice') }}</p>
                  </div>
                  <div class="w-50">
                    <p class="bundle-price">${{ bundle.bundle_price }}</p>
                  </div>
                </div>


                <button class="btn w-75" @click="saveOnChookies(bundle.note)" :id="'bundle-' + bundle.note" style="z-index: 999;">
                  {{ $t('bundle.buyNow') }}
                </button>

              </div>

            </div>
          </div>
         <!--  <a class="carousel-control-prev" role="button">
            <img src="/assets/icons/arrow.svg" alt="arrow" class="arrow-img" />
          </a>
          <a class="carousel-control-next" role="button">
            <img src="/assets/icons/arrow.svg" alt="arrow" class="arrow-img right" />
          </a> -->
        </div>
        <!-- END Bundle Offer Mobile -->

        <!-- Bundle Offer destop -->

        <div class="bundles p-md-6">
          <div v-for="(bundle, index) in bundles" :key="index" class="d-flex flex-column align-items-center bundle-item"
            :class="['table-shadow-' + index]">
            <h2>
              {{ bundle.title }}
            </h2>
            <p class="more-account">+</p>

            <table class="bundle-mobile-item__table w-100">
              <thead class="bg-thead">
                <tr>
                  <th aria-hidden="true"></th>
                  <th>{{ $t('bundle.account1') }}<br><span class="size-account">{{ bundle.accounts[0].size }}K</span></th>
                  <th>{{ $t('bundle.account2') }}<br><span class="size-account">{{ bundle.accounts[1].size }}K</span></th>
                </tr>
              </thead>
              <tbody class="bg-tbody">
                <tr>
                  <td>{{ $t('bundle.currency') }}</td>
                  <td>{{ bundle.accounts[0].currency }}</td>
                  <td>{{ bundle.accounts[1].currency }}</td>
                </tr>
                <tr>
                  <td>{{ $t('bundle.payoutModel') }}</td>
                  <td class="text-capitalize">{{ bundle.accounts[0].payout }}</td>
                  <td class="text-capitalize">{{ bundle.accounts[1].payout }}</td>
                </tr>
                <tr>
                  <td>{{ $t('bundle.profitSplit') }}</td>
                  <td>{{ bundle.accounts[0].split }}%</td>
                  <td class="ribbon-cell ribbon-effect">{{ bundle.accounts[1].split }}%</td>
                </tr>
                <tr>
                  <td>{{ $t('bundle.dailyDrawdown') }}</td>
                  <td>{{ bundle.accounts[0].drawdown }}%</td>
                  <td>{{ bundle.accounts[1].drawdown }}%</td>
                </tr>
              </tbody>
            </table>

            <div class="prices w-100 m-0 align-items-center">
              <!-- Regular Price -->
              <div class="d-flex w-75 box-regular">
                <div class="w-50">
                  <p class="reg-title">{{ $t('bundle.regularPrice') }}</p>
                  <p class="reg-subtitle">{{ $t('bundle.regularSubtitle') }}</p>
                </div>
                <div class="w-50">
                  <p class="crossed-out">${{ bundle.price }}</p>
                </div>
              </div>

              <!-- Bundle Price -->
              <div class="d-flex w-75 box-regular">
                <div class="w-50">
                  <p class="bundle-title">{{ $t('bundle.bundlePrice') }}</p>
                </div>
                <div class="w-50">
                  <p class="bundle-price">${{ bundle.bundle_price }}</p>
                </div>
              </div>

              <button class="btn w-75" @click="saveOnChookies(bundle.note)" :id="'bundle-' + bundle.note">
                {{ $t('bundle.buyNow') }}
              </button>

            </div>

          </div>
        </div>
        <!-- END Bundle Offer desktop -->

        <!-- CountDown -->
        <div class="limited-offer justify-content-center">
          <div class="countdown-mobile content-limit-offer d-flex w-75 justify-content-center align-items-center">
            <div class="countdown-content">
              <p class="title-limit">{{ $t('countdown.title') }}</p>
              <div class="count-mobile d-flex direction-row">
                <div class="countdown">
                  <span id="days">{{ daysDiff }}</span>
                  <p>
                    {{ $t('countdown.days') }}
                  </p>
                </div>
                <div class="countdown">
                  <span id="hours">{{ hoursDiff }}</span>
                  <p>
                    {{ $t('countdown.hours') }}
                  </p>
                </div>
                <div class="countdown">
                  <span id="minutes">{{ minDiff }}</span>
                  <p>
                    {{ $t('countdown.minutes') }}
                  </p>
                </div>
                <div class="countdown">
                  <span id="seconds">{{ secondsDiff }}</span>
                  <p>
                    {{ $t('countdown.seconds') }}
                  </p>
                </div>
              </div>
              <div class="box-gradient hidden-mobile">
                <div>
                  <router-link to="/buy-account" class="btn-nostyle">
                    {{ $t('buttons.backToTablePrices') }}<br><span>{{ $t('buttons.backToTablePrices2') }}</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="back-price hidden-desktop">
          <router-link to="/buy-account" class="back-link">
            ⇦ {{ $t('buttons.backToTablePrices') }}
          </router-link>
      </div>
      </div>
      <!-- End CountDown -->

    </div>
    <footerBundle :prevent-navigation="!user?._id" />
  </div>
</template>
<script setup>
import { ref, onMounted, defineComponent, compile, render, h } from 'vue';
import { useRouter } from "vue-router";
import { useCookies } from "vue3-cookies";

import Navbar from "../components/statics/navbar";
import Spinner from "../components/Spinner";
import Sidebar from "../components/Sidebar";
import { useUserStore } from '../store/user.store';
import { storeToRefs } from 'pinia';
import { useItemsStore } from '../store/items.store';
import footerBundle from '../components/statics/footerBundle.vue';
import { usePopup } from '../store/popup.store';
import {useCartStore} from "../store/cart.store";

const router = useRouter();

const { cookies } = useCookies();
const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const cartStore = useCartStore()
const { addBundleToCart } = cartStore;

const itemsStore = useItemsStore();
const { fetchBundles: getBundles } = itemsStore;
const { popup } = usePopup();

const loading = ref(false);
const bundles = ref([
  {
    id: "50KUSD25",
    title: 'BUNDLE 1',
    totalAllocation: 50,
    accounts: [
      {
        size: 50,
        currency: 'USD',
        payout: 'Monthly',
        split: 80,
        drawdown: 5,
        type: "custom"
      },
      {
        size: 25,
        currency: 'USD',
        payout: 'Monthly',
        split: 95,
        drawdown: 5,
        type: "bundle"
      }
    ],
    price: 347,
    bundle_price: 299,
    note: "BUNDLE1-FEB2025",
  },
  {
    id: "100KUSD50",
    title: 'BUNDLE 2',
    totalAllocation: 100,
    accounts: [
      {
        size: 100,
        currency: 'USD',
        payout: 'Monthly',
        split: 80,
        drawdown: 5,
        type: "custom"
      },
      {
        size: 50,
        currency: 'USD',
        payout: 'Monthly',
        split: 95,
        drawdown: 5,
        type: "bundle"
      }
    ],
    price: 642,
    bundle_price: 512,
    note: "BUNDLE2-FEB2025",
  }
]);
const untilDate = new Date('2025-02-25 23:59:59');
const daysDiff = ref('00');
const hoursDiff = ref('00');
const minDiff = ref('00');
const secondsDiff = ref('00');
const boundled = ref(false);

onMounted(async () => {
  // if time passed, redirect to buy-account page
    if (new Date() > untilDate) {
       router.replace('/buy-account');
    }

  getTimeDiff();
  setInterval(getTimeDiff, 1000);

  if (!boundled.value) {
    const _bundles = await getBundles();
    console.log(_bundles)
    bundles.value = _bundles.map((bundle, i) => {
      const itemName = bundle.items[0].item;
      const totalAllocation = itemName.substring(0, itemName.indexOf('K'));
      return {
        id: bundle.id,
        title: bundles.value[i].title,
        totalAllocation,
        accounts: bundle.items.map(item => {
          const currency = item.item.substring(item.item.indexOf('K') + 1, item.item.indexOf('K') + 4);
          const split = item.item.substring(item.item.length - 2)
          return {
            size: item.size / 1000,
            currency,
            payout: item.payout_type,
            split,
            drawdown: item.max_daily_drawdown,
            type: item.type
          }
        }),
        note: bundle.id,
        price : bundles.value[i].price,
        bundle_price : bundle.price
      };
    });
  }
  boundled.value = true;

  setTimeout(() => {
    $('#bundlesCarousel .carousel-control-prev').click(() => {
      document.querySelector('.carousel-inner').scrollTo({
        left: 0,
        behavior: 'smooth'
      });
    });
    $('#bundlesCarousel .carousel-control-next').click(() => {
      const $last = $('#bundlesCarousel .carousel-item').last();
      document.querySelector('.carousel-inner').scrollTo({
        left: $last.offset().left,
        behavior: 'smooth'
      });
    });
  }, 1000);
});

const getTimeDiff = () => {
  const currentDate = new Date();
  const timeLeft = untilDate.getTime() - currentDate.getTime();
  if (timeLeft <= 0) {
    router.replace('/buy-account');
  }

  let delta = Math.abs(timeLeft) / 1000;

  const days = Math.floor(delta / 86400).toString();
  daysDiff.value = days.length === 1 ? `0${days}` : days;
  delta -= Number(days) * 86400;

  const hours = (Math.floor(delta / 3600) % 24).toString();
  hoursDiff.value = hours.length === 1 ? `0${hours}` : hours;
  delta -= Number(hours) * 3600;

  const min = (Math.floor(delta / 60) % 60).toString();
  minDiff.value = min.length === 1 ? `0${min}` : min;

  const sec = Math.floor(delta % 60).toString();
  secondsDiff.value = sec.length === 1 ? `0${sec}` : sec;
}

const saveOnChookies = async (note) => {
    console.log('entro')
  const currentDate = new Date();
  const expirationDate = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);
  cookies.set('bundle', note, { expires: expirationDate });

  loading.value = true;
  await addBundleToCart(note)
  loading.value = false;

  router.push('/checkout');
}

const scrollTo = note => {
  const el = document.getElementById(`bundle-${note}`);
  window.scrollTo({
    top: el.offsetTop - 100,
    behavior: 'smooth'
  });

  el.classList.add('pulse');
  setTimeout(() => {
    el.classList.remove('pulse');
  }, 2000);
}

const popupContent = ({
  template: ``,
});
const showInfoPopup = () => {
  popup({
    body: popupContent.template,
  })
}

</script>
<style scoped lang="scss">
.bg {

  background: linear-gradient(200.9deg, #110E32 7.24%, #01061D 80.23%) !important;
  padding: 140px 100px;

}

@media (max-width: 768px) {
  .bg {
    padding: 60px 16px;
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;


  h1 {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 49px;
    display: inline;
    color: #FFF2ED;
    text-shadow: 1px 0px 10px #FF6363;
  }

  @media (min-width: 1024px) {
    .hidden-desktop {
      display: none;
    }
  }

  @media (max-width: 768px) {
    h1 {
      margin-top: 0px;
      font-size: 33.7px;
      letter-spacing: 2px;
    }

    .hidden-mobile {
      display: none;
    }

    .subtitle-mobile {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #FFFFFF;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .primary {
      color: #FF6363;
    }
  }
}

.step {
  color: #FF9571;
  font-size: 14px;
  font-weight: 400;
  line-height: 56px;
  margin-bottom: 4px;
  display: none;

  @media (min-width: 768px) {
    font-size: 28px;
    line-height: 56px;
    display: block;
  }

  .orange-title {
    font-size: 32px;
    font-weight: 500;
    line-height: 56px;
  }

  .white-title {
    color: rgba(255, 255, 255, 0.93);
    font-size: 28px;
  }
}

.text {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: rgba(255, 255, 255, 0.60);
  display: none;
  margin: 0px;

  @media (min-width: 768px) {
    display: block;
    line-height: 30px;
  }
}

.text-strong {
  font-size: 20px;
  font-weight: 600;
}

small {
  font-size: 14px;
  font-weight: 300;
  line-height: 20.56px;
  color: rgba(255, 255, 255, 0.87);
  display: none;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 23.5px;
    display: block;
  }
}

.values {
  display: none;
  justify-content: space-evenly;
  font-size: 20px;
  font-weight: 500;
  line-height: 29.38px;
  gap: 12px;
  margin-bottom: 28px;
  align-items: center;
  text-transform: uppercase;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    display: flex;
  }

  p {
    background: linear-gradient(180.46deg, rgba(110, 124, 255, 0.03) 0.4%, rgba(23, 19, 83, 0.3) 150.44%);
    font-size: 38px;
    font-weight: 500;
    line-height: 55.81px;
    color: rgba(255, 104, 68, 1);
    padding: 5px 24px;
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px rgba(92, 88, 88, 0.25) inset;
    position: relative;
    margin-bottom: 0px;
    width: 100%;
    z-index: 998;

    @media (min-width: 768px) {
      width: unset;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 8px;
      padding: 2px;
      background: linear-gradient(180deg, rgba(102, 102, 102, 0.6) 0%, rgba(212, 212, 212, 0) 100%),
        linear-gradient(248.52deg, rgba(151, 171, 255, 0.6) 13.21%, rgba(173, 173, 173, 0.3) 44.76%, #97ABFF 65.26%, #24208F 72.35%);
      -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask-composite: exclude;
      -webkit-mask-composite: destination-out;
    }
  }
}

.limited-offer {
  background: linear-gradient(180deg, rgba(31, 25, 124, 0.6) -116.3%, #100D3F 100%);
  backdrop-filter: blur(30px);
  border-radius: 36px;
  display: flex;
  padding: 40px 16px;
  margin-top: 26px;
  position: relative;
  margin-bottom: 40px;
  width: 100%;

  @media (min-width: 768px) {
    margin-top: 60px;
    padding: 45px 50px;
    margin-bottom: calc(60px - 24px);
    width: 100vw;

    .countdown-content {
      display: flex;
      align-items: center;
    }

    .title-limit {
      font-family: 'Heebo';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 35px;
      text-align: center;
      letter-spacing: 0.18em;
      color: #FFC0A8;
      margin-right: 40px;
    }
  }

  @media (min-width: 768px) {
    .hidden-desktop {
      display: none;
    }
  }

  .countdown {
    background: rgba(138, 135, 135, 0.1);
    border-radius: 16px;
    padding: 10px 15px;
    width: 78px;
    height: 68px;
    margin-right: 12px;
    min-width: 78px;

    @media (min-width: 768px) {
      margin-right: 12px;
      padding: 5px 10px;
    }

    &:last-of-type {
      margin-right: 0;
    }

    span {
      font-family: Heebo;
      font-size: 24px;
      font-weight: 600;
      line-height: 28px;
      color: rgba(255, 255, 255, .93);
    }

    p {
      font-family: Heebo;
      color: rgba(255, 255, 255, .93);
      font-size: 14px;
      font-weight: 300;
      line-height: 28px;
      text-transform: uppercase;
      margin-bottom: 0;
      margin-top: 4px;
    }
  }
}

.box-gradient {
  display: flex;
  align-items: center;
  margin-left: 40px;
  position: relative;
  padding: 16px 32px;
  background-color: transparent;
  border-radius: 5px;
  color: inherit;
  text-decoration: none;
  z-index: 1;
  border: none;
}

.box-gradient::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 5px;
  padding: 1px;
  background: linear-gradient(142.67deg, #FFB7A7 8.58%, #FB8469 25.6%, #FB8469 35.86%, #7D5FFC 66.99%, #FB8469 98.31%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: -1;
}

.btn-nostyle {
  border: none;
  background: transparent;
  color: rgba(255, 255, 255, .93);
  font-size: 16px;
  font-weight: 500;

  span{
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.6);
  }
}


.bundles {
  font-family: Heebo;
  display: none;
  justify-content: center;
  flex-direction: column;
  color: rgba(255, 255, 255, 0.8);

  @media (min-width: 768px) {
    flex-direction: row;
    display: flex;
    gap: 130px;
    max-width: 80%;
    width: 100vw;
    margin-top: 25px;
  }


  .table-shadow-0::before {
    content: "";
    position: absolute;
    width: 350px;
    height: 537px;
    background: rgba(50, 74, 170, 0.4);
    filter: blur(50px);
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  }

  .table-shadow-1::before {
    content: "";
    position: absolute;
    width: 350px;
    height: 537px;
    background: rgba(130, 74, 55, 0.4);
    filter: blur(50px);
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .bundle-item {

    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: radial-gradient(70.76% 70.76% at 50% 50%, #0B0C2F 0%, #131548 100%);
    padding: 32px 0px 0px 0px;
    border-radius: 16px;
    padding-top: 0;
    z-index: 1;

    @media (min-width: 768px) {
      width: 50%;
      height: fit-content;

    }

    h2 {
      position: absolute;
      top: -1.5rem;
      left: 50%;
      transform: translateX(-50%);
      background-color: #231F90;
      border-radius: 4px;
      padding: 0.1rem .6rem;
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      text-align: center;
      margin-bottom: 16px;
    }

    .more-account {
      position: absolute;
      top: 1.5rem;
      left: 67%;
      transform: translateX(-50%);
      font-size: 36px;
      font-weight: 400;
      text-align: center;
    }

    .subtitle {
      font-size: 18px;
      font-weight: 300;
      line-height: 28px;
      text-align: center;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 16px;
      margin-bottom: 20px;
      padding: 0 10px;

      span {
        font-weight: 700;
        color: rgba(255, 104, 68, 1);
      }
    }

    .total-allocation {
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      text-align: center;
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      p {
        margin-bottom: 0;
      }

      span {
        font-size: 48px;
        font-weight: 600;
        line-height: 40px;
        text-align: left;
        color: rgba(255, 104, 68, 1);
      }
    }

    .plus {
      color: rgba(255, 255, 255, 1);
      font-weight: 500;
      font-size: 36px;
      margin: 0;
      text-align: center;
    }

    .account-table {
      border: 1px solid rgba(251, 132, 105, 0.5);
      padding: 0;
      gap: 16px;
      border-radius: 8px;
      list-style: none;
      margin-bottom: 0;


      .account-title {
        background: rgba(255, 255, 255, 0.15);
        font-family: Heebo;
        font-size: 14px;
        font-weight: 500;
        line-height: 28px;
        text-align: center;
        padding: 11px;

        &.second {
          background: linear-gradient(246.65deg, rgba(255, 104, 68, 0.6) -2.03%, rgba(255, 104, 68, 0.6) 0.04%, #161254 67.14%);

          span {
            font-size: 18px;
            font-weight: 700;
            line-height: 26.44px;
          }
        }
      }

      li:not(.account-title) {
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
        text-align: center;
        padding: 0 24px;
        margin: 5px 0;
        display: flex;
        justify-content: space-between;
        color: rgba(255, 255, 255, 0.6);

        p {
          margin-bottom: 0;

          &:before {
            content: "• ";
            color: rgba(255, 104, 68, 1);
          }
        }

        span {
          font-size: 14px;
          font-weight: 400;
          color: rgba(255, 255, 255, 1);

          &.primary {
            color: rgba(255, 104, 68, 1);
          }
        }
      }
    }

    .prices {
      font-family: 'Heebo';
      background: radial-gradient(50% 50% at 50% 50%, rgba(24, 26, 81, 0.6) 0%, #0A0B2D 100%);
      display: flex;
      flex-direction: column;
      padding-top: 20px;
      border-radius: 0 0px 16px 16px;

      .box-regular {
        border-bottom: solid rgba(255, 255, 255, 0.10) 1px;
      }

      .reg-title {
        font-size: 16px;
        line-height: 16px;
        font-weight: 300;
        text-align: center;
        color: rgba(255, 255, 255, 0.87) !important;
        text-align: left;
        margin: 0px;
      }

      .reg-subtitle {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        text-align: center;
        color: rgba(255, 255, 255, 0.87);
        text-align: left;
        margin: 5px 0px;
      }

      .crossed-out {
        position: relative;
        display: inline-block;
        font-size: 24px;
        line-height: 39px;
        font-weight: 500;
        text-align: center;
        color: rgba(255, 255, 255, 0.60);
        padding: 0px;
      }

      .crossed-out::after {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        width: 120%;
        height: 2px;
        background-color: #FF9571;
        transform: rotate(152deg);
      }

      .bundle-title {
        font-size: 18px;
        font-weight: 500;
        line-height: 46px;
        color: #95AAFF;
        text-align: left;
      }

      .bundle-price {
        font-size: 32px !important;
        font-weight: 600;
        line-height: 46px;
        text-align: center;
        color: rgba(255, 255, 255, 0.93);
      }

      .btn {
        margin-bottom: 19px;
      }
    }
  }
}

.btn {
  padding: 10px 8px 10px 8px;
  border-radius: 8px;
  background: rgba(255, 104, 70, 1);
  font-size: 16px;
  font-weight: 600;
  line-height: 23.5px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 1);
}

.bundles-mobile {
  display: inline-flex;
  gap: 16px;
  margin-bottom: 60px;
  width: 100%;
  z-index: 19;
  margin-top: -25px;

  .bundle-mobile-item {
    background: linear-gradient(180.46deg, rgba(60, 70, 158, 0.03) 0.4%, rgba(23, 19, 83, 0.3) 150.44%);
    border-radius: 8px;
    position: relative;
    width: 300px;
    z-index: -20;

    .carousel-item {
      position: relative;
      z-index: -1;
    }

    .bundle-flag {
      position: absolute;
      top: -1rem;
      left: 50%;
      transform: translateX(-50%);
      background-color: #231F90;
      border-radius: 4px;
      padding: 0.1rem .6rem;
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      text-align: center;
      margin-bottom: 16px;
      z-index: 10;
    }

    .more-account {
      position: absolute;
      top: 1.5rem;
      left: 67%;
      transform: translateX(-50%);
      font-size: 36px;
      font-weight: 400;
      text-align: center;
    }


    .prices {
      font-family: 'Heebo';
      background: radial-gradient(50% 50% at 50% 50%, rgba(24, 26, 81, 0.6) 0%, #0A0B2D 100%);
      display: flex;
      flex-direction: column;
      padding-top: 20px;
      border-radius: 0 0px 16px 16px;

      .box-regular,
      .box-bundle {
        border-bottom: solid rgba(255, 255, 255, 0.10) 1px;
        display: flex !important
      }

      .reg-title {
        font-size: 16px;
        line-height: 16px;
        font-weight: 300;
        text-align: center;
        color: rgba(255, 255, 255, 0.87) !important;
        text-align: left;
        margin: 0px;
      }

      .reg-subtitle {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        text-align: center;
        color: rgba(255, 255, 255, 0.87);
        text-align: left;
        margin: 5px 0px;
      }

      .crossed-out {
        position: relative;
        display: inline-block;
        font-size: 24px;
        line-height: 39px;
        font-weight: 500;
        text-align: center;
        color: rgba(255, 255, 255, 0.60);
        padding: 0px;
      }

      .crossed-out::after {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        width: 120%;
        height: 2px;
        background-color: #FF9571;
        transform: rotate(152deg);
      }

      .bundle-title {
        font-size: 18px;
        font-weight: 500;
        line-height: 46px;
        color: #95AAFF;
        text-align: left;
      }

      .bundle-price {
        font-size: 32px !important;
        font-weight: 600;
        line-height: 46px;
        text-align: center;
        color: rgba(255, 255, 255, 0.93);
      }

      .btn {
        margin-bottom: 19px;
      }

    }

    @media (min-width: 768px) {
      display: none;

    }
  }
}

.size-account {
  font-size: 30px;
  font-weight: 500;
  margin: 0 20px;
}

.bg-tbody {
  background-color: #2B2A52 !important;
  font-size: 14px;
  color: rgba(255, 255, 255, .93);

  td {
    padding: 12px !important;
  }

  td:first-child {
    text-align: left;
  }

  td:nth-child(1),
  td:nth-child(2) {
    border-right: 1px solid rgba(255, 255, 255, .10);
  }

  td:nth-child(2),
  td:nth-child(3) {
    width: 33% !important;
    font-size: 16px;
    font-weight: 500;
  }

  .primary {
    color: #FF6844;
    font-size: 16px;
    font-weight: 500;
  }
}

table {
  background: radial-gradient(70.76% 70.76% at 50% 50%, #0B0C2F 0%, #131548 100%);
  border-radius: 16px;

  th {
    padding-top: 16px;
  }
}


.ribbon-cell {
  position: relative;
  background: #FF6844;
  padding: 8px;
  text-align: center;
  font-weight: bold;
  z-index: 1;
}

.ribbon-effect::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -9px;
  width: 80px;
  height: 100%;
  background-color: #FF6844;
  transform: translateY(-50%) skewX(20deg);
  z-index: -1;
}

.ribbon-effect::before {
  content: "";
  position: absolute;
  bottom: -14px;
  right: -14px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 20px solid #E5856E80;
  transform: rotate(25deg);
  z-index: -1;
}



::-webkit-scrollbar {
  display: none;
}

.carousel-inner {
  display: flex;
  overflow-x: scroll;
  padding-top: 80px;
}

.carousel-item {
  flex: 0 0 90%;
  margin-right: 5.5%;
  display: flex;
  flex-direction: column;
}

.arrow-img {
  width: 40px;
  height: 40px;

  margin: 24px;

  background: rgba(255, 255, 255, 0.08);
  border-radius: 4px;

  &.right {
    transform: rotate(180deg);
  }
}

.carousel-control-next,
.carousel-control-prev {
  opacity: 1;
}

.pulse {
  animation: pulse 1s infinite ease-in-out alternate;
}

@keyframes pulse {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.4);
  }
}
/* Tablet Style*/
@media (min-width: 768px) and (max-width:1024px) {

  .header{
    align-items: center;
   
    h1{
      font-size: 40px;
    }
    .step {
      font-size: 24px;
    }
    .orange-title{
      font-size: 24px;
    }
  }
  .bundles{
    gap: 30px;
    max-width: 100%;
    width: 100vw;
    margin-top: 35px;
    margin-bottom: 35px;
    .size-account{
      font-size: 25px;
    }
  }
  .box-regular{
    display: flex !important;
  }

  .title-limit{
    margin-right: 0px !important; 
  }

  .countdown-mobile{
    width: 100% !important;
  }  
  
}

@media (max-width: 768px) {
  .countdown-mobile{
    display: flex !important;
  }
  .countdown-content {
    display: flex;
    flex-direction: column;
  }

  .title-limit {
    font-family: 'Heebo';
    font-weight: 600;
    margin-top: 8px;
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FF9571;
  }

  .count-mobile {
    display: flex !important;
    flex-direction: row;
  }

  .limited-offer {
    margin-top: 0px;
    padding: 8px 24px 15px 24px;
    margin-bottom: 12px;
  }

  .limited-offer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 30px;
    padding: 2px;
    background: linear-gradient(9.31deg, rgba(151, 171, 255, 0.1) 0.23%, rgba(173, 173, 173, 0.3) 10.7%, #97ABFF 18.49%, rgba(38, 35, 102, 0.6) 25.33%),
      linear-gradient(14.64deg, rgba(38, 35, 102, 0.6) 38.37%, rgba(173, 173, 173, 0.2) 48.93%, rgba(10, 8, 46, 0.6) 62.2%);
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: destination-out;
    z-index: 0;
  }
  .back-link{
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFE2D4;
  }
}
</style>
