
export const getSize = (value) => {
    return typeof value === 'number' ? `${value}px` : value
}

/** Get the distance of the element from the top/left of the page */
export const getOffset = (elem) => {
    const doc = document.documentElement
    const body = document.body
    const rect = elem.getBoundingClientRect()
    const offset = {
        y: rect.top + (window.pageYOffset || doc.scrollTop) - (doc.clientTop || body.clientTop || 0),
        x:
            rect.left + (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || body.clientLeft || 0),
    }
    return offset
}

/**
 * Get the position of the mouse/finger in the element
 * @param e Trigger event
 * @param elem Container element
 * @param isReverse From the right/bottom
 */
export const getPos = (
    e,
    elem,
    isReverse,
) => {
    const event = 'targetTouches' in e ? e.targetTouches[0] : e
    const offset = getOffset(elem)
    const posObj = {
        x: event.pageX - offset.x,
        y: event.pageY - offset.y,
    }
    return {
        x: isReverse ? elem.offsetWidth - posObj.x : posObj.x,
        y: isReverse ? elem.offsetHeight - posObj.y : posObj.y,
    }
}

const KEY_CODE = {
    PAGE_UP: 33,
    PAGE_DOWN: 34,
    END: 35,
    HOME: 36,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
}

export const getKeyboardHandleFunc = (
    e,
    params
) => {
    if (params.hook) {
        const result = params.hook(e)
        if (typeof result === 'function') return result
        if (!result) return null
    }
    switch (e.keyCode) {
        case KEY_CODE.UP:
            return (i) => (params.direction === 'ttb' ? i - 1 : i + 1)
        case KEY_CODE.RIGHT:
            return (i) => (params.direction === 'rtl' ? i - 1 : i + 1)
        case KEY_CODE.DOWN:
            return (i) => (params.direction === 'ttb' ? i + 1 : i - 1)
        case KEY_CODE.LEFT:
            return (i) => (params.direction === 'rtl' ? i + 1 : i - 1)

        case KEY_CODE.END:
            return () => params.max
        case KEY_CODE.HOME:
            return () => params.min

        case KEY_CODE.PAGE_UP:
            return (i) => i + 10
        case KEY_CODE.PAGE_DOWN:
            return (i) => i - 10

        default:
            return null
    }
}