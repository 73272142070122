<template>
    <div id="main-wrapper " >
        <toastMessage v-if="showToast" :status="copiedStatus" :messageToast="message" :subMessToast="subMessage"/>

<!--        <Spinner v-if="loading" />-->

        <header class="topbar backgroundImage"  :class="modalOn||loading?'opacity-25':''"  >
            <Navbar ref="navbarRef" />
        </header>

        <aside class="left-sidebar backgroundImage"  :class="modalOn||loading?'opacity-25':''" >
            <div class="scroll-sidebar" style="background-color: rgba(255, 255, 255, 0.1);">
                <Sidebar @rank="watchRank" />
            </div>
        </aside>

        <div class="page-wrapper backgroundImage" :class="modalOn || showRank?'opacity-25':''" s>
            <PriceCarousel />

            <div class="container-fluid">
                <bannerKyc />
                <!--modal PROMO-->

                <!-- Button trigger modal -->
                <button id="promo" type="button" class="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    PROMO
                </button>

                <!-- Modal -->
                <div class="modal fade p-0" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog d-lg-none" >
                        <div class="modal-content" style="background-color: transparent!important;">
                        <div class="modal-body" v-if="user?.language">
                            <button style="background-color:transparent!important;color: white!important; position: absolute; left: 93%;" id="closePromo" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            <img v-if="promo"  @click="goBuy()" class="img-fluid" :src="'assets/images/doublepay-'+ user.language +'.png'" alt="">
                            <img v-else @click="goBuy()" class="img-fluid" :src="'assets/images/custom-promo-'+ user.language +'.png'" alt="">
                        </div>
                        <div class="modal-body" v-else>
                            <button style="background-color:transparent!important;color: white!important; position: absolute; left: 93%;" id="closePromo" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            <img v-if="promo" @click="goBuy()" src="assets/images/doublepay-en.png" alt="">
                            <img v-else @click="goBuy()" src="assets/images/custom-promo-en.png" alt="">
                        </div>
                        </div>
                    </div>
                    <div class="modal-dialog d-none d-lg-block" style="max-width: 40%!important;">
                        <div class="modal-content" style="background-color: transparent!important; text-align: center;">
                        <div class="modal-body" v-if="user?.language">
                            <button style="background-color:transparent!important;color: white!important; position: absolute; right: 5px;padding: 10px" id="closePromo" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            <img v-if="promo"  @click="goBuy()" class="w-100" :src="'assets/images/doublepay-'+ user.language +'.png'" alt="">
                            <img v-else @click="goBuy()" class="w-100" :src="'assets/images/custom-promo-'+ user.language +'.png'" alt="">
                        </div>
                        <div class="modal-body" v-else @click="goBuy()">
                            <button style="background-color:transparent!important;color: white!important; position: absolute; right: 5px; padding: 10px" id="closePromo" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            <img v-if="promo" @click="goBuy()" src="assets/images/doublepay-en.png" alt="">
                            <img v-else @click="goBuy()" src="assets/images/custom-promo-en.png" alt="">
                        </div>
                        </div>
                    </div>
                </div>
                <!-- END modal PROMO-->

                <!--- Start Content --->
                <div class="w-100 mx-auto row " style="margin-bottom: 100px;"  >
                <template v-if="areAccountsLoaded && accountActive.length !== 0">
                    <!--Profile info-->
                    <div class="row">
                        <div class="col-12 ">
                        <div class="my-2">
                            <div class="">
                                <div class="d-flex align-items-center gap-3 mt-2" style="display: flex!important;">
                                    <h1 class="text-white fw-bold">Dashboard</h1>
                                    <i @click="showDelay()" class="curPointer align-self-start mdi mdi-information-outline"></i>
                                </div>
                                <div v-if="delay" class="text-white">
                                    <span class=" fw-bold text-danger">{{ $t('Warning') }}:</span> {{ $t('profile-delay') }}
                                </div>
                            </div>
                        </div>
                    </div>

                    </div>
                    <!--4 box info trading-->
                    <template v-if="true">
                        <div class="row d-xl-flex justify-content-between p-0 m-auto m-lg-0 closest-payout-date" v-if="!onlyOnDemandAccounts">
                            <div class=" col-12 ">
                                <div class="card " >
                                    <div class="card-body flex-column active">
                                        <div class=" mt-3">
                                            <div class="px-0" v-if="accountActive && accountActive.length > 0 && nextPayout">
                                                <h2 v-if="nextPayout.includes('Invalid')" class="my-auto fw-bold">{{ $t('profile-noDate') }}</h2>
                                                <h2 v-else class="my-auto fw-bold text-primary">{{ formatData(nextPayout.slice(nextPayout.indexOf(' '))) }}</h2>
                                            </div>
                                            <div class="px-0 skeleton" v-else>
                                                <h2 class="my-auto fw-bold text-white"></h2>
                                            </div>
                                        </div>
                                        <div class="d-flex no-block al m-r-15ign-items-center">
                                            <div class="m-t-10">
                                                <h6 class="text-white font-medium">{{ $t('profile-DatePayout') }}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <!--Stats full screen-->
                    <div class=" row d-none d-xl-flex justify-content-between p-0 m-auto m-lg-0" >
                    <div class="col-sm-3 ">
                        <div class="card lossBox"  id="card-primary">
                            <div class="card-body flex-column active">
                                <div class=" mt-3">
                                    <div class="px-0">
                                        <h3 v-if="profitAccounts > 0" class="fw-bold text-primary">{{ (profitAccounts/accountActive.length).toFixed(2)}}</h3>
                                        <h3 v-else-if="profitAccounts === 0" class="fw-bold text-primary">-</h3>
                                        <h3 v-else class="fw-bold text-primary">
                                            <i class="fa fa-spinner fa-spin fa-2xl text-white"></i>
                                        </h3>
                                    </div>
                                </div>
                                <div class="d-flex no-block al m-r-15ign-items-center">
                                    <div class="m-t-10">
                                        <h5 class="text-white font-medium">{{ $t('profile-APF') }}</h5>
                                        <p class='text-dark'>{{ $t('profile-ActAcc') }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-3 "  >
                        <div class="card expBox"   id="card-aqua">
                            <div class="card-body flex-column active">
                                <div class="text-primary mt-3">
                                    <div class="px-0">
                                        <h3 v-if="expAccounts < 0 || expAccounts > 0" class="fw-bold">{{ currSymbol }} {{ (expAccounts/accountActive.length).toFixed(2)}}</h3>
                                        <h3 v-else-if="expAccounts === 0" class="fw-bold text-primary">-</h3>
                                        <h3 v-else class="fw-bold">
                                            <i class="fa fa-spinner fa-spin fa-2xl text-white"></i>
                                        </h3>
                                    </div>
                                </div>
                                <div class="d-flex no-block al m-r-15ign-items-center">
                                    <div class="m-t-10">
                                        <h5 class="text-white font-medium">{{ $t('profile-AE') }}</h5>
                                        <p class='text-dark'>{{ $t('profile-ActAcc') }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-3 " >
                        <div class="card winBox"  id="card-pink">
                            <div class="card-body flex-column active">
                                <div class=" mt-3">
                                    <div class="px-0">
                                        <h3 v-if="wonTradesPercent > 0" class="fw-bold text-primary">{{ (wonTradesPercent/accountActive.length).toFixed(2)}} %</h3>
                                        <h3 v-else-if="wonTradesPercent === 0" class="fw-bold text-primary">-</h3>
                                        <h3 v-else class="fw-bold text-primary">
                                            <i class="fa fa-spinner fa-spin fa-2xl text-white"></i>
                                        </h3>
                                    </div>
                                </div>
                                <div class="d-flex no-block al m-r-15ign-items-center">
                                    <div class="m-t-10">
                                        <h5 class="text-white font-medium">{{ $t('profile-AWR') }}</h5>
                                        <p class='text-dark'>{{ $t('profile-ActAcc') }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-3" >
                        <div class="card totalBox" id="card-azure">
                            <div class="card-body flex-column active">
                                <div class=" mt-3">
                                    <div class=" px-0">
                                        <h3 v-if="rr > 0" class="fw-bold text-primary">1 : {{ (rr/accountActive.length).toFixed(2) }}</h3>
                                        <h3 v-else-if="rr === 0" class="fw-bold text-primary">-</h3>
                                        <h3 v-else class="fw-bold text-primary">
                                            <i class="fa fa-spinner fa-spin fa-2xl text-white"></i>
                                        </h3>
                                    </div>
                                </div>
                                <div class="d-flex no-block al m-r-15ign-items-center">
                                    <div class="m-t-10">
                                        <h5 class="text-white font-medium">{{ $t('profile-ARR') }}</h5>
                                        <p class='text-dark'>{{ $t('profile-ActAcc') }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                  <!--Fine stas per full screen-->

                  <!--Stats carousel mobile-->
                  <div id="carouselExampleInterval" class="carousel slide d-sm-block d-xl-none " data-bs-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active" data-bs-interval="10000">
                                <div class="card lossBox"   id="card-primary">
                                    <div class="card-body flex-column active">
                                        <div class=" mt-3">
                                            <div class="px-0">
                                                <h3 v-if="profitAccounts" class="fw-bold text-primary">{{ (profitAccounts/accountActive.length).toFixed(2)}}</h3>
                                                <h3 v-else class="fw-bold text-primary">
                                                    <i class="fa fa-spinner fa-spin fa-2xl text-white"></i>
                                                </h3>
                                            </div>
                                        </div>
                                        <div class="d-flex no-block al m-r-15ign-items-center">
                                            <div class="m-t-10">
                                                <h5 class="text-white font-medium">{{ $t('profile-APF') }}</h5>
                                                <p class='text-dark'>{{ $t('profile-ActAcc') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="2000">
                                <div class="card expBox"  id="card-aqua">
                                    <div class="card-body flex-column active">
                                        <div class="text-primary mt-3">
                                            <div class="px-0">
                                                <h3 v-if="expAccounts" class="fw-bold">{{ currSymbol }} {{ (expAccounts/accountActive.length).toFixed(2)}}</h3>
                                                <h3 v-else class="fw-bold">
                                                    <i class="fa fa-spinner fa-spin fa-2xl text-white"></i>
                                                </h3>
                                            </div>
                                        </div>
                                        <div class="d-flex no-block al m-r-15ign-items-center">
                                            <div class="m-t-10">
                                                <h5 class="text-white font-medium">{{ $t('profile-AE') }}</h5>
                                                <p class='text-dark'>{{ $t('profile-ActAcc') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div class="card winBox"  id="card-pink">
                                    <div class="card-body flex-column active">
                                        <div class=" mt-3">
                                            <div class="px-0">
                                                <h3 v-if="wonTradesPercent" class="fw-bold text-primary">{{ (wonTradesPercent/accountActive.length).toFixed(2)}} %</h3>
                                                <h3 v-else class="fw-bold text-primary">
                                                    <i class="fa fa-spinner fa-spin fa-2xl text-white"></i>
                                                </h3>
                                            </div>
                                        </div>
                                        <div class="d-flex no-block al m-r-15ign-items-center">
                                            <div class="m-t-10">
                                                <h5 class="text-white font-medium">{{ $t('profile-AWR') }}</h5>
                                                <p class='text-dark'>{{ $t('profile-ActAcc') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div class="card totalBox" id="card-azure">
                                    <div class="card-body flex-column active">
                                        <div class=" mt-3">
                                            <div class=" px-0">
                                                <h3 v-if="rr" class="fw-bold text-primary">1 : {{ (rr/accountActive.length).toFixed(2) }}</h3>
                                                <h3 v-else class="fw-bold text-primary">
                                                    <i class="fa fa-spinner fa-spin fa-2xl text-white"></i>
                                                </h3>
                                            </div>
                                        </div>
                                        <div class="d-flex no-block al m-r-15ign-items-center">
                                            <div class="m-t-10">
                                                <h5 class="text-white font-medium">{{ $t('profile-ARR') }}</h5>
                                                <p class='text-dark'>{{ $t('profile-ActAcc') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">{{ $t('Prev') }}</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">{{ $t('Next') }}</span>
                        </button>
                    </div>
                    <!--End Stats carousel mobile-->
                    <!--end 4 box-->
                    <!--Area Chart-->
                    <div v-if="areAccountsLoaded" class="row m-auto d-none d-md-block mt-2 px-2" id="chart">
                        <div class="d-flex flex-row gap-2">
                            <p class="account-balance__title" v-for="(balance, currency) in totalAccountsBalance" :key="currency">
                                <small>{{ $t('profile-AccsBalance') }} ({{ currency }})</small>
                                <span>{{ $formatAmount(balance, currency) }}</span>
                            </p>
                        </div>
<!--                        <div class="loading" v-if="!showAreaChart">-->
<!--                            {{ $t('Load') }}<span class="dot dot1">.</span><span class="dot dot2">.</span><span class="dot dot3">.</span>-->
<!--                        </div>-->
                        <div class="card">
                            <AreaChart v-if="showAreaChart"/>
                        </div>
                    </div>
                    <div v-else-if="!areAccountsLoaded" class="row m-auto d-none d-md-block mt-2 px-2" id="chart">
                        <div class="card">
                            <div class="w-100 h-100 text-center p-30">
                                <i class="fa fa-spinner fa-spin fa-5xl"></i>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="areAccountsLoaded && !accountActive.length" class="row m-auto d-none d-md-block mt-2 px-2" id="chart">
                        <div class="card">
                            <h5 class="text-start my-2 text-white">{{ $t('profile-AccsBalance') }}</h5>
                            <p>{{ $t('profile-NodataChart') }}</p>
                        </div>
                    </div>

                    <div v-if="accountActive && accountActive.length!=0" class="d-sm-block d-md-none text-center mb-5" >
                        <a href="/chartareamobile" class="btn btn-outline-primary w-100">{{ $t('ShowChart') }}</a>
                    </div>
                    <!--End areaChart-->
                </template>
                    <!--List Accounts-->
                    <div class="col-12 p-0 mt-3" id="active" v-if="(accountActive || []).length > 0 || (accountInactive || []).length > 0">
                       <div class="w-100">
                        <div class=" ms-2 card-body p-0">
                            <div class=" d-flex justify-content-between align-items-center card-body px-0" style="display: flex!important;">
                                <div>
                                    <h3 class="accounts-title">Accounts</h3>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="checkbox" :checked="user.hide_inactive_accounts" @change="hideInactiveAccounts">
                                            <span class="form-check-sign">
                                                <span class="check">{{ $t('profile-HideInact') }}</span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div class=" rounded"  >
                                    <div class="d-flex gap-5 justify-content-center h-100 align-items-center mt-2"  >
                                        <div class=" w-100">
                                            <div class="card-body d-flex align-items-center justify-content-center" >
                                                <a  href="/buy-account" class="btn btn-ofp text-center"  ><i class="fa fa-plus"></i> {{ $t('NewAcc') }}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-start my-3 flex-wrap overflow-auto">
                                <table class="table accounts-table" style=" overflow:auto ;">
                                    <thead>
                                        <tr class="text-white fw-semibold">
                                            <th scope="col">{{ $t('AccNumb') }}</th>
                                            <th scope="col">{{ $t('Status') }}</th>
                                            <th scope="col">{{ $t('Password') }}</th>
                                            <th scope="col">{{ $t('Platform') }}</th>
                                            <th scope="col">{{ $t('Server') }}</th>
                                            <th scope="col">{{ $t('Type') }}</th>
                                            <th scope="col">{{ $t('Currency') }}</th>
                                            <th scope="col">{{ $t('InitBalance') }}</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="account in allAccounts" :key="account._id" class="text-white">
                                            <template v-if="loadingAccountIds[account.userID]">
                                                <th scope="row">
                                                    {{ account.platform === 'match-trader' ? account.email : account.username }}
                                                </th>
                                                <th colspan="8" class="text-center">
                                                    <i class="fa fa-spinner fa-spin fa-2xl"></i>
                                                </th>
                                            </template>
                                            <template v-else-if="errorLoadingAccountIds[account.userID]">
                                                <th scope="row">{{ account.username}}</th>
                                                <th colspan="8" class="text-center text-danger">
                                                    {{ $t('profile-ErrorLoadingAccount') }}
                                                </th>
                                            </template>
                                            <template v-else>
                                                <template v-if="account.status === 'active' || account.status === 'migrated' || account.status === 'violated' || (!hide_inactive_accounts && account.status === '0')">
                                                    <th scope="row" :class="{ 'match-trader-row': account.platform === 'match-trader' }" @click="account.platform === 'match-trader' && copyToClipboard(account.email)">
                                                        {{ account.platform === 'match-trader' ? account.email : account.username }}

                                                        <span v-if="account.platform === 'match-trader'" class="copy-credentials">
                                                            <i class="fa fa-copy"></i>
                                                        </span>
                                                    </th>
                                                    <td ><button :class="{'btn-danger': account.status !== 'active'}" :style="{'background-color': (account.status === 'active' ? 'rgb(39, 174, 96)' : 'inline')}" class="text-capitalize text-white rounded-5 btn cur" >{{account.status === '0' ? 'Inactive' : account.status}}</button></td>
                                                    <td>{{account.password}}</td>
                                                    <td class="text-uppercase">
                                                        {{account.platform}}
                                                    </td>
                                                    <td>
                                                        <template v-if="account.platform === 'match-trader' || account.platform === 'ctrader'">
                                                            <a :href="account.server" class="text-ofp" target="_blank" >
                                                                {{ account.server }}
                                                                <font-awesome-icon icon="fa-solid fa-arrow-up-right-from-square" />
                                                            </a>
                                                        </template>
                                                        <template v-else-if="account.platform === 'tradelocker'">
                                                            <a :href="account.server" class="text-ofp me-2" target="_blank">
                                                                {{ account.server }}
                                                                <font-awesome-icon icon="fa-solid fa-arrow-up-right-from-square" />
                                                            </a>
                                                            <span>(Server: OFPD)</span>
                                                        </template>
                                                        <template v-else>
                                                            {{ account.server }}
                                                        </template>
                                                    </td>
                                                    <td>{{account.type_account}}</td>
                                                    <td>{{account.currency}}</td>
                                                    <td>{{ $formatAmount(account.initial_balance, account.currency) }}</td>
                                                    <td>
                                                        <a :href="'/profile/' + account._id + '?currency=' + account.currency" class="card-link btn open btn-outline-ofp"  >
                                                            {{ $t('Open') }}
                                                        </a>
                                                    </td>
                                                </template>
                                            </template>
                                        </tr>
                                    </tbody>
                                </table>
                           </div>
                        </div>
                       </div>
                    </div>
                    <!--End List Accounts-->
                </div>
                <!--- End Content --->
            </div>
        </div>

        <RankModal :showRank="showRank" @close="closeRank"/>

        <Footer :lang="user?.language ?? 'en'" />
    </div>
</template>

<script>
import Sidebar from "../components/Sidebar";
import Navbar from "../components/statics/navbar";
import Footer from "../components/statics/footer";
import AreaChart from "../components/AreaChart";
import PriceCarousel from "../components/PriceCarousel";
import Spinner from '../components/Spinner'
import bannerKyc from '../components/statics/bannerKyc';
import RankModal from "../components/RankModal";
import { mapGetters } from 'pinia';
import { useUserStore } from '../store/user.store';
import toastMessage from '../components/statics/toastMessage.vue'
export default {
    name: "Home",
    components: {
        Footer,
        Navbar,
        Sidebar,
        AreaChart,
        PriceCarousel,
        Spinner,
        bannerKyc,
        RankModal,
        toastMessage,
    },
    setup() {
        const { getAccounts, update } = useUserStore();
        return { getAccounts, update };
    },
    data() {
        const timestamp = new Date().getTime();
        const random = Math.floor(Math.random() * 1000000);
        const key = `${timestamp}-${random}`;

        return {
            profileLetter:'',
            rr:null,
            accountLen:0,
            idAccounts:[],
            accountActive: [],
            accountInactive:[],
            accountIdColl:[],
            client:{},
            account:null,
            openInactive:false,
            loading: true,
            key,
            nextPayout: null,
            minday:0,
            payTotal:'',
            mediaElement:0,
            popupVisible:false,
            profitAccounts: null,
            expAccounts: null,
            badTrades:[],
            wonTradesPercent:null,
            giorno:'',
            currAccounts:[],
            currSymbol:'',
            modalOn:false,
            showAreaChart: false,
            showRank:false,
            delay:false,
            onlyOnDemandAccounts: false,
            loadingAccountIds: {},
            errorLoadingAccountIds: {},
            allAccounts: [],
            hide_inactive_accounts: false,
            copiedStatus: false,
            message: 'OFP Dashboard',
            subMessage: 'Copied to clipboard',
            showToast: false,
            totalAccountsBalance: {},
            promo: false,
        }
    },
    computed: {
        ...mapGetters(useUserStore, ['user']),
        areAccountsLoaded() {
            return Object.values(this.loadingAccountIds).every(loading => !loading);
        }
    },
    watch: {
        user: {
            handler: 'infoAccount',
            deep: true
        }
    },
    methods: {
        checkPromo() {
            const now = new Date();
            const year = new Date().getUTCFullYear();

            const startPromo = new Date(Date.UTC(year, 10, 14, 8, 30));
            const endPromo = new Date(Date.UTC(year, 10, 20, 23, 59));
            if (now >= startPromo && now <= endPromo) {
                this.promo = true;
            }
        },
        copyToClipboard(text) {
            navigator.clipboard.writeText(text);

            this.showToast = true;
            this.copiedStatus = true;
            setTimeout(() => {
                this.showToast = false;
                this.copiedStatus = false;
            }, 3000);
        },
        goBuy() {
            if(this.promo){
                document.getElementById("closePromo").click()
                this.$router.push({ path: '/bundles' })
            }else{
                document.getElementById("closePromo").click()
                this.$router.push({ path: '/buy-account' })
            }
        },
        showDelay() {
            this.delay= !this.delay
        },
        closeRank() {
            this.showRank=false
        },
        watchRank(value) {
            this.showRank=value
        },
        trovaValorePiuFrequente(array) {
            let valoreMassimo = 'USD';
            let conteggioMassimo = 0;
            for (let i = 0; i < array.length; i++) {
                let valore = array[i];
                let conteggio = array.filter((v) => v === valore).length;

                if (conteggio > conteggioMassimo) {
                    valoreMassimo = valore;
                    conteggioMassimo = conteggio;
                }
            }

            let currSymbol = (new Intl.NumberFormat('en-US', { style: 'currency', currency: valoreMassimo }).formatToParts(1)[0].value);
            return currSymbol;
        },
        // get The Profile Informartion
        async infoAccount(user, oldUser) {
            if (!this.user) {
                return;
            }

            if (user._id === oldUser?._id) {
                return;
            }

            try {
                if (this.user.status === 'banned') {
                    this.$cookies.keys().forEach(key => {
                        if (key != 'emailAccount' || key !='language') {
                            this.$cookies.remove(key)
                        }
                    });
                    this.$cookies.remove();
                    window.location.replace('/')
                    return;
                }

                let affiliateFromCookie = this.$cookies.get('ofp_aff') || this.$cookies.get('pap_custom_tracker');
                if ((!this.user.affiliate && affiliateFromCookie) || (this.user.affiliate && new Date(this.user.affiliate_expire) < new Date() && affiliateFromCookie)) {
                    axios.post(`${this.$baseUrl}/customers/add-affiliate`, { email: this.user.email, affiliate: affiliateFromCookie })
                        .then(() => {
                            this.user.affiliate = affiliateFromCookie;
                            //this.$cookies.remove('ofp_aff', '/', `.${window.location.hostname}`);
                            //this.$cookies.remove('pap_custom_tracker', '/', '.ofpfunding.com');
                        });
                }

                this.user.language = this.user.language ? this.user.language.toLowerCase():null
                this.modalPromo()
                this.user.hide_inactive_accounts = this.user.hide_inactive_accounts || false;
                this.hide_inactive_accounts = this.user.hide_inactive_accounts;
                this.profileLetter = (this.user.firstName[0] + this.user.lastName[0]);
                this.loading = false;

                const accountsResponse = await this.getAccounts();
                if(accountsResponse.accounts < 1) {
                    window.location.replace('/buy-account');
                    return;
                }

                let list_account = accountsResponse.accounts.map(item => item._id);
                let accounts = {
                    accounts: accountsResponse.accounts.length,
                    list_account: list_account
                };
                accountsResponse.accounts.forEach((item) => {
                    let name = item._id;
                    if (item.bad_trades) {
                        this.badTrades = item.bad_trades;
                        item.bad_trades = '';
                    }
                    let value = JSON.stringify(item);
                    sessionStorage.setItem(name, value);
                });

                sessionStorage.setItem('accounts', JSON.stringify(accounts));
                let list = sessionStorage.getItem('accounts');
                this.accountLen = JSON.parse(list).accounts;

            } catch (error) {
                console.error("Si è verificato un errore durante l'esecuzione di infoAccount:", error);
            }
            await this.setClient();
            this.showAreaChart = true;
            this.loading = false;
        },
        async setClient() {
            let profitAccounts = 0;
            let expAccounts = 0;
            let wonTradesPercent = 0;
            let rr = 0;

            try {
                let list = sessionStorage.getItem('accounts');
                let accountsCookie = JSON.parse(list).list_account;
                accountsCookie.forEach(element => {
                    let acc = sessionStorage.getItem(element);
                    let elemento = JSON.parse(acc);
                    var account = elemento;
                    if (account.status != 0) {
                        this.accountActive.push(account);
                    } else {
                        this.accountInactive.push(account);
                    }
                    this.idAccounts.push(account.userID);
                    this.accountIdColl.push(account._id);
                });
                this.onlyOnDemandAccounts = [...this.accountActive, ...this.accountInactive].every(account => account.type_account.includes('On Demand'));
                this.allAccounts = [...this.accountActive, ...this.accountInactive];

                let days = [];
                let sommaRR = 0;

                this.loadingAccountIds = this.idAccounts.reduce((acc, id) => {
                    acc[id] = true;
                    return acc;
                }, {});
                this.errorLoadingAccountIds = this.idAccounts.reduce((acc, id) => {
                    acc[id] = false;
                    return acc;
                }, {});
                for (let i = 0; i < this.idAccounts.length; i++) {
                    if (this.idAccounts.length>0 && this.idAccounts[i] != '' && (this.idAccounts[i] != undefined || this.accountIdColl[i] != undefined)) {
                        axios.get(`${this.$baseUrl}/accounts/getAccountInfo/${this.idAccounts[i] || this.accountIdColl[i]}`)
                            .then(async response => {
                                this.loadingAccountIds[this.idAccounts[i]] = false;
                                this.errorLoadingAccountIds[this.idAccounts[i]] = false;

                                if (response.data.nextReview && !days.includes(response.data.nextReview) && (response.data.status != '0' && response.data.status != 'violated')) {
                                    days.push(response.data.nextReview);
                                }
                                if (response.data.currency) {
                                    let curr = response.data.currency;
                                    this.currAccounts.push(curr);
                                }

                                if(this.accountActive.length>0) {
                                    this.currSymbol = this.trovaValorePiuFrequente(this.currAccounts);
                                }
                                if(response.data.status != '0' && response.data.status != 'violated' && response.data.status!=undefined) {
                                    if ((!localStorage.getItem(this.idAccounts[i]) || new Date() > JSON.stringify(localStorage.getItem(this.idAccounts[i]).expire))) {
                                        let metricsResponse = await axios.get(`${this.$baseUrl}/getUserMetrics?user_id=${this.idAccounts[i]}`)
                                        metricsResponse = metricsResponse.data;
                                        if (metricsResponse) {
                                            metricsResponse.expire = Date.now() + 5 * 60 * 1000; // 5 minuti in millisecondi
                                        }

                                        localStorage.setItem(this.idAccounts[i], JSON.stringify(metricsResponse))
                                        if (metricsResponse?.profitFactor) {
                                            let element = metricsResponse.profitFactor
                                            console.log(element)
                                            profitAccounts += parseFloat(element)
                                        }
                                        if (metricsResponse?.expectancy) {
                                            let element = metricsResponse.expectancy
                                            expAccounts += parseFloat(element)
                                        }

                                        if (metricsResponse?.wonTradesPercent) {
                                            let element = metricsResponse.wonTradesPercent
                                            wonTradesPercent += parseFloat(element)
                                        }
                                        if (metricsResponse && metricsResponse.monthlyAnalytics && metricsResponse.monthlyAnalytics.length > 0) {
                                            let monthlyAnalytics = metricsResponse.monthlyAnalytics
                                            let mediaElement = []
                                            monthlyAnalytics.forEach(element => {
                                                element.currencies.forEach(item => {
                                                    mediaElement.push(parseFloat(item.rewardToRiskRatio.toFixed(2)))
                                                })
                                            })
                                        }
                                        let averageRR = 0
                                        if (metricsResponse && metricsResponse.averageWin && metricsResponse.averageLoss) {
                                            averageRR = Math.abs(metricsResponse.averageWin / metricsResponse.averageLoss).toFixed(2)
                                        }
                                        sommaRR = (sommaRR + parseFloat(averageRR))

                                        rr = sommaRR

                                        const account = this.allAccounts.find(account => account.userID === this.idAccounts[i]);
                                        this.totalAccountsBalance[account.currency] = (this.totalAccountsBalance[account.currency] || 0) + +metricsResponse.balance;
                                    } else {
                                        let metricheAcc = JSON.parse(localStorage.getItem((this.idAccounts[i])))
                                        if (metricheAcc) {
                                            if (metricheAcc.profitFactor) {
                                                let element = metricheAcc.profitFactor
                                                profitAccounts += parseFloat(element)
                                            }
                                            if (metricheAcc.expectancy) {
                                                let element = metricheAcc.expectancy
                                                expAccounts += parseFloat(element)
                                            }

                                            if (metricheAcc.wonTradesPercent) {
                                                let element = metricheAcc.wonTradesPercent
                                                wonTradesPercent += parseFloat(element)
                                            }
                                            if (metricheAcc.monthlyAnalytics && metricheAcc.monthlyAnalytics.length > 0) {
                                                let monthlyAnalytics = metricheAcc.monthlyAnalytics
                                                let mediaElement = []
                                                monthlyAnalytics.forEach(element => {
                                                    element.currencies.forEach(item => {
                                                        mediaElement.push(parseFloat(item.rewardToRiskRatio.toFixed(2)))
                                                    })
                                                })
                                            }
                                            let averageRR = 0
                                            if (metricheAcc.averageWin && metricheAcc.averageLoss) {
                                                averageRR = Math.abs(metricheAcc.averageWin / metricheAcc.averageLoss).toFixed(2)
                                            }
                                            sommaRR = (sommaRR + parseFloat(averageRR))

                                            rr = sommaRR
                                            const account = this.allAccounts.find(account => account.userID === this.idAccounts[i]);
                                            this.totalAccountsBalance[account.currency] = (this.totalAccountsBalance[account.currency] || 0) + +metricheAcc.balance;
                                        }
                                    }
                                }

                                const allAccountsWereLoaded = Object.values(this.loadingAccountIds).every(loading => !loading);
                                if (allAccountsWereLoaded) {
                                    this.min = Math.min(...days);
                                    const newDate = new Date();
                                    newDate.setDate(newDate.getDate() + this.min);
                                    this.nextPayout = newDate.toDateString();

                                    var options = {weekday: 'long'};
                                    var dayName = newDate.toLocaleDateString('en-US', options);
                                    this.giorno = dayName;

                                    console.log(expAccounts)
                                    this.profitAccounts = profitAccounts;
                                    this.expAccounts = expAccounts;
                                    this.wonTradesPercent = wonTradesPercent;
                                    this.rr = rr;
                                }
                            })
                            .catch(error => {
                                this.loadingAccountIds[this.idAccounts[i]] = false;
                                this.errorLoadingAccountIds[this.idAccounts[i]] = true;
                                console.error("Si è verificato un errore durante setClient per", this.idAccounts[i], error);
                            });
                    }
                    this.loading = false;
                }
                this.loading = false;
            return true
            } catch (error) {
                console.error("Si è verificato un errore durante l'esecuzione di setClient:", error);
                // Qui puoi gestire ulteriormente l'errore, ad esempio mostrando un messaggio all'utente.
            }
        },
        formatData(value){
            if (!value) {
                return;
            }
            let date = new Date(value)
            let local = this.user.language ? this.$languages[this.user.language] : this.$languages['en']
            var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            var formatter = new Intl.DateTimeFormat(local, options);
            return formatter.format(date);
        },
        // go page detail account
        goTrial($event, currency) {
            this.$router.push({ path: '/trial', params: { id }, query: { currency } });

            let id=$event
            localStorage.setItem('id',id)
            localStorage.setItem('accountCurrency',currency)
        },
        formatCurrency(value){
            const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'GBP',
                        minimumFractionDigits: 2,
                        });
                        return formatter.format(value);
        },
        async hideInactiveAccounts() {
            if (!this.user._id) {
                return;
            }

            const data = { hide_inactive_accounts: !this.hide_inactive_accounts }
            this.hide_inactive_accounts = data.hide_inactive_accounts;
            await this.update(data);
        },
        modalPromo() {
            const now = new Date();
            const year = now.getFullYear();
            const startDate = new Date(Date.UTC(year, 5,24,12,30));
            const endDate = new Date(Date.UTC(2026, 11, 31, 23, 59)); // 31 dicembre 2026
            const promoShown = this.$cookies.get('promoShown-1');

            if(!promoShown && now>=startDate && now<=endDate){
                document.getElementById('promo').click();
                const tomorrow = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1);
                this.$cookies.set('promoShown-1', 'true', {
                    expires: tomorrow
                });
            }
        }
    },
    mounted() {
        this.checkPromo()
    },
    // before enter check the cookies for the email -> no email -> login
    beforeMount(){
        if(!this.$cookies.get('emailAccount')){
            this.$router.push({ path: '/',})
        }
    }
}
</script>

<style scoped lang="scss">

.modal{
    top: 30%!important;
    transform: translate(0px,-50px);
}
.modal-body{
    cursor: pointer!important;
    border:1.5px solid #ff6844 !important;
    padding: 0!important;
    border-radius: 10px!important;
    overflow: auto;
}
.user-img{
    border-radius: 100%;
    width: 100px;
    height: 100px;
}
.card {
    overflow: hidden;
}

li{
    margin: 10px;
}
.btn_green{
   background-color: #12FCA8!important;
}

.open:hover{
    transform: scale(1.1);
}
.carousel-control-prev{
    width: 2%;
}
.carousel-control-next{
    width: 2%;
}
h5.font-medium{
    font-size: 1.3rem;
}
.popup-container {
  position: relative;
  width: 100%;
  height: 100vh;
}
.popup:hover{
    box-shadow:none;
}
.popup {
  padding: 30px;
  border: 2px solid rgb(207, 97, 70);
  border-radius: 10px;
  display: none;
  width: 30%;
}

.popup.active {
  display: block;
}
.popup-0{
    position: absolute;
    top: 25%;
    right: 5%;
}
.popup-1{
    position: absolute;
    top: 55%;
    left: 65%;
}
.popup-2{
    position: absolute;
    top: 80%;
    left: 65%;
}
.cur{
    cursor: default;
}
.curPointer{
    cursor: pointer;
}
.loading {
    font-family: Arial, sans-serif;
    font-size: 20px;
}

.dot {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}

.dot1 { animation-delay: 0.2s; }
.dot2 { animation-delay: 0.4s; }
.dot3 { animation-delay: 0.6s; }

@keyframes blink {
    0% { opacity: 0.2; }
    20% { opacity: 1; }
    100% { opacity: 0.2; }
}

@media only screen and (max-width: 600px) {
    li{
        padding: 5px;
    }
}

.table{
    color: white;
}

.table > tbody {
    vertical-align: middle;
}
.form-check-input:not(:checked) {
    background-color: white !important;
}
.match-trader-row {
    cursor: pointer;
}
.copy-credentials i {
    opacity: 0;
}
.match-trader-row:hover > .copy-credentials i {
    opacity: 1;
}
.accounts-title {
    font-family: 'Heebo';
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2.188rem;
    color: white;
}
.account-balance__title {
    display: flex;
    flex-direction: column;

    small {
        font-family: 'Heebo';
        font-size: 0.875rem;
        font-weight: 400;

        line-height: 1.25rem;
    }

    span {
        font-family: Heebo;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 2.188rem;
    }
}
.accounts-table {
    thead tr {
        border-bottom: 1px solid rgba(64, 71, 84, 1);

        th {
            font-family: 'Heebo';
            font-size: 0.875rem;
            font-weight: 700;
            line-height: 1.313rem;
            text-transform: capitalize;
        }
    }
}
</style>
