<template>
  <div class="card p-3">
    <div class="d-flex gap-2 align-items-center" style="display: flex!important;">
        <h3 class="card-title my-2" style="color:#cf6146">Consistency Metric</h3>
        <i class="mdi mdi-information-outline btn my-2"  data-bs-toggle="modal" data-bs-target="#consModal"></i>
        <div class="modal fade" id="consModal" tabindex="-1" aria-labelledby="consModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-4 text-primary" id="consModalLabel">Consistency Metric</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body p-2">
                    This metric shows you how much of your profits was made in your best trading day.
                    <br class="py-2">
                    For example, if your total profit is 1,000$ and 350$ were made in your best trading day, the score will be 35%.
                    <br class="py-2">
                    Note that this metric is not a rule for the 40% Account model, 26% Account Model and 40% Supercharged Model.
                </div>
                </div>
            </div>
        </div>
   </div>
   <div style="height:300px" class="d-flex flex-column justify-content-center align-items-center">
       <canvas id="ConsChart"></canvas>
   </div>
  </div>
 </template>

<script>
import Chart from "chart.js/auto"
export default {
   props: {
     consistency:String
 },
 data () {
   return {
    consistencyfinal:0,
     options: {
       legend: {
         display: true
       },
       responsive: true,
       maintainAspectRatio: false
     },
     pieChart:null
   }
 },
 methods:{
   createChart(){
              const ctx = document.getElementById('ConsChart');
              this.pieChart = new Chart(ctx, {
              type: 'pie',
              data: this.chartData,

              options: {
              responsive: true,
              plugins: {
         legend: {
           display: true,
           labels: {
             color: "white",
             font: {
               size: 13
             }
           }
         }
       },
               },
           });
          },
   chartSelezione(){
    axios.get(`${this.$baseUrl}/accounts/getAccountInfo/${this.consistency}`).then((response)=>{
        let consis=response.data.consistencyScore
        if(consis=="Infinity"){
            this.consistencyfinal=100
        }else{
            this.consistencyfinal=consis
        }
    this.chartData= {
        labels: ["Consistency%"],
        datasets: [{
            borderWidth: 1,
            borderColor: [
        'rgba(228,106,118,1)',
        ],
            backgroundColor: [
            'rgba(228,106,118, 0.2)',
            ],
            data: [this.consistencyfinal]
        }]
    }
    this.destroyChart();
        this.createChart()
        })


          },
          destroyChart() {
           if (this.pieChart !== null) {
               this.pieChart.destroy();
               this.pieChart = null;
           }
       },
 },
 mounted() {
    this.destroyChart()
    this.chartSelezione()
    },
    beforeUnmount() {
      this.destroyChart();
    },
    watch: {
        consistency() {
        this.chartSelezione()
    }
  },
}
</script>
<style scoped></style>
