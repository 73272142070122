import { defineStore } from "pinia";
import { useUserStore } from "./user.store";

export const usePayoutStore = defineStore("payout", {
    state: () => ({
        details: [],
        payouts: [],
        data: [],
        loading: false,
        currentStep: localStorage.getItem('currentStep') ? parseInt(localStorage.getItem('currentStep')) : 1, 
        selectedPayout: JSON.parse(localStorage.getItem('selectedPayout')) || null,
        singleDetail:[]
    }),
    actions: {
        async fetchPaymentDetails() {
            this.loading = true;
            try {
                const { user } = useUserStore();
                const response = await this.$httpClient.get(
                    `/customers/getPayoutsDetails/${user._id}`
                );
                this.details = response.data;
                return response.data;
            } catch (e) {
                console.error("Error payment method:", e.message || e);
            } finally {
                this.loading = false;
            }
        },
        async fetchDataPayouts() {
            this.loading = true;
            try {
                const response = await this.$httpClient.get("/payouts");
                this.data = response.data;
                return response.data;
            } catch (e) {
                console.error("Error fetching payout data:", e.message || e);
            } finally {
                this.loading = false;
            }
        },
        async fetchSingleDataPayout(payoutId) {
            this.loading = true;
            try {
                const response = await this.$httpClient.get(`/payouts/${payoutId}`);  
                return response.data;  
            } catch (e) {
                console.error("Error fetching payout:", e.message || e);
            } finally {
                this.loading = false;
            }
        },
        
        async fetchUpdatePayouts(payoutId, payload) {
            this.loading = true;
            try {
                const response = await this.$httpClient.put(
                    `/payouts/${payoutId}`,
                    payload
                );
                this.data = response.data;
                return response.data;
            } catch (e) {
                console.error("Error fetching payout data:", e.message || e);
            } finally {
                this.loading = false;
            }
        },
        async downloadFilePayout(payoutId) {
            this.loading = true;
            try {
                const response = await this.$httpClient.get(`/payouts/${payoutId}`);
                const payoutData = response.data;
        
                if (payoutData.proofsUrls?.length > 0) {
                    for (const proof of payoutData.proofsUrls) {
                        const fileUrl = proof.url; 
                        const fileName = proof.name; 
                        
                        console.log('File URL:', fileUrl);
                        console.log('File Name:', fileName);
    
                        const fileResponse = await this.$httpClient.get(
                            `/payouts/${payoutId}/download-proof?url=${fileUrl}`,
                            {
                                responseType: 'blob'
                            }
                        );
    
                        const blob = fileResponse.data;
                        const downloadUrl = URL.createObjectURL(blob);
                        const link = document.createElement("a");
                        link.href = downloadUrl;
                        link.download = fileName;
                        document.body.appendChild(link);
                        link.click();
    
                        URL.revokeObjectURL(downloadUrl);
                        document.body.removeChild(link);
                    }
                } else {
                    console.error("No proof URLs found for this payout.");
                }
        
                return payoutData; 
            } catch (e) {
                console.error("Error fetching payout data:", e.message || e);
            } finally {
                this.loading = false; 
            }
        },   
        
        setStep(step) {
            this.currentStep = step;
            localStorage.setItem('currentStep', step);
        },
        setSelectedPayout(payout) {
            this.selectedPayout = payout;
            localStorage.setItem('selectedPayout', JSON.stringify(payout)); 
        },
    },
    getters: {
        getCurrentStep: (state) => state.currentStep,
        getSelectedPayout: (state) => state.selectedPayout,
    },
});
