import { defineStore } from "pinia";

export const useSettingStore = defineStore("setting", {
    state: () => ({
        settings: [],
        setting: {},
    }),
    actions: {
        async index() {
            this.loading = true;
            try {
                const response = await axios.get('/settings');
                this.settings = response.data.data;
            } catch (e) {
                throw e;
            }
            finally {
                this.loading = false;
            }
        },
        async showType() {
            this.loading = true;
            try {
                const response = await this.$httpClient.get(`/settings/by-type/${this.setting?.type}`);
                this.setting = response.data;
                console.log(this.setting)
            } catch (e) {
                throw e;
            } finally {
                this.loading = false;
            }
        },
    }
})
