<template>
    <p class="description gradient-border" v-html="description" />
    <br />
    <template v-if="tableKeys?.length">
        <template v-for="key of tableKeys" :key="key">
            <div
                class="warning-container"
                v-if="
                    hasViolatedTrades[key] &&
                    (key === 'martingale' || key === 'hedging')
                "
            >
                <div class="warning" v-if="key === 'martingale'">
                    <i
                        class="fas fa-exclamation-triangle"
                        style="color: #f44336"
                    ></i>
                    Martingale
                </div>
                <div class="warning" v-if="key === 'hedging'">
                    <i
                        class="fas fa-exclamation-triangle"
                        style="color: #ffc107"
                    ></i>
                    Hedging
                </div>
            </div>
            <div class="customTable" v-if="key.includes('custom-news-')">
                <div class="customTable__header">
                    <div>News</div>
                    <div>Times</div>
                </div>
                <div class="customTable__body">
                    <div>
                        {{
                            customNews.find(
                                (header) =>
                                    header.event_id ===
                                    key.split("custom-news-")[1]
                            ).event_name
                        }}
                    </div>
                    <div>
                        {{
                            customNews.find(
                                (header) =>
                                    header.event_id ===
                                    key.split("custom-news-")[1]
                            ).event_timestamp
                        }}
                    </div>
                </div>
            </div>
            <div class="customTable" v-if="key.includes('custom-gambling-')">
                <div class="customTable__header">
                    <div>
                        <strong>
                            {{
                                customGambling.find(
                                    (header) =>
                                        header.symbol ===
                                        key.split("custom-gambling-")[1]
                                ).symbol
                            }}
                        </strong>
                        <p>Max Usable Volume:</p>
                        <strong>
                            {{
                                customGambling.find(
                                    (header) =>
                                        header.symbol ===
                                        key.split("custom-gambling-")[1]
                                ).maxUsableVolume
                            }}
                        </strong>
                    </div>
                </div>
                <div class="customTable__body">
                    <div>
                        Violation
                        {{
                            customGambling.find(
                                (header) =>
                                    header.symbol ===
                                    key.split("custom-gambling-")[1]
                            ).symbol
                        }}
                        ({{
                            customGambling.find(
                                (header) =>
                                    header.symbol ===
                                    key.split("custom-gambling-")[1]
                            ).count
                        }}
                        Items)
                    </div>
                </div>
            </div>
            <div
                class="violation-table"
                :ref="
                    (el) => {
                        table[key] = el;
                    }
                "
                v-show="hasViolatedTrades[key]"
            />
        </template>
    </template>
</template>

<script setup>
import {
    onMounted,
    ref,
    reactive,
    inject,
    onUnmounted,
    watch,
    nextTick,
} from "vue";
import { useAccountStore } from "../../../store/account.store";
import { storeToRefs } from "pinia";
import { TabulatorFull as Tabulator } from "tabulator-tables";

const props = defineProps({
    keys: Array,
    description: String,
});

const $formatAmountRounded = inject("$formatAmountRounded");
const $formatDate = inject("$formatDate");

const accountStore = useAccountStore();
const { accountInfo } = storeToRefs(accountStore);

const customNews = ref([]);
const customGambling = ref([]);
const tableKeys = ref([]);

const table = reactive({});
const tabulator = ref({});
const hasViolatedTrades = reactive({});
const baseOptions = {
    data: [],
    responsiveLayout: true,
    reactiveData: true,
    dataTree: false,
    rowHeight: 40,
    pagination: true,
    paginationSize: 20,
    paginationSizeSelector: [10, 20],
    paginationInitialPage: 1,
    paginationCounter: "rows",
    layout: "fitColumns",
    columnDefaults: {
        resizable: true,
    },
    columns: [
        {
            title: "ID",
            field: "positionId",
            formatter: (cell) => {
                if (cell.getData().icon)
                    return `<i class="fas fa-exclamation-triangle" style="color: ${
                        cell.getData().icon === "martingale"
                            ? "#F44336"
                            : "#FFC107"
                    };"></i> ${cell.getValue()}`;
                return cell.getValue();
            },
        },
        {
            title: "Symbol",
            field: "symbol",
            editor: "input",
        },
        {
            title: "Volume",
            field: "lots",
            sorter: "string",
            formatter: function (cell, formatterParams, onRendered) {
                return Number(cell.getValue()).toFixed(2);
            },
        },
        {
            title: "Open Price",
            field: "openPrice",
            formatter: function (cell, formatterParams, onRendered) {
                return $formatAmountRounded(cell.getValue());
            },
        },
        {
            title: "Close Price",
            field: "closePrice",
            formatter: function (cell, formatterParams, onRendered) {
                return $formatAmountRounded(cell.getValue());
            },
        },
        {
            title: "Profit",
            field: "profit",
            formatter: function (cell, formatterParams, onRendered) {
                return cell.getValue() > 0
                    ? `<span class="text-success">${$formatAmountRounded(
                          cell.getValue()
                      )}</span>`
                    : `<span class="text-danger">${$formatAmountRounded(
                          cell.getValue()
                      )}</span>`;
            },
        },
        {
            title: "Type",
            field: "type",
            sorter: "string",
            formatter: function (cell, formatterParams, onRendered) {
                return cell.getValue() === "DEAL_TYPE_BUY"
                    ? `<i class="fas fa-arrow-up text-success"></i> Buy`
                    : `<i class="fas fa-arrow-down text-danger"></i> Sell`;
            },
        },
        {
            title: "Open at",
            field: "openTime",
            sorter: "string",
            formatter: function (cell, formatterParams, onRendered) {
                return $formatDate(cell.getValue());
            },
        },
        {
            title: "Close at",
            field: "closeTime",
            sorter: "string",
            formatter: function (cell, formatterParams, onRendered) {
                return $formatDate(cell.getValue());
            },
        },
        {
            title: "Durations",
            field: "durationInMinutes",
        },
    ],
    rowFormatter: function (row) {
        const data = row.getData();
        const nextRow = row.getNextRow();
        if (
            nextRow &&
            nextRow?.getData()?._violation_id !== data?._violation_id
        ) {
            row.getElement().style.borderBottom = "4px solid #1E1E1E";
        }
    },
};

const makeTable = async () => {
    if (tableKeys.value?.length === 0) {
        return;
    }

    tabulator.value = {};

    for await (const key of tableKeys.value) {
        const violatedTrades = accountInfo.value.health_check_violations
            .filter((violation) => {
                if (key.includes("custom-news-"))
                    return violation.rule === "news-straddling";
                if (key.includes("custom-gambling"))
                    return violation.rule === "gambling";
                return violation.rule === key;
            })
            .flatMap((violation) => {
                if (key.includes("custom-news-")) {
                    const eventId = key.split("custom-news-")[1];
                    const trades = Object.keys(
                        violation.stats.tradesByNews
                    ).map((trade) => violation.stats.tradesByNews[trade]);
                    if (trades.some((trade) => trade.event_id !== eventId))
                        return violation.trades;
                }
                if (key.includes("custom-gambling-")) {
                    const symbol = key.split("custom-gambling-")[1];
                    return violation.trades.filter(
                        (trade) => trade.symbol === symbol
                    );
                }
                if (key.includes("martingale") || key.includes("hedging")) {
                    return violation.trades.map((trade) => ({
                        ...trade,
                        icon: key,
                    }));
                }
                return violation.trades;
            });
        hasViolatedTrades[key] = violatedTrades.length > 0;
        const options = { ...baseOptions };
        options.data = violatedTrades.sort(
            (a, b) => a._violation_id < b._violation_id
        );

        await nextTick();
        if (!tabulator.value[key]) {
            setTimeout(() => {
                if (options.data.length)
                    tabulator.value[key] = new Tabulator(table[key], options);
            }, 500);
        } else {
            tabulator.value[key].setData(violatedTrades);
            tabulator.value[key].redraw();
        }
    }
};

const checkCustomTables = () => {
    if (tableKeys.value.includes("news-straddling")) {
        const newsStraddling = accountInfo.value.health_check_violations.find(
            (violation) => violation.rule === "news-straddling"
        );
        const news = Object.keys(newsStraddling.stats.tradesByNews).map(
            (trade) => newsStraddling.stats.tradesByNews[trade]
        );
        customNews.value = news
            .filter(
                (value, index, self) =>
                    index ===
                    self.findIndex((t) => t.event_id === value.event_id)
            )
            .flat();
        tableKeys.value = tableKeys.value.filter(
            (key) => key !== "news-straddling"
        );
        tableKeys.value.push(
            ...customNews.value.map(
                (header) => `custom-news-${header.event_id}`
            )
        );
    }
    if (tableKeys.value.includes("gambling")) {
        const gamblingRule = accountInfo.value.health_check_violations.find(
            (v) => v.rule === "gambling"
        );
        const gamblingItems =
            gamblingRule?.trades?.map((violation) => violation.symbol) || [];
        const symbols = [...new Set(gamblingItems)];
        customGambling.value = symbols.map((symbol) => ({
            symbol: symbol,
            maxUsableVolume:
                Number(
                    gamblingRule.stats.symbols[symbol].maxUsableVolume
                ).toFixed(2) || "Unknown",
            count: gamblingItems.length,
        }));
        tableKeys.value = tableKeys.value.filter((key) => key !== "gambling");
        tableKeys.value.push(
            ...customGambling.value.map(
                (header) => `custom-gambling-${header.symbol}`
            )
        );
    }
};

onMounted(async () => {
    tableKeys.value = props.keys;
    checkCustomTables();
    makeTable();
});

watch(
    () => props.keys,
    () => {
        tableKeys.value = props.keys;
        checkCustomTables();
        makeTable();
    },
    { deep: true }
);
</script>

<style scoped lang="scss">
.warning-container {
    display: inline-flex;
    gap: 10px;
    justify-content: flex-start;
    width: 100%;

    .warning {
        border: 1px solid #f443364d;
        padding: 8px 24px;
        margin-bottom: 24px;
        color: white;
        border-radius: 8px;
        text-align: left;
        width: fit-content;
    }
}

.customTable {
    width: 100%;

    &__header,
    &__body {
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        div {
            width: 100%;
            padding: 10px;

            &:first-child {
                border-right: 1px solid rgba(255, 255, 255, 0.15);
            }
        }
    }

    &__header {
        color: rgba(255, 149, 113, 1);
        font-size: 600;
        background: linear-gradient(
            90deg,
            #090635 0%,
            rgba(47, 39, 171, 0.6) 129.37%
        );

        strong {
            font-size: 800;
            color: #f821ff;
        }

        p {
            color: #f821ff;
            display: inline-block;
            margin: 0 4px;
        }
    }

    &__body {
        color: white;
        background: #07042f;
    }
}

.gradient-border {
    padding: 1.5rem 1rem;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 8px;
        padding: 2px;
        background: linear-gradient(
            142.67deg,
            #ffb7a7 8.58%,
            #fb8469 25.6%,
            #fb8469 35.86%,
            #7d5ffc 66.99%,
            #fb8469 98.31%
        );
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask-composite: exclude;
        -webkit-mask-composite: destination-out;
    }
}

.description {
    font-family: "Heebo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    color: white;
}

.violation-table {
    margin-bottom: 20px;
    font-family: Heebo;
}

.violation-title {
    font-family: "Heebo";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    text-transform: capitalize;
}

.violation-table.tabulator,
.violation-table :deep(.tabulator-table),
.violation-table :deep(.tabulator-row),
.violation-table :deep(.tabulator) {
    background: #07042f;
}

.violation-table :deep(.tabulator-row) {
    color: white;
    padding: 0.4rem;
    border-bottom: 0;
}

.violation-table :deep(.tabulator-header) {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: none;
    background: linear-gradient(
        90deg,
        #090635 0%,
        rgba(47, 39, 171, 0.6) 129.37%
    );
}

.violation-table :deep(.tabulator-header .tabulator-col) {
    background-color: transparent !important;
    color: rgba(255, 149, 113, 1);
    font-weight: normal;
}

.violation-table :deep(.tabulator-footer) {
    background: transparent !important;
    color: white;
}

.violation-table :deep(.tabulator-footer-contents) {
    background: transparent !important;
    color: white;
}

.violation-table :deep(.tabulator-footer-contents) button {
    background: linear-gradient(
        90deg,
        #090635 0%,
        rgba(47, 39, 171, 0.6) 129.37%
    );
    color: white !important;
    border: none;
}

.violation-table :deep(.tabulator-footer-contents) .tabulator-page.active {
    border: 0.8px solid rgba(255, 104, 68, 0.8);
}

.violation-table :deep(.tabulator-footer) .tabulator-page,
.violation-table :deep(.tabulator-footer) .tabulator-page:disabled {
    background: linear-gradient(
        90deg,
        #090635 0%,
        rgba(47, 39, 171, 0.6) 129.37%
    );
    color: white !important;
}

.violation-table :deep(.tabulator-footer-contents) select {
    background: linear-gradient(
        90deg,
        #090635 0%,
        rgba(47, 39, 171, 0.6) 129.37%
    );
    border: none;
}

.violation-table :deep(.tabulator-page) {
    margin-left: 10px;
}
</style>
