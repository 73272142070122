<script setup>
import { useGtm } from '@gtm-support/vue-gtm';

const $gtm = useGtm();

const testGtm = () => {
    $gtm.trackEvent({
        event: 'registered_client',
        category: 'test',
        action: 'test',
        label: 'test',
        value: 'test',
    });
}
</script>

<template>
    <div>
        <button class="button" @click="testGtm()">test</button>
    </div>
</template>
