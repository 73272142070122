<template>
    <div id="main-wrapper">
        <!-- ============================================================== -->
        <!-- Topbar header - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <header class="topbar">
            <Navbar />
        </header>
        <!-- ============================================================== -->
        <!-- End Topbar header -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <aside class="left-sidebar">
            <!-- Sidebar scroll-->
            <div class="scroll-sidebar">
                <!-- Sidebar navigation-->
                <Sidebar @rank="watchRank" />
                <!-- End Sidebar navigation -->
            </div>
            <!-- End Sidebar scroll-->
        </aside>
        <!-- ============================================================== -->
        <!-- End Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Page wrapper  -->
        <div class="page-wrapper" :class="showRank?'opacity-25':''" id="pageH" >
            <!-- ============================================================== -->
            <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="container-fluid">
                <!-- ============================================================== -->
                <!-- Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->
                <!-- End Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->

                <!--- Start Content --->
                <div class="mt-5 ">
                    <h2 class="text-white text-start fw-bold">{{$t('sidebar-Certificati')}}</h2>
                    <div class="row d-flex dispFlex mt-3 justify-content-start gap-5 wrapCard dispFlex">
                        <div class="cardConteiner dispFlex flex-column gap-2" v-for="element,index in certificates" :key="index">
                        <div  class="boxCard position-relative dispFlex flex-column p-3 pb-0 mb-0">
                            <div id="displayHover" v-if="element.reached_at!=null && !loadingIMG" class="position-absolute absPostion col-12 text-center gap-2">
                                <span role="button" @click="shareOnFacebook(element.hash)"><font-awesome-icon icon="fa-brands fa-facebook" class="fs-1 text-white mx-2 "/></span>
                                <span role="button" @click="shareOnTwitter(element.hash)"><img class="imgW rounded-circle mb-3 bg-white" src="assets/images/logox.png"/></span>
                                <!-- <span role="button" class="border-0" data-sharer="facebook" data-hashtag="OFPFunding" :data-url="$baseUrl + '/certificate/' + element.hash"><font-awesome-icon icon="fa-brands fa-facebook" class="fs-1 text-white mx-2"/></span>
                                <span role="button" class="border-0" data-sharer="twitter" data-hashtag="OFPFunding" :data-url=" $baseUrl + '/certificate/' + element.hash"><img class="imgW rounded-circle mb-3 bg-white" src="assets/images/logox.png"/></span> -->
                                <span class="border-0 "><font-awesome-icon icon="fa-solid fa-file-pdf" class="fs-1 text-white mx-2 " @click="goPDF(element.hash)"/></span>
                            </div>
                            <div :id="index" class=" blurBox col-12 rounded h-100 d-flex dispFlex flex-column justify-content-between" :class="element.reached_at==null && !loadingIMG?'boxOp':''">
                                <button v-if="loadingIMG" class="btn btn-outline-primary border-0 d-flex gap-3 justify-content-start align-items-center" type="button" disabled>
                                    <h4 class="spinner-6" aria-hidden="true"></h4>
                                </button>
                               <img class="prevCertificate" :src="$baseUrl + '/certificates/preview?hash=' + element.hash" alt="" @load="loaded">
                            </div>
                            <font-awesome-icon v-if="element.reached_at==null && !loadingIMG" icon="fa-solid fa-lock" class="textLock" />
                        </div>
                        <div v-if="element.type=='single-payout'" class="ps-3 pt-3">
                            <h3 class="certificateTitle ">{{ formattedString(element.type)}} {{ formatCurrency(element.value) }}</h3>
                            <h6 class="text-white">Date: {{ formatData(element.reached_at) }}</h6>
                        </div>
                        <div v-else class="ps-3 pt-3">
                            <h3  class="certificateTitle">{{ formattedString(element.type)}}</h3>
                            <h6 class="textTrasp">Date:</h6>
                        </div>
                    </div>
                    </div>

                </div>
                <!--- End Content --->
            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->

    </div>
     <!-- ============================================================== -->
     <RankModal :showRank="showRank" @close="closeRank"/>
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <Footer/>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
</template>
<script>
    import Sidebar from "./Sidebar";
    import Navbar from "./statics/navbar";
    import Footer from "./statics/footer";
    import bannerKyc from './statics/bannerKyc';
    import RankModal from "./RankModal";
    import moment from "moment";
    import Spinner from "./Spinner";

    import Sharer from 'sharer.js';
    export default {
    components: { Footer, Navbar, Sidebar,bannerKyc,RankModal,Spinner},
    data() {
        return {
            loading:true,
            showRank:false,
            client:{},
            blockPayout:true,
            certificates:[],
            url:this.$baseUrl + '/certificate/',
            loadingIMG:true
        };
    },
    methods:{
        loaded(){
            this.loadingIMG = false;
        },
        shareOnFacebook(value) {
            const shareUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(this.url + value)
            window.open(shareUrl, '_blank');
        },
        shareOnTwitter(value) {
            const shareUrl = 'https://twitter.com/intent/tweet?url=' + encodeURIComponent(this.url + value);
            window.open(shareUrl, '_blank');
        },
        goPDF(hash){
            window.open(`certificate/${hash}`, '_blank');
        },
        formatCurrency(value) {
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: "USD",
                minimumFractionDigits: 2,
            });
            const formattedValue = formatter.format(value);
            // Controllo se i decimali sono nulli e restituisco solo la parte intera
            if (formattedValue.endsWith('.00')) {
                return formattedValue.slice(0, -3);
            } else {
                return formattedValue;
            }
        },
        formatCurrency0(value){
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: "USD",
                minimumFractionDigits:0,
            });
            return formatter.format(value);
        },
        formatData(value){
            return moment(value).format('DD-MM-YYYY')
        },
        takePayout() {
            try {
                this.client = this.$cookies.get("client");
                axios.get(`${this.$baseUrl}/certificates`).then(({ data }) => {
                    if (data.length != 0) {
                        this.certificates = data;
                        const requests = data.map((element) =>
                            axios.get(`${this.$baseUrl}/certificates/preview?hash=${element.hash}`)
                        );
                        Promise.all(requests)
                            .then((responses) => {
                                responses.forEach((response, index) => {
                                    this.certificates[index].prev = response.data;
                                });
                                //
                                const prelights = this.certificates.map((certificate) => certificate.prev.prelight);
                                this.loading = false;
                            })
                            .catch((error) => {
                                console.error("Error fetching previews:", error);
                            });
                    }
                });
            } catch (error) {
                //console.log(error);
            }
        },
        formattedString(value) {
            let words = value.split("-");
            words = words.filter(word => word.toLowerCase() !== "single");
            return words.map(word => {

                return word.charAt(0).toUpperCase() + word.slice(1)
            }).join(" ");
        },
        shareCertificateFB(id) {
            const certificateUrl =`https://ofpfounding.com/certificate/${id}`;
            const certificateContent = document.getElementById(id).innerHTML;
            const sharer = new sharer({
                url: certificateUrl,
                title: 'Condivisione del certificato',
                networks: {
                facebook: Sharer.facebook
                }
            });
            sharer.share();
        }
    },
    computed: {

    },
    created(){
        this.takePayout()
    }
};
</script>
<style scoped>
.prevCertificate{
    max-height: 100%;
}
.spinner-6 {
  width: 50px;
  aspect-ratio: 1;
  display:grid;
  -webkit-mask: conic-gradient(from 15deg,#0000,#000);
  animation: s6 1.5s infinite steps(12);
}
.spinner-6,
.spinner-6:before,
.spinner-6:after{
  background:
    radial-gradient(closest-side at 50% 12.5%,
     #C24F35 96%,#0000) 50% 0/20% 80% repeat-y,
    radial-gradient(closest-side at 12.5% 50%,
     #C24F35 96%,#0000) 0 50%/80% 20% repeat-x;
}
.spinner-6:before,
.spinner-6:after {
  content: "";
  grid-area: 1/1;
  transform: rotate(30deg);
}
.spinner-6:after {
  transform: rotate(60deg);
}

@keyframes s6 {
  100% {transform:rotate(1turn)}
}

.textTrasp{
    color: transparent!important;
}
img{
    border-radius: 5%;
}
.boxOp{
    opacity: 20%;
    position: relative;
    filter: grayscale(80%);
}
.textLock{
    color: rgba(147, 147, 147, 0.9)!important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.fa-lock{
    width: 20%;
    height: 20%;
}
.rotImg{
    transform: rotate(10deg);
    margin-bottom: -5px;
}
    .boxCard{
        width: 100%;
        height: 90%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        border-radius: 10px!important;
    }
    .cardConteiner{
        width: 30%;
        margin-bottom: 100px;
    }
    .opacitaCertificato{
        opacity:40%;
    }
    .no-opacity-icon {
        opacity: 1;
    }
    .dispWrap{
        flex-wrap: wrap;
    }
     #pageH{
       margin-bottom: 100px!important;
    }
    .wrapCard{
        flex-wrap: wrap;
        display: flex!important;
    }
    .absPostion{
        z-index: 99999;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
    }
    #displayHover{
        display: none;
        cursor: pointer;
    }
    .boxCard:hover #displayHover {
        display: block;
    }
    .boxCard:hover  .blurBox{
        filter: blur(4px);
    }
    .bottom-border {
        color: white;
        position: absolute;
        bottom: -12px;
        left: 50%;
        transform: translate(-50%, -0%);
        width: 50%;
        text-align: center;
        background: radial-gradient(circle at center, rgba(53,24,34,1) 0%, rgba(53,24,34,0.8) 40%, rgba(53,24,34,0.6) 50%,rgba(53,24,34,0.5) 60%,rgba(53,24,34,0.4) 70%,rgba(53,24,34,0.2) 80%,rgba(53,24,34,0.1) 90%, rgba(53,24,34,0) 100%);
    }
    .certificateTitle{
        color: #c24f35;
        font-weight: 700!important;
        text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
    }
    .colorGradient{
        background: linear-gradient(to right, rgba(255, 104, 68, 1), rgba(255, 255, 255, 1));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .bgGradient{
        background: linear-gradient(to right, rgba(255, 104, 68, 1), rgba(36, 29, 128, 1));
        padding: 10px;
        min-width: 50%;
        text-align: center;
    }
    .borderGradient {
        position: relative;
        border: 1px solid transparent;
        box-shadow: -1px 1px 9px 1px rgba(255, 104, 68, 1), 0 0 0 2px rgba(255, 255, 255, 1);
    }

    .bg5k{
        background: linear-gradient(to left, rgba(255, 104, 68, 0.8), rgba(255, 183, 183, 1), rgba(255, 104, 68, 1));
        padding: 10px;
        min-width: 50%;
        text-align: center;
    }

    .bg10k{
        background: linear-gradient(to left, rgba(205, 127, 50, 0.8), rgba(222, 167, 113, 1), rgba(205, 127, 50, 1));
        padding: 10px;
        min-width: 50%;
        text-align: center;
    }

    .bg20k{
        background: linear-gradient(to left, rgba(192, 192, 192, 0.8), rgb(117, 116, 116), rgba(192, 192, 192, 1));
        padding: 10px;
        min-width: 50%;
        text-align: center;
    }

    .bg50K{
        background: linear-gradient(to left, rgba(205, 164, 52, 0.8), rgba(205, 164, 52, 1), rgba(205, 164, 52, 1));
        padding: 10px;
        min-width: 50%;
        text-align: center;
    }

    .bg100K{
        background: linear-gradient(to left, rgba(15,115,105, 0.8), rgba(205, 164, 52, 0.8), rgba(15,115,105, 1));
        padding: 10px;
        min-width: 50%;
        text-align: center;
    }
    .fw600{
        font-weight: 600!important;
    }
    .dispFlex{
        display: flex!important;
    }
    .imgW{
        width: 36px;
        height: 36px;
    }
    @media (max-width: 1750px) {
        .boxCard{
        width: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        border-radius: 10px!important;
    }
    .cardConteiner{
        width: 100%;
    }
    }
    @media (max-width: 850px) {
        .imgW{
        width: 26px;
        height: 26px;
    }
    }
</style>
