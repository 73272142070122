<template>
    <div style="background-image: url(/assets/images/sfondo.jpg);background-size: cover;background-attachment: fixed; width: 100vw; height: 100vh;">
        <div v-if="!emailSent" class="card col-12 box-position login-box p-5">
            <div v-if="error" class="card text-center py-4 m-0 ">
                <h3 class="text-danger fw-bold">{{ texterror }}</h3>
            </div>
            <h3 class="text-center text-primary fw-bold py-4">{{ $t('credential-resetPsw-Title') }}</h3>
            <div class="card-body">
                <form class="form form-material d-flex flex-column" style="row-gap: 40px; display:flex!important;" id="new-password-form" @submit.prevent="changePassword">
                    <div class="form-group d-flex flex-column justify-content-center flex-lg-row gap-5"  style="display:flex!important;">
                            <div class="col-12 col-lg-5">
                                <label for="password">{{ $t('credential-resetPsw-newPSW') }}</label>
                                <div class="d-flex" style="display:flex!important;">
                                    <input id="password" type="password"  class="form-control" v-model="password" required autocomplete="off" :placeholder="$t('credential-resetPsw-newPSW-placeholder')">
                                </div>
                            </div>
                            <div class="col-12 col-lg-5">
                                <label for="confirmPassword">{{ $t('credential-resetPsw-confPSW') }}</label>
                                <div class="d-flex" style="display:flex!important;">
                                    <input id="confirmPassword" type="password" class="form-control" v-model="confirmPassword" required autocomplete="off" :placeholder="$t('credential-resetPsw-confPSW-placeholder')">
                                </div>
                                <span v-if="pswError" class="error text-danger">{{ pswError }}</span>
                            </div>
                    </div>
                    <button :disabled="!isPswValid || loading"  class="btn btn-outline-primary align-self-end">
                        <span v-if="!loading">
                        {{ $t('credential-resetPsw-reset') }}
                        </span>
                        <span v-else>
                            <i class="fa fa-spinner fa-spin"></i>
                        </span>
                    </button>
                </form>
            </div>
        </div>
        <div v-if="emailSent" class="card box-position text-center py-4 login-box">
            <h2 class="text-success fw-bold">{{ $t('credential-resetPsw-succ') }}</h2>
            <p>{{ $t('credential-goLogin') }}</p>
            <a class="btn btn-primary m-auto" :href="'/' + i18n.locale.value" style="width: 30%;">{{ $t('credential-Login') }} </a>
        </div>
    </div>
</template>

<script setup>
import {getCurrentInstance, ref, computed, onMounted} from 'vue';
import {useRouter, useRoute} from 'vue-router';
import {useI18n} from "vue-i18n";
import Spinner from "../components/Spinner.vue";

const baseUrl = getCurrentInstance().appContext.config.globalProperties.$baseUrl;
const router = useRouter();
const route = useRoute();
const i18n = useI18n();
const token = route.params.token;

const loading = ref(false);
const password = ref('');
const confirmPassword = ref('');
const emailSent = ref(false);
const pswError = ref('');

const isPswValid = computed(() => {
    if (!password.value.length) {
        return false;
    }

    const valid = password.value === confirmPassword.value;
    pswError.value = valid ? '' : i18n.t('credential-resetPsw-Error');
    return valid;
});

const changePassword = async () => {
    if (!isPswValid.value) {
        return;
    }

    try {
        loading.value = true;
        await axios.post(`${baseUrl}/customers/set-new-password`, {
            password: confirmPassword.value,
            token
        });
        loading.value = false;

        emailSent.value = true;
    } catch (e) {
        console.error(e);
    }
}

onMounted(async () => {
    try {
        loading.value = true;
        const { data } = await axios.get(`${baseUrl}/customers/get-customer-by-token/${token}`);
        i18n.locale.value = data.language;
        loading.value = false;
    } catch(e) {
        router.replace('/login');
    }
});
</script>

<style scoped>
.box-position{
    position: absolute;
    width: 50%;
     top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
}
.login-box{
    border-radius: 10px;
}
input{
    background-color: transparent!important;
}
@media only screen and (max-width: 600px) {
    .box-position{
        width:100%;
    }
    .box-position>.login-box{
        margin: 0 auto;

    }
}
</style>
