
<template>
    <div id="main-wrapper">
        <Spinner v-if="loading"/>
        <!-- ============================================================== -->
        <!-- Topbar header - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <header class="topbar" :class="{ 'opacity-25': loading }" >
            <Navbar />
        </header>
        <!-- ============================================================== -->
        <!-- End Topbar header -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <aside class="left-sidebar" :class="{ 'opacity-25': loading }">
            <!-- Sidebar scroll-->
            <div class="scroll-sidebar">
                <!-- Sidebar navigation-->
                <Sidebar />
                <!-- End Sidebar navigation -->
            </div>
            <!-- End Sidebar scroll-->
        </aside>
        <!-- ============================================================== -->
        <!-- End Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Page wrapper  -->
        <!-- ============================================================== -->
        <div class="page-wrapper position-relative d-flex aling-items-center" :class="{ 'opacity-25': loading }">
            <div class="container-fluid mb-5 mb-lg-0 pt-4 pt-lg-5 mt-0 mt-lg-5" :class="modalOn?'opacity-25':''">
            <!-- ============================================================== -->
            <!-- Container fluid  -->
            <!-- ============================================================== -->
                <div class="row mx-auto w-100 d-flex justify-content-between" style="height: 40%;">
                    <div class="col-12 col-lg-8 mx-auto rounded pt-0 pt-lg-5 pb-5 px-1 px-lg-3" style="box-shadow: 0px 0px 50px 0px #02071b;background-image: url(assets/images/black-friday.png)!important; background-position:top ; background-size:cover;">
                        <div id="carouselExampleCaptions" class="carousel slide carousel-fade pt-1 pt-lg-3" style="height: 80%!important; ">
                            <div class="carousel-inner">
                                <div class="carousel-item fade-carousel-item active ">
                                    <div class="carousel-caption text-center d-block">
                                        <h2 class="mb-5 d-none d-lg-block">OFP</h2>
                                        <h2 class="text-white fw-bold">BLACK FRIDAY</h2>
                                        <h2 class="text-primary fw-bold">SALE</h2>
                                        <hr class="w-50 mx-auto">
                                        <h4 class="text-white fw-bold">25% OFF</h4>
                                        <hr class="w-50 mx-auto">
                                        <h5 class="text-white">November 20th - 24th</h5>
                                    </div>
                                    <div class="d-flex flex-row flex-wrap justify-content-center gap-3 align-items-start col-12 gap-5 my-5" style="display: flex!important;">
                                        <button @click="chooseBundle(1)"  class="btn btn-primary col-12 col-lg-5 rounded bundle-box" style="background-color: rgba(0, 0, 0, 0.3);border: 1px solid transparent;">
                                            <h2 class="text-primary fw-bold">BUNDLE 1</h2>
                                            <h4 class="text-white fw-bold">10K + 25K</h4>
                                            <h4 class="text-white fw-bold">ACCOUNT</h4>
                                        </button>
                                        <button @click="chooseBundle(2)" class="btn btn-primary col-12 col-lg-5 rounded p-0 bundle-box" style="background-color: rgba(0, 0, 0, 0.3);border: 1px solid transparent;overflow-y:hidden ;">
                                            <h2 class="text-primary fw-bold">BUNDLE 2</h2>
                                            <h4 class="text-white fw-bold">50K + 100K</h4>
                                            <h4 class="text-white fw-bold">ACCOUNT</h4>
                                            <h5 class="text-black fw-bold bg-primary w-100 mb-0">GET A FREE 10K ACCOUNT</h5>
                                        </button>
                                    </div>
                                    <div class="d-flex flex-column align-items-center justify-content-center mb-3" style="display: flex!important;">
                                        <h4 class="fw-bold text-white">YOUR CHANCE IS HERE</h4>
                                        <h4 class="bg-primary text-black px-3 py-2 fw-bold border rounded">GET YOUR BUNDLE NOW</h4>
                                    </div>
                                </div>
                                <div class="carousel-item fade-carousel-item ">
                                    <div class="carousel-caption text-start d-block" >
                                        <h2 class="fw-bold">Choose the Platform</h2>
                                        <p>Choose the platform for the three accounts in the bundle</p>
                                    </div>
                                    <div class="d-flex flex-column gap-3 align-items-center col-12 gap-4 py-3">
                                        <div v-for="plat in platforms" :key="plat" class="d-flex flex-column gap-3 align-items-center col-12" @click="selectPlatform( plat)" >
                                            <label class="col-12 border border-dark border-1 py-2 rounded text-start ps-3"  :class="{ 'bg-primary': platform == plat}">
                                                <h6 class="p-0 m-0">{{ plat }}</h6>
                                            </label>
                                            <input class="form-check-input d-none" type="radio" title="curr">
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-end gap-3 my-5 my-lg-0" style="display: flex!important;">
                                        <button :class="platform==''?'disabled': ''" class="btn btn-outline-primary" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                            <span class="">Next -></span>
                                        </button>
                                    </div>
                                </div>
                                <div class="carousel-item fade-carousel-item">
                                    <div class="carousel-caption text-start d-block" >
                                        <h2 class="fw-bold">Choose the type for your accounts</h2>
                                        <p>In this bundle all accounts will be of the same type, for further information on how each model works <a target="_blank" href="https://ofpfunding.com/trading-rules/">Read here</a>
                                        </p>
                                    </div>
                                    <div class="d-flex flex-column gap-3 align-items-center col-12 gap-4 py-3">
                                        <div v-for="model in models" :key="model" class="d-flex flex-column gap-3 align-items-center col-12" @click="selectModel(model)" >
                                            <label class="col-12 border border-dark border-1 py-2 rounded text-start ps-3"  :class="{ 'bg-primary': modelSelect == model}">
                                                <h6 class="p-0 m-0">{{ model }}% Instant Monthly</h6>
                                            </label>
                                            <input class="form-check-input d-none" type="radio" title="curr">
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-end gap-3 mt-5 mb-5" style="display: flex!important;">
                                        <button class="btn btn-outline-dark" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                            <span class="">Prev</span>
                                        </button>
                                        <button :class="modelSelect==''?'disabled':''" class="btn btn-outline-primary" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                            <span class="">Next -></span>
                                        </button>
                                    </div>
                                </div>
                                <div class="carousel-item fade-carousel-item" >
                                    <div class="carousel-caption text-start d-block">
                                        <h2 class="fw-bold">Choose the Currency for your accounts</h2>
                                        <p>In this bundle all accounts will be of the same currency</p>
                                    </div>
                                    <div class="d-flex flex-column gap-3 align-items-center col-12 gap-5 py-4 py-md-0">
                                        <div v-for="curr in currency" :key="curr" class="d-flex flex-column gap-3 align-items-center col-12" @click="selectCurr(curr)" >
                                        <label class="col-12 border border-dark border-1 py-2 rounded text-start ps-3"  :class="{ 'bg-primary': currSelect == curr}">
                                            <h6 class="p-0 m-0">{{ curr }}</h6>
                                        </label>
                                        <input class="form-check-input d-none" type="radio" title="curr">
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-end gap-3 mt-5 mb-5" style="display: flex!important;">
                                        <button class="btn btn-outline-dark" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                            <span class="">Prev</span>
                                        </button>
                                        <button :class="currSelect==''?'disabled':''" class="btn btn-outline-primary" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                            <span class="">Next -></span>
                                        </button>
                                    </div>
                                </div>

                                <div class="carousel-item fade-carousel-item">
                                    <div class="carousel-caption text-start d-block">
                                        <h2 class="fw-bold align-self-start">Recap of Order</h2>
                                        <p class="text-warning">Attention: once confirmed you will no longer be able to change your choices</p>
                                    </div>
                                    <div class="d-flex flex-column gap-3 align-items-center col-12 gap-5 py-4 py-md-0" style="overflow-x: auto;">
                                        <h2  v-if="modelSelect=='' || currSelect==''" class="text-danger">Please select all the options for these accounts</h2>
                                        <table class="table table-borderless">
                                            <thead>
                                                <tr>
                                                <th scope="col" class="p-1 p-md-3">Size</th>
                                                <th scope="col" class="p-1 p-md-3">Account Model</th>
                                                <th scope="col" class="p-1 p-md-3">Currency</th>
                                                <th scope="col" class="p-1 p-md-3">Price</th>
                                                <th scope="col" class="p-1 p-md-3">Platform</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr >
                                                    <td class="p-1 p-md-3">10K</td>
                                                    <td class="p-1 p-md-3" >{{modelSelect+'%'}} </td>
                                                    <td class="p-1 p-md-3">{{ currSelect }}</td>
                                                    <td class="p-1 p-md-3" v-if="modelSelect!='' && currSelect!='' && bundle==2"><span class="d-block text-decoration-line-through text-danger">{{formatCurrency(parseInt(this.items[`10K${currSelect}${modelSelect}`]))}} </span>   {{ formatCurrency(0) }}<span class="text-success"> FREE </span></td>
                                                    <td class="p-1 p-md-3" v-if="modelSelect!='' && currSelect!='' && bundle==1"><span class=" d-block text-decoration-line-through text-danger">{{formatCurrency(parseInt(this.items[`10K${currSelect}${modelSelect}`]))}} </span>  {{ formatCurrency((this.items[`10K${currSelect}${modelSelect}`])*0.75) }}<span class="text-success"> (-25%) </span></td>
                                                    <td class="p-1 p-md-3">{{ platform }}</td>
                                                </tr>
                                                <tr v-if="bundle==1">
                                                    <td class="p-1 p-md-3">25K</td>
                                                    <td class="p-1 p-md-3" >{{modelSelect+'%'}} </td>
                                                    <td class="p-1 p-md-3">{{ currSelect }}</td>
                                                    <td class="p-1 p-md-3" v-if="modelSelect!='' && currSelect!='' && bundle==1"><span class=" d-block text-decoration-line-through text-danger">{{formatCurrency(parseInt(this.items[`25K${currSelect}${modelSelect}`]))}} </span>  {{ formatCurrency((this.items[`25K${currSelect}${modelSelect}`])*0.75) }}<span class="text-success"> (-25%) </span></td>
                                                    <td class="p-1 p-md-3">{{ platform }}</td>
                                                </tr>
                                                <tr v-if="bundle==2">
                                                    <td class="p-1 p-md-3">50K</td>
                                                    <td class="p-1 p-md-3" >{{modelSelect+'%'}}</td>
                                                    <td class="p-1 p-md-3">{{ currSelect }}</td>
                                                    <td class="p-1 p-md-3" v-if="modelSelect!='' && currSelect!=''"><span class="d-block text-decoration-line-through text-danger">{{formatCurrency(parseInt(this.items[`50K${currSelect}${modelSelect}`])) }} </span> {{ formatCurrency((this.items[`50K${currSelect}${modelSelect}`])*0.75) }} <span class="text-success"> ( -25% )</span></td>
                                                    <td class="p-1 p-md-3" v-else>-</td>
                                                    <td class="p-1 p-md-3">{{ platform }}</td>
                                                </tr>
                                                <tr v-if="bundle==2">
                                                    <td class="p-1 p-md-3">100K</td>
                                                    <td class="p-1 p-md-3" >{{modelSelect+'%'}}</td>
                                                    <td class="p-1 p-md-3">{{ currSelect }}</td>
                                                    <td class="p-1 p-md-3" v-if="modelSelect!='' && currSelect!=''"><span class=" d-block text-decoration-line-through text-danger"> {{formatCurrency(this.items[`100K${currSelect}${modelSelect}`]) }}  </span> {{ formatCurrency((this.items[`100K${currSelect}${modelSelect}`])*0.75) }}<span class="text-success"> ( -25% )</span></td>
                                                    <td class="p-1 p-md-3" v-else>-</td>
                                                    <td class="p-1 p-md-3">{{ platform }}</td>
                                                </tr>
                                                <tr class="fs-4">
                                                    <td class="text-primary">TOTAL</td>
                                                    <td ></td>
                                                    <td></td>
                                                    <td v-if="bundle==2"><span class="d-block text-decoration-line-through text-danger">{{formatCurrency((this.items[`10K${currSelect}${modelSelect}`])+(this.items[`50K${currSelect}${modelSelect}`])+(this.items[`100K${currSelect}${modelSelect}`])) }}  </span> {{ formatCurrency(((this.items[`100K${currSelect}${modelSelect}`])*0.75)+((this.items[`50K${currSelect}${modelSelect}`])*0.75)) }}</td>
                                                    <td v-else-if="bundle==1"><span class="d-block text-decoration-line-through text-danger">{{formatCurrency((this.items[`10K${currSelect}${modelSelect}`])+(this.items[`25K${currSelect}${modelSelect}`])) }}  </span> {{ formatCurrency(((this.items[`10K${currSelect}${modelSelect}`])*0.75)+((this.items[`25K${currSelect}${modelSelect}`])*0.75)) }}</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="d-flex justify-content-start gap-3 my-5 my-md-0" style="display: flex!important;">
                                    <button class="btn btn-outline-dark" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                            <span class="">Prev</span>
                                        </button>
                                    <button @click="addItemToCart()" :class="modelSelect==''?'disabled':currSelect==''?'disabled':platform==''?'disabled': ''" class="btn btn-primary mx-auto" type="button">
                                        <span class="">Confirm</span>
                                    </button>
                                </div>
                                </div>
                                <div class="carousel-item fade-carousel-item">
                                    <div class="carousel-caption text-start d-block">
                                        <h2 class="fw-bold">Thank you for your choices</h2>
                                        <p>To continue paying for the order</p>
                                    </div>
                                    <div class="d-flex flex-row justify-content-center gap-3 align-items-center col-12 gap-5 py-4" v-if="chooseLoginOrRegister">
                                        <button v-if="showLoginButton" class="btn btn-outline-success" @click="RegisterOrLogin(true)">Already Register? LOGIN</button>
                                        <button v-if="showRegisterButton" class="btn btn-outline-primary" @click="RegisterOrLogin(false)">Checkout as Guest</button>
                                    </div>
                                    <div v-if="showLogin==false"  >
                                    <div class="carousel-caption text-start d-block">
                                        <h2 class="fw-bold">Register</h2>
                                    </div>
                                        <form @submit.prevent="createClient()" class="form-material d-flex flex-column gap-2 col-11 col-lg-8 mx-auto">
                                            <div class="form-group">
                                                <input class="form-control border border-1 border-white rounded" placeholder="Name" type="text" v-model="firstName" required id="firstName">
                                            </div>
                                            <div class="form-group">
                                                <input class="form-control border border-1 border-white rounded" placeholder="Last Name" type="text" v-model="lastName" required id="lastName">
                                            </div>
                                            <div class="form-group">
                                                <input class="form-control border border-1 border-white rounded" placeholder="Email" type="email" v-model="email" required id="email" @input="validateEmail()">
                                            </div>
                                            <div class="form-group">
                                                <div class="d-flex border border-1 border-white rounded">
                                                    <input id="password" placeholder="Password" :type="passType ? 'password' : 'text'" class="form-control" v-model="password" required autocomplete="off">
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="d-flex border border-1 border-white rounded">
                                                    <input id="confirmPassword" placeholder="Confirm Password" :type="confType ? 'password' : 'text'" class="form-control" v-model="confirmPassword" required autocomplete="off" @input="validatePsw">
                                                    <span v-if="pswError" class="error text-danger">{{ pswError }}</span>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="d-flex align-items-center gap-2">
                                                    <input type="checkbox" class="form-check-input border border-2 border-light" id="customCheck1" v-model="isChecked">
                                                    <label class="form-check-label" for="customCheck1">I agree to all <a target="_blank" href="https://ofpfunding.com/terms-conditions/">Terms</a></label>
                                                </div>
                                                <p class="text-danger text-uppercase" v-if="checkText != ''">{{ checkText }}</p>
                                            </div>
                                            <div class="form-group text-center">
                                                <button class="btn btn-primary btn-lg w-100 rounded waves-effect waves-light" type="submit">Confirm and Complete</button>
                                            </div>
                                        </form>
                                    </div>
                                    <div v-else-if="showLogin" class="carousel-caption text-start d-block">
                                        <h2 class="fw-bold">Login</h2>
                                        <div v-if="error" class="text-center pb-5 ">
                                            <h3 class="text-danger fw-bold">{{ texterror }}</h3>
                                        </div>
                                        <form class="form-material d-flex flex-column gap-3 col-11 col-lg-7 mx-2 mx-lg-auto" id="loginform" @submit.prevent="logIn()">
                                            <div class="form-group">
                                                <label class="text-white mb-1">Email</label>
                                                <input class="form-control border border-1 border-white rounded" type="email" v-model="email" @input="resetError" required placeholder="Email">
                                            </div>
                                            <div class="form-group">
                                                <label class="text-white mb-1">Password</label>
                                                <div class="d-flex border border-1 border-white rounded align-items-center">
                                                    <input class="form-control" :type="typePsw ? 'password' : 'text'" v-model="password" @input="resetError" required placeholder="Password">
                                                    <i class="icons-Eye-Visible" @click="changeTipePsw()"></i>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <button class="btn btn-primary btn-lg w-100 rounded text-white">Log In & Complete</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <Footer/>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
        </div>

</template>

<script>
import moment from 'moment';
import Sidebar from "./Sidebar";
import Navbar from "./statics/navbar";
import Footer from "./statics/footer";
import Spinner from "./Spinner"
export default {
    components: {Footer, Navbar, Sidebar, Spinner},
    data(){
        return{
            platforms:['MT4', 'MT5'],
            platform:'',
            currency:["EUR","USD","GBP"],
            currSelect:'',
            models:['26', '40', '60', '80'],
            modelSelect:'',
            items:[],
            isLoggedIn: true,
            showLogin:null,
            chooseLoginOrRegister:true,
            error:null,
            asGuest:null,
            showLoginButton:true,
            showRegisterButton:true,
            loading: false,
            total:0,
            bundle:0
        }
    },
    created() {
        this.checkLoginStatus();
    },
    methods:{
        chooseBundle(value){
            this.bundle=value
            this.$nextTick(() => {
                $('#carouselExampleCaptions').carousel('next');
            });
        },
        resetError() {
            this.error = false;
        },
        changeTipePsw(){
            this.typePsw=!this.typePsw
        },
        RegisterOrLogin(value){
            this.chooseLoginOrRegister=false
            this.showLogin=value
        },
        async createClient() {
            if (!this.isChecked) {
                this.checkText = 'Required';
                return;
            }

            const payload = {
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.email,
                password: this.password,
            };

            try {
                const response = await axios.post(`${this.$baseUrl}/customers/createCustomer`, payload)
                if(response.status !== 200) {
                    console.error(response.data.message)
                    return;
                }

                // nuovo customer creato
                this.id = response.data._id;
                this.email = response.data.email;
                this.$cookies.set('login', true, Infinity);
                this.$cookies.set('emailAccount', this.email, Infinity);
                this.isLoggedIn=true;

                try {
                    window.sendinblue.track(
                        'registered_client',
                        {
                            email: this.email,
                            FIRSTNAME: this.firstName,
                            LASTNAME: this.lastName
                        },
                        {
                            data: {
                                email: this.email,
                                FIRSTNAME: this.firstName,
                                LASTNAME: this.lastName
                            }
                        }
                    );
                } catch (e) {
                    console.error('brevo', e);
                }

                console.log('account creato')

                // Aggiungo il cart al cloud
                await this.sendItemsToCart();
            } catch(e) {
                console.error(e);
            }
        },
        getItems() {
            axios.get(`${this.$baseUrl}/ecommerce/getItems`).then((response) => {
                let items = response.data;
                let flattenedArray = items.reduce((acc, element) => {
                element.versions.forEach((version) => {
                    acc.push({
                    id: version.id,
                    price: version.price,
                    });
                });
                return acc;
                }, []);
                this.items = flattenedArray.reduce((acc, item) => {
                acc[item.id] = item.price;
                return acc;
                }, {});
            });
        },
        formatCurrency(value){
            const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 2,
                        });
                        return formatter.format(value);
        },
        selectPlatform(value){
            this.platform=value
        },
        selectCurr(value){
            this.currSelect=value
        },
        selectModel(value){
            this.modelSelect=value
        },
        getCartCustomer() {
            if(this.$cookies.get('emailAccount')){
                axios.get(`${this.$baseUrl}/customers/getCustomerInfo?email=${this.$cookies.get('emailAccount')}`).then((response) => {
                this.client = response.data;
                this.id = this.client._id;
                this.clearCart();
            });
            }

        },
         async sendItemsToCart(){
            await this.getCartCustomer()
            await this.addItemToCart();
            this.$router.push({ path: '/checkout/bundle' });
        },
        clearCart(){
            axios.post(`${this.$baseUrl}/ecommerce/cleanCart`, {
                    "customer_id": this.id,
                }).then((response) => {
                    console.log('vuoto',response)
                }).catch((error) => {
                    console.error(error);
                });
        },
        async addItemToCart() {
            this.loading=true;
            let itemsToAdd=[]
            if(this.bundle==1){
                let ID25 = `25K${this.currSelect}${this.modelSelect}`;
                let ID10 = `10K${this.currSelect}${this.modelSelect}`;
                let item25 = {
                    "customer_id": this.id,
                    "item": ID25,
                    "platform": this.platform,
                    "tag": "black-friday1"
                };
                let item10 = {
                    "customer_id": this.id,
                    "item": ID10,
                    "platform": this.platform,
                    "tag": "black-friday1"
                };

                itemsToAdd = [ item10, item25];

                // Impostazione dei prezzi per il bundle

                item25.price = this.items[ID25];
                item10.price = this.items[ID10];

                item25.priceBundle = this.items[ID25] * 0.75;
                item10.priceBundle = this.items[ID10] * 0.75;
                // Aggiornamento di itemsToAdd con i prezzi
                itemsToAdd = [ item10, item25];
            }else{
                let ID10 = `10K${this.currSelect}${this.modelSelect}`;
                let ID50 = `50K${this.currSelect}${this.modelSelect}`;
                let ID100 = `100K${this.currSelect}${this.modelSelect}`;

                let item10 = {
                    "customer_id": this.id,
                    "item": ID10,
                    "platform": this.platform,
                    "tag": "black-friday"
                };
                let item50 = {
                    "customer_id": this.id,
                    "item": ID50,
                    "platform": this.platform,
                    "tag": "black-friday"
                };
                let item100 = {
                    "customer_id": this.id,
                    "item": ID100,
                    "platform": this.platform,
                    "tag": "black-friday"
                };

                itemsToAdd = [item10, item50, item100];

                // Impostazione dei prezzi per il bundle
                item10.price = this.items[ID10];
                item50.price = this.items[ID50];
                item100.price = this.items[ID100];
                item10.priceBundle = 0;
                item50.priceBundle = this.items[ID50] * 0.75;
                item100.priceBundle = this.items[ID100] * 0.75;
                // Aggiornamento di itemsToAdd con i prezzi
                itemsToAdd = [item10, item50, item100];
            }

            // Salva in API solo se l'utente è loggato
            if (this.isLoggedIn) {
                for (const element of itemsToAdd) {
                    if (this.asGuest) {
                        element.note = "asGuest";
                    }
                    console.log("element", element);
                    try {
                        let config = {
                            method: 'post',
                            maxBodyLength: Infinity,
                            url: `${this.$baseUrl}/ecommerce/cart/addItem`,
                            headers: { 'Content-Type': 'application/json' },
                            data: element
                        };
                        const response = await axios.request(config);
                        console.log(response);
                    } catch (error) {
                        console.error(error);
                    }
                }
            }
            this.loading=false;

            let cartBundle = {
                items: itemsToAdd
            };

            if (!this.isLoggedIn) {
                // Aggiungi la nota 'asGuest' all'oggetto cartBundle
                this.asGuest= true;
                cartBundle.note = "asGuest";
            }
            console.log('Utente loggato: ', this.isLoggedIn);

            localStorage.setItem('cartBundle', JSON.stringify(cartBundle));
            if(this.isLoggedIn){
                this.$router.push({ path: '/checkout/bundle' });
            }else{
                this.$nextTick(() => {
                $('#carouselExampleCaptions').carousel('next');
            });
            }


        },
        async logIn() {
            try {
                const response = await axios.post(`${this.$baseUrl}/customers/login`, {
                    "email": this.email,
                    "password": this.password
                });

                console.log(response);

                if (response.data.status == 200) {
                    this.error = false;
                    this.$cookies.set('login', true, Infinity);
                    this.$cookies.set('emailAccount', this.email, Infinity);
                    window.Laravel.isLoggedin = true;
                    this.isLoggedIn = true;
                    this.id = response.data.customer_id;
                    this.clearCart()
                    // Mando gli items del bundle nel carrello!!
                    await this.sendItemsToCart();
                    // window.location.replace('/home');
                } else {
                    this.error = true;
                    this.handleLoginError(response.data.status);
                }
            } catch (error) {
                console.error(error);
            }
        },
        handleLoginError(status) {
            if (status == 401) {
                this.texterror = 'Wrong Password';
            } else if (status == 400) {
                this.texterror = 'User not found';
                this.chooseLoginOrRegister = true;
                this.showLoginButton = false;
                this.showRegisterButton = true;
            } else if (status == 403) {
                this.texterror = "User not enabled for Login";
            } else {
                this.texterror = "User is not authorized to perform login";
            }
        },
        checkLoginStatus() {
            const emailAccount = this.$cookies.get('emailAccount');
            if (emailAccount === null) {
                this.isLoggedIn = false;
            }
        }
    },
    beforeRouteEnter(to, from, next){
        const currentDate = moment().format('YYYY-MM-DD');
        const startDate = moment('2023-11-18').format('YYYY-MM-DD');
        const endDate = moment('2023-11-24').format('YYYY-MM-DD');
        console.log(currentDate,startDate,endDate)
        if (currentDate >= startDate && currentDate <= endDate) {
            next();
        } else {
            next({ path: '/home' });
        }
    },

    mounted(){
        const carousel = document.getElementById('carouselExampleCaptions');
        if (carousel) {
        carousel.addEventListener('slide.bs.carousel', this.handleSlide);
        }
        this.getItems()
        this.getCartCustomer()
        localStorage.removeItem('cartBundle')
    }
}
</script>

<style scoped>
.carousel-caption {
    position:static;
    top: 0%;
    color: #fff;
    text-align: center;
}
td,label{
    color: white!important;
}
input{
    background-color: transparent!important;
}
select{
    background-color: transparent!important;
}
select>option{
    background-color: #1e1f41;
}
.fade-carousel-item {
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }

  .fade-carousel-item.active {
    opacity: 1;
    transition: opacity 0.3s ease-in;
  }

  .bundle-box{
      border-color:#fb9678!important;
  }
</style>
